@use 'sass:math';

$icons-svg-origin: (
  total-width: 754px,
  total-height: 727px,
  padding: 4px,
  imageSrc: '../img/icons-svg.svg'
);

$icons-svg-vars: (
  'arrow-right': (
    offset-x: -616px,
    offset-y: -675px,
    width: 16px,
    height: 16px
  ),
  'arrow-right_fill': (
    offset-x: -526px,
    offset-y: -448px,
    width: 8px,
    height: 8px
  ),
  'arrow-right_half': (
    offset-x: -640px,
    offset-y: -675px,
    width: 16px,
    height: 16px
  ),
  'arrow_white': (
    offset-x: -57px,
    offset-y: -467px,
    width: 48px,
    height: 48px
  ),
  'arrow_white-24': (
    offset-x: -384px,
    offset-y: -367px,
    width: 24px,
    height: 24px
  ),
  'back_layer': (
    offset-x: -590px,
    offset-y: -226px,
    width: 32px,
    height: 32px
  ),
  'bell': (
    offset-x: -416px,
    offset-y: -367px,
    width: 24px,
    height: 24px
  ),
  'bi_auction': (
    offset-x: -138px,
    offset-y: -60px,
    width: 90px,
    height: 14px
  ),
  'bi_gmarket': (
    offset-x: 0px,
    offset-y: -291px,
    width: 77px,
    height: 20px
  ),
  'bubble_tap_orange': (
    offset-x: -730px,
    offset-y: -599px,
    width: 14px,
    height: 14px
  ),
  'calendar': (
    offset-x: -698px,
    offset-y: -360px,
    width: 20px,
    height: 20px
  ),
  'calendar_arrow': (
    offset-x: -590px,
    offset-y: -266px,
    width: 32px,
    height: 32px
  ),
  'calendar_arrow_year': (
    offset-x: -590px,
    offset-y: -306px,
    width: 32px,
    height: 32px
  ),
  'cancel-20': (
    offset-x: -698px,
    offset-y: -388px,
    width: 20px,
    height: 20px
  ),
  'checked': (
    offset-x: -698px,
    offset-y: -416px,
    width: 20px,
    height: 20px
  ),
  'checked_active': (
    offset-x: -698px,
    offset-y: -444px,
    width: 20px,
    height: 20px
  ),
  'checked_disabled': (
    offset-x: -698px,
    offset-y: -472px,
    width: 20px,
    height: 20px
  ),
  'close_image': (
    offset-x: -540px,
    offset-y: -571px,
    width: 24px,
    height: 24px
  ),
  'close_layer': (
    offset-x: -590px,
    offset-y: -346px,
    width: 32px,
    height: 32px
  ),
  'close_tooltip': (
    offset-x: -664px,
    offset-y: -675px,
    width: 16px,
    height: 16px
  ),
  'complete': (
    offset-x: -572px,
    offset-y: -571px,
    width: 24px,
    height: 24px
  ),
  'count_best': (
    offset-x: -634px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'count_bg': (
    offset-x: -220px,
    offset-y: -97px,
    width: 44px,
    height: 44px
  ),
  'count_bg_active': (
    offset-x: -225px,
    offset-y: -467px,
    width: 44px,
    height: 44px
  ),
  'count_exposure': (
    offset-x: -634px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'count_manage': (
    offset-x: -634px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'count_strong': (
    offset-x: -634px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'count_unexposed': (
    offset-x: -634px,
    offset-y: -128px,
    width: 24px,
    height: 24px
  ),
  'crop_image': (
    offset-x: -634px,
    offset-y: -160px,
    width: 24px,
    height: 24px
  ),
  'deal_equals': (
    offset-x: -217px,
    offset-y: -208px,
    width: 17px,
    height: 16px
  ),
  'deal_minus': (
    offset-x: -688px,
    offset-y: -675px,
    width: 16px,
    height: 16px
  ),
  'deal_plus': (
    offset-x: -242px,
    offset-y: -208px,
    width: 17px,
    height: 16px
  ),
  'delivery_delete': (
    offset-x: 0px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'download_black': (
    offset-x: -24px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'download_gray': (
    offset-x: -698px,
    offset-y: -500px,
    width: 20px,
    height: 20px
  ),
  'download_large_black': (
    offset-x: -698px,
    offset-y: -264px,
    width: 21px,
    height: 20px
  ),
  'download_large_white': (
    offset-x: -698px,
    offset-y: -292px,
    width: 21px,
    height: 20px
  ),
  'download_white': (
    offset-x: -48px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'dropdown_fill': (
    offset-x: -72px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'emoji': (
    offset-x: -381px,
    offset-y: -467px,
    width: 40px,
    height: 41px
  ),
  'emoji-dart': (
    offset-x: 0px,
    offset-y: -523px,
    width: 40px,
    height: 40px
  ),
  'emoji_bsd': (
    offset-x: -429px,
    offset-y: -467px,
    width: 40px,
    height: 41px
  ),
  'emoji_clapping': (
    offset-x: -476px,
    offset-y: -571px,
    width: 24px,
    height: 25px
  ),
  'emoji_crown': (
    offset-x: -508px,
    offset-y: -571px,
    width: 24px,
    height: 25px
  ),
  'emoji_cry': (
    offset-x: -48px,
    offset-y: -523px,
    width: 40px,
    height: 40px
  ),
  'emoji_gift': (
    offset-x: -477px,
    offset-y: -467px,
    width: 40px,
    height: 41px
  ),
  'emoji_goodsflow': (
    offset-x: -132px,
    offset-y: -97px,
    width: 80px,
    height: 80px
  ),
  'emoji_search': (
    offset-x: -96px,
    offset-y: -523px,
    width: 40px,
    height: 40px
  ),
  'emoji_smile': (
    offset-x: -525px,
    offset-y: -467px,
    width: 40px,
    height: 41px
  ),
  'equals': (
    offset-x: -96px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'exclm_mark-24': (
    offset-x: -634px,
    offset-y: -192px,
    width: 24px,
    height: 24px
  ),
  'exclm_mark_red-24': (
    offset-x: -634px,
    offset-y: -224px,
    width: 24px,
    height: 24px
  ),
  'exclm_mark_transparent-24': (
    offset-x: -634px,
    offset-y: -256px,
    width: 24px,
    height: 24px
  ),
  'expand_image': (
    offset-x: -634px,
    offset-y: -288px,
    width: 24px,
    height: 24px
  ),
  'extra_equals': (
    offset-x: -698px,
    offset-y: -110px,
    width: 22px,
    height: 22px
  ),
  'extra_minus': (
    offset-x: -698px,
    offset-y: -140px,
    width: 22px,
    height: 22px
  ),
  'extra_plus': (
    offset-x: -698px,
    offset-y: -170px,
    width: 22px,
    height: 22px
  ),
  'flag_star': (
    offset-x: -698px,
    offset-y: -528px,
    width: 20px,
    height: 20px
  ),
  'flag_star_active': (
    offset-x: -698px,
    offset-y: -556px,
    width: 20px,
    height: 20px
  ),
  'ico_checked_blue-16': (
    offset-x: -267px,
    offset-y: -208px,
    width: 17px,
    height: 16px
  ),
  'ico_checked_blue-20': (
    offset-x: -698px,
    offset-y: -584px,
    width: 20px,
    height: 20px
  ),
  'ico_checked_blue-48': (
    offset-x: -113px,
    offset-y: -467px,
    width: 48px,
    height: 48px
  ),
  'ico_checked_white-16': (
    offset-x: -120px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'ico_checked_white-20': (
    offset-x: -698px,
    offset-y: -612px,
    width: 20px,
    height: 20px
  ),
  'ico_close_black-24': (
    offset-x: -634px,
    offset-y: -320px,
    width: 24px,
    height: 24px
  ),
  'icon-check-black-16': (
    offset-x: -144px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon-check-black-small-16': (
    offset-x: -168px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon-check-blue-16': (
    offset-x: -192px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon-check-blue-small-16': (
    offset-x: -216px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon-check-gray-small-16': (
    offset-x: -240px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_ADcenter': (
    offset-x: -390px,
    offset-y: -403px,
    width: 56px,
    height: 56px
  ),
  'icon_ADcenter_on': (
    offset-x: -454px,
    offset-y: -403px,
    width: 56px,
    height: 56px
  ),
  'icon__board-contact': (
    offset-x: -634px,
    offset-y: -352px,
    width: 24px,
    height: 24px
  ),
  'icon__emergency-message': (
    offset-x: -634px,
    offset-y: -384px,
    width: 24px,
    height: 24px
  ),
  'icon__imminent': (
    offset-x: -634px,
    offset-y: -416px,
    width: 24px,
    height: 24px
  ),
  'icon_abbreviation': (
    offset-x: -590px,
    offset-y: -386px,
    width: 32px,
    height: 32px
  ),
  'icon_accordion': (
    offset-x: -634px,
    offset-y: -448px,
    width: 24px,
    height: 24px
  ),
  'icon_action-allkill': (
    offset-x: 0px,
    offset-y: -403px,
    width: 57px,
    height: 56px
  ),
  'icon_after': (
    offset-x: -634px,
    offset-y: -480px,
    width: 24px,
    height: 24px
  ),
  'icon_ai': (
    offset-x: -526px,
    offset-y: 0px,
    width: 56px,
    height: 56px
  ),
  'icon_ai-sales': (
    offset-x: -65px,
    offset-y: -403px,
    width: 57px,
    height: 56px
  ),
  'icon_ai_on': (
    offset-x: -526px,
    offset-y: -64px,
    width: 56px,
    height: 56px
  ),
  'icon_alert': (
    offset-x: -264px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_alert-blue': (
    offset-x: -288px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_alert-blue-fill': (
    offset-x: -312px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_allkill': (
    offset-x: -127px,
    offset-y: -367px,
    width: 40px,
    height: 27px
  ),
  'icon_allkill_main': (
    offset-x: -175px,
    offset-y: -367px,
    width: 36px,
    height: 24px
  ),
  'icon_apply_done': (
    offset-x: -634px,
    offset-y: -512px,
    width: 24px,
    height: 24px
  ),
  'icon_apply_imminent': (
    offset-x: -634px,
    offset-y: -544px,
    width: 24px,
    height: 24px
  ),
  'icon_apply_new': (
    offset-x: -634px,
    offset-y: -576px,
    width: 24px,
    height: 24px
  ),
  'icon_apply_progressing': (
    offset-x: 0px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_apply_rejected': (
    offset-x: -32px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_arrow': (
    offset-x: -590px,
    offset-y: -426px,
    width: 32px,
    height: 32px
  ),
  'icon_arrow-up': (
    offset-x: -590px,
    offset-y: -466px,
    width: 32px,
    height: 32px
  ),
  'icon_arrow-up-hover': (
    offset-x: -590px,
    offset-y: -506px,
    width: 32px,
    height: 32px
  ),
  'icon_arrow_active': (
    offset-x: -698px,
    offset-y: -640px,
    width: 20px,
    height: 20px
  ),
  'icon_arrow_apply': (
    offset-x: -336px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_arrow_double': (
    offset-x: -121px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_arrow_down-12': (
    offset-x: -730px,
    offset-y: -621px,
    width: 12px,
    height: 12px
  ),
  'icon_arrow_down-12-500': (
    offset-x: -730px,
    offset-y: -641px,
    width: 12px,
    height: 12px
  ),
  'icon_arrow_down-16-800': (
    offset-x: -360px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_arrow_down-20': (
    offset-x: -149px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_arrow_down-24': (
    offset-x: -64px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_arrow_inactive': (
    offset-x: -177px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_ask': (
    offset-x: -272px,
    offset-y: 0px,
    width: 80px,
    height: 80px
  ),
  'icon_auction': (
    offset-x: -96px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_badge': (
    offset-x: -384px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_badge_delivery': (
    offset-x: -205px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_banner': (
    offset-x: -233px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_bell': (
    offset-x: -128px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_big-promotion': (
    offset-x: -130px,
    offset-y: -403px,
    width: 57px,
    height: 56px
  ),
  'icon_bsd-lack': (
    offset-x: -277px,
    offset-y: -467px,
    width: 44px,
    height: 44px
  ),
  'icon_bsd-price': (
    offset-x: -329px,
    offset-y: -467px,
    width: 44px,
    height: 44px
  ),
  'icon_calculate': (
    offset-x: -160px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_calculate_done': (
    offset-x: -192px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_camera': (
    offset-x: -224px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_cancel': (
    offset-x: -256px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_cancel_request': (
    offset-x: -288px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_captcha-refresh': (
    offset-x: -261px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_captcha-sound': (
    offset-x: -289px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_chart': (
    offset-x: -408px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_chart--clicked': (
    offset-x: -432px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_chat-consult': (
    offset-x: -272px,
    offset-y: -88px,
    width: 80px,
    height: 80px
  ),
  'icon_chevron_right': (
    offset-x: -456px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_chevron_right-20': (
    offset-x: -317px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_chevron_right-blue': (
    offset-x: -480px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_chevron_right-tiny-gray': (
    offset-x: -542px,
    offset-y: -448px,
    width: 8px,
    height: 8px
  ),
  'icon_clamp': (
    offset-x: -504px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_close-white': (
    offset-x: -548px,
    offset-y: -523px,
    width: 32px,
    height: 32px
  ),
  'icon_comment': (
    offset-x: -528px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_complete': (
    offset-x: -320px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_cs-center': (
    offset-x: -552px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_customer_new': (
    offset-x: -352px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_data-del-gray-16': (
    offset-x: -576px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_data_small': (
    offset-x: -144px,
    offset-y: -523px,
    width: 40px,
    height: 40px
  ),
  'icon_deadline': (
    offset-x: -384px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_decrease_value': (
    offset-x: -640px,
    offset-y: -611px,
    width: 16px,
    height: 17px
  ),
  'icon_delay': (
    offset-x: -416px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_delete': (
    offset-x: -345px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_delete-16': (
    offset-x: -600px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_delete_16': (
    offset-x: -624px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_delete_small': (
    offset-x: -648px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_delivery': (
    offset-x: -448px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_delivery_done': (
    offset-x: -480px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_delivery_penalty': (
    offset-x: -512px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_delivery_today': (
    offset-x: -544px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_deposit': (
    offset-x: -576px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_drag': (
    offset-x: -672px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_edit': (
    offset-x: -373px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_edit_card': (
    offset-x: -448px,
    offset-y: 0px,
    width: 70px,
    height: 52px
  ),
  'icon_edit_gallery': (
    offset-x: 0px,
    offset-y: -319px,
    width: 58px,
    height: 76px
  ),
  'icon_edit_gallery-three': (
    offset-x: 0px,
    offset-y: -97px,
    width: 58px,
    height: 82px
  ),
  'icon_edit_horizontal': (
    offset-x: -66px,
    offset-y: -97px,
    width: 58px,
    height: 82px
  ),
  'icon_edit_image': (
    offset-x: -696px,
    offset-y: -703px,
    width: 16px,
    height: 16px
  ),
  'icon_edit_list': (
    offset-x: -66px,
    offset-y: -319px,
    width: 53px,
    height: 76px
  ),
  'icon_edit_single-row': (
    offset-x: -448px,
    offset-y: -260px,
    width: 64px,
    height: 32px
  ),
  'icon_edit_square': (
    offset-x: -448px,
    offset-y: -186px,
    width: 58px,
    height: 66px
  ),
  'icon_edit_square-deal': (
    offset-x: -448px,
    offset-y: -300px,
    width: 64px,
    height: 48px
  ),
  'icon_edit_text': (
    offset-x: -139px,
    offset-y: -208px,
    width: 18px,
    height: 18px
  ),
  'icon_edit_title': (
    offset-x: -349px,
    offset-y: -319px,
    width: 40px,
    height: 23px
  ),
  'icon_edit_title-center': (
    offset-x: -397px,
    offset-y: -319px,
    width: 40px,
    height: 23px
  ),
  'icon_edit_two-row': (
    offset-x: -448px,
    offset-y: -60px,
    width: 64px,
    height: 70px
  ),
  'icon_editor_component': (
    offset-x: -608px,
    offset-y: -611px,
    width: 24px,
    height: 24px
  ),
  'icon_editor_header': (
    offset-x: -666px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'icon_editor_theme': (
    offset-x: -666px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'icon_equals': (
    offset-x: -730px,
    offset-y: 0px,
    width: 16px,
    height: 16px
  ),
  'icon_esm': (
    offset-x: -666px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'icon_excel': (
    offset-x: -666px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'icon_exchange': (
    offset-x: -666px,
    offset-y: -128px,
    width: 24px,
    height: 24px
  ),
  'icon_exchange_delaying': (
    offset-x: -666px,
    offset-y: -160px,
    width: 24px,
    height: 24px
  ),
  'icon_exclam-mark': (
    offset-x: -165px,
    offset-y: -208px,
    width: 18px,
    height: 18px
  ),
  'icon_expand': (
    offset-x: -401px,
    offset-y: -291px,
    width: 20px,
    height: 20px
  ),
  'icon_exposure_limit': (
    offset-x: -219px,
    offset-y: -367px,
    width: 25px,
    height: 24px
  ),
  'icon_extra-service': (
    offset-x: -526px,
    offset-y: -128px,
    width: 56px,
    height: 56px
  ),
  'icon_extra-service-purple': (
    offset-x: -526px,
    offset-y: -192px,
    width: 56px,
    height: 56px
  ),
  'icon_extra-service_on': (
    offset-x: -526px,
    offset-y: -256px,
    width: 56px,
    height: 56px
  ),
  'icon_fail_red': (
    offset-x: -604px,
    offset-y: -571px,
    width: 20px,
    height: 20px
  ),
  'icon_favorites': (
    offset-x: -440px,
    offset-y: -571px,
    width: 28px,
    height: 28px
  ),
  'icon_gift': (
    offset-x: -666px,
    offset-y: -192px,
    width: 24px,
    height: 24px
  ),
  'icon_gmarket': (
    offset-x: -666px,
    offset-y: -224px,
    width: 24px,
    height: 24px
  ),
  'icon_gmarket-superdeal': (
    offset-x: -526px,
    offset-y: -320px,
    width: 56px,
    height: 56px
  ),
  'icon_heart': (
    offset-x: -666px,
    offset-y: -256px,
    width: 24px,
    height: 24px
  ),
  'icon_help-hover': (
    offset-x: 0px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'icon_holding': (
    offset-x: -666px,
    offset-y: -288px,
    width: 24px,
    height: 24px
  ),
  'icon_id': (
    offset-x: 0px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_id-on': (
    offset-x: -28px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_increase_value': (
    offset-x: -672px,
    offset-y: -643px,
    width: 16px,
    height: 17px
  ),
  'icon_items': (
    offset-x: -56px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_last': (
    offset-x: -730px,
    offset-y: -576px,
    width: 9px,
    height: 15px
  ),
  'icon_limit_product': (
    offset-x: -666px,
    offset-y: -320px,
    width: 24px,
    height: 24px
  ),
  'icon_link': (
    offset-x: -730px,
    offset-y: -24px,
    width: 16px,
    height: 16px
  ),
  'icon_link_gray': (
    offset-x: -730px,
    offset-y: -48px,
    width: 16px,
    height: 16px
  ),
  'icon_lottie_arr': (
    offset-x: -730px,
    offset-y: -675px,
    width: 10px,
    height: 10px
  ),
  'icon_main_refresh': (
    offset-x: -84px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_menu-check': (
    offset-x: -252px,
    offset-y: -367px,
    width: 25px,
    height: 24px
  ),
  'icon_menu-check_white': (
    offset-x: -666px,
    offset-y: -352px,
    width: 24px,
    height: 24px
  ),
  'icon_minus': (
    offset-x: -730px,
    offset-y: -72px,
    width: 16px,
    height: 16px
  ),
  'icon_missing': (
    offset-x: -666px,
    offset-y: -384px,
    width: 24px,
    height: 24px
  ),
  'icon_mobile': (
    offset-x: -40px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'icon_money': (
    offset-x: -730px,
    offset-y: -96px,
    width: 16px,
    height: 16px
  ),
  'icon_move': (
    offset-x: -112px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_nav_beta': (
    offset-x: -85px,
    offset-y: -291px,
    width: 28px,
    height: 20px
  ),
  'icon_nav_new': (
    offset-x: -140px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_nodata': (
    offset-x: -169px,
    offset-y: -467px,
    width: 48px,
    height: 48px
  ),
  'icon_nodata_gray': (
    offset-x: 0px,
    offset-y: -467px,
    width: 49px,
    height: 48px
  ),
  'icon_nodata_gray_small': (
    offset-x: -192px,
    offset-y: -523px,
    width: 40px,
    height: 40px
  ),
  'icon_overseas': (
    offset-x: -666px,
    offset-y: -416px,
    width: 24px,
    height: 24px
  ),
  'icon_pass': (
    offset-x: -168px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_pass-on': (
    offset-x: -196px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_pc': (
    offset-x: -80px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'icon_pencil': (
    offset-x: -730px,
    offset-y: -120px,
    width: 16px,
    height: 16px
  ),
  'icon_person': (
    offset-x: -666px,
    offset-y: -448px,
    width: 24px,
    height: 24px
  ),
  'icon_playauto-esmplus': (
    offset-x: 0px,
    offset-y: -187px,
    width: 80px,
    height: 13px
  ),
  'icon_playauto-smiledelivery': (
    offset-x: 0px,
    offset-y: -208px,
    width: 80px,
    height: 19px
  ),
  'icon_plus': (
    offset-x: -292px,
    offset-y: -208px,
    width: 17px,
    height: 16px
  ),
  'icon_plus_black': (
    offset-x: -730px,
    offset-y: -144px,
    width: 16px,
    height: 16px
  ),
  'icon_plus_white': (
    offset-x: -730px,
    offset-y: -168px,
    width: 16px,
    height: 16px
  ),
  'icon_point-empty': (
    offset-x: -448px,
    offset-y: -138px,
    width: 68px,
    height: 16px
  ),
  'icon_point-full': (
    offset-x: -448px,
    offset-y: -162px,
    width: 68px,
    height: 16px
  ),
  'icon_poll': (
    offset-x: -590px,
    offset-y: -158px,
    width: 34px,
    height: 34px
  ),
  'icon_power-click': (
    offset-x: -526px,
    offset-y: -384px,
    width: 56px,
    height: 56px
  ),
  'icon_power-click-purple': (
    offset-x: -195px,
    offset-y: -403px,
    width: 57px,
    height: 56px
  ),
  'icon_power-click_on': (
    offset-x: -260px,
    offset-y: -403px,
    width: 57px,
    height: 56px
  ),
  'icon_prev-white': (
    offset-x: -120px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'icon_price-comparison': (
    offset-x: -325px,
    offset-y: -403px,
    width: 57px,
    height: 56px
  ),
  'icon_product': (
    offset-x: -666px,
    offset-y: -480px,
    width: 24px,
    height: 24px
  ),
  'icon_product_cancel': (
    offset-x: -666px,
    offset-y: -512px,
    width: 24px,
    height: 24px
  ),
  'icon_product_combine': (
    offset-x: -666px,
    offset-y: -544px,
    width: 24px,
    height: 24px
  ),
  'icon_product_delay': (
    offset-x: -666px,
    offset-y: -576px,
    width: 24px,
    height: 24px
  ),
  'icon_product_imminent': (
    offset-x: -666px,
    offset-y: -608px,
    width: 24px,
    height: 24px
  ),
  'icon_product_return': (
    offset-x: -191px,
    offset-y: -208px,
    width: 18px,
    height: 18px
  ),
  'icon_receipt-long': (
    offset-x: 0px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_refresh': (
    offset-x: -224px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_refund_delaying': (
    offset-x: -32px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_refund_request': (
    offset-x: -64px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_resize': (
    offset-x: -590px,
    offset-y: -546px,
    width: 20px,
    height: 5px
  ),
  'icon_right_arrow': (
    offset-x: -730px,
    offset-y: -192px,
    width: 16px,
    height: 16px
  ),
  'icon_sale': (
    offset-x: -285px,
    offset-y: -367px,
    width: 25px,
    height: 24px
  ),
  'icon_sale_ends': (
    offset-x: -318px,
    offset-y: -367px,
    width: 25px,
    height: 24px
  ),
  'icon_sale_not': (
    offset-x: -96px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_sale_star': (
    offset-x: -128px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_sale_stop': (
    offset-x: -160px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_sample': (
    offset-x: -160px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'icon_search': (
    offset-x: -252px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_search-gray': (
    offset-x: -192px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_search-total-fill': (
    offset-x: -730px,
    offset-y: -216px,
    width: 16px,
    height: 16px
  ),
  'icon_second_off': (
    offset-x: 0px,
    offset-y: -235px,
    width: 80px,
    height: 48px
  ),
  'icon_second_on': (
    offset-x: -88px,
    offset-y: -235px,
    width: 80px,
    height: 48px
  ),
  'icon_selected': (
    offset-x: -240px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_approval': (
    offset-x: -284px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_approval_on': (
    offset-x: -328px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_collect': (
    offset-x: -372px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_collect_on': (
    offset-x: -416px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_exchange': (
    offset-x: -460px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_exchange_on': (
    offset-x: -504px,
    offset-y: -523px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_on': (
    offset-x: -590px,
    offset-y: 0px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_pending': (
    offset-x: -590px,
    offset-y: -44px,
    width: 36px,
    height: 36px
  ),
  'icon_selected_pending_on': (
    offset-x: -590px,
    offset-y: -88px,
    width: 36px,
    height: 36px
  ),
  'icon_seller-agree': (
    offset-x: -224px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_showing': (
    offset-x: -256px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_showing_end': (
    offset-x: -288px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_showing_stop': (
    offset-x: -320px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_single_off': (
    offset-x: -176px,
    offset-y: -235px,
    width: 80px,
    height: 48px
  ),
  'icon_single_on': (
    offset-x: -264px,
    offset-y: -235px,
    width: 80px,
    height: 48px
  ),
  'icon_smile-delivery': (
    offset-x: -360px,
    offset-y: 0px,
    width: 80px,
    height: 80px
  ),
  'icon_sold_out': (
    offset-x: -352px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_sort': (
    offset-x: -730px,
    offset-y: -240px,
    width: 16px,
    height: 16px
  ),
  'icon_sort_default': (
    offset-x: -730px,
    offset-y: -264px,
    width: 16px,
    height: 16px
  ),
  'icon_sort_down': (
    offset-x: -317px,
    offset-y: -208px,
    width: 17px,
    height: 16px
  ),
  'icon_star-fill': (
    offset-x: -384px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_star-line': (
    offset-x: -416px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_stock_less': (
    offset-x: -448px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_store': (
    offset-x: -351px,
    offset-y: -367px,
    width: 25px,
    height: 24px
  ),
  'icon_superdeal': (
    offset-x: -448px,
    offset-y: -356px,
    width: 51px,
    height: 27px
  ),
  'icon_superdeal_main': (
    offset-x: -295px,
    offset-y: -319px,
    width: 46px,
    height: 24px
  ),
  'icon_thumbsup': (
    offset-x: -730px,
    offset-y: -288px,
    width: 16px,
    height: 16px
  ),
  'icon_today': (
    offset-x: -480px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_tomorrow': (
    offset-x: -512px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_total': (
    offset-x: -544px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_trash': (
    offset-x: -280px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_truck-fast': (
    offset-x: -576px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_tv-play': (
    offset-x: -608px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_type_allKill': (
    offset-x: -308px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_type_superdeal': (
    offset-x: -336px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'icon_w': (
    offset-x: -640px,
    offset-y: -643px,
    width: 24px,
    height: 24px
  ),
  'icon_wide': (
    offset-x: -200px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'image_auction': (
    offset-x: 0px,
    offset-y: 0px,
    width: 264px,
    height: 22px
  ),
  'image_gmarket': (
    offset-x: 0px,
    offset-y: -30px,
    width: 264px,
    height: 22px
  ),
  'information_mark': (
    offset-x: -730px,
    offset-y: -312px,
    width: 16px,
    height: 16px
  ),
  'information_mark_blue': (
    offset-x: -730px,
    offset-y: -336px,
    width: 16px,
    height: 16px
  ),
  'information_mark_blue_line': (
    offset-x: -730px,
    offset-y: -360px,
    width: 16px,
    height: 16px
  ),
  'information_mark_bsd': (
    offset-x: -730px,
    offset-y: -384px,
    width: 16px,
    height: 16px
  ),
  'information_mark_gray': (
    offset-x: -730px,
    offset-y: -408px,
    width: 16px,
    height: 16px
  ),
  'information_mark_login': (
    offset-x: -364px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'information_mark_red': (
    offset-x: -730px,
    offset-y: -432px,
    width: 16px,
    height: 16px
  ),
  'information_mark_yellow': (
    offset-x: -698px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'legend_bar_allkill': (
    offset-x: -590px,
    offset-y: -132px,
    width: 36px,
    height: 18px
  ),
  'legend_bar_allkill_main': (
    offset-x: -590px,
    offset-y: -200px,
    width: 34px,
    height: 18px
  ),
  'legend_bar_blue': (
    offset-x: -236px,
    offset-y: -60px,
    width: 20px,
    height: 12px
  ),
  'legend_bar_blue2': (
    offset-x: -169px,
    offset-y: -187px,
    width: 20px,
    height: 12px
  ),
  'legend_bar_gray': (
    offset-x: -197px,
    offset-y: -187px,
    width: 20px,
    height: 12px
  ),
  'legend_bar_mint': (
    offset-x: -698px,
    offset-y: -320px,
    width: 21px,
    height: 12px
  ),
  'legend_bar_purple': (
    offset-x: -698px,
    offset-y: -340px,
    width: 21px,
    height: 12px
  ),
  'legend_bar_red': (
    offset-x: -225px,
    offset-y: -187px,
    width: 20px,
    height: 12px
  ),
  'legend_bar_superdeal': (
    offset-x: -220px,
    offset-y: -149px,
    width: 44px,
    height: 18px
  ),
  'legend_bar_superdeal_main': (
    offset-x: -88px,
    offset-y: -208px,
    width: 43px,
    height: 18px
  ),
  'legend_blue': (
    offset-x: -698px,
    offset-y: -200px,
    width: 22px,
    height: 8px
  ),
  'legend_dash': (
    offset-x: -698px,
    offset-y: -216px,
    width: 22px,
    height: 8px
  ),
  'legend_gray': (
    offset-x: -698px,
    offset-y: -232px,
    width: 22px,
    height: 8px
  ),
  'legend_gray-dot': (
    offset-x: -698px,
    offset-y: -248px,
    width: 22px,
    height: 8px
  ),
  'legend_line': (
    offset-x: -698px,
    offset-y: -64px,
    width: 23px,
    height: 2px
  ),
  'legend_mint': (
    offset-x: -698px,
    offset-y: -74px,
    width: 23px,
    height: 10px
  ),
  'legend_purple': (
    offset-x: -698px,
    offset-y: -92px,
    width: 23px,
    height: 10px
  ),
  'logo_a': (
    offset-x: -360px,
    offset-y: -88px,
    width: 80px,
    height: 20px
  ),
  'logo_a_on': (
    offset-x: -360px,
    offset-y: -116px,
    width: 80px,
    height: 20px
  ),
  'logo_e': (
    offset-x: -360px,
    offset-y: -144px,
    width: 80px,
    height: 20px
  ),
  'logo_e_on': (
    offset-x: -360px,
    offset-y: -172px,
    width: 80px,
    height: 20px
  ),
  'logo_footer': (
    offset-x: -360px,
    offset-y: -256px,
    width: 78px,
    height: 20px
  ),
  'logo_g': (
    offset-x: -360px,
    offset-y: -200px,
    width: 80px,
    height: 20px
  ),
  'logo_g_on': (
    offset-x: -360px,
    offset-y: -228px,
    width: 80px,
    height: 20px
  ),
  'logo_layer_company': (
    offset-x: -88px,
    offset-y: -187px,
    width: 73px,
    height: 12px
  ),
  'logo_playauto': (
    offset-x: 0px,
    offset-y: -60px,
    width: 130px,
    height: 29px
  ),
  'minus': (
    offset-x: -730px,
    offset-y: -456px,
    width: 16px,
    height: 16px
  ),
  'pagination_arrow': (
    offset-x: -240px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'pagination_arrow_disabled': (
    offset-x: -280px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'person': (
    offset-x: -730px,
    offset-y: -480px,
    width: 16px,
    height: 16px
  ),
  'plus': (
    offset-x: -320px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'popup_arrow': (
    offset-x: -360px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'popup_arrow_disabled': (
    offset-x: -400px,
    offset-y: -571px,
    width: 32px,
    height: 32px
  ),
  'popup_refresh': (
    offset-x: -730px,
    offset-y: -504px,
    width: 16px,
    height: 16px
  ),
  'question_mark': (
    offset-x: -698px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'refresh': (
    offset-x: -392px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'refresh_hover': (
    offset-x: -420px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'shadow-bubble': (
    offset-x: -730px,
    offset-y: -528px,
    width: 16px,
    height: 16px
  ),
  'tab_star': (
    offset-x: -448px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'tab_star_active': (
    offset-x: -476px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'tab_star_disabled': (
    offset-x: -504px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'table_calculator': (
    offset-x: -532px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'table_calculator_hover': (
    offset-x: -560px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'toggle_auction': (
    offset-x: -127px,
    offset-y: -319px,
    width: 76px,
    height: 40px
  ),
  'toggle_gmarket': (
    offset-x: -211px,
    offset-y: -319px,
    width: 76px,
    height: 40px
  ),
  'tooltip-bubble_triangle': (
    offset-x: -558px,
    offset-y: -448px,
    width: 8px,
    height: 8px
  ),
  'tooltip_triangle': (
    offset-x: -730px,
    offset-y: -693px,
    width: 9px,
    height: 5px
  ),
  'tooltip_triangle_large': (
    offset-x: -730px,
    offset-y: -661px,
    width: 12px,
    height: 6px
  ),
  'tooltip_triangle_small': (
    offset-x: -730px,
    offset-y: -706px,
    width: 9px,
    height: 5px
  ),
  'triangle_down_fill': (
    offset-x: -588px,
    offset-y: -675px,
    width: 20px,
    height: 20px
  ),
  'upload_white': (
    offset-x: -730px,
    offset-y: -552px,
    width: 16px,
    height: 16px
  ),
);
@function get-ratio($width, $height) {
    @return parse-int(math.div($width, $height));
}

@function get-scale($width, $new-width) {
    @return parse-int(math.div($new-width, $width));
}

@mixin useSvg-icons($image, $size: false) {
  $image-origin: $icons-svg-origin;
  $image-var: map-get($icons-svg-vars, $image);
  $image-path: map-get($image-origin, 'imageSrc');
  $padding: map-get($icons-svg-origin, 'padding');
  $aspectRatio: math.div(map-get($image-var, 'height'), map-get($image-var, 'width'));
  $sizeRatio: math.div($size, map-get($image-var, 'width'));

  @if($size){
    @if(unitless($size)){
      @warn '"#{$size}" of "#{$image}" is unitless value. Please insert value with unit(px)';
    }
    @else {
      width: $size;
      height: $size * $aspectRatio;
      background-image: url($image-path);
      background-size: (map-get($image-origin, 'total-width') * $sizeRatio) (map-get($image-origin, 'total-height') * $sizeRatio);
      background-position: ((map-get($image-var, 'offset-x') - $padding) * $sizeRatio) ((map-get($image-var, 'offset-y') - $padding) * $sizeRatio);
    }
  }
  @else {
    width: map-get($image-var, 'width');
    height: map-get($image-var, 'height');
    background-image: url($image-path);
    background-size: map-get($image-origin, 'total-width') map-get($image-origin, 'total-height');
    background-position: (map-get($image-var, 'offset-x') - $padding) (map-get($image-var, 'offset-y') - $padding);
  }
  background-repeat: no-repeat;
}
