.box__layer {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;

    &.is-active {
        display: block;
    }

    &-dimmed {
        opacity: .65;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        background: $color-black;

        + .box__layer-w432 {
            z-index: 20;
        }
    }

    &-inner {
        display: inline-block;
        position: absolute;
        top: 70px;
        left: 50%;
        z-index: 20;
        width: 100%;
        max-width: 480px;
        border: 1px solid $color-gray-900;
        border-radius: 16px;
        background: $color-white;
        box-shadow: 0 16px 24px rgba($color-black, .2);
        transform: translateX(-50%);

        .button__close {
            position: absolute;
            top: 12px;
            right: 15px;
            z-index: 1;
            font-size: 0;

            &::before {
                @include useSvg-icons("close_layer", 32px);
                display: inline-block;
                content: "";
            }
        }

        &.login {
            height: auto;
            padding: 0;

            > .box__layer-content {
                // box-sizing: border-box;
                height: 622px;
                max-height: calc(100vh - 90px - 59px);

                &.is-scroll {
                    &::-webkit-scrollbar-track {
                        border-bottom-right-radius: 16px;
                    }
                }
            }
        }

        &.box__layer-help {
            overflow: hidden;
            top: auto;
            right: 32px;
            bottom: 32px;
            left: auto;
            height: 747px;
            border-radius: 8px;
            transform: none;
        }
    }

    &-head {
        border-bottom: 1px solid $color-gray-200;
    }

    &-content {
        display: flex;
        position: relative;
        flex-direction: column;
        // box-sizing: content-box;
        max-height: calc(100vh - 90px - 155px);
        padding: 32px;

        &.is-scroll {
            @include custom-scrollbar-track;
            // overflow-x: hidden;
            overflow-y: auto;
            // box-sizing: border-box;
            padding-right: 14px;

            + .button__wrap::before {
                display: block;
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                height: 2px;
                background-image: linear-gradient(to top, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, 0) 100%);
            }

            .list__item .list-item {
                padding-right: 18px;

                &::before {
                    right: -14px;
                }
            }
        }

        &-inner { // 상품등록 > 정보 수정요청 레이어팝업 참고
            width: 100%;
            padding: 16px 0;
            border-top: 1px solid $color-gray-200;

            &:first-of-type {
                padding-top: 0;
                border-top: none;
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    &-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        padding: 21px 0;
        background-color: $color-white;
        box-shadow: 0 16px 24px 0 rgba($color-black, .2);
        text-align: center;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to top, rgba(0, 0, 0, .1) 100%, rgba(0, 0, 0, 0) 33%);
        }

        .link__text {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &-company {
        margin-top: 40px;

        .list__layer-company {
            font-size: 0;

            .list-item {
                display: inline-block;
                position: relative;
                margin-left: 8px;
                padding-left: 8px;
                color: $color-gray-500;
                font-size: 12px;
                line-height: 16px;

                &:first-child {
                    margin-left: 0;
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 0;
                    width: 1px;
                    height: 10px;
                    background: $color-gray-300;
                }
            }
        }

        .text__layer-company {
            position: relative;
            margin-top: 4px;
            color: $color-gray-500;
            font-size: 12px;
            line-height: 16px;

            &::before {
                @include useSvg-icons("logo_layer_company", 73px);
                display: inline-block;
                content: "";
                margin-right: 8px;
                vertical-align: middle;
            }
        }
    }

    .button__wrap {
        padding: 20px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        background: $color-white;

        .button {
            width: 214px;
        }

        &--notice {
            justify-content: flex-start;
            border-top: 1px solid $color-gray-200;
        }
    }

    .title__layer {
        color: $color-gray-800;
        font-family: $gmarketSans;
        font-size: 20px;
        line-height: 28px;
        text-align: center;

        &--left {
            color: $color-gray-900;
            font-size: 22px;
            line-height: 30px;
            letter-spacing: -.4px;
            text-align: left;
        }

        &--noto {
            font-family: $font-default;
            text-align: left;
        }

        + .box__information {
            margin-top: 16px; // 얼럿 팝업일 때
        }

        + .table,
        + .box__table {
            margin-top: 24px;
        }

        + .form--icon {
            margin-top: 20px;
        }
    }

    .text__layer {
        display: inline-block;
        color: $color-gray-800;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        vertical-align: middle;
        word-break: keep-all;

        &-title { // <h3>
            padding: 16px 0;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }

        + .box__information {
            margin-top: 16px;
        }

        + .box__tooltip {
            margin-left: 4px;
        }

        .required-mark::before {
            margin: 0 0 5px 9px;
        }

        .link__icon {
            top: -2px;
            font-size: 16px;

            &::after {
                top: -2px;
            }
        }

        &--gray {
            color: $color-gray-500;
        }
    }

    .title__layer,
    .text__layer {
        + .text__layer {
            margin-top: 12px;
            text-align: center;
        }
    }

    .text__information {
        .link__text {
            margin-left: 8px;
        }
    }

    &-w432 {
        max-width: 432px;
        @media only screen and (max-width: 767px) {
            max-width: 280px;
            border: 0;
            border-radius: 8px;
        }

        .box__layer-content {
            max-height: 100%;
            padding: 64px 32px 32px;
            @media only screen and (max-width: 767px) {
                padding: 32px 16px;
            }

            &.is-scroll {
                + .button__wrap::before {
                    display: none;
                }
            }
        }

        .button__wrap {
            padding-top: 0;
            @media only screen and (max-width: 767px) {
                padding: 0 0 16px;
            }

            .button {
                width: 190px;
                @media only screen and (max-width: 767px) {
                    width: 248px;
                    height: 44px;
                    font-size: 16px;
                }
            }
        }

        .button__close {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        .title__layer {
            @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .box__loader-circle {
            position: static;
            margin: 0 auto 12px;
            transform: translateX(0);
        }
    }

    &-w600 {
        max-width: 600px;

        .button__wrap {
            .button {
                width: 274px;
            }
        }

        .button__tab {
            width: 113px;
        }
    }

    &-w960 {
        max-width: 960px;

        .button__wrap {
            .button {
                width: 274px;
            }
        }
    }

    &-w1200 {
        max-width: 1200px;

        .button__wrap {
            .button {
                width: 274px;
            }
        }
    }

    &-full {
        .box__layer-content {
            padding: 0;
        }
    }

    &-button-none {
        .box__layer-content {
            max-height: calc(100vh - 90px - 59px);

            &.is-scroll {
                &::-webkit-scrollbar-track {
                    border-bottom-right-radius: 16px;
                }
            }
        }

        .box__image {
            width: 100%;

            .image {
                display: block;
                width: auto;
                max-width: 100%;
                height: auto;
                margin: 0 auto;
            }

            &-pdf {
                height: calc(100vh - 90px - 123px);
            }
        }
    }

    &-antimoneylaundering {
        .box__layer-content {
            min-height: 553px;
        }
    }

    &-guide {
        max-width: 960px;

        .image + .image {
            margin-top: 20px;
        }

        .box__preview {
            .list__preview {
                margin: 0 0 24px;

                &--sample ~ .box__image {
                    padding: 30px 0;
                    background: $color-gray-100;

                    .image {
                        width: 300px;
                    }
                }
            }
        }
    }

    .box__coach-mark {
        position: absolute;
        left: 50%;
        z-index: 20;
        width: 1222px;
        height: 785px;
        margin: 10px 0 0 15px;
        transform: translateX(-50%);
        @media only screen and (max-width: 1060px) {
            width: 976px;
            margin-left: 12px;
        }

        .button__close {
            position: absolute;
            top: 9px;
            right: 30px;
            width: 72px;
            height: 72px;
        }

        img {
            height: auto;
        }

        &.statistics {
            top: 0;
            left: 0;
            // top: -18px;
            // left: -24px;
            width: 100%;
            margin: 0;
            transform: translateX(0);

            img {
                // width: 1700px;
                width: 1652px;
            }

            .button__close {
                position: fixed;
                top: 0;
                right: 40px;
                // top: 18px;
                // right: 16px;

                &::after {
                    @include useSvg-icons("close_image", 72px);
                    display: inline-block;
                    content: "";
                }
            }
        }
    }

    .box__table {
        &:not(.box__table--scroll) {
            overflow-x: visible;
        }

        &--scroll {
            min-height: 582px;
        }

        &-row {
            .box__group > .form + .button {
                margin-left: 0;
            }
        }

        .table {
            .box__group {
                flex-wrap: nowrap;
            }
        }

        &-row:not(&.box__table) {
            > .table {
                > .table-body >.table-row {
                    &:first-of-type {
                        border-top: 1px solid $color-gray-300;
                    }

                    > th.table-data {
                        background: $color-gray-50;
                    }
                }
            }
        }

        + .link__text {
            margin-top: 16px;
        }

        + .box__bundle {
            margin-top: 16px;
        }
    }

    &-cs-certify {
        .button--gray {
            min-width: 117px;
        }
    }

    .box__align--right {
        padding-top: 12px;

        .link__more {
            float: left;
            margin-top: 10px;
            font-size: 14px;
            line-height: 20px;
        }

        .form {
            float: left;
            margin-top: 10px;
        }
    }

    .box__setting {
        font-size: 0;

        .button {
            + .button {
                margin-left: 12px;
            }
        }
    }

    .box__view {
        display: flex;
        align-items: center;
        gap: 8px;

        .text__view {
            color: $color-gray-600;
            font-size: 14px;
            line-height: 20px;

            &-title {
                width: 118px;
                color: $color-gray-700;
                font-size: 14px;
                line-height: 20px;
            }

            &-category {
                @include ellipsis;
                display: inline-block;
                max-width: 342px;
                color: $color-gray-800;
                vertical-align: middle;
            }

            &-guide {
                color: $color-gray-500;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .box__content {
        margin-top: 20px;

        &#shipping-place,
        &#shipping-policy {
            .box__table--scroll-body {
                max-height: 294px;
            }
        }
    }

    .box__category-wrapper {
        .box__category {
            max-height: 200px;
        }
    }

    .box__image {
        &-detail {
            border: 1px solid $color-gray-300;
        }

        &-view {
            display: flex;
            gap: 20px;
            padding: 20px;
            border: 1px solid $color-gray-300;
            border-radius: 8px;
            background: $color-gray-50;

            + .box__process-setting {
                margin-top: 20px;
            }
        }
    }

    .box__result-data {
        margin-top: 20px;

        .box__data-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            margin-bottom: 20px;
            padding: 16px 20px;
            border: 1px solid $color-gray-300;
            border-radius: 6px;
            background: $color-gray-50;

            ~ .box__data-item {
                margin-top: -10px;
            }

            .text__data {
                flex: 1;
            }

            .button--delete {
                width: 20px;
                height: 20px;
                padding: 2px;
                border: 0;

                &::before {
                    @include useSvg-icons("icon_data-del-gray-16", 16px);
                    display: inline-block;
                    content: "";
                }
            }
        }

        .box__no-content {
            height: 140px;
            margin-bottom: 20px;
            border: 1px solid $color-gray-300;
            border-radius: 6px;
            background: $color-gray-50;

            .text__no-content {
                font-family: $gmarketSans;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .box__identification {
        .list__identification {
            .list-item {
                margin-bottom: 14px;
                color: $color-gray-900;
                font-weight: 700;
                text-align: left;

                .text {
                    display: inline-block;
                    width: 76px;

                    + .form,
                    + .box__dropdown {
                        margin-top: 8px;
                    }

                    + .link__option {
                        margin: 8px 0 0;
                    }
                }
            }
        }

        .box__dropdown {
            .button__opener {
                height: 56px;
            }
        }
    }

    .box__required {
        .list-item {
            margin-top: 12px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    .box__bottom-line {
        margin-top: 24px;
        padding-top: 32px;
        border-top: 1px solid $color-gray-200;

        .text {
            +.link__option {
                margin-top: 8px;
                border-color: $color-gray-400;
            }
        }
    }

    .link__option {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        margin-bottom: 12px;
        padding: 0 16px;
        border: 1px solid $color-gray-400;
        border-radius: 8px;
        background-color: $color-white;
        color: $color-gray-900;
        font-family: $gmarketSans;
        font-size: 16px;

        &.link--certify {
            border: 1px solid $color-gray-900;

            &::after {
                @include useSvg-icons("icon_chevron_right", 16px);
                content: "";
                margin-left: 4px;
            }
        }
    }

    .list__image {
        gap: 10px;

        .button__close {
            position: static;
            width: 100%;
            height: 100%;
        }
    }

    .form__chip {
        padding-bottom: 8px;
    }

    .box__layer-manage-address {
        .box__table {
            overflow: visible;
            height: 100%;
            min-height: min-content;

            &.box__table--scroll-body {
                overflow: auto;
                min-height: 294px;
            }

            &:has(.table-body .table-row:nth-child(n+6)) {
                overflow: hidden;
                overflow-y: auto;
                height: 294px;
                min-height: 294px;
            }

            &:has(.box__no-content) {
                overflow: visible;
                max-height: none;
            }

            .table > .table-head {
                position: sticky;
                top: 0;
                z-index: 4;
            }

            &.is-empty {
                .table-body {
                    height: 244px;
                }

                + .box__no-content {
                    height: 244px;
                }
            }

        }

        .box__table,
        .box__table-wrap {
            + .box__process-setting {
                margin-top: 32px;
            }
        }
    }

    .box__layer-delivery-change {
        .box__process-setting {
            margin-bottom: 16px;
        }

        .box__panel-content {
            .box__filter-head {
                width: 156px;
                min-width: 156px;
                padding-right: 0;

                > .text__layer + .box__tooltip {
                    margin-left: 7px;
                }
            }

            .box__filter-content {
                width: calc(100% - 156px);
            }

            .box__tab--multiple {
                min-width: 100%;
            }

            .box__table {
                .table {
                    .table-data {
                        padding: 12px 16px;

                        &__inner {
                            +.table-data__inner {
                                margin-top: 8px;
                            }
                        }

                        .text.text--red {
                            width: auto;
                            text-align: left;
                            white-space: wrap;
                        }
                    }
                }

                + .box__information {
                    margin-top: 0;
                }

                &--merge {
                    .table-body {
                        .table-row {
                            &:first-of-type {
                                .table-data {
                                    padding: 12px 14px 0 !important;
                                }
                            }

                            &:last-of-type {
                                .table-data {
                                    padding: 0 14px 12px !important;
                                }
                            }
                        }

                        .table-data {
                            padding: 0 14px !important;
                        }
                    }
                }
            }
        }
    }

    .box__line-radius {
        overflow: hidden;
        overflow-y: auto;
        max-height: 304px;
    }

    .box__layer-announce-ex {
        .text__layer {
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
        }

        .box__image {
            width: 536px;
        }
    }

    .box__space-none {
        margin: 0 -32px;
    }

    //이벤트 홍보 등록 > 상품검색 레이어 팝업
    .box__layer-product-search {
        .box__information + .box__panel {
            margin-top: 20px;
        }

        .box__panel {
            background: $color-gray-50;

            &-content {
                padding: 20px 40px 16px;
            }

            + .box__process-setting {
                margin: 20px 0 16px;
            }

            .box__filter-item {
                padding: 16px 0;

                &:first-child {
                    padding-top: 0;
                }
            }

            .box__filter-head {
                width: 104px;
                min-width: 104px;
            }
        }
    }

    // 상품등록 > 판매가 > 가격분석 레이어 팝업
    .box__layer-chart-wrap {
        .box__layer-chart {
            position: relative;
            width: 100%;
            padding: 32px 0 28px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: -32px;
                bottom: 0;
                left: -32px;
                z-index: -1;
                background: $color-gray-50;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: -32px;
                left: -32px;
                height: 1px;
                background-color: $color-gray-300;
            }
        }

        .title__chart {
            color: $color-gray-800;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
        }

        .box__chart {
            width: 100%;
            height: auto;
            padding: 30px 0 0;
        }

        .list__legend {
            padding-top: 24px;
        }
    }

    // 홈메인 공지 팝업
    &-notice {
        &.box__layer-inner {
            left: 230px;
            transform: translateX(0);
        }

        .box__layer-content {
            padding: 0 0 32px;
        }

        .button__wrap {
            justify-content: flex-start;

            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-image: linear-gradient(to top, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, 0) 100%);
            }
        }

        .box__swiper--banner {
            .swiper {
                &-slide {
                    overflow-y: auto;
                    height: 566px;
                    padding: 32px 32px 0;

                    &.is-scroll {
                        &::-webkit-scrollbar-thumb {
                            background: $color-gray-500;
                            background-clip: padding-box;
                        }
                    }

                    &.box__slide--scroll {
                        padding-right: 14px;
                    }

                    > img {
                        display: block;
                    }

                    > p {
                        color: $color-gray-800;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    &::-webkit-scrollbar-track:vertical {
                        border-right: 0;
                        border-left: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $color-white;
                    }
                }

                &-button-next {
                    top: auto;
                    right: 32px;
                    bottom: 32px;
                }

                &-button-prev {
                    top: auto;
                    bottom: 32px;
                    left: 32px;
                }

                &-pagination {
                    position: static;
                    margin-top: 16px;
                    padding: 6px 0 8px;
                }
            }
        }
    }

    &--bsd-promotion {
        overflow: hidden;
        top: 50%;
        width: 500px;
        max-width: 500px;
        border: 0;
        transform: translate(-50%, -50%);

        .button__close {
            &::before {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(135deg) brightness(99%) contrast(107%);
            }
        }

        .box__layer-content {
            max-height: calc(100vh - 100px);
            padding: 0;

            .box__image {
                position: relative;
            }

            .box__promotion-text {
                position: absolute;
                top: 270px;
                left: 50%;
                width: 86.6%;
                height: 22.01%;
                border: 1px solid #884ec7;
                border-radius: 8px;
                background: rgba(166, 82, 255, .2);
                text-align: center;
                transform: translateX(-50%);
                backdrop-filter: blur(7.5px);

                > .text__point {
                    display: inline-block;
                    margin-top: 16px;
                    font-family: $gmarketSans;
                    font-size: 18px;
                    line-height: 24px;
                }

                .text {
                    margin-top: 12px;
                    color: $color-white;
                    font-family: $gmarketSans;
                    font-size: 16px;
                    line-height: 22px;
                }

                .text__yellow {
                    color: $color-yellow;
                }
            }

            .link {
                position: absolute;
                top: 85.63%;
                left: 50%;
                width: 64.8%;
                height: 9.7%;
                transform: translateX(-50%);
            }
        }

        .button__wrap {
            justify-content: flex-start;
        }
    }

    // 신규주문 > 주의사항 팝업
    .box__layer-caution {
        .list__information {
            .list-item {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .box__toast {
        &.is-show {
            bottom: 104px;
        }
    }

    .box__filter {
        .button__wrap {
            border-radius: 0;
            background: transparent;
        }
    }

    // 이미지 미리보기(여러개)
    .box__photo {
        overflow: hidden;
        border: 1px solid $color-gray-300;
        border-radius: 8px;

        &-wrap {
            display: flex;
            gap: 16px;
        }

        .box {
            &__image-wrap {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-gray-50;

                .image {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            &__text-wrap {
                padding: 12px 14px;
            }
        }

        &-thumb {
            .button__photo {
                display: block;
                overflow: hidden;
                position: relative;
                width: 48px;
                height: 48px;
                border: 1px solid $color-gray-300;
                border-radius: 4px;

                + .button__photo {
                    margin-top: 8px;
                }

                &.is-active {
                    border-color: $color-blue-500;
                }
            }

            .icon__comment {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 24px;
                height: 20px;
                border-radius: 4px 0;
                background-color: rgba($color-gray-800, .9);

                &::before {
                    @include useSvg-icons("icon_comment", 16px);
                    display: inline-block;
                    content: "";
                    vertical-align: middle;
                }
            }
        }
    }

    // 리뷰관리 > 리뷰 템플릿 관리하기
    .box__layer-template-manage {
        .box__button {
            &-wrap {
                display: flex;
                align-items: center;
                gap: 12px;

                .bar--gray {
                    height: 20px;
                }
            }

            .button {
                &__arrow {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .box__table {
            .table-data--ellipsis {
                .text:not(.text--gmarket,.text--auction) {
                    max-width: none;
                }
            }

            .text__number {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border: 1px solid $color-gray-400;
                border-radius: 6px;
                background: $color-white;
                font-size: 12px;
            }

            .box__add-template {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 301px;
                border-bottom: 1px solid $color-gray-300;

                .text {
                    color: $color-gray-600;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;

                    + .button {
                        margin-top: 12px;
                        font-family: $gmarketSans;
                    }
                }
            }
        }
    }

    // 리뷰관리 > 리뷰 이미지 미리보기
    .box__layer-image {
        .box__photo {
            flex: 1;
            height: 560px;

            &:has(.box__text-wrap) {
                .box__image-wrap {
                    height: 494px;
                }
            }
        }
    }

    // 광고넛징 예시보기
    .box__preview {
        margin: 0;
        padding: 0;
        border: 0;
        background: none;

        &-content {
            height: 538px !important;
        }

        &-wrap .button__preview--toggle {
            top: 458px;
        }
    }

    // 즐겨 찾는 카테고리 설정
    &-category {
        .box__search {
            margin-bottom: 16px;

            .box__gap--center {
                justify-content: space-between;
            }

            .text {
                font-size: 16px;
            }
        }

        .box__table-wrap:has(.box__no-content--gray) {
            .box__no-content--gray,
            .is-empty:not(.box__table--scroll) .table-body {
                height: 293px;
            }

            .box__no-content .text__no-content {
                white-space: nowrap;
            }
        }

        .box__table .table-data--ellipsis {
            .box__gap {
                flex-wrap: nowrap;
                width: 100%;
            }

            .button__star--toggle-24:disabled {
                + .text {
                    color: $color-gray-300;
                }
            }

            .table-data__category {
                width: auto;
                max-width: 60%;
            }
        }

        .box__align--right {
            margin-bottom: 10px;

            .form {
                margin-top: 8px;
            }
        }
    }

    // 구매안전서비스 이용 확인증 발급
    .box__update-info {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 20px;
        padding: 16px 14px;
        border: 1px solid $color-blue-500;
        border-radius: 8px;
        background: $color-blue-50;
        font-size: 0;

        .button {
            flex-shrink: 0;
        }
    }
    
    .box__guide-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        padding: 32px 0;
        border: 1px solid $color-gray-300;
        border-radius: 8px;
        background: $color-blue-50;

        .text {
            font-family: $gmarketSans;
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.box__popup {
    overflow: hidden;
    height: 100%;
    background: $color-white;

    .wrap {
        height: 100%;

        .box__layer {
            display: block;
            position: static;
            height: 100%;

            &-inner {
                position: static;
                max-width: 100%;
                height: 100%;
                padding: 57px 0 96px;
                border: 0;
                border-radius: 0;
                box-shadow: none;
                transform: translateX(0);
            }

            &-head {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
            }

            &-content {
                overflow-y: auto;
                box-sizing: border-box;
                height: 100%;
                max-height: 100%;

                ~ .button__wrap {
                    position: fixed;
                    right: 0;
                    bottom: 0;
                    left: 0;
    
                    &::before {
                        display: block;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-image: linear-gradient(to top, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, 0) 100%);
                    }
                }
            }

            .box__table-row {
                .table-data {
                    vertical-align: top;

                    &__inner {
                        font-size: 0;
                    }

                    .box__tooltip {
                        margin-left: 2px;
                    }
                }
            }
        }
    }

    ~ div .box__layer-w432,
    .box__layer-w432 {
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        @media only screen and (max-width: 767px) {
            max-width: 432px;
            border: 1px solid $color-gray-900;
            border-radius: 16px;
        }

        .box__layer-content {
            @media only screen and (max-width: 767px) {
                padding: 64px 32px 32px;
            }
        }

        .button__wrap {
            @media only screen and (max-width: 767px) {
                padding-top: 0;
            }

            .button {
                @media only screen and (max-width: 767px) {
                    width: 190px;
                    height: 56px;
                    font-size: 18px;
                }
            }
        }

        .button__close {
            @media only screen and (max-width: 767px) {
                display: block;
            }
        }

        .title__layer {
            @media only screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    .button__wrap {
        .button {
            width: 274px;
        }
    }

    .box__table {
        &--scroll-body {
            max-height: fit-content;
        }

        &-row {
            .box__flag {
                &::before {
                    margin-right: 8px;
                }

                + .box__flag {
                    margin-top: 4px;
                }
            }
        }

        + .box__process-setting {
            margin-top: 32px;
        }
    }

    .box__layer-invoicing {
        .box__layer-content {
            display: block;
            padding: 0;
        }

        .box__invoicing {
            &-inner {
                width: 100%;
                height: 100%;
            }

            &--banner {
                height: 305px;
                background: linear-gradient(117deg, #076fdf -10.45%, #067cfd 13.3%, #0678fd 43.54%, #06d1fd 93.21%);
                background-size: auto 100%;

                .box__invoicing-inner {
                    background: url("../img/single/invoicing_banner.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--information {
                height: 225px;
                background: $color-white;

                .box__invoicing-inner {
                    background: url("../img/single/invoicing_info.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--explain {
                height: 434px;
                background: #f6faff;

                .box__invoicing-inner {
                    background: url("../img/single/invoicing_explain.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--good {
                height: 462px;
                background: $color-white;

                .box__invoicing-inner {
                    background: url("../img/single/invoicing_good.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }
        }

        .button__wrap {
            .box__tooltip-bubble {
                top: -32px;
                padding: 6px 15px 5px;
                animation: tooltipMotion 2.5s ease-in-out infinite;

                &::before {
                    left: 50%;
                    transform: translateX(-50%);
                }

                .text__tooltip {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .box__information {
        &--gray {
            + .box__table-row,
            + .box__process-setting {
                margin-top: 8px !important;
            }
        }

        &--blue {
            + .box__process-setting {
                margin-top: 32px !important;
            }
        }
    }

    
    .box__layer-service-fee {
        .text__layer {
            margin-top: 32px;

            &:first-child {
                margin-top: 0;
            }

            + .box__table,
            + .box__table-row {
                margin-top: 16px;
            }
        }

        .box__information {
            margin-bottom: 0;
        }
        
        .box__table {
            &--scroll-body {
                min-height: 538px;
            }

            + .box__information--blue {
                margin-top: 16px;
            }
        }
    }
}

.box__dimmed { //data table 딤드
    opacity: .4;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background: $color-black;

    &--white {
        opacity: .65;
        background: $color-white;
    }
}

.box__layer--crop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;

    &.is-active {
        display: block;
    }

    .box__crop {
        display: grid;
        align-content: center;
        height: 100%;

        &--inner {
            overflow-y: auto;
            position: relative;
            z-index: 20;
            padding: 20px 0;

            .image-wrap {
                overflow: hidden;
                width: 720px;
                height: 720px;
                margin: 0 auto;

                .image {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                margin-top: 16px;
                color: $color-white;
                font-family: "Noto Sans KR",sans-serif;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
            }

            .button__wrap {
                margin-top: 32px;

                .button--xxlarge {
                    width: 84px;
                }
            }
        }
    }
}

.box__notice-area {
    .title__layer {
        text-align: left;

        + .box__information--gray {
            padding: 16px 24px;
        }
    }

    > .title__layer {
        margin-bottom: 24px;
    }

    .box__gap + .box__information--gray,
    .text + .box__information--gray {
        margin: 24px 0 0;
    }

    + .box__table-row {
        margin-top: 24px;
    }

    .box__information--gray {
        .title {
            margin-bottom: -8px;
            font-family: $font-default;
            font-size: 16px;
            line-height: 22px;
        }

        + .box__information--gray {
            margin: 24px 0 0;
        }
    }
}

@keyframes tooltipMotion {
    0% {
        top: -32px;
    }

    25% {
        top: -28px;
    }

    50% {
        top: -32px;
    }

    75% {
        top: -28px;
    }

    100% {
        top: -32px;
    }
}

.box__layer-terms {
    .box__tab--list {
        margin-bottom: 0;
        border: 0;
        border-bottom: 1px solid $color-gray-300;
        border-radius: 0;

        .button__tab {
            width: 50%;

            &.is-active {
                &::after {
                    left: 50%;
                    width: inherit;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .box__tab--panel {
        overflow-y: auto;
        border-bottom: 1px solid $color-gray-300;
    }

    .box__terms-content {
        background-color: $color-gray-100;

        .box__top-side {
            top: 32px;
            right: 16px;
        }
    }
}

.box__layer--bsd {
    .box__image {
        position: relative;
        width: 534px;

        .box__tooltip-bubble {
            top: 327px;
            left: 126px;
            padding: 8px 14px;
            background: linear-gradient(90.59deg, #ffd200 3.21%, #e4bc00 97.72%);

            .text__tooltip {
                color: $color-gray-900;
                font-size: 14px;
                line-height: 14px;
            }

            &::before {
                @include useSvg-icons("tooltip-bubble_triangle", 8px);
                display: inline-block;
                content: "";
                left: 16px;
            }
        }

        .link {
            position: absolute;
            top: 349px;
            left: 50%;
            width: 418px;
            height: 80px;
            transform: translateX(-50%);
        }
    }
}
