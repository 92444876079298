.login {
    height: 100%;
    padding: 60px 0 0;
    background-color: $color-white;
    @media only screen and (max-width: 767px) {
        padding: 48px 0 0;
    }

    .header {
        .box__header {
            min-width: 480px;
            border-bottom: 1px solid $color-gray-300;
            background: $color-white;
            @media only screen and (max-width: 767px) {
                justify-content: center;
                min-width: 100%;
                height: 48px;
            }
        }

        .box__service {
            .title {
                margin: 0 auto;
                font-weight: normal;
                font-size: 18px;
            }

            .link__logo {
                color: $color-gray-900;
            }
        }

        .box__menu .link {
            color: $color-gray-800;
        }

        .list-item+.list-item::before {
            background: $color-gray-300;
        }
    }

    .footer {
        @media only screen and (max-width: 767px) {
            display: none;
        }

        .box__footer {
            background: $color-white;
        }
    }

    #container {
        min-height: calc(100% - 256px);
        background: $color-white;
    }

    .box {
        &__wrap {
            min-width: 480px;
            padding: 0;
            @media only screen and (max-width: 767px) {
                min-width: 100%;
            }
        }

        &__content-wrap {
            box-sizing: content-box;
            max-width: 468px;
            margin: 0 auto;
            padding: 80px 32px;
            @media only screen and (max-width: 767px) {
                padding: 40px 16px;
            }
        }

        &__content {
            margin-top: 0;
        }

        &__title {
            margin-bottom: 40px;
            font-family: $gmarketSans;

            .title {
                color: $color-black;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: left;

                &--center {
                    margin-bottom: 0;
                    padding-bottom: 12px;
                    color: $color-black;
                    font-size: 36px;
                    line-height: 40px;
                    text-align: center;
                    @media only screen and (max-width: 767px) {
                        font-size: 28px;
                    }
                }

                &--other {
                    padding-bottom: 24px;
                    line-height: 44px;
                    @media only screen and (max-width: 767px) {
                        padding-bottom: 16px;
                        line-height: 36px;
                    }
                }
            }

            .text {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            + .box__only-submit {
                padding-top: 40px;
            }
        }

        &__tab {
            display: flex;
            position: relative;
            align-items: center;
            border: 1px solid $color-gray-300;
            border-bottom: 0;
            border-radius: 8px 8px 0 0;
            background-color: $color-gray-100;

            &-content {
                margin-bottom: 16px;
            }

            .button__tab {
                flex: 1;
                width: calc(100% / 3);
                height: 48px;
                border: 0 none;
                border-radius: 8px 8px 0 0;
                background-color: inherit;
                transition: none;

                &:hover {
                    border-color: inherit;
                    background-color: inherit;
                }

                &::after {
                    display: block;
                    content: "";
                    margin: 0 auto;
                }

                &--esm::after {
                    @include useSvg-icons("logo_e", 80px);
                }

                &--gmarket::after {
                    @include useSvg-icons("logo_g", 80px);
                }

                &--auction::after {
                    @include useSvg-icons("logo_a", 80px);
                }

                &:nth-child(1) {
                    border-left: 0 none;

                    &.is-active {
                        margin: -1px 0 0 -1px;
                    }
                }

                &:nth-child(2) {
                    &.is-active {
                        margin: -1px 0 0;
                    }
                }

                &:nth-child(3) {
                    border-right: 0 none !important;

                    &.is-active {
                        margin: -1px -1px 0 0;
                    }
                }

                + .button__tab {
                    border-left: 1px solid $color-gray-300;
                    border-radius: 8px 0 0;
                }

                &.is-active ~ .button__tab {
                    border-right: 1px solid $color-gray-300;
                    border-left: 0 none;
                    border-radius: 0 8px 0 0;
                }

                &.is-active {
                    border: 1px solid $color-blue-500 !important;
                    border-bottom: 0 !important;
                    border-radius: 8px 8px 0 0 !important;
                    background-color: $color-white;

                    &.button__tab--esm::after {
                        @include useSvg-icons("logo_e_on", 80px);
                    }

                    &.button__tab--gmarket::after {
                        @include useSvg-icons("logo_g_on", 80px);
                    }

                    &.button__tab--auction::after {
                        @include useSvg-icons("logo_a_on", 80px);
                    }
                }
            }
        }

        &__article {
            .form__label {
                padding-left: 16px;

                &::before {
                    display: block;
                    content: "";
                }
            }

            .form ~ .form {
                border-radius: 0 0 8px 8px;
            }
        }

        &__captcha {
            margin-bottom: 16px;

            > .text {
                margin-bottom: 8px;
                padding: 0 16px;
                color: $color-gray-900;
                font-size: 14px;
                line-height: 20px;
            }

            .form {
                &.captcha-input {
                    border: 0;
                }
            }

            .captcha-info {
                border: 1px solid $color-gray-400;
                border-radius: 8px 8px 0 0;

                .box__captcha-image {
                    margin: 0 auto;
                }

                .image__captcha {
                    display: block;
                    width: 214px;
                    height: 100%;
                }
            }
        }

        &__article,
        &__captcha {
            .button__delete {
                @include useSvg-icons("icon_delete", 20px);
                display: none;
                position: absolute;
                top: 50%;
                right: 16px;
                z-index: 1;
                background-color: $color-white;
                transform: translateY(-50%);
            }
        }

        &__alert {
            position: relative;
            margin-top: 16px;
            padding: 16px 16px 16px 44px;
            border-radius: 8px;
            font-size: 12px;
            line-height: 16px;

            &.type--red {
                display: none;
                border: 1px solid $color-red-100;
                background-color: $color-red-50;
                color: $color-red-500;

                &::before {
                    @include useSvg-icons("information_mark_login", 20px);
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-50%);
                }
            }

            &.type--input {
                display: none;
                padding: 0;
                border-radius: 0;
            }

            + .box__captcha {
                display: none;
                margin-bottom: 0;
                padding-top: 16px;

                .text {
                    padding: 0;
                }

                .captcha-info {
                    .box__captcha-image {
                        margin: 5px auto;
                    }

                    .image__captcha {
                        display: block;
                        width: 181px;
                        height: 100%;
                    }
                }
            }
        }

        &__modify {
            > .form {
                margin-bottom: 24px;
            }
        }

        &__link-salesid {
            padding: 8px 0 24px;
        }

        &__agree {
            font-size: 0;

            .title {
                margin-bottom: 12px;
                color: $color-black;
                font-size: 16px;
                line-height: 22px;
            }

            &-top {
                display: flex;
                align-items: center;
                padding: 16px;
                border-radius: 8px;
                background-color: $color-gray-100;

            }

            .link__text {
                flex: 0 0 auto;
                color: $color-gray-600;

                &::after {
                    filter: brightness(0) saturate(100%) invert(50%) sepia(0%) saturate(80%) hue-rotate(275deg) brightness(92%) contrast(91%);
                }
            }
        }

        &__sub-info {
            padding-top: 8px;

            .list__info {
                .list-item {
                    padding-left: 8px;
                    color: $color-gray-500;
                    font-size: 11px;
                    line-height: 16px;

                    &::before {
                        width: 2px;
                        height: 2px;
                        background-color: $color-gray-500;
                    }
                }
            }
        }

        &__submit,
        &__only-submit {
            .button {
                width: 100%;
            }
        }

        &__bottom {
            padding-top: 40px;

            .title {
                position: relative;
                margin-bottom: 0;
                padding-left: 20px;
                color: $color-gray-900;
                font-family: $font-default;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;

                &::before {
                    @include useSvg-icons("icon_alert-blue-fill", 16px);
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 0;
                }
            }

            &.box__help {
                padding-top: 16px;
                text-align: center;

                .text__help {
                    color: $color-gray-600;
                    font-size: 14px;
                    line-height: 20px;

                    .link__text {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .section {
        &__title {
            margin-bottom: 44px;

            .title {
                margin-bottom: 0;

                + .text {
                    padding-top: 20px;
                }
            }

            .text {
                color: $color-gray-600;
                text-align: left;
            }

            + .box__only-submit {
                padding-top: 36px;
            }
        }

        &__content {
            ~ .box__submit {
                padding-top: 24px;
            }

            ~ .box__agree {
                padding-top: 24px;
            }

            // UIO 초기화
            .form {
                &__inner {
                    width: 100%;
                }

                .button {
                    padding: 0 9px;
                }

                .text {
                    font-family: inherit;
                }
            }

            &.type--radio {
                .section__item {
                    position: relative;
                    margin-bottom: 8px;
                    padding: 16px;
                    font-size: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .type--data-none {
                    .form__radio {
                        &:checked {
                            ~ .type--red {
                                display: block;
                            }

                            ~ .type--input {
                                display: none;
                            }
                        }
                    }
                }

                .form__radio {
                    + .form__label {
                        position: static;
                        color: $color-black;
                        line-height: 22px;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            box-sizing: border-box;
                            width: 100%;
                            height: 100%;
                            border: 1px solid $color-gray-400;
                            border-radius: 8px;
                        }
                    }

                    &:checked {
                        + .form__label::after {
                            border-color: $color-gray-900;
                        }

                        ~ .type--input {
                            display: block;
                        }

                        ~ .box__captcha {
                            display: block;
                        }
                    }
                }
            }

            &.type--select {
                .link__option {
                    display: inline-flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 48px;
                    margin-bottom: 12px;
                    padding: 0 16px;
                    border: 1px solid $color-gray-400;
                    border-radius: 8px;
                    background-color: $color-white;
                    color: $color-gray-900;
                    font-family: $gmarketSans;
                    font-size: 16px;
                    text-decoration: none;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 16px;
                        transform: translateY(-50%);
                    }

                    &.link--esm::before {
                        @include useSvg-icons("icon_esm", 24px);
                    }


                    &.link--gmarket::before {
                        @include useSvg-icons("icon_gmarket", 24px);
                    }

                    &.link--auction::before {
                        @include useSvg-icons("icon_auction", 24px);
                    }

                    &.link--certify {
                        border: 1px solid $color-gray-900;

                        &::before {
                            content: none;
                        }

                        &::after {
                            @include useSvg-icons("icon_chevron_right", 16px);
                            content: "";
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
    }

    .form {
        position: relative;
        width: 100%;

        &__input {
            width: 100%;
        }

        ~ .form {
            margin-top: 8px;
        }

        &__group ~ .form__group {
            margin-top: 8px;
        }

        ~ .box__modify {
            margin-top: 24px;
        }

        &__group {
            .text--red {
                display: none;
                width: auto;
            }

            .form {
                display: flex;
                overflow: hidden;
                align-items: center;
                justify-content: space-evenly;
                height: 56px;

                ~ .form {
                    .form__input {
                        border-radius: 0 0 8px 8px;
                    }
                }

                &:first-child {
                    .form__input {
                        border-radius: 8px 8px 0 0;
                    }
                }

                &--id,
                &--password {
                    border: 1px solid $color-gray-300;
                    background-color: $color-white;

                    .form__input {
                        border: 0;

                        &::placeholder {
                            color: $color-gray-500;
                        }
                    }
                }
            }

            &--select {
                overflow: hidden;
                border: 1px solid $color-gray-400;
                border-radius: 8px;

                .form {
                    align-items: flex-start;
                    justify-content: flex-start;
                    height: 44px;
                    padding: 12px 16px;

                    ~ .form {
                        border-top: 1px solid $color-gray-400;
                    }

                    &.type--list-wrap {
                        height: auto;
                        min-height: 52px;
                        max-height: 180px;
                        padding: 0;

                        &::-webkit-scrollbar-thumb {
                            border-right: 4px solid transparent;
                        }

                        &::-webkit-scrollbar-track:vertical {
                            border-right: 0;
                        }

                        &.is-scroll {
                            overflow-y: auto;
                        }

                        .type--list {
                            width: 100%;
                            padding: 15px 16px;
                        }
                    }

                    &__item {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &__label {
                        flex: 1;
                        max-width: 296px;
                    }
                }

                .text__form-core {
                    color: $color-gray-900;
                    font-family: $gmarketSans;
                    font-size: 14px;
                    line-height: 20px;
                }

                .text__sub {
                    max-width: 96px;
                    margin-left: auto;
                    padding-right: 4px;
                    color: $color-gray-500;
                    font-size: 11px;
                    line-height: 14px;
                    text-align: right;
                }
            }
            // only login style
            &.is-error {
                .form--password {
                    z-index: 1;
                    border-color: $color-red-500;
                }

                .text--red {
                    display: block;
                }
            }
            // only login style
            &.is-error-id {
                .form--id {
                    z-index: 1;
                    border-color: $color-red-500;
                }

                .text--red {
                    display: block;
                }
            }
        }

        &__radio {
            &.radio--large {
                width: 24px;
                height: 24px;

                &::after {
                    width: 12px;
                    height: 12px;
                }

                + .form__label {
                    font-family: $gmarketSans;
                    font-size: 16px;
                }
            }

            + .label--gmarket {
                &::before {
                    @include useSvg-icons("image_gmarket", 264px);
                    display: block;
                    content: "";
                }
            }

            + .label--auction {
                &::before {
                    @include useSvg-icons("image_auction", 264px);
                    display: block;
                    content: "";
                }
            }
        }

        &--lines {
            &.is-error {
                .form__label {
                    z-index: 1;
                }
            }
        }

        &.is-focus {
            z-index: 1;
            border-color: $color-gray-900;
        }

        &__fixdata {
            &:focus {
                border-color: $color-gray-400;
            }
        }

        &--id {
            .form__label {
                &::before {
                    @include useSvg-icons("icon_id", 20px);
                }
            }

            &.is-focus {
                .form__label {
                    &::before {
                        @include useSvg-icons("icon_id-on", 20px);
                    }
                }
            }
        }

        &--password {
            .form__label {
                &::before {
                    @include useSvg-icons("icon_pass", 20px);
                }
            }

            &.is-focus {
                .form__label {
                    &::before {
                        @include useSvg-icons("icon_pass-on", 20px);
                    }
                }
            }
        }

        &--focus {
            .form__input {
                &:focus,
                &:not(:placeholder-shown),
                &:-webkit-autofill {
                    ~ .form__label span {
                        display: inline-block;
                        top: 11px;
                        left: 17px;
                        font-size: 11px;
                        line-height: 14px;
                        transform: none;

                        &.text__main {
                            display: none;
                        }

                        &.text__sub {
                            padding-left: 0;
                            color: $color-gray-400;
                        }
                    }
                }
            }

            .form__label {
                display: flex;
                align-items: center;

                .text__main {
                    color: $color-gray-500;
                }

                .text__sub {
                    padding-left: 6px;
                    color: $color-gray-400;
                    font-size: 14px;
                    letter-spacing: -.5px;
                }
            }

            &.multi-label {
                .form__label {
                    .text__sub {
                        display: none;
                    }
                }
            }
        }

        &__wrap {
            margin-bottom: 20px;
            padding-left: 16px;
            font-size: 0;
        }
    }

    .list {
        &__option {
            padding: 12px 0 40px;
            border-bottom: 1px solid $color-gray-300;
            font-size: 0;
            text-align: center;

            .list-item {
                display: inline-block;
                position: relative;
                margin-left: 25px;
                vertical-align: middle;

                &:first-child {
                    margin-left: 0;

                    &::before {
                        content: none;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: -13px;
                    width: 1px;
                    height: 13px;
                    background-color: $color-gray-300;
                }
            }

            .link__option {
                color: $color-gray-600;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-decoration: none;
            }
        }

        &__cs {
            .list-item {
                color: $color-gray-600;
                font-size: 14px;
                line-height: 20px;

                ~ .list-item {
                    padding-top: 8px;
                }
            }

            .link__text {
                &.link__mail {
                    color: $color-gray-600;
                }
            }
        }

        &__info {
            .list-item {
                position: relative;
                padding-top: 4px;
                padding-left: 12px;
                color: $color-gray-600;
                font-size: 14px;
                line-height: 20px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: $color-gray-300;
                }

                &:first-child {
                    padding-top: 8px;

                    &::before {
                        top: 16px;
                    }
                }

                .text__mark {
                    color: $color-gray-800;
                }
            }
        }

        &__check {
            &.type--default {
                .icon {
                    @include useSvg-icons("icon-check-black-small-16", 16px);
                }
            }

            &.type--assistive {
                .list-item {
                    &.is-active {
                        .icon {
                            @include useSvg-icons("icon-check-blue-small-16", 16px);
                        }
                    }
                }

                .icon {
                    @include useSvg-icons("icon-check-gray-small-16", 16px);
                }
            }
        }

        &__agree {
            .list-item {
                display: flex;
                align-items: center;
                padding: 16px 16px 0;

                &.is-open {
                    .button__text::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .button {
        &__sound,
        &__refresh {
            width: 68px;
            height: 100%;
            border: 0 none;
            border-left: 1px solid $color-gray-400;
            border-radius: 0;

            &::before {
                display: block;
                content: "";
                margin: 0 auto;
            }
        }

        &__sound::before {
            @include useSvg-icons("icon_captcha-sound", 20px);
        }

        &__refresh::before {
            @include useSvg-icons("icon_captcha-refresh", 20px);
        }

        &__toggle {
            @include useSvg-icons("icon_arrow_down-20", 20px);

            &.is-open {
                transform: rotate(180deg);
            }
        }
    }
}
