// layer buybox
.box__layer-product {
    overflow: hidden;
    min-width: 1200px;
    height: 718px;
    max-height: calc(100% - 80px);
    padding: 0;
    border: 1px solid $color-gray-900;
    border-radius: 16px;
    box-shadow: 0 16px 24px rgba($color-black, .2);
    @media only screen and (max-width: 1060px) {
        min-width: 960px;
    }

    &::after {
        display: none;
        content: "";
        position: absolute;
        top: 56px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        border-radius: 0 0 16px 16px;
        background: rgba($color-white, .65);
    }

    &.is-loaded {
        &::after {
            display: block;
        }

        .box__loader {
            display: block;
        }
    }

    &--statistics {
        min-width: 960px;
        height: auto;

        .box__layer-content {
            .box__layer-chart {
                width: 100%;
                height: auto;
                padding: 0 40px 46px;
                background: $color-white;

                &::before {
                    display: none;
                }

                .box__chart {
                    width: auto;
                    height: auto;
                    padding: 25px 0;
                }
            }
        }
    }

    .box__tooltip-bubble {
        visibility: hidden;
        opacity: 0;
        padding: 8px 16px 5px;
        border: 1px solid $color-gray-900;
        background: $color-white;
        white-space: nowrap;

        &::before {
            display: block;
            content: "";
            position: absolute;
            bottom: -5px;
            z-index: 1;
        }

        .text__tooltip {
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .box__layer {
        &-head {
            height: 56px;
            padding: 16px 0 0 32px;

            .title {
                display: inline-block;
                margin: 0;
                color: $color-gray-900;
                font-family: $gmarketSans;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
            }

            .button__guide-faq {
                margin: -1px 0 1px;
                vertical-align: middle;

                &::before {
                    @include useSvg-icons("question_mark", 20px);
                }
            }
        }

        &-content {
            flex-direction: row;
            max-height: calc(100% - 56px);
            padding: 0;
        }

        &-grid {
            display: flex;
            justify-content: space-between;
            height: 170px;
            padding: 28px 32px;

            &-wrap {
                position: relative;
                width: 798px;
                @media only screen and (max-width: 1060px) {
                    width: 558px;
                }
            }
        }

        &-chart {
            position: relative;
            height: 490px;
            padding: 28px 32px;
            background: $color-gray-50;

            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 1px;
                background: $color-gray-300;
            }

            .title__chart {
                display: inline-block;
                padding: 6px 0;
                color: $color-gray-800;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
            }

            .box__chart {
                @media only screen and (max-width: 1060px) {
                    width: 495px;
                }
            }
        }
    }

    &--category {
        min-width: 1462px;
        @media only screen and (max-width: 1680px) {
            min-width: 740px;
        }

        .text__layer-title {
            padding: 16px 24px 12px;
            color: $color-gray-900;
            text-align: left;

            .text__point {
                @media only screen and (max-width: 1680px) {
                    display: block;
                    margin-bottom: 8px;
                }
            }
        }

        .button__close {
            top: 26px;
            @media only screen and (min-width: 1680px) {
                top: 12px;
            }
        }

        .box__layer-content {
            flex-direction: column;
            padding: 0;
            @media only screen and (min-width: 1680px) {
                flex-direction: row;
                max-height: 459px;
            }
        }

        .box__layer-chart {
            padding: 32px 24px;
            @media only screen and (min-width: 1680px) {
                width: 50%;
            }

            .title__chart {
                display: inline-block;
                position: relative;
                top: 1px;
                color: $color-gray-800;
                font-size: 16px;
                line-height: 22px;
            }

            .box__chart {
                width: auto;
                height: auto;
                padding: 25px 0;
            }

            .button__guide-faq {
                margin-left: 0;
            }

            + .box__layer-chart {
                border-top: 1px solid $color-gray-200;
                @media only screen and (min-width: 1680px) {
                    border-top: 0;
                    border-left: 1px solid $color-gray-200;
                }
            }
        }
    }

    // grid 1
    .box__information {
        &-wrap {
            display: flex;
        }

        &-product {
            display: flex;
            position: relative;
            flex-direction: column;
            min-width: 530px;
            text-align: left;
            @media only screen and (max-width: 1060px) {
                width: 290px;
                min-width: 290px;
            }

            .text {
                &__information {
                    &-name {
                        @include ellipsis;
                        max-width: 530px;
                        min-height: 22px;
                        margin-bottom: 5px;
                        color: $color-gray-800;
                        font-family: $gmarketSans;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;

                        &:hover {
                            ~ .text__hover {
                                display: block;
                                top: -62px;
                                left: 0;
                                transform: translateX(0);
                            }
                        }
                    }

                    &-price {
                        margin-bottom: 4px;
                        color: $color-red-400;
                        font-family: $gmarketSans;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;

                        &-unit {
                            font-weight: 400;
                        }
                    }

                    &-detail {
                        margin-bottom: 12px;
                        font-size: 0;

                        &:last-of-type {
                            margin: 0;
                        }

                        &.text__status {
                            .text__information-dt,
                            .text__information-dd {
                                font-size: 13px;
                                line-height: 20px;
                            }

                            .text__information-dd + .text__information-dt {
                                &::before {
                                    display: inline-block;
                                    content: "";
                                    width: 1px;
                                    height: 12px;
                                    margin: 0 8px 0 -8px;
                                    background: $color-gray-300;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }

                    &-dt,
                    &-dd {
                        display: inline-block;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    &-dt {
                        color: $color-gray-600;
                    }

                    &-dd {
                        margin: 0 16px 0 3px;
                        color: $color-gray-800;
                    }
                }
            }

            .box__gap {
                gap: 4px;

                .text__information-detail {
                    margin-bottom: 0;
                }
            }
        }

        &-attribute {
            color: $color-gray-800;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
        }
    }

    .img__product {
        overflow: hidden;
        width: 114px;
        height: 114px;
        margin-right: 20px;
        border: 1px solid $color-gray-300;
        border-radius: 4px;
    }

    .box__tab--sort {
        position: absolute;
        top: 28px;
        right: 32px;
    }

    .title__calculator {
        margin-bottom: 8px;
        color: $color-gray-900;
        font-family: $gmarketSans;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
    }

    .text {
        margin-bottom: 20px;
        color: $color-gray-600;
        font-size: 12px;
        line-height: 16px;
        text-align: left;

        &__notice {
            color: $color-gray-500;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;

            &::before {
                @include useSvg-icons("information_mark_gray", 16px);
                display: inline-block;
                content: "";
                position: static;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
            }
        }

        &__current {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            &.crown {
                .text__current-ranking {
                    &::before {
                        display: block;
                    }

                    &::after {
                        @include useSvg-icons("emoji_crown", 24px);
                        display: block;
                    }
                }

                .button__refresh {
                    margin-left: 25px;
                }

                .box__tooltip-bubble {
                    left: 7px;
                }
            }

            &.clapping {
                .text__current-ranking {
                    &::before {
                        display: block;
                    }

                    &::after {
                        @include useSvg-icons("emoji_clapping", 24px);
                        display: block;
                    }
                }

                .button__refresh {
                    margin-left: 25px;
                }

                .box__tooltip-bubble {
                    left: 7px;
                }
            }

            &-ranking {
                position: relative;

                &::before {
                    display: none;
                    content: "";
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    z-index: -1;
                    width: 0;
                    height: 14px;
                    background-color: #ffea7a;
                    animation: showUnderline 1s .3s ease forwards;
                }

                &::after {
                    display: none;
                    content: "";
                    position: absolute;
                    top: -2px;
                    right: -24px;
                    animation: showEmoji 1s ease-in-out forwards;
                }
            }

            &-price {
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    .box__calculator {
        position: relative;
        flex-direction: column;
        width: 400px;
        height: 660px;
        padding: 28px 32px;
        text-align: center;

        &::before {
            display: inline-block;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            background: $color-gray-300;
        }

        .box__tooltip-bubble {
            top: -38px;
            left: -18px;
            padding: 6px 12px 4px;

            &::before {
                @include useSvg-icons("tooltip_triangle_small", 9px);
                bottom: -5px;
                left: 24px;
            }
        }

        &-lowest {
            margin-bottom: 16px;

            .box__lowest {
                display: flex;
                position: relative;
                justify-content: space-between;
                height: 55px;
                padding: 16px 11px 16px 16px;
                border-right: 1px solid $color-gray-600;
                border-left: 1px solid $color-gray-600;

                &.is-disabled {
                    background: $color-gray-100;

                    .text__lowest-title {
                        color: $color-gray-500;
                    }

                    .text__lowest-price {
                        em {
                            margin-right: 2px;
                            color: $color-gray-500;
                        }
                    }
                }

                &:not(.box__lowest-notice, .box__lowest-edit) {
                    &::before {
                        display: inline-block;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: $color-gray-300;
                    }
                }

                &:last-of-type {
                    border-bottom: 1px solid $color-gray-600;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &-notice {
                    height: 72px;
                    padding: 16px 16px 12px;
                    border-top: 1px solid $color-gray-600;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    background: $color-blue-50;
                    color: $color-gray-800;


                    .box__notice {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        max-width: 225px;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        background: none;
                        text-align: left;
                    }

                    .text__lowest-notice {
                        font-family: $gmarketSans;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: -.32px;
                    }

                    .button {
                        &.button--tiny {
                            padding: 6px 7px;
                        }

                        &::before {
                            @include useSvg-icons("popup_refresh", 16px);
                            display: inline-block;
                            content: "";
                            vertical-align: top;
                        }
                    }
                }

                &-edit {
                    padding: 0;
                    border-right: 0;
                    border-left: 0;

                    &.is-error {
                        .form__input {
                            padding: 10px 30px 25px 90px;

                            &:focus {
                                z-index: 1;
                                border: 1px solid $color-red-500;
                            }
                        }

                        .text__lowest-price {
                            top: 9px;
                            transform: none;
                        }

                        .text__error {
                            visibility: visible;
                            opacity: 1;
                            transition: opacity .2s ease-in-out;
                        }
                    }

                    .form {
                        position: relative;
                    }

                    .text__lowest-title {
                        position: absolute;
                        top: 50%;
                        left: 16px;
                        z-index: 2;
                        transform: translateY(-50%);
                    }

                    .text__lowest-price {
                        position: absolute;
                        top: 50%;
                        right: 13px;
                        z-index: 2;
                        transform: translateY(-50%);
                    }

                    .text__error {
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        right: 16px;
                        bottom: 9px;
                        z-index: 2;
                        color: $color-red-500;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: right;
                    }

                    .form__input {
                        width: 336px;
                        height: 56px;
                        padding: 14px 30px 17px 90px;
                        border: 1px solid $color-gray-600;
                        border-top-color: $color-gray-300;
                        border-bottom: none;
                        border-radius: 0;
                        font-family: $gmarketSans;
                        font-size: 16px;
                        text-align: right;

                        &:focus {
                            z-index: 1;
                            border: 1px solid $color-blue-500;
                        }
                    }
                }

                .text__lowest-title {
                    font-size: 16px;
                    line-height: 22px;
                }

                .text__lowest-price {
                    color: $color-gray-500;
                    font-family: $gmarketSans;
                    font-size: 16px;
                    line-height: 22px;

                    em {
                        color: $color-gray-800;
                    }
                }

                .box__tooltip {
                    width: 20px;
                    height: 20px;
                    margin: -1px 0 1px;
                    padding: 2px;
                    vertical-align: middle;

                    &-bubble {
                        top: -55px;
                        left: -10px;
                    }

                    .button__tooltip {
                        &::before {
                            @include useSvg-icons("information_mark", 16px);
                            display: inline-block;
                            content: "";
                        }
                    }
                }
            }

            .box__tooltip-bubble {
                left: 69px;
                min-width: 127px;
                padding: 8px 12px;
                border-radius: 6px;

                &::before {
                    @include useSvg-icons("tooltip_triangle_small", 9px);
                    bottom: -5px;
                    left: 14px;
                }

                .text__tooltip {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    line-height: 16px;

                    &-title {
                        display: inline-block;
                        min-width: 58px;
                        margin-right: 8px;
                        color: $color-gray-600;
                        font-weight: 400;
                        text-align: left;
                    }

                    &-price {
                        color: $color-gray-800;
                        font-weight: 600;
                        text-align: right;
                    }
                }
            }
        }

        &-current {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            font-family: $gmarketSans;

            &.is-disabled {
                .text__current-price,
                .text__current .text__current-ranking {
                    opacity: .4;
                }
            }
        }
    }

    .box__refresh {
        display: inline-block;
        position: relative;
        margin-left: 6px;
    }

    .button__refresh {
        width: 24px;
        height: 24px;
        margin: -1px 0 1px;
        padding: 3px;
        border: 1px solid $color-gray-300;
        border-radius: 50%;
        vertical-align: middle;

        &::before {
            @include useSvg-icons("popup_refresh", 16px);
            display: block;
            content: "";
        }
    }

    .box__button-group {
        margin-bottom: 16px;

        .button {
            position: relative;
            width: 100%;
            margin-bottom: 12px;
            border-color: $color-blue-500;
            color: $color-blue-500;

            &:last-of-type {
                margin: 0;
            }

            &--blue {
                color: $color-white;
            }
        }

        .box__tooltip-bubble {
            top: -25px;
            left: 82px;

            &::before {
                @include useSvg-icons("tooltip_triangle_small", 9px);
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .box__smiley {
        display: none;
        position: absolute;
        top: 1px;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 99%;
        border-bottom-right-radius: 16px;
        background: rgba($color-white, .65);

        &.is-show {
            .box__tooltip-bubble {
                visibility: visible;
                opacity: 1;
            }
        }

        &-inner {
            position: absolute;
            top: 183px;
            left: 50%;
            transform: translateX(-50%);

            img {
                width: 256px;
            }
        }

        .box__tooltip-bubble {
            top: -17px;
            left: 50%;
            transform: translateX(-50%);

            &::before {
                @include useSvg-icons("tooltip_triangle_large", 12px);
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%);
            }

            .text__tooltip {
                font-size: 20px;
                line-height: 28px;

                .text__warning {
                    color: $color-red-500;
                }
            }
        }
    }

    .button {
        &__wrap {
            flex-direction: column;
            justify-content: flex-start;
            gap: 6px;
            padding: 0;

            .button__arrow {
                width: 40px;
                height: 54px;
                padding: 11px 0;
                border: 1px solid $color-gray-300;
                border-radius: 4px;
                background: $color-white;

                &::before {
                    @include useSvg-icons("popup_arrow", 32px);
                    display: inline-block;
                    content: "";
                }

                &:disabled {
                    &::before {
                        @include useSvg-icons("popup_arrow_disabled", 32px);
                    }
                }

                &--next {
                    &::before {
                        transform: rotate(180deg);
                    }

                    &:hover {
                        + .text__hover {
                            display: block;
                            top: 122px !important;
                        }
                    }
                }

                &:hover {
                    + .text__hover {
                        display: block;
                        top: -62px;
                    }
                }
            }
        }

        &__close {
            position: absolute;
            top: 4px;
            right: 0;
            width: 48px;
            height: 48px;

            &::before {
                @include useSvg-icons("close_layer", 32px);
                display: inline-block;
                content: "";
            }
        }
    }
}

@keyframes showEmoji {
    0% {
        visibility: hidden;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}
@keyframes showUnderline {
    0% {
        width: 0;
    }

    100% {
        width: calc(100% + 24px);
    }
}
