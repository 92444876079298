.box__banner {
    position: relative;
    height: 60px;
    padding: 19px 20px 0 56px;
    border-bottom: 1px solid $color-gray-300;
    background: $color-white;

    &::before {
        @include useSvg-icons("icon_bell", 20px);
        content: "";
        position: absolute;
        top: 19px;
        left: 27px;
    }

    .text__banner {
        color: $color-gray-900;
        font-size: 16px;
        line-height: 22px;

        .link__text {
            margin-left: 12px;
            color: #5855e2;
        }

        .link {
            color: inherit;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    .text__update {
        margin-left: 12px;
        color: $color-gray-500;
        font-size: 14px;
        line-height: 20px;
    }

    .icon__new {
        @include useSvg-icons("icon_nav_new", 20px);
        margin: -2px 0 2px 4px;
        background-color: transparent;
        vertical-align: middle;
    }

    &--seller {
        background: $color-blue-50;

        &::before {
            @include useSvg-icons("icon_alert-blue-fill", 20px);
        }
    }
}
