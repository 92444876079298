.box__loader {
    width: 320px !important;
    padding: 80px 32px 32px;
    border: 1px solid $color-gray-900;
    border-radius: 8px;
    background: $color-white;
    box-shadow: 0 2px 8px rgba($color-black, .12);
    font-family: $gmarketSans;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    &:not(.box__layer-inner) {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 20;
        transform: translate(-50%, -50%);
    }

    &-circle {
        --size: 32px;
        --stroke-width: calc(var(--size) / 6);
        --color: currentColor;
        --animation-duration: 1s;
        position: absolute;
        top: 32px;
        left: 50%;
        width: var(--size);
        height: var(--size);
        color: $color-blue-500;
        transform: translateX(-50%);
    }

    &-circle::before,
    &-circle::after {
        content: "";
        position: absolute;
        border-width: var(--stroke-width);
        border-style: solid;
        border-color: var(--color) var(--color) transparent transparent;
        border-radius: 50%;
        transform: rotate(0deg);
        animation: var(--animation-duration) infinite loader-circle-animation;
        inset: 0;
    }

    &-circle::before {
        animation-timing-function: cubic-bezier(.5, 0, .75, 0);
    }

    &-circle::before {
        animation-timing-function: cubic-bezier(.25, 1, .5, 1);
    }
}

@keyframes loader-circle-animation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
