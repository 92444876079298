// 광고넛징페이지
.box__register {
    .box__board-wrap--74 {
        padding: 12px 0 0;

        .list__tab-board {
            .list-item {
                height: 100px;

                &:last-child {
                    .form__label {
                        border-color: $color-gray-300;
                    }
                }

                .form__label {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .form__label {
                border-color: $color-gray-300;

                .text__tab {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                }

                .text__sub {
                    display: block;
                    margin-top: 4px;
                    font-family: $gmarketSans;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .box__board {
            flex-direction: column;
            align-items: center;
            gap: 40px;
            height: auto;
            padding: 40px 72px;
            border-color: $color-gray-300;
            background: $color-white;

            @media only screen and (min-width: 1680px) {
                flex-direction: row;
                align-items: flex-start;
                gap: 50px;
                padding: 40px 80px;
            }
        }

        .box__gap-12 .list-item {
            width: auto;
        }
    }

    .box__content {
        min-width: 890px;

        .box__emphasis {
            .box__button-wrap {
                .button {
                    width: 200px;
                }
            }

            &--center {
                margin-top: 20px;
            }
        }

        .box__service-total--plus > .text {
            white-space: normal;
        }

        .button__wrap--200 {
            margin-top: 28px;

            .button--xxlarge {
                width: 200px;
            }
        }

        &--full {
            max-width: 100%;

            .box__emphasis--center {
                margin-top: 0;
            }
        }
    }

    .box__preview {
        min-width: 573px;
        margin: 0;
    }

    .box__chain-item {
        height: 560px;
    }
}
