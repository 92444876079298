.statistics {
    // padding-top: 20px;

    .box {
        &__title {
            display: flex;

            .text {
                margin-bottom: 2px;

                + .text {
                    margin: 1px 0 -1px 12px;
                    color: $color-gray-500;
                    font-weight: normal !important;
                    font-size: 14px;
                }
            }

            ~ .text--gray {
                margin-bottom: 21px;
            }

            .button__guide-faq {
                margin-top: -2px;
            }
        }

        &__statistics {
            &__title {
                display: flex;
                position: relative;
                align-items: center;
                margin-bottom: 16px;

                .title {
                    margin-top: 2px;
                    margin-bottom: 0;

                    .text--gmarket {
                        font-weight: 600;
                        font-size: inherit;
                    }
                }

                .box__dropdown {
                    min-width: 230px;

                    .button__opener,
                    .button__option {
                        font-family: $gmarketSans;
                    }
                }
            }

            &__user {
                margin-right: 12px;
            }
        }

        &__summary {
            flex-wrap: nowrap;
            gap: 20px 60px;
            margin-bottom: 12px;
            padding: 25px 0 0 40px;

            &--group {
                display: flex;
                gap: 16px;
                margin-bottom: 0;
                padding: 40px 40px 32px;

                .box__summary--head {
                    position: relative;
                    margin-bottom: 15px;
                    padding: 16px 0 7px;
                    border-bottom: 1px solid $color-gray-300;

                    .title {
                        font-family: $font-default;
                        font-weight: normal;
                        font-size: 18px;
                    }

                    .link__text {
                        position: absolute;
                        top: 22px;
                        right: 0;
                        color: $color-gray-600;
                    }
                }

                .list__count {
                    flex-wrap: nowrap;

                    .list-item {
                        width: 50% !important;
                        min-width: 180px;
                        max-width: 300px;
                    }
                }

                .box__table {
                    &-wrap {
                        padding: 0 24px 36px;
                        border-radius: 8px;
                        background: $color-gray-50;

                        .table-head {
                            .text {
                                margin-bottom: 0;
                            }

                            .box__tooltip {
                                margin: 0 0 0 4px;
                            }
                        }
                    }

                    .box__item-info {
                        flex-direction: row;
                        flex-wrap: nowrap;
                        white-space: nowrap;
                        @media only screen and (max-width: 1280px) {
                            flex-wrap: wrap;
                            align-content: center;
                            height: 100px;
                        }

                        .text {
                            @include ellipsis;
                            height: auto;
                            font-family: $font-default;
                            font-size: 14px;
                            line-height: 20px;
                            @media only screen and (max-width: 1280px) {
                                width: 100%;
                                max-height: 60px;
                                white-space: normal;
                            }
                        }
                    }
                }
            }

            &--head {
                .list__count {
                    padding-right: 0;
                }
            }

            &:has(.box__summary--head .list__count) {
                gap: 40px;
            }

            .box__table .text {
                margin-bottom: 0;
            }
        }

        &__detail {
            height: auto;
            padding: 0;

            .list__count {
                gap: 20px 0;

                .list-item {
                    width: 240px;
                    margin-top: 0;

                    .box__item-container {
                        flex-direction: column;
                    }
                }
            }
        }

        &__filter {
            &-head {
                min-width: 255px;

                .text {
                    color: $color-gray-900;
                    font-family: $gmarketSans;
                    font-size: 18px;
                }
            }

            &-group {
                .text__information {
                    padding-left: 0;
                    color: $color-gray-500;
                    line-height: 40px;

                    &::before {
                        display: none;
                    }
                }
            }

            &-item {
                &--small {
                    .box__filter {
                        &-head {
                            width: 152px;
                            min-width: 152px;
                            padding: 9px 0;

                            &.w-190 {
                                width: 190px;
                                min-width: 190px;
                            }

                            .text {
                                color: $color-gray-700;
                                font-family: $font-default;
                                font-size: 16px;
                            }
                        }

                        &-content {
                            width: calc(100% - 160px);
                        }
                    }
                }

                &:not(.box__filter-item--small) {
                    @media only screen and (max-width: 1280px) {
                        flex-wrap: wrap;
                    }

                    .box__filter-head,
                    .box__filter-content {
                        @media only screen and (max-width: 1280px) {
                            width: 100%;
                        }
                    }
                }
            }

            .button--more {
                top: 19px;
                bottom: auto;
            }
        }

        &__panel {
            &-content {
                padding-bottom: 40px;

                .box__information .text--gray,
                .box__announce--none .list__information .list-item {
                    color: $color-gray-800;
                }
            }

            &-summary {
                flex-wrap: wrap;
                gap: 16px 0;
                margin-bottom: 25px;
                padding-bottom: 0;

                .box__filter {
                    min-height: 40px;

                    &-content {
                        width: 100%;
                    }

                    + .box__setting {
                        @media only screen and (max-width: 1060px) {
                            justify-content: flex-end;
                            width: 100%;
                        }
                    }
                }

                > .box__align {
                    max-width: calc(100% - 456px);

                    &:has(.button__star--toggle-24, .button__guide-faq) {
                        max-width: none;
                    }

                    .box__tab--radio + .text {
                        @include ellipsis;
                    }

                    .text + .button__guide-faq {
                        margin-left: -12px;

                        + .bar--gray {
                            margin: 0 12px;
                        }
                    }
                }

                + .box__table-wrap {
                    margin-top: -9px;
                }

                ~ .box__announce,
                ~ .box__information {
                    margin-top: -5px;
                    margin-bottom: 20px;
                }

                + .box__rank {
                    margin-top: -9px;
                }
            }

            &-group {
                display: flex;
                gap: 16px;
                margin-top: 16px;
                @media only screen and (max-width: 1060px) {
                    display: block;
                }

                .box {
                    +.box {
                        margin-top: 0;
                        @media only screen and (max-width: 1060px) {
                            margin-top: 16px;
                        }
                    }

                    &__panel {
                        &-summary {
                            margin-bottom: 20px;
                        }

                        &-content {
                            padding: 20px 20px 32px;
                        }
                    }

                    &__title {
                        .text {
                            font-weight: 600;
                        }
                    }

                    &__score {
                        width: calc(100vw - 468px);
                        margin-bottom: 0;
                        padding: 19px 30px 0;
                        @media only screen and (max-width: 1060px) {
                            width: auto;
                            padding-left: 80px;
                        }

                        &-info {
                            .text {
                                height: auto;
                                white-space: nowrap;
                            }
                        }

                        &--body {
                            gap: 22px;
                            @media only screen and (max-width: 1060px) {
                                flex-wrap: wrap;
                                gap: 45px 100px;
                            }
                        }
                    }

                    &__keyword {
                        display: block;
                        width: 400px;
                        min-height: 375px;
                        padding: 16px 40px 23px;
                        @media only screen and (max-width: 1060px) {
                            width: auto;
                            min-height: 0;
                        }

                        &-rank {
                            height: auto;

                            .box__no-content {
                                height: 338px;
                            }
                        }

                        .list__keyword {
                            display: block;
                            @media only screen and (max-width: 1060px) {
                                display: grid;
                                grid-template-rows: repeat(5, 1fr);
                                grid-template-columns: repeat(auto-fill, minmax(50%, auto));
                                grid-auto-flow: column;
                            }

                            .list-item {
                                height: 30px;

                                + .list-item {
                                    margin-top: 4px;
                                }
                            }

                            .text {
                                font-size: 16px;
                                line-height: 30px;

                                &__rank {
                                    min-width: 26px;
                                    margin: 0;
                                    font-weight: 600;

                                    + .text {
                                        min-width: 40px;
                                    }
                                }
                            }
                        }
                    }
                }

                .box__summary--head {
                    position: relative;
                    padding: 20px 40px 11px;
                    border-bottom: 1px solid $color-gray-300;

                    .title {
                        font-family: $font-default;
                        font-size: 18px;
                    }

                    .box__gap {
                        .text {
                            font-family: $font-default;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                .link__text {
                    position: absolute;
                    top: 26px;
                    right: 40px;
                    color: $color-gray-600;
                }
            }

            .box__setting {
                > .text {
                    color: $color-gray-500;
                    font-size: 12px;
                }
            }

            &--reset {
                .box__panel-summary {
                    justify-content: flex-end;
                    margin-bottom: 12px;
                }
            }
        }

        &__align {
            .box__tab--radio {
                margin-right: 16px;
            }
        }

        &__chart {
            overflow: hidden;
            padding-top: 0;

            .box__no-content {
                margin-bottom: 91px;
            }
        }

        &__table {
            &--scroll {
                min-height: 582px;

                &:not(.is-empty) {
                    height: auto;
                }
            }

            .button--icon {
                border: 1px solid $color-blue-500;
                color: $color-blue-500;
                font-size: 14px;
                transition: none;

                &::before {
                    @include useSvg-icons("icon_chart", 16px);
                    content: " ";
                    margin-right: 4px;
                }

                &:hover,
                &.is-active {
                    background: $color-blue-500;
                    color: $color-white;

                    &::before {
                        @include useSvg-icons("icon_chart--clicked", 16px);
                    }
                }
            }
        }

        &__check {
            width: 20px;
            height: 20px;

            &--superdeal {
                @include useSvg-icons("icon_type_superdeal", 20px);
            }

            &--allkill {
                @include useSvg-icons("icon_type_allKill", 20px);
            }
        }

        &__graph {
            &-summary {
                .title {
                    .text__primary {
                        font-weight: normal;

                        &::before {
                            display: inline-block;
                            content: "";
                            width: 2px;
                            height: 22px;
                            margin: -1px 15px 1px 17px;
                            background: $color-gray-300;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        &__category {
            width: 100%;
            max-height: 411px;
            @media only screen and (min-width: 1680px) {
                width: calc(50% - 8px);
            }

            &--favorite {
                .box__swiper--category {
                    .box__gap-24 {
                        padding-left: 0;
                    }

                    .text__ranking {
                        width: 28px;
                    }

                    .button__star--toggle-24 {
                        margin: -2px 2px 0 -8px;
                    }
                }
            }

            .box__tooltip-bubble {
                top: -10px;
                right: 38px;
                border: 1px solid $color-gray-900;
                background: $color-white;
                white-space: nowrap;
                
                .text__tooltip {
                    color: $color-gray-800;
                    font-size: 14px;
                    line-height: 20px;
                }

                &::before {
                    @include useSvg-icons("tooltip_triangle_small", 9px);
                    right: 79px;
                    left: auto;
                }
            }

            &:has(.box__panel-content) {
                &::after {
                    display: none;
                }
            }

            &-group {
                margin-bottom: 16px;
                @media only screen and (min-width: 1680px) {
                    display: flex;
                    gap: 16px;
                }

                .box {
                    overflow: visible;
                    margin-top: 16px;
                }
            }

            .box__panel {
                &-content {
                    padding: 24px 0 20px;
                }

                &-summary {
                    padding: 0 40px;
                }
            }

            .box__no-content {
                height: 240px;
            }
        }

        &__status {
            white-space: nowrap;

            .text {
                font-size: 14px;
                line-height: 20px;

                &:last-child {
                    display: inline-block;
                    width: 48px;
                    margin: 0 0 0 4px;
                    font-family: $gmarketSans;
                    text-align: right;
                }
            }
        }
    }

    .box__keyword,
    .box__recommend {
        display: flex;
        position: relative;
        gap: 40px;
        padding: 24px 90px 22px 40px;

        &-rank {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 26px;

            &.is-open {
                height: auto;

                .list__recommend + .list__recommend {
                    margin-top: 24px;
                }
            }
        }

        .title {
            margin: 0;
            color: $color-gray-800;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            white-space: nowrap;

            .button__guide-faq {
                margin: -1px 0 1px;

                &::before {
                    @include useSvg-icons("question_mark", 16px);
                }
            }
        }

        .link__text,
        .button__text {
            position: absolute;
            top: 24px;
            right: 40px;
        }
    }

    .box__recommend {
        gap: 0;
        padding-right: 130px;
    }

    .list__keyword {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, auto));
        width: 100% !important;

        .list-item {
            display: flex;
            align-items: center;
            gap: 4px;
            height: 26px;
        }

        .text {
            font-size: 12px;
            white-space: nowrap;

            &__rank {
                margin: 2px 6px -2px 0;
                font-family: $gmarketSans;
                font-size: 16px;
            }

            &:last-of-type {
                @include ellipsis;
                display: inline-block;
                max-width: calc(100% - 60px);
            }
        }
    }

    .list__recommend {
        width: 100% !important;
        height: 26px;

        .list-item {
            display: flex;
            overflow: hidden;
            float: left;
            align-items: center;
            justify-content: space-between;
            width: 33.33%;
            height: 26px;
            padding-left: 50px;
        }

        .box__gap {
            flex-wrap: nowrap;
            width: calc(100% - 84px);

            .text {
                @include ellipsis;
                display: inline-block;
                max-width: calc(100% - 70px);
                color: $color-gray-700;
                font-family: $gmarketSans;
                font-size: 15px;

                &__badge {
                    margin: -1px 0 1px;
                }
            }
        }

        .box__status {
            .text {
                font-size: 14px;

                &:last-of-type {
                    font-family: $gmarketSans;
                }
            }
        }
    }
}

.text {
    &__status-new {
        color: $color-red-500;
        font-size: 12px;
    }

    &__status-up {
        color: $color-red-500;
        font-size: 12px;

        &::before {
            @include useSvg-icons("icon_increase_value", 16px);
            display: inline-block;
            content: "";
            margin: -1px 4px 1px 0;
            vertical-align: middle;
        }
    }

    &__status-down {
        color: $color-blue-500;
        font-size: 12px;

        &::before {
            @include useSvg-icons("icon_decrease_value", 16px);
            display: inline-block;
            content: "";
            margin: -1px 4px 1px 0;
            vertical-align: middle;
        }
    }
}

.box__score {
    margin-bottom: 25px;
    padding: 32px 40px;

    &--head {
        .title {
            margin-bottom: 24px;
        }
    }

    &--body {
        display: flex;
        overflow-x: auto;
        justify-content: space-between;
        gap: 58px;
        max-width: 1388px;
        padding: 0 0 14px;
    }

    .list__score {
        margin-left: 24px;

        .list-item {
            display: flex;
            justify-content: space-between;
            width: 200px;
            margin-top: 8px;
        }
    }

    .text {
        color: $color-gray-600;

        .num {
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-weight: normal;
            font-size: 16px;
        }
    }

    &-item {
        flex: 1;
    }

    &-info {
        margin: 0 0 2px 24px;

        .title {
            display: inline-block;
            margin: 0;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            vertical-align: middle;

            + .box__tooltip {
                margin: -1px 0 1px 4px;
            }
        }

        .text {
            height: 40px;
        }
    }

    &-status {
        overflow: hidden;
        position: relative;
        width: 246px;
        height: 197px;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 50%;
            width: 214px;
            height: 214px;
            border: 1px solid $color-gray-200;
            border-radius: 50%;
            transform: translateX(-50%);
        }
    }

    &-inner {
        position: absolute;
        top: 90px;
        right: 0;
        left: 0;
        text-align: center;

        .text {
            color: $color-gray-600;

            .num {
                color: $color-gray-800;
                font-family: $gmarketSans;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
            }

            &__status {
                display: block;
                color: $color-gray-800;
                font-family: $gmarketSans;
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    &-gradation {
        .text {
            position: absolute;
            color: $color-gray-500;
            font-size: 13px;

            &__0 {
                bottom: -5px;
                left: 32px;
            }

            &__20 {
                top: 94px;
                left: 0;
            }

            &__40 {
                top: 7px;
                left: 58px;
            }

            &__60 {
                top: 7px;
                right: 58px;
            }

            &__80 {
                top: 94px;
                right: 0;
            }

            &__100 {
                display: none;
                right: 20px;
                bottom: -5px;
            }

            &__90 {
                right: 4px;
                bottom: 37px;
                color: $color-green-500;
            }

            &__95 {
                right: 10px;
                bottom: 22px;
                color: $color-green-500;
            }

            &__98 {
                right: 24px;
                bottom: -5px;
                color: $color-green-500;
            }
        }
    }

    &-chart {
        width: 210px;
        height: 210px;
        margin: 13px auto 0;
        padding-top: 1px;
        border-radius: 50%;
    }
}

.box__survey {
    margin-top: 6px;

    .box__gap-12 {
        position: relative;

        &:first-of-type {
            .text {
                line-height: 40px;
            }
        }

        + .box__gap-12 {
            margin-top: 40px;
        }

        .box__gap:not(.box__gap--vertical) {
            justify-content: space-between;
        }

        .box__dropdown {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.text__tag {
    &-my {
        display: block;
        width: 48px;
        height: 20px;
        border-radius: 15px;
        background: #f9560e;
        color: $color-white;
        font-family: $gmarketSans;
        font-size: 10px;
        line-height: 20px;
        text-align: center;

        + .text__badge {
            margin: 0;
        }
    }
}

.box__rank {
    padding: 12px 0;
    border-top: 1px solid $color-gray-300;

    .bar--gray {
        margin: -5px 18px 5px;
    }

    .button__rank {
        overflow: hidden;
        max-width: 260px;
        color: $color-gray-600;
        font-family: $gmarketSans;
        font-size: 18px;
        line-height: 53px;
        text-overflow: ellipsis;
        white-space: nowrap;

        .text__label {
            position: relative;
            top: -1px;
            font-family: $font-default;
        }

        &.is-active {
            color: $color-blue-500;
        }
    }
}
