.buybox {
    .box__table {
        .button {
            border-radius: 4px;
            font-size: 14px;
            transition: none;

            &__refresh {
                margin-right: 8px;
                padding: 5px;
                border: 1px solid $color-gray-400;
                border-radius: 4px;

                &::before {
                    @include useSvg-icons("refresh", 20px);
                    display: block;
                    content: "";
                }
            }

            &--icon {
                border-color: $color-blue-500;
                color: $color-blue-500;

                &::before {
                    @include useSvg-icons("table_calculator", 20px);
                    display: block;
                    content: "";
                    margin-right: 4px;
                }
            }
        }


        .table-body {
            .table-row:hover {
                .table-row {
                    border-collapse: collapse;
                }

                .table-data {
                    background: rgba(238, 247, 255, 1);
                    box-shadow: inset 0 2px 0 0 $color-blue-500, inset 0 -2px 0 0 $color-blue-500, inset -1px 0 0 0 $color-gray-300;

                    &:first-of-type {
                        box-shadow: inset 2px 2px 0 0 $color-blue-500, inset 0 -2px 0 0 $color-blue-500, inset -1px 0 0 0 $color-gray-300;
                    }

                    &:last-of-type {
                        box-shadow: inset 0 2px 0 0 $color-blue-500, inset -2px -2px 0 0 $color-blue-500;
                    }

                    &::after {
                        display: none;
                    }

                    &--point {
                        background: rgba(250, 248, 229);
                    }
                }

                .button {
                    border-color: $color-blue-500;
                    color: $color-blue-500;

                    &__refresh {
                        border-color: $color-blue-500;

                        &::before {
                            @include useSvg-icons("refresh_hover", 20px);
                        }
                    }

                    &--blue {
                        background: $color-blue-900;
                        color: $color-white;
                    }

                    &--icon {
                        border-color: $color-blue-900;
                        background: $color-blue-900;
                        color: $color-white;

                        &::before {
                            @include useSvg-icons("table_calculator_hover", 20px);
                        }
                    }
                }
            }
        }

        .table-data {
            &--multiline {
                padding: 14px 12px;

                .text {
                    word-break: keep-all;
                }
            }
        }
    }

    .box__filter-group {
        .box__information--fit {
            margin-left: 36px;
        }
    }
}
