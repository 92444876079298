.box__catalog {
    overflow: hidden;
    overflow-y: auto;
    max-height: 640px;
    border: solid 1px $color-gray-300;
    border-radius: 6px;

    &-item {
        display: flex;
        padding: 20px 132px 20px 20px;
        background: $color-white;

        + .box__catalog-item {
            border-top: 1px solid $color-gray-300;
        }
    }

    &-matching {
        .box__catalog-item {
            &:hover {
                background: $color-gray-50;
            }

            &.is-active {
                background: #f5faff;
            }
        }
    }

    .box__image {
        display: flex;
        overflow: hidden;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 96px;
        border: 1px solid $color-gray-300;
        border-radius: 4px;
        background: $color-gray-50;

        img {
            width: auto;
        }

        .image__blank::after {
            width: 48px;
            height: 48px;
        }
    }

    .box__image + .list,
    .form + .list {
        margin-left: 16px;
    }

    .list-item {
        display: flex;
        flex-wrap: wrap;
        font-size: 0;

        &:first-child .text {
            margin-bottom: 8px;
        }

        > .text {
            position: relative;
            margin: 0 17px 4px 0;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: -9px;
                width: 1px;
                height: 12px;
                background: $color-gray-300;
                transform: translateY(-50%);
            }

            &:last-of-type {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    .text--gray {
        color: $color-gray-500;
    }

    .button__underline {
        margin: 8px 0 0;
        color: $color-gray-600;
        font-size: 12px;
        line-height: 16px;
        text-decoration: underline;
    }

    .box__no-content {
        height: 232px;
    }
}
