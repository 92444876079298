.box__calendar {
    display: inline-block;
    position: relative;
    vertical-align: top;

    .box__date {
        display: flex;

        &.is-show {
            + .box__layer-calendar {
                display: block;
            }

            input {
                border-color: $color-gray-900;
                outline: 1px solid transparent;
            }
        }

        .box__start,
        .box__end {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between; // 가로 크기 넓어질 경우 (판매관리 > 반품관리)
            height: 40px;
            padding: 0 6px;
            border: 1px solid $color-gray-400;
            border-radius: 6px;
            background: $color-white;

            &.is-active {
                border-color: $color-gray-900;
            }

            &.is-disabled,
            &.is-disabled .form__input-day {
                background: $color-gray-100;
            }

            &.is-error {
                margin-bottom: 20px;
                border-color: $color-red-500;
            }

            .text {
                position: absolute;
                top: calc(100% + 4px);
                left: 16px;
                font-size: 12px;
                line-height: 16px;
            }

            + .box__dropdown {
                margin-left: 12px;
            }
        }

        .text__middle {
            margin: 0 8px;
            color: $color-gray-500;
            font-size: 14px;
            line-height: 40px;
        }

        .button__set-date {
            padding: 10px;

            &::after {
                @include useSvg-icons("calendar", 20px);
                display: block;
                content: "";
            }

            + .form__input-day {
                padding-left: 0;
            }
        }

        .form__input-day {
            width: 96px;
            height: 100%;
            padding: 0 10px;
            border: 0;
            border-radius: 6px;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;

            &:focus,
            &:-webkit-autofill {
                caret-color: $color-blue-500;
            }

            &:focus-visible {
                border-color: $color-gray-900;
                outline: 1px solid transparent;
            }

            &::placeholder {
                color: $color-gray-500;
            }
        }
    }

    &--header {
        ~ .box__calendar--nav {
            top: 88px;
        }
    }

    &--wrap {
        display: flex;
        gap: 12px;
        box-sizing: border-box;
        width: 732px;
        padding: 24px;
    }

    &--group {
        width: 50%;
    }

    &--head {
        position: relative;
        padding: 8px 0;
        text-align: center;

        .text__title {
            color: $color-gray-900;
            font-family: $gmarketSans;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &--table {
        width: 100%;
        margin-top: 12px;
        cursor: default;

        th {
            .text {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 46px;
                margin: 0;
                color: $color-gray-600;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
            }
        }

        td {
            position: relative;
            z-index: 1;

            &.text__state--highlight::before,
            &.text__state--point::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: -2;
                width: auto;
                background: $color-blue-50;
            }

            &.text__state--highlight::before {
                right: 0;
                left: 0;
            }

            &[title="시작일"]::before {
                right: 0;
                left: 50%;
            }

            &[title="종료일"]::before {
                right: 50%;
                left: 0;
            }

            &[title]::after {
                position: absolute;
                bottom: 9px;
                z-index: -1;
                width: 100%;
                color: $color-white;
                font-weight: 400;
                font-size: 9px;
                line-height: 10px;
                text-align: center;
            }

            &[title="오늘"]::after {
                content: "오늘";
                color: $color-blue-500;
            }

            &[title="시작일"]::after {
                content: "시작일";
            }

            &[title="종료일"]::after {
                content: "종료일";
            }

            &[title="당일"]::after {
                content: "당일";
            }

            &[title="최종결제"]::after {
                content: "최종결제";
            }

            &.text__state {
                &--holiday .text {
                    color: $color-red-500;
                }

                &--disabled .text {
                    color: $color-gray-300;
                }

                &--point {
                    z-index: 2;

                    .text {
                        color: $color-white;
                        font-weight: 700;
                    }
                }

                &--today {
                    .text__status {
                        color: $color-blue-500;
                    }
                }

                &--start {
                    &::before {
                        right: 0;
                        left: 50%;
                    }
                }

                &--end {
                    &::before {
                        right: 50%;
                        left: 0;
                    }
                }
            }

            .text {
                display: flex;
                position: relative;
                justify-content: center;
                width: 48px;
                height: 48px;
                margin: 0;
                padding-top: 9px;
                color: $color-gray-800;
                font-size: 16px;
                line-height: 20px;
                text-decoration: none;

                &__status {
                    position: absolute;
                    bottom: 9px;
                    z-index: -1;
                    width: 100%;
                    color: $color-white;
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 10px;
                    text-align: center;
                }
            }

            &:not(.text__state--disabled) .text:hover,
            &.text__state--point .text {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 50%;
                    z-index: -1;
                    box-sizing: border-box;
                    width: 48px;
                    height: 48px;
                    border-radius: 50px;
                    transform: translateX(-50%);
                }
            }

            &:not(.text__state--disabled) .text:hover::before {
                border: 2px solid $color-blue-500;
            }

            &.text__state--point .text::before {
                background: $color-blue-500;;
            }
        }
    }

    &--month {
        gap: 16px;
        width: 556px;
        padding: 28px;

        .box__calendar--table td {
            padding: 0 16px;

            &[title]::after {
                content: "";
            }

            .text {
                align-items: center;
                padding-top: 0;
            }
        }

        ~ .button__wrap .button {
            width: 100px !important;
        }
    }

    &--onemonth {
        width: 314px;
        padding: 22px;

        .box__calendar--head {
            padding: 5px 0;

            .text__title {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .box__calendar--group {
            width: 100%;
        }

        .box__calendar--table {
            th {
                .text {
                    height: 46px;
                    font-size: 11px;
                    line-height: 14px;
                    text-align: center;
                }
            }

            td {
                &[title]::after {
                    bottom: 5px;
                }

                .text {
                    width: 36px;
                    height: 36px;
                    padding-top: 5px;
                    font-size: 12px;
                    line-height: 16px;

                    &__status {
                        bottom: 5px;
                    }
                }

                &:not(.text__state--disabled) .text:hover,
                &.text__state--point .text {
                    &::before {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }

        + .box__calendar--nav {
            top: 22px;
            right: 22px;
            left: 22px;

            .button {
                width: 32px !important;
                height: 32px;
                padding: 0;

                &::before {
                    @include useSvg-icons("calendar_arrow", 24px);
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 3px;
                }

                &--year {
                    &::before {
                        @include useSvg-icons("calendar_arrow_year", 24px);
                    }
                }
            }
        }
    }

    &--nav {
        display: flex;
        position: absolute;
        top: 28px;
        right: 28px;
        left: 28px;
        z-index: 10;
        justify-content: space-between;

        .button {
            position: relative;
            right: 0;
            width: 40px !important;
            min-width: auto;
            max-width: none !important;
            height: 40px;
            border: 1px solid $color-gray-400;
            border-radius: 6px;

            &::before {
                @include useSvg-icons("calendar_arrow", 32px);
                content: "";
                position: absolute;
                top: 3px;
                left: 3px;
            }

            &--year {
                &::before {
                    @include useSvg-icons("calendar_arrow_year", 32px);
                }
            }
        }
    }

    &--next {
        .button::before {
            transform: rotate(180deg);
        }
    }

    .box__layer-calendar {
        display: none;
        position: absolute;
        top: 39px;
        left: 0;
        z-index: 100;
        border: 1px solid $color-gray-900;
        border-radius: 6px;
        background: $color-white;

        &--right {
            right: 0;
            left: auto;
        }

        &--center {
            left: 50%;
            transform: translateX(-50%);
        }

        &--top {
            top: auto;
            bottom: 100%;
        }

        &.is-show {
            display: block;
        }

        .button__wrap {
            align-items: center;
            justify-content: flex-end;
            gap: 12px;
            padding: 24px;
            border-top: 1px solid $color-gray-300;

            .button {
                position: static;
                width: 140px;
                max-width: none !important;
                height: 56px;
                border-radius: 8px;
                font-size: 18px;
                transform: translateY(0);
            }

            .text {
                margin-right: auto;
                color: $color-blue-500;
                font-size: 18px;
            }
        }

        .box__tab--radio {
            display: flex;
            margin: 24px 24px 0;

            .button__tab {
                width: 100%;
            }
        }

        .box__calendar--error {
            display: none;
            position: absolute;
            right: 26px;
            bottom: 113px;
            z-index: 1;
            z-index: 1;
            border-radius: 8px;
            background: $color-gray-900;
            box-shadow: 0 2px 8px 0 rgba($color-black, .12);

            &.is-show {
                display: block;
            }

            .text {
                margin-bottom: 0;
                padding: 21px 24px;
                color: #fff6a3;
                font-size: 16px;

                &::before {
                    @include useSvg-icons("information_mark_yellow", 24px);
                    display: inline-block;
                    content: "";
                    margin: -1px 8px 1px 0;
                    vertical-align: middle;
                }
            }
        }
    }

    .button__date {
        &--prev,
        &--next {
            position: relative;
            width: 40px;
            height: 40px;

            &::before {
                @include useSvg-icons("icon_arrow_active", 20px);
                content: "";
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }

            &:disabled {
                opacity: 1;

                &::before {
                    opacity: .4;
                }
            }

            &:hover {
                border-color: $color-gray-400;
            }
        }

        &--prev {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &--next {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &--statistical {
        display: flex;

        .box__date {
            .box__start {
                width: 336px;
                border-right: 1px solid $color-gray-200;
                border-left: 1px solid $color-gray-200;
                border-radius: 0;

                .form__input-day {
                    width: 100%;
                }
            }
        }

        .box__calendar {
            &--wrap:not(.box__calendar--month) {
                gap: 16px;
                width: 818px;
                padding: 28px 64px 7px;
            }

            &--nav {
                right: 20px;
                left: 20px;

                .button {
                    margin: 0 4px;
                }
            }
        }
    }
}
