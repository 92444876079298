.box__table {
    overflow-x: auto;
    position: relative;
    background: $color-white;

    .table {
        width: 100%;
        // min-width: 100%;
        border-collapse: separate;
        table-layout: fixed;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }

    .table-head {
        .table-row + .table-row {
            .table-data {
                border-top: none;
            }
        }

        .table-data {
            border-top: 1px solid $color-gray-500;
            background: $color-gray-100;
        }

        .text {
            color: $color-gray-700;
        }

        .button__sort {
            position: relative;
            // height: 20px;
            padding-right: 20px;
            font-size: 0;
            cursor: $cursor-hover;

            &::after {
                @include useSvg-icons("icon_sort", 16px);
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }

            &-ascending {
                &::after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }

            &-default {
                &::after {
                    @include useSvg-icons("icon_sort_default", 16px);
                    transform: translateY(-50%);
                }
            }
        }

        .box__dropdown {
            .button__sort { // sorting dropdown
                margin-left: 4px;

                + .list { // 왼쪽 기준
                    top: 100%;
                    right: auto;
                    border: 1px solid $color-gray-900;
                    border-radius: 6px;

                    &.list--align-right { // 오른쪽 기준
                        right: 0;
                        left: auto;
                    }
                }

                &::after {
                    @include useSvg-icons("icon_sort_down", 16px);
                }
            }
        }
    }

    .table-body {
        .form--select,
        .form--select .form__select,
        .box__dropdown {
            width: 100%;
        }
    }

    .table-row {
        &.is-active {
            .table-data,
            .box__dropdown .button__opener,
            .form__input {
                background: $color-blue-50;
            }

            .box__dropdown.is-open .button__opener {
                background: $color-white;
            }
        }

        &.is-fail .text {
            color: $color-red-500 !important;
        }

        &.is-disabled {
            .image__item,
            .text__item-name {
                position: relative;

                &::after {
                    @include absolute-full;
                    opacity: .4;
                    content: "";
                    background: $color-white;
                }
            }
        }

        &--group { // 테이블 헤더 2행 이상일 때
            .table-data:last-of-type::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background: $color-gray-300;
            }
        }

        &--vertical {
            .table-data[rowspan] {
                border-bottom: 0;
            }
        }

        &--point,
        &--combine {  //합배송 관련(combine)
            .table-data {
                background: #fffbe4;
            }
        }

        &--combine2 {
            .table-data {
                background: #fffefa;
            }
        }

        &--gray {
            .table-data {
                background: $color-gray-50;
            }
        }

        &.is-select {
            .table-data {
                box-shadow: inset 0 2px 0 0 $color-blue-500, inset 0 -2px 0 0 $color-blue-500, inset -1px 0 0 0 $color-gray-300;

                &:first-of-type {
                    box-shadow: inset 2px 2px 0 0 $color-blue-500, inset 0 -2px 0 0 $color-blue-500, inset -1px 0 0 0 $color-gray-300;
                }

                &:last-of-type {
                    box-shadow: inset 0 2px 0 0 $color-blue-500, inset -2px -2px 0 0 $color-blue-500;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .table-data {
        position: relative;
        height: 49px;
        padding: 0 16px;
        border-bottom: 1px solid $color-gray-300;
        background: $color-white;
        white-space: nowrap;

        &:not(:last-of-type) {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background: $color-gray-300;
            }
        }

        &.column,
        &.some,
        &.plus {
            border-right: none;

            &::after {
                content: "";
                position: absolute;
                top: 16px;
                right: -8px;
                z-index: 1;
                background-color: transparent;
            }
        }

        &.column::after {
            @include useSvg-icons("minus", 16px);
        }

        &.some::after {
            @include useSvg-icons("equals", 16px);
        }

        &.plus::after {
            @include useSvg-icons("icon_plus", 16px);
        }

        &__inner {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            .text + .button__text {
                margin-left: 4px;
            }

            .box__cta-wrap {
                .text + .button__text {
                    margin-left: 0;
                }
            }

            &--group {
                margin: 0 -16px;

                .table-data__inner {
                    min-height: 49px;
                    padding: 0 16px;

                    + .table-data__inner {
                        border-top: 1px solid $color-gray-300;
                    }
                }
            }

            .image__wrap + .text {
                margin-left: 8px;
                line-height: 32px;
            }

            .box__item-info {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 7px;
                width: 100%;
                white-space: normal;

                .text {
                    overflow: hidden;
                    height: 50px;
                    font-family: $gmarketSans;
                    font-size: 15px;
                    line-height: 25px;
                }

                .image__wrap {
                    width: 64px;
                    height: 64px;
                }
            }
        }

        &__shadow {
            display: block;
            opacity: 1;
            position: absolute;
            top: -1px;
            right: 0;
            bottom: -1px;
            z-index: 1;
            width: 1px;
            background: $color-gray-800;

            &::after {
                opacity: .3;
                content: "";
                position: absolute;
                top: 0;
                right: -4px;
                width: 4px;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, .4) 0%, rgba(217, 217, 217, 0) 90%);
            }
        }

        &__line {
            display: block;
            opacity: 1;
            position: absolute;
            top: -1px;
            right: 0;
            bottom: -1px;
            z-index: 1;
            width: 1px;
            background: #555;
        }

        &__category {
            display: flex;
            position: relative;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 32px;
            margin: 0 auto;

            .text__category {
                .text:not(:last-of-type) {
                    padding-right: 3px;
                }

                &:hover {
                    ~ .text__hover {
                        display: block;
                    }
                }
            }
        }

        &--fixed {
            position: sticky;
            z-index: 2;
        }

        &--clicked {
            padding: 0 12px; // 클릭 요소일 경우 패딩 12px

            .box__dropdown {
                min-width: 108px;

                &.is-error {
                    .button__opener {
                        border: solid 1px $color-red-500;
                    }

                    .box__tooltip {
                        left: 12px;

                        &:hover {
                            z-index: 2;
                        }

                        &-information {
                            max-width: max-content;
                        }
                    }

                    &:hover {
                        .box__tooltip {
                            left: 6px;
                        }

                        .button__opener {
                            padding-left: 26px;
                            border: solid 1px $color-red-500 !important;
                        }
                    }

                    &.is-open {
                        .box__tooltip {
                            left: 6px;
                            z-index: 6;
                        }

                        .button__opener {
                            padding-left: 26px;
                            border: solid 1px $color-red-500;
                        }
                    }
                }

                .box__tooltip:hover + .button__opener,
                &.is-open .button__opener,
                .button__opener:hover {
                    border: solid 1px $color-gray-500;
                    text-align: left;

                    &::after {
                        display: block;
                    }
                }

                .button__opener {
                    width: 100%;
                    padding: 0 8px 0 16px;
                    border: none;
                    border-radius: initial;
                    color: $color-gray-800;
                    text-align: center;

                    &:hover {
                        background: $color-white !important;
                    }

                    &::after {
                        @include useSvg-icons("dropdown_fill", 16px);
                        display: none;
                        right: 12px;
                    }
                }

                .list {
                    border: solid 1px $color-gray-500;
                    border-top: 0;
                    border-radius: initial;

                    &-item {
                        &.is-selected {
                            .button__option {
                                color: $color-blue-500;
                            }
                        }

                        &:hover {
                            .button__opener {
                                background-color: $color-gray-50;
                                color: $color-blue-500;
                            }
                        }

                        .button__option {
                            padding: 0 8px 0 16px;
                        }
                    }
                }
            }

            .table-data__inner--group {
                margin: 0 -12px;

                .table-data__inner {
                    padding: 0 12px;
                }
            }
        }

        &--gap {
            padding: 0 8px;

            &:has(.box__dropdown.is-open) {
                z-index: 5;
            }
        }

        &--left {
            > .table-data__inner--group .table-data__inner {
                justify-content: flex-start;
            }
            
            > .table-data__inner {
                align-items: flex-start;
                justify-content: flex-start;

                .table-data__category {
                    justify-content: flex-start;
                    margin: 0;
                }

                .form__input {
                    text-align: left;
                }
            }

            .form--system,
            .text {
                text-align: left;
            }
        }

        &--right {
            > .table-data__inner--group .table-data__inner {
                justify-content: flex-end;
            }

            > .table-data__inner {
                align-items: flex-end;
                justify-content: flex-end;
            }

            .form__input,
            .button__text {
                text-align: right;
            }
        }

        &--horizontal {
            > .table-data__inner {
                justify-content: space-between;
            }
        }

        &--vertical {
            > .table-data__inner {
                flex-direction: column;
            }
        }

        &--point {
            background: #fffbe4;
            text-align: right;
        }

        &--ellipsis {
            .text:not(.text--gmarket, .text--auction),
            .button__text,
            .link__text,
            .form--system:not(.is-error),
            .table-data__category .text__category {
                @include ellipsis;
            }

            .box__gap--vertical {
                width: 100%;

                > .text {
                    width: 100%;
                }
            }

            .form--system .text__value {
                @include ellipsis;
                width: 100%;
                vertical-align: middle;
            }
        }

        &--null {
            background: $color-gray-50;
        }

        &--multiline {
            padding: 14px 16px;

            &.table-data--ellipsis {
                .text {
                    @include multi-ellipsis(2);
                    white-space: break-spaces;
                    word-break: normal;
                }
            }

            &.table-data--center {
                .box__gap {
                    justify-content: center;
                }
            }

            .table-data__inner {
                flex-wrap: wrap;
            }

            .text,
            .button__text {
                white-space: initial;
                word-break: break-all;
            }

            .text + .text__sub {
                color: $color-gray-700;
                font-size: 12px;
                line-height: 16px;
            }

            .table-data__category {
                display: block;
                height: auto;
                text-align: left;

                > .text,
                .text__category {
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }

        &--top {
            padding-top: 15px;
            vertical-align: top;
        }

        &--summary {
            height: 56px;
            padding: 0 16px;

            &.is-active {
                background: $color-blue-50;
                box-shadow: inset 2px 2px 0 0 $color-blue-500,inset -2px -2px 0 0 $color-blue-500;
            }

            .table-data__inner {
                justify-content: space-between;
                gap: 16px;
                width: calc(100vw - 194px);
                max-width: 1120px;

                .box__product {
                    &--name {
                        display: flex;
                        align-items: center;
                        width: 700px;

                        .text {
                            @include ellipsis;
                            max-width: calc(100vw - 648px);
                            font-family: $gmarketSans;
                            font-size: 16px;
                        }
                    }

                    &--detail {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                    }

                    &--link {
                        .link__text {
                            padding-right: 12px;
                            color: $color-gray-600;

                            &::before {
                                display: inline-block;
                                content: " ";
                                width: 1px;
                                height: 16px;
                                margin: -1px 16px 1px 0;
                                background: $color-gray-300;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }

        &--manage {
            height: 80px;
            padding: 0 16px;

            .table-data__inner {
                justify-content: space-between;

                .box__product--info {
                    width: 100%;
                    min-width: 444px;
                    max-width: 1084px;
                    text-align: left;

                    .text__product--number {
                        margin-left: 44px;
                        color: $color-gray-600;
                    }
                }

                .box__product--name {
                    display: flex;
                    align-items: center;

                    .text {
                        @include ellipsis;
                        margin-left: 12px;
                        font-family: $gmarketSans;
                        font-size: 16px;
                    }
                }

                .box__product--detail {
                    min-width: 380px;
                    margin-left: 32px;
                    text-align: right;

                    .text__detail {
                        display: inline-block;
                        color: $color-gray-800;
                        font-family: $gmarketSans;
                        font-size: 16px;
                        line-height: 32px;
                    }

                    .text__penalty {
                        color: $color-gray-600;
                    }
                }
            }
        }

        &--scroll {
            padding: 0;

            .table-data__inner {
                overflow-y: auto;
                padding: 14px 16px;
            }
        }
    }

    .text {
        &:hover ~ .text__hover {
            display: block;
        }

        &--gray {
            color: $color-gray-500 !important;
        }

        &--red {
            color: $color-red-500 !important;

            + .link__icon {
                margin-left: 6px;
            }
        }
    }

    .box__cta-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin: 0;
        text-align: center;

        .text__ellipsis {
            @include ellipsis();
            max-width: 161px;
        }
    }

    .button {
        font-family: $font-default;
    }

    .form + .text {
        color: $color-gray-600;
    }

    .box__no-content {
        height: 160px;
        border-bottom: 1px solid $color-gray-300;

        &:not(.box__no-content--gray) {
            &::before {
                display: none;
            }
        }

        &--gray {
            height: 515px;
        }
    }

    &--scroll {
        overflow-x: scroll;
        overflow-y: auto;
        width: 100%;
        height: 582px;

        &.is-empty {
            .table-data__line,
            .table-data__shadow {
                display: none;
            }
        }

        .table {
            width: 100%;

            > .table-head {
                position: sticky;
                top: 0;
                z-index: 4;
            }
        }

        .box__no-content {
            height: calc(100% - 98px);
            border-bottom: 0;
        }

        &-body {
            overflow: auto;
            width: 100%;
            max-height: 539px;
            border-bottom: 1px solid $color-gray-300;

            .table {
                > .table-head {
                    position: sticky;
                    top: 0;
                    z-index: 4;
                }

                > .table-body {
                    .table-row:last-child {
                        .table-data {
                            border-bottom: 0;
                        }
                    }
                }
            }

            .box__no-content {
                border-bottom: 0;
            }
        }
    }

    &--hover {
        .table-body {
            .table-row:hover:not(.is-disabled) {
                .table-data:not(.table-data--manage) {
                    background: $color-gray-50;
                }
            }

            .table-row.is-active:hover {
                .table-data:not(.table-data--manage) {
                    background: $color-blue-50;
                }

                .table-data--gap {
                    .box__dropdown .button__opener:hover,
                    .form__input:hover {
                        background: $color-white;
                    }
                }
            }
        }
    }

    &--merge {
        .table-body {
            .table-row {
                &:first-of-type {
                    .table-data {
                        padding: 12px 20px 0 !important;

                        &__inner {
                            margin-top: 0;
                        }
                    }
                }

                &:last-of-type {
                    .table-data {
                        padding: 0 20px 12px !important;
                        border-bottom: 1px solid $color-gray-300;
                    }
                }
            }

            .table-data {
                height: 40px;
                padding: 0 20px !important;
                border: 0;
                vertical-align: top;

                &__inner {
                    gap: 8px;
                    margin-top: 8px;
                }
            }
        }
    }

    &--line-none {
        .table-data {
            text-align: left;
            vertical-align: top;
        }

        .table-head .table-data {
            padding: 0 0 2px;

            .text {
                margin-right: 4px;
                color: $color-gray-800;
            }
        }

        .table-body .table-data {
            padding: 12px 16px 0 0;

            .box__group {
                padding-right: 16px;

                .form {
                    width: calc(50% - 16px);
                }
            }
        }

        .box__cta-wrap {
            display: flex;
            gap: 8px;

            .button {
                font-family: $font-default;
                white-space: nowrap;

                &--delete {
                    background: $color-white;
                }
            }
        }

        .form {
            width: 100%;

            &.price-oper {
                width: calc(25% - 32px); // 상품등록 > 판매가 > 휴대폰 추가정보
            }

            > .text {
                width: auto;
                margin-right: 16px;
            }
        }

        .box__dropdown {
            width: 100%;
        }

        .text--multiplication {
            @include useSvg-icons("delivery_delete", 16px);
            margin-top: 12px;
        }

        .text--subtraction {
            @include useSvg-icons("icon_minus", 16px);
            margin-top: 12px;
        }

        .text--equals {
            @include useSvg-icons("icon_equals", 16px);
            margin-top: 12px;
        }

        .is-field .button__opener,
        .is-field.box__search .form {
            &::before {
                content: "";
                position: absolute;
                top: 8px;
                right: 52px;
                bottom: 8px;
                z-index: 1;
                width: 1px;
                background: $color-gray-300;
            }

            .form__input {
                padding-right: 60px;
            }
        }

        ~ .button {
            width: 268px;
        }
    }

    &--multi {
        .table-head {
            height: 89px;

            .button--small {
                align-self: center;
            }
        }

        .table-body {
            .table-data {
                height: 65px;
            }
        }
    }

    &--row {
        .table {
            border-collapse: collapse;
        }

        .table-row:first-of-type {
            border-top: 1px solid $color-gray-500;
        }

        .table-data {
            &__inner {
                justify-content: flex-start;
            }
        }

        td.table-data {
            padding: 14px 16px;

            &:not(:last-of-type)::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background: $color-gray-300;
            }
        }

        th.table-data {
            padding: 14px 16px;
            background: $color-gray-100;
            vertical-align: top;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background: $color-gray-300;
            }

            &:first-child:last-child {
                &::after {
                    display: none;
                }
            }

            &--middle {
                vertical-align: middle;
            }

            .text {
                color: $color-gray-700;
            }
        }

        &:has(+.box__table) {
            .table-row:last-of-type .table-data {
                border-bottom: none;
            }
        }
    }

    &--category {
        .table-data::after {
            display: none;
        }

        .table-body {
            .table-row {
                &:hover {
                    .table-data {
                        background: $color-gray-50 !important;
                    }
                }

                &.is-active {
                    .table-data {
                        background: rgba(238, 247, 255, 1);
                        box-shadow: inset 0 2px 0 0 $color-blue-500, inset 0 -2px 0 0 $color-blue-500;

                        &:first-of-type {
                            box-shadow: inset 2px 2px 0 0 $color-blue-500, inset 0 -2px 0 0 $color-blue-500;
                        }

                        &:last-of-type {
                            box-shadow: inset 0 2px 0 0 $color-blue-500, inset -2px -2px 0 0 $color-blue-500;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .button--icon {
                        background: $color-blue-500;
                        color: $color-white;

                        &::before {
                            @include useSvg-icons("icon_chart--clicked", 16px);
                        }
                    }
                }
            }

            .table-data {
                height: 96px;
                font-family: $gmarketSans;

                .text {
                    font-size: 16px;

                    &--increase {
                        margin-right: 8px;
                        color: $color-red-500;
                        font-size: 13px;
                    }

                    &--decrease {
                        margin-right: 8px;
                        color: $color-blue-500;
                        font-size: 13px;

                        .icon__arrow {
                            &::before {
                                @include useSvg-icons("icon_decrease_value", 16px);
                            }
                        }
                    }

                    &--same {
                        margin-right: 8px;
                        color: $color-gray-500;
                        font-size: 13px;

                        .icon__arrow {
                            &::before {
                                width: 7px;
                                height: 2px;
                                margin: -1px 6px 1px 4px;
                                background: $color-gray-500;
                                vertical-align: middle;
                            }
                        }
                    }
                }

                .icon__arrow {
                    display: inline-block;

                    &::before {
                        @include useSvg-icons("icon_increase_value", 16px);
                        display: inline-block;
                        content: " ";
                        margin: -1px 0 1px;
                        vertical-align: middle;
                    }
                }
            }
        }

        .image__wrap {
            width: 64px;
            height: 64px;

            .image__blank::after {
                width: 44px;
                height: 44px;
            }
        }

        .text__wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            max-width: 83%;
            height: 64px;
            margin-left: 10px;

            .box__gap {
                flex: 0;
                gap: 4px;

                + .text {
                    margin-top: 2px;
                }

                .link__icon {
                    font-family: $font-default;
                }
            }

            .text__epin {
                color: $color-gray-600;
                font-family: $font-default;
                font-size: 14px;
                line-height: 16px;
            }

            .button__text {
                line-height: 16px;

                + .box__tooltip {
                    margin: 0;
                }
            }
        }

        .box__progress {
            overflow: hidden;
            position: relative;
            width: 156px;
            height: 32px;
            border-radius: 4px;
            background: rgba($color-black, .05);
            text-align: left;


            .text {
                display: inline-block;
                position: relative;
                width: 50px;
                margin-left: 4px;
                padding-top: 7px;
                color: $color-blue-400;
                text-align: left;
            }

            &-inner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &-bar {
                display: inline-block;
                height: 32px;
                background: $color-blue-400;
                vertical-align: top;
            }

            &-more {
                .box__progress-bar {
                    background: $color-blue-700;
                }

                .text {
                    left: -58px;
                    margin-left: 0;
                    color: $color-white;
                    text-align: right;
                }
            }
        }
    }

    &.box__table-row {
        .table-body {
            th.table-data {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 100%;
                    background: $color-gray-300;
                }
            }
        }
    }

    + .box__pagination,
    + .box__process-setting,
    + .box__information {
        margin-top: 20px;
    }

    .box__board,
    + .box__board {
        height: calc(100% - 98px);
        border: 0;
    }
    
    &:has(.box__tooltip-bubble) {
        .table-data--fixed {
            z-index: 4;
        }
    }
}

.box__table-row {
    > .table {
        width: 100%;
        table-layout: fixed;

        > .table-body >.table-row {
            border-bottom: 1px solid $color-gray-300;

            &:first-of-type {
                border-top: 1px solid $color-gray-500;
            }

            > th.table-data {
                padding: 19px 16px;
                background: $color-gray-100;
                text-align: left;
                vertical-align: top;

                &--center {
                    text-align: center;
                }

                &--middle { //vertical-align:middle 대신 사용
                    padding: 29px 16px 20px;
                }

                .text { //고정(사용X -> .text__layer사용)
                    font-size: 16px;
                    line-height: 22px;
                }

                .table-data__inner {
                    &:has(.box__tooltip) {
                        font-size: 0;

                        .box__tooltip {
                            margin-left: 0;
                        }
                    }
                }
            }

            > td.table-data {
                padding: 20px 0 20px 20px;
                background: $color-white;

                &:not(.table-data--vertical) {
                    .table-data__inner {
                        gap: 12px;
                    }
                }

                .table-data__inner {
                    display: flex;

                    .button {
                        max-width: fit-content;
                    }
                }

                &--vertical {
                    > .table-data__inner {
                        flex-direction: column;

                        + .table-data__inner {
                            margin-top: 20px;
                        }
                    }
                }

                &--middle { //vertical-align:middle 대신 사용
                    padding: 29px 16px 20px;
                }
            }
        }
    }

    + .box__information {
        margin: 16px 0 0;

        & + .box__bundle {
            margin-top: 16px;
        }

    }
    
    + .box__pagination {
        margin-top: 20px;
    }

    + .box__panel,
    + .box__bundle,
    + .box--gray {
        margin-top: 16px;

        &:not(.box__panel, .box__bundle) {
            .form__checkbox {
                vertical-align: initial;
            }
        }
    }

    + .box__process-setting {
        margin-top: 32px;
    }

    .box__table {
        .table { // row 테이블 안에 default 테이블인 경우
            .table-data {
                padding: 12px 16px;

                &__inner {
                    gap: 8px;

                    + .table-data__inner {
                        margin-top: 8px;
                    }
                }

                .box__cta-wrap {
                    .button {
                        padding: 0 12px;
                    }
                }
            }
        }
    }

    .box__information {
        margin: 0;
    }
}

// 상품등록 > 임시저장 레이어팝업
.box__table.save-temporary {
    .table-body {
        .table-row:hover {
            cursor: $cursor-hover;
        }
    }

    .table-data {
        padding: 20px;

        &__inner {
            align-items: flex-start;
        }
    }

    .image__wrap {
        width: 98px;
        height: 98px;

        &:hover {
            border-color: $color-gray-300;
        }
    }

    .text__wrap {
        margin-left: 16px;
        text-align: left;

        .text__point {
            @include multi-ellipsis(2);
            text-align: left;
            white-space: break-spaces;
        }

        .text__date {
            display: inline-block;
            margin: 4px 0 0;
            color: $color-gray-500;
        }
    }
}

.box__table-wrap {
    position: relative;

    .is-empty {
        + .box__no-content,
        + .box__board {
            overflow: visible;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .table-body {
            display: block; //맥 사파리 대응
            height: 160px;
        }

        + .box__no-content:not(.box__no-content--gray) {
            height: 160px;

            &::before {
                display: none;
            }
        }

        &:not(.box__table--scroll) {
            border-bottom: 1px solid $color-gray-300;
        }
    }

    &:has(.box__no-content--gray),
    &:has(.box__board), {
        .is-empty:not(.box__table--scroll) {
            .table-body {
                height: 515px;
            }
        }

        .box__no-content--gray,
        .box__board {
            height: 515px;
        }

        .box__table--scroll-body {
            &.is-empty:not(.box__table--scroll) {
                .table-body {
                    height: 489px;
                }
            }

            + .box__no-content--gray,
            + .box__board {
                height: 489px;
            }
        }
    }

    + .box__pagination {
        margin-top: 20px;
    }
}
