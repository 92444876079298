.box {
    position: relative;
    border: 1px solid $color-gray-300;
    border-radius: 8px;
    background: $color-white;
    font-size: 0;

    + .box {
        margin-top: 16px;
    }

    + .box__notice-summary,
    + .button__wrap {
        margin-top: 24px;
    }

    + .box__information {
        margin-top: 16px;
        margin-bottom: 0;
    }

    > .button__close {
        @include useSvg-icons("ico_close_black-24", 24px);
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &__container {
        .box__error {
            min-height: 960px;
        }
    }

    &__wrap {
        position: relative;
        // min-width: 1060px;
        // padding: 0 16px 24px 0;
        padding: 28px 24px 24px;

        > .box__tab--panel > .box__information,
        > .box__information:not(.box__information--red, .box__information--blue) {
            .list__information {
                .list-item {
                    color: $color-gray-800;
                }
            }
        }

        > .box__information {
            .link__text:first-child {
                margin-left: 8px;
            }

            .button__guide-faq {
                margin: -1px 0 1px 5px;
            }
        }
        @at-root .ifm_contents & {
            padding: 10px 20px 24px 0;
        }
        
        &__title {
            display: flex;
            position: relative;
            align-items: center;
            gap: 12px;
            margin-bottom: 16px;
    
            .box__dropdown {
                min-width: 230px;
            }
    
            .title {
                margin-bottom: 0;
            }
        }
    }

    &--blue {
        padding: 16px 16px 16px 51px;
        border-color: $color-blue-100;
        background: $color-blue-50;

        &::before {
            @include useSvg-icons("icon_alert-blue", 24px);
            content: "";
            position: absolute;
            top: 23px;
            left: 15px;
        }
    }

    &--red {
        border-color: $color-red-300;
        background: $color-red-50;
    }

    &--gray {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding: 16px;
        background: $color-gray-100;

        .text__description {
            display: inline-flex;
            align-items: center;
            height: 36px;
            margin-bottom: 0;
            padding: 0 16px;
            border: 1px solid $color-gray-300;
            border-radius: 6px;
            background: $color-white;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;
        }

        .button__description-delete {
            height: 100%;

            &::after {
                @include useSvg-icons("delivery_delete", 16px);
                display: inline-block;
                content: "";
                margin: -1px 0 1px 8px;
                vertical-align: middle;
            }
        }

        .button__all-delete {
            position: absolute;
            top: 16px;
            right: 16px;
        }

        .box__align > .text {
            line-height: 20px;
        }
    }
}

.box__line-radius {
    border: 1px solid $color-gray-300;
    border-radius: 6px;
    background: $color-white;

    .box__filter-group {
        padding: 16px;
    }
}

.box__indent {
    margin-left: 36px;
}

.box__announce {
    padding: 16px 40px 16px 72px;
    border: 1px solid $color-blue-500;
    background: $color-blue-50;

    &::before {
        @include useSvg-icons("emoji", 40px);
        content: "";
        position: absolute;
        top: 14px;
        left: 20px;
    }

    &.icon-gift::before {
        @include useSvg-icons("emoji_gift", 40px);
    }

    &.icon-finger::before {
        @include useSvg-icons("emoji_goodsflow", 40px);
    }

    &.icon-smile::before {
        @include useSvg-icons("emoji_smile", 40px);
    }

    &.icon-dart::before {
        @include useSvg-icons("emoji-dart", 40px);
    }

    &.icon-search::before {
        @include useSvg-icons("emoji_search", 40px);
    }

    &.icon-info-red::before {
        @include useSvg-icons("information_mark_red", 32px);
        top: 50%;
        transform: translateY(-50%);
    }

    &.icon-bsd::before {
        @include useSvg-icons("emoji_bsd", 40px);
        top: 24px;
    }

    .text + .text {
        margin-top: 2px;
        font-size: 12px;
        line-height: 16px;

        .button__guide-faq {
            margin: -1px 0 1px 5px;
        }
    }

    .text__point {
        .button__guide-faq {
            margin: -1px 0 1px 4px;
        }
    }

    .text__sub {
        margin-left: 8px;
        color: $color-gray-500;
        font-weight: 400;
        font-size: 12px;
    }

    .button__more {
        color: $color-blue-500;
        font-size: inherit;
        line-height: 16px;
        text-decoration: underline;
    }

    .box__information {
        margin-top: 4px;

        .list-item {
            font-size: 12px;
            line-height: 16px;

            .link__text {
                margin-left: 8px;
            }
        }
    }

    &--none {
        padding-left: 24px;

        &::before {
            display: none;
        }

        ~ .box__table,
        ~ .table {
            margin-top: 13px;
        }
    }

    &--red {
        border: 1px solid $color-red-400;
        background: $color-red-50;

        + .box__panel--reset {
            margin-top: 12px;
        }
    }

    &--bsd {
        padding: 24px 40px 24px 72px;
        border: 1px solid #7b00e7;
        background: #fbf5ff;

        .text__bsd {
            color: #7b00e7;
        }
    }
}

.box__flag {
    display: inline-block;
    font-size: 0;
    vertical-align: middle;

    &-wrap {
        display: flex;
        position: relative;
        align-items: center;
        gap: 12px;

        &--absolute {
            .box__flag {
                position: absolute;
                top: 8px;
                left: 16px;
                z-index: 3;

                &::before {
                    display: block;
                }
            }

            .form .form__input {
                padding-left: 48px;

                ~ .form__label {
                    left: 48px;
                }
            }

            .form__multi {
                .form .form__input {
                    padding-right: 80px;
                }
            }

            &:has(.text__unit) {
                .form .form__input {
                    padding-right: 35px;
                }
            }

            &:has(.text__side) {
                .form .form__input {
                    padding-right: 60px;
                }
            }
        }

        .box__dropdown {
            .button__opener {
                padding: 0 42px 0 16px;
            }
        }
    }

    &::before {
        display: inline-block;
        content: "";
        vertical-align: middle;
    }

    &--gmarket::before {
        @include useSvg-icons("icon_gmarket", 24px);
    }

    &--auction::before {
        @include useSvg-icons("icon_auction", 24px);
    }

    &--keb::before { //하나은행, 하나증권
        @include sp-pc("bank_keb");
    }

    &--bankofamerica::before { //뱅크오브아메리카
        @include sp-pc("bank_bankofamerica");
    }

    &--bookook::before { //부국증권
        @include sp-pc("bank_bookook");
    }

    &--busan::before { //부산은행
        @include sp-pc("bank_busan");
    }

    &--capefn::before { //케이프투자증권
        @include sp-pc("bank_capefn");
    }

    &--citi::before { //한국시티은행
        @include sp-pc("bank_citi");
    }

    &--cu::before { //신협
        @include sp-pc("bank_cu");
    }

    &--daishin::before { //대신증권
        @include sp-pc("bank_daishin");
    }

    &--dbfi::before { //DB금융투자
        @include sp-pc("bank_dbfi");
    }

    &--deutsche::before { //도이치은행
        @include sp-pc("bank_deutsche");
    }

    &--dgb::before { //대구은행
        @include sp-pc("bank_dgb");
    }

    &--ebestsec::before { //이베스트투자증권
        @include sp-pc("bank_ebestsec");
    }

    &--epost::before { //우체국
        @include sp-pc("bank_epost");
    }

    &--eugenefn::before { //유진증권
        @include sp-pc("bank_eugenefn");
    }

    &--fsb::before { //저축은행중앙회
        @include sp-pc("bank_fsb");
    }

    &--hanwhawm::before { //한화투자증권
        @include sp-pc("bank_hanwhawm");
    }

    &--hiib::before { //하이투자증권
        @include sp-pc("bank_hiib");
    }

    &--hmsec::before { //현대차증권
        @include sp-pc("bank_hmsec");
    }

    &--hsbc::before { //HSBC은행
        @include sp-pc("bank_hsbc");
    }

    &--ibk::before { //기업은행
        @include sp-pc("bank_ibk");
    }

    &--icbc::before { //중국공상은행
        @include sp-pc("bank_icbc");
    }

    &--imeritz::before { //메리츠증권
        @include sp-pc("bank_imeritz");
    }

    &--iprovest::before { //교보증권
        @include sp-pc("bank_iprovest");
    }

    &--jb::before { //전북은행
        @include sp-pc("bank_jb");
    }

    &--jeju::before { //제주은행
        @include sp-pc("bank_jeju");
    }

    &--jp::before { //JP모간체이스은행
        @include sp-pc("bank_jp");
    }

    &--k::before { //케이뱅크
        @include sp-pc("bank_k");
    }

    &--kakao::before { //카카오뱅크
        @include sp-pc("bank_kakao");
    }

    &--kb::before { //국민은행, KB증권
        @include sp-pc("bank_kb");
    }

    &--kdb::before { //산업은행
        @include sp-pc("bank_kdb");
    }

    &--kiwoom::before { //키움증권
        @include sp-pc("bank_kiwoom");
    }

    &--kj::before { //광주은행
        @include sp-pc("bank_kj");
    }

    &--kn::before { //경남은행
        @include sp-pc("bank_kn");
    }

    &--koreainvestment::before { //한국투자증권
        @include sp-pc("bank_koreainvestment");
    }

    &--mg::before { //MG새마을금고
        @include sp-pc("bank_mg");
    }

    &--miraeasset::before { //미래에셋증권
        @include sp-pc("bank_miraeasset");
    }

    &--myasset::before { //유안타증권
        @include sp-pc("bank_myasset");
    }

    &--nfcf::before { //산림조합중앙회
        @include sp-pc("bank_nfcf");
    }

    &--nh::before { //농협은행, NH투자증권
        @include sp-pc("bank_nh");
    }

    &--samsung::before { //삼성증권
        @include sp-pc("bank_samsung");
    }

    &--sbisb::before { //SBI저축은행
        @include sp-pc("bank_sbisb");
    }

    &--sc::before { //SC제일은행
        @include sp-pc("bank_sc");
    }

    &--shin::before { //신한은행, 신한투자증권
        @include sp-pc("bank_shin");
    }

    &--shinyoung::before { //신영증권
        @include sp-pc("bank_shinyoung");
    }

    &--sks::before { //SK증권
        @include sp-pc("bank_sks");
    }

    &--suhyup::before { //수협은행
        @include sp-pc("bank_suhyup");
    }

    &--woori::before { //우리은행
        @include sp-pc("bank_woori");
    }

    &--toss::before { //토스뱅크
        @include sp-pc("bank_toss");
    }
}

.box__process-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    &-inner {
        font-size: 0;

        .text {
            margin-left: 12px;
        }
    }

    .box__flag {
        &::before {
            margin-right: 8px;
        }
    }
}

.box__description {
    margin: 32px 0 16px;

    .text__layer + .text {
        margin-top: 8px;
    }

    .text {
        margin-bottom: 4px;
        color: $color-gray-600;
    }
}

.box__bundle { // 요소들간 공통 간격(12px)이 필요할 때
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    font-size: 0;

    &--line {
        width: 100%;
        padding-top: 20px;
        border-top: 1px solid $color-gray-200;
    }

    &--gray {
        width: 100%;
        padding: 16px;
        background-color: $color-gray-50;
    }

    > .box__filter-item {
        padding: 0 !important;
        border-top: none;
    }

    .box__align {
        .text {
            margin-right: 8px;
            line-height: normal;

            + .box__tooltip {
                margin-left: -4px;
            }
        }
    }

    .box__filter-group {
        + .box__information {
            margin: 0;
        }
    }

    .box__tab--multiple + .text--red {
        margin-top: -10px;
    }
}

.box__group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;

    > .text,
    > .link__text {
        line-height: 40px;
    }

    .text__description {
        margin-top: 0 !important;
    }

    > .form + .button {
        margin-left: 4px;
    }

    &--vertical {
        flex-direction: column;
    }

    .box__label {
        padding: 0;
        font-size: 0;

        > .text {
            line-height: 40px;
        }

        .text.text__primary {
            + .box__tooltip .button__tooltip::before {
                @include useSvg-icons("information_mark_blue_line", 16px);
            }
        }
    }
}

.list__tab-board {
    display: flex;

    .list-item {
        display: inline-block;
        position: relative;
        flex: 1;
        height: 40px;

        &:last-child {
            .button__tab {
                border-right: 1px solid $color-gray-400;
            }
        }

        &.is-active {
            .button__tab {
                border-color: $color-blue-500;
                background: $color-white;
                color: $color-blue-500;

                &::before {
                    @include useSvg-icons("ico_checked_blue-16", 16px);
                    display: inline-block;
                    content: "";
                    margin-right: 4px;
                    vertical-align: middle;
                }
            }

            + .list-item {
                .button__tab {
                    border-left: 1px solid $color-blue-500;
                }
            }
        }

        &:first-child {
            .button__tab {
                border-radius: 6px 0 0;
            }
        }

        &:last-child {
            .button__tab {
                border-radius: 0 6px 0 0;
            }
        }
    }

    .button__tab {
        @include absolute-full;
        width: 100%;
        height: 100%;
        border-top: 1px solid $color-gray-400;
        border-bottom: 1px solid $color-gray-400;
        border-left: 1px solid $color-gray-400;
        background: $color-gray-50;
        color: $color-gray-600;
        font-size: 14px;
        line-height: 20px;
    }
}

.box__board {
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 232px;
    border: 1px solid $color-gray-400;
    border-top: none;
    border-radius: 0 0 6px 6px;

    &-wrap {
        padding: 0 0 20px;

        &:last-of-type {
            padding-bottom: 0;
        }

        + .box__board-wrap {
            margin-top: 12px;
            padding-top: 32px;
            border-top: 1px solid $color-gray-200;
        }

        + .box__global {
            border-top: 1px solid $color-gray-200;

            + .box__filter-group {
                + .box__board-wrap {
                    margin-top: 20px;
                }
            }

            .button__text--down + .box__tooltip {
                margin-left: 0;
            }
        }

        + .text__wrap {
            margin-top: 20px;

            + .box__filter-item {
                display: none;
                border-top: none;
            }
        }

        .box__align--between {
            align-items: flex-start;
            gap: 40px;
            margin-top: 8px;

            &:has(.box__button-group) {
                align-items: center;
            }
        }

        .box__button-group {
            display: flex;
            gap: 12px;
            margin-top: 4px;

            .button {
                word-break: keep-all;
            }
        }
    }

    &-wrap--74 {
        .list__tab-board {
            .list-item {
                position: relative;
                height: 74px;

                &:first-child {
                    .form__label {
                        border-radius: 6px 0 0;
                    }

                    &:has(.form__radio:disabled) {
                        .text__description {
                            position: absolute;
                            top: -18px;
                            left: 0;
                            z-index: 10;
                            margin: 0;
                        }
                    }
                }

                &:last-child {
                    .form__label {
                        border-right: 1px solid $color-gray-400;
                        border-radius: 0 6px 0 0;
                    }
                }

                &.is-active {
                    .form__label {
                        border-color: $color-blue-500 !important;
                        background: $color-white;
                        color: $color-blue-500;

                        &-ai::before {
                            @include useSvg-icons("tab_star_active", 20px);
                        }

                    }

                    .text__tab {
                        font-weight: 700;
                    }


                    + .list-item {
                        .form__label {
                            border-left: 1px solid $color-blue-500 !important;
                        }
                    }
                }

                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ li {
                    .form__label {
                        @media only screen and (max-width: 1060px) {
                            padding: 8px 8px 8px 47px;
                        }
                    }
                }
            }


            .form__radio {
                position: absolute;
                top: 50%;
                left: 32px;
                z-index: 10;
                transform: translateY(-50%);
                @media only screen and (max-width: 1060px) {
                    left: 16px;
                }

                &:disabled + .form__label {
                    color: $color-gray-400;

                    &-ai::before {
                        @include useSvg-icons("tab_star_disabled", 20px);
                    }
                }
            }

            .form__label {
                @include absolute-full;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 16px 52px;
                border-top: 1px solid $color-gray-400;
                border-bottom: 1px solid $color-gray-400;
                border-left: 1px solid $color-gray-400;
                background: $color-white;
                color: $color-gray-600;
                font-size: 0;
                line-height: normal;
                text-align: center;
                cursor: pointer;

                &-ai {
                    &::before {
                        @include useSvg-icons("tab_star", 20px);
                        display: inline-block;
                        content: "";
                        margin-right: 4px;
                        vertical-align: middle;
                    }

                    .text__tab {
                        vertical-align: middle;
                    }
                }

                .text__tab {
                    font-family: $gmarketSans;
                    font-size: 16px;
                    line-height: 22px;
                }

                .text__sub {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .box__board {
            overflow: visible;
            height: 400px;
            padding: 24px;
            background: $color-gray-50;

            &:has(.box__board-search) {
                justify-content: space-between;
                @media only screen and (max-width: 1060px) {
                    height: 421px;
                }

                .text__board-title {
                    margin-top: 42px;
                }
            }

            .text__board-title {
                margin-bottom: 6px;
                color: $color-gray-800;
                font-family: $gmarketSans;
                font-size: 24px;
                line-height: 32px;
                text-align: center;

                +.text {
                    margin-bottom: 24px;
                    color: $color-gray-600;
                }
            }

            .box__image-wrap {
                .box__align--between {
                    display: flex;
                    margin-top: 12px;

                    .button {
                        width: 160px;
                    }
                }
            }

            .image__wrap {
                width: 547px;
                height: 300px;
                border-radius: 6px;

                &:hover {
                    border-color: $color-gray-300;
                }
            }

            .box__no-content {
                &::before {
                    @include useSvg-icons("icon_nodata_gray", 48px);
                }

                .text__board-title {
                    margin: 0;

                    + .text {
                        margin-top: 6px;
                    }
                }

                .text__no-content {
                    margin-bottom: 16px;
                }

                + .button {
                    width: auto;
                }
            }
        }

        .text__wrap {
            margin-top: 16px;
        }

        .box__information {
            margin-top: 16px;

            .link__text:first-of-type {
                margin-left: 4px;
            }
        }

        .box__gap-12 {
            margin-top: 20px;

            .list__image {
                gap: 8px;
            }

            .list-item {
                width: 132px !important;

                &::after {
                    content: none;
                }

                &.is-active {
                    .box__image {
                        border-color: $color-blue-500;
                    }
                }

                .box__image {
                    height: 72px;
                }
            }
        }
    }

    &-textarea {
        width: 100%;
        height: 100%;
        padding: 16px;
        border: 0;
        resize: none;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $color-gray-400;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 67px;
        padding: 22px;
        border: 1px solid $color-gray-200;
        border-radius: 6px;
        background: $color-white;

        .text {
            margin-bottom: 16px;
            color: $color-gray-600;
            text-align: center;
        }
    }

    &-spinner {
        color: $color-gray-800;
        font-family: $gmarketSans;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        .box__loader-circle {
            position: relative;
            top: -16px;
        }
    }

    .button {
        width: 268px;
    }

    .box__no-content {
        &::before {
            @include useSvg-icons("icon_nodata_gray_small", 40px);
        }

        &--done {
            &::before {
                @include useSvg-icons("icon_data_small", 40px);
            }
        }

        .text__no-content {
            margin-bottom: 20px;
        }
    }

    + .text--red {
        margin: 2px 0 0 16px;
        font-size: 12px;
        line-height: 16px;
    }
}

.button__option {
    &.is-active {
        border-color: $color-blue-500;
        color: $color-blue-500;
    }
}

.box__option-choice {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    border: solid 1px $color-gray-400;
    border-radius: 36px;
    background: $color-white;

    .button__option-delete {
        width: 16px;
        height: 16px;
        padding-left: 4px;

        &::after {
            @include useSvg-icons("delivery_delete", 16px);
            display: inline-block;
            content: "";
        }
    }
}

.box__no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text__no-content {
        color: $color-gray-500;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
    }

    &::before {
        @include useSvg-icons("icon_nodata", 48px);
        display: block;
        content: "";
        margin-bottom: 8px;
    }

    &--gray {
        &::before {
            @include useSvg-icons("icon_nodata_gray", 48px);
        }
    }

    &--graysmall {
        &::before {
            @include useSvg-icons("icon_nodata_gray_small", 40px);
        }
    }
}

.title {
    margin-bottom: 16px;
    color: $color-gray-900;
    font-family: $gmarketSans;
    font-size: 24px;
    line-height: 32px;

    > .button__guide-faq {
        margin: -2px 0 2px 4px;

        &::before {
            @include useSvg-icons("question_mark", 24px);
        }
    }

    .required-mark {
        &::before {
            margin-left: 21px;
        }
    }

    @at-root .box__wrap > .title .box__tooltip {
        width: 24px;
        height: 24px;

        &--rb {
            .box__tooltip-information {
                top: 30px;
                left: -6px;
            }
        }

        .button__tooltip {
            width: 24px;
            height: 24px;

            &::before {
                @include useSvg-icons("information_mark", 24px);
            }
        }
    }
}

.text {
    color: $color-gray-800;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;

    &--gmarket {
        color: $color-green-500 !important;

        + .text {
            margin-left: 3px;
        }

        + .text__category {
            max-width: calc(100% - 13px);
            margin-left: 3px;
        }
    }

    &--auction {
        color: $color-red-500 !important;

        + .text {
            margin-left: 3px;
        }

        + .text__category {
            max-width: calc(100% - 13px);
            margin-left: 3px;
        }
    }

    &--red {
        width: max-content;
        color: $color-red-500 !important;

        .link__text {
            color: $color-red-500;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &--blue {
        width: max-content;
        color: $color-blue-500 !important;
    }

    &--green {
        color: $color-green-500;
    }

    &--gray,
    &.text__address {
        color: $color-gray-600;
    }

    &__wrap { // 카테고리, 검색 요소 아래 파란색 텍스트 wrapper
        display: block;
        font-size: 0;

        .text + .text {
            margin-left: 4px;
            vertical-align: middle;
        }

        .box__flag + .text,
        .text + .text__price,
        .text__price + .button__text,
        .text + .button,
        .button + .button {
            margin-left: 8px;
            vertical-align: middle;
        }

        &--line {
            width: 100%;
            padding-top: 16px;
            border-top: 1px solid $color-gray-300;

            + .text__wrap--line {
                margin-top: -8px;
                padding-top: 0;
                border-top: 0;
            }
        }

        + .box__information:not(.box__information--fit) {
            margin-top: 16px;
        }
    }

    &__price {
        color: $color-gray-800;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        vertical-align: middle;

        .text__number {
            font-family: $gmarketSans;
            font-size: 20px;
            line-height: 28px;
        }

        + .text {
            margin-left: 4px;
        }

        &--blue {
            color: $color-blue-500;
        }
    }

    &__hover {
        display: none;
        position: absolute;
        left: 50%;
        z-index: 3;
        padding: 15px 16px;
        border: 1px solid $color-gray-900;
        border-radius: 8px;
        background: $color-white;
        color: $color-gray-800;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        transform: translateX(-50%);

        &--top {
            top: -56px;
        }

        &--bottom {
            top: calc(100% + 5px);
        }
    }

    &--choice {
        position: absolute;
        top: 50%;
        right: 96px;
        color: $color-gray-600;
        transform: translateY(-50%);
    }

    &--middle {
        .button {
            margin-left: 8px;
            vertical-align: middle;
        }
    }

    &--16 {
        font-size: 16px;
    }

    &--22 {
        font-size: 22px;
    }

    &--gmarketSans {
        font-family: $gmarketSans;
    }

    &__center {
        margin: 0 8px;
    }

    &__title {
        font-family: $gmarketSans;
        font-size: 16px;
        line-height: 22px;
    }

    &__result {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 9px 16px 0;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        background: $color-gray-100;
        font-size: 0;

        .button__result-cancel {
            height: 20px;

            &::before {
                @include useSvg-icons("cancel-20", 20px);
                display: block;
                content: "";
            }
        }

        .text {
            color: $color-gray-600;
        }
    }

    &__label {
        display: inline-flex;
        align-items: center;
        height: 20px;
        margin-right: 8px;
        padding: 0 4px;
        border-radius: 4px;
        font-size: 12px;
    
        &--important {
            border: 1px solid $color-blue-500;
            color: $color-blue-500;
        }
    
        &--tip {
            border: 1px solid $color-gray-600;
            color: $color-gray-600;
        }
    
        &--type {
            height: 24px;
            padding: 1px 8px;
            border: 1px solid $color-gray-300;
            border-radius: 16px;
            background: $color-gray-50;
            color: $color-gray-700;
        }
    
        &--question {
            margin-bottom: 5px;
            color: $color-gray-400;
            font-family: $gmarketSans;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.bar--gray {
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 8px;
    background: $color-gray-300;
    vertical-align: middle;
}

.text__description {
    display: inline-block;
    width: fit-content;
    margin-bottom: 14px;
    padding: 5px 7px;
    border: 1px solid $color-blue-100;
    border-radius: 4px;
    background: $color-blue-50;
    color: $color-blue-500;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &--red {
        border: 1px solid $color-red-100;
        border-radius: 4px;
        background: $color-red-50;
        color: $color-red-500;
    }
}

.button__temporary {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    padding: 24px 42px;
    color: $color-gray-800;
    font-weight: 700;
    font-size: 18px;

    &::before {
        @include useSvg-icons("exclm_mark-24", 24px);
        display: block;
        content: "";
        margin-right: 10px;
    }

    &::after {
        @include useSvg-icons("icon_chevron_right", 24px);
        content: "";
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
    }
}

.link__text {
    color: $color-gray-800;
    font-size: 14px;
    line-height: 20px;

    &--small {
        font-size: 12px;
        line-height: 16px;
    }

    &--right {
        text-decoration: none;

        &::after {
            @include useSvg-icons("icon_chevron_right",16px);
            display: inline-block;
            content: "";
            width: 16px;
            height: 16px;
            margin: -1px 0 1px 2px;
            vertical-align: middle;
        }

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }

    &--gray {
        color: $color-gray-500;

        &::after {
            filter: brightness(0) saturate(100%) invert(74%) sepia(6%) saturate(11%) hue-rotate(358deg) brightness(84%) contrast(98%);
        }
    }

    &.text__primary::after {
        filter: brightness(0) saturate(100%) invert(37%) sepia(54%) saturate(4886%) hue-rotate(200deg) brightness(102%) contrast(101%);
    }
}

.link__icon {
    position: relative;
    color: $color-blue-500;
    font-size: 14px;
    vertical-align: middle;

    &::after {
        @include useSvg-icons("icon_link", 16px);
        display: inline-block;
        content: "";
        position: relative;
        margin-left: 2px;
        vertical-align: middle;
    }
}

.box__select {
    &-group {
        width: 100%;

        > .text {
            margin-top: 20px;
        }

        + .box__information {
            margin-top: 20px;
        }
    }

    &-all {
        padding-bottom: 16px;
        border-bottom: 1px solid $color-gray-300;

        ~ .box__select-option {
            margin-top: 20px;
        }
    }

    &-option {
        ~ .box__select-option {
            margin-top: 20px;
        }

        .text__option {
            margin-bottom: 16px;
            color: $color-gray-800;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }

        .box__option {
            margin-bottom: 0;

            .text__option {
                margin-bottom: 0;
                color: $color-gray-800;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 16px;

            &-item {
                .form__label {
                    @include ellipsis;
                    width: 140px;
                }
            }

            &__text {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 16px;

                .list-item {
                    .text {
                        @include ellipsis;
                        width: 168px;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;

        ~ .box__select-view {
            margin-top: 8px;
        }
    }
}

.required-mark {
    color: $color-red-500;
    font-family: $font-default;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;

    &::before {
        display: inline-block;
        content: "";
        width: 6px;
        height: 6px;
        margin: 0 9px 5px;
        border-radius: 50%;
        background-color: $color-red-500;
        vertical-align: middle;
    }
}

.box__option {
    margin: 16px 0 0;
    padding: 20px;
    background: $color-gray-50;

    .text__option {
        margin-bottom: 0;
        color: $color-gray-800;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    + .text {
        margin-top: 16px;
    }

    &--line {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $color-gray-300;
    }

    .box__gap {
        .text:not(.text__point) {
            color: $color-gray-600;
        }
    }
}

.box__align {
    display: flex;
    align-items: center;
    width: 100%;

    > .text {
        margin-right: 16px;
        line-height: 32px;
    }

    .text__location {
        font-size: 14px;
        line-height: 20px;
    }

    > .button {
        margin-left: 8px;
    }

    &--right {
        padding-top: 16px;
        text-align: right;
    }

    &--end {
        justify-content: flex-end;
    }

    &--between {
        justify-content: space-between;
    }

    &--start {
        align-items: flex-start;
    }
}

.box__site-group {
    display: flex;

    .box__bundle {
        width: 510px;
    }

    .form--gmarket,
    .form--auction {
        padding: 10px 0;
    }

    .link__icon {
        margin-top: 12px;
    }

    .bar--gray {
        margin: 16px 0 0;
    }
}

.list__image {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    font-size: 0;

    .list-item {
        position: relative;
        flex-shrink: 0;
        width: 140px;
        height: 100%;

        &:only-child {
            width: 100%;

            .form__file {
                width: 100%;
            }
        }

        &:first-child {
            background: transparent;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 140px;
            height: 140px;
            border-radius: 6px;
            background-color: $color-gray-200;
        }
    }

    .form {
        z-index: 1;
        width: 140px;
        border: 1px dashed $color-gray-400;
        border-radius: 6px;
        background: $color-white;

        &::before {
            @include useSvg-icons("plus", 32px);
            content: "";
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
        }

        &__file {
            opacity: 0;
            position: relative;
            z-index: 1;
            width: 140px;
            height: 140px;
        }

        .text {
            position: absolute;
            top: 70px;
            left: 50%;
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            white-space: nowrap;
            transform: translateX(-50%);

            &__information {
                color: $color-gray-800;
                font-size: 16px;
                line-height: 22px;
            }

            + .text {
                top: 90px;
            }
        }
    }

    .box__image {
        overflow: hidden;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 140px;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        background: $color-white;

        &:hover {
            border-color: $color-blue-500;
        }
    }

    .form,
    .box__image {
        + .text {
            @include multi-ellipsis(2);
            margin-top: 12px;

            &--red {
                margin-top: 2px;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .button__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    .box__button-group {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 40px;
        border-radius: 0 0 6px 6px;
        font-size: 0;

        > * {
            opacity: .5;
            flex: 2;
            width: 33.3333%;
            background: $color-black;

            &:hover {
                opacity: 1;
            }

            &::before {
                display: inline-block;
                content: "";
            }
        }
    }

    .button__expand::before {
        @include useSvg-icons("expand_image", 24px);
    }

    .button__crop::before {
        @include useSvg-icons("crop_image", 24px);
    }

    .button__close::before {
        @include useSvg-icons("close_image", 24px);
    }

    + .text__information {
        margin-top: 8px;
    }

    + .box__information--blue {
        margin-top: 16px;
    }
}

.image__thumb {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border: 1px solid $color-gray-400;
    border-radius: 4px;

    .button__thumb {
        flex: 1;
    }

    .button__thumb-close {
        display: flex;
        opacity: .5;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        height: 20px;
        background: $color-black;

        &::after {
            @include useSvg-icons("close_image", 16px);
            display: block;
            content: "";
        }

        &:hover {
            opacity: 1;
        }
    }
}

.list__store {
    margin-top: 16px;

    > .list-item {
        + .list-item {
            margin-top: 28px;
        }
    }

    .text__wrap {
        margin-bottom: 16px;

        .text + .text {
            position: relative;
            margin-left: 16px;

            &::before {
                content: "";
                position: absolute;
                top: 4px;
                left: -9px;
                width: 1px;
                height: 12px;
                background: $color-gray-300;
            }
        }
    }

    .box__dropdown {
        width: 840px;

        &.is-open {
            width: 100%;
        }
    }

    .box__category-wrap {
        position: relative;
    }
}

.box__file {
    &-bulk {
        margin-top: 20px;
        font-size: 0;

        .button--download {
            position: relative;
            justify-content: flex-start;
            width: 100%;
            border-color: $color-gray-300;

            + .button--download {
                margin-top: 12px;
            }

            &::before {
                @include useSvg-icons("download_gray", 20px);
                position: absolute;
                right: 16px;
                margin-right: 0;
            }
        }

        .form {
            .form__label {
                padding: 9px 0;
                font-size: 14px;
            }
        }
    }

    &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        height: 40px;
        margin-bottom: 12px;
        padding: 0 16px;
        border: 1px solid $color-gray-400;
        border-radius: 8px;
        background: $color-gray-100;

        .text {
            @include ellipsis;
            color: $color-gray-600;
        }

        &.is-disabled {
            .text {
                color: $color-gray-400;
            }
        }

        .button {
            padding: 0;
            border: 0;

            &--delete {
                background: transparent;

                &::before {
                    @include useSvg-icons("cancel-20", 20px);
                    margin-right: 0;
                }
            }
        }
    }
}

.box__tooltip-bubble {
    position: absolute;
    top: 10px;
    z-index: 2;
    padding: 6px 12px;
    border-radius: 100px;
    background: $color-gray-800;
    box-shadow: 0 2px 8px 0 rgba($color-black, .12);
    text-align: center;

    &::before {
        @include useSvg-icons("tooltip_triangle", 9px);
        display: block;
        content: "";
        position: absolute;
        top: 100%;
        left: 24px;
    }

    &.is-show {
        visibility: visible;
        opacity: 1;
    }

    .text__tooltip {
        color: $color-white;
        font-family: $gmarketSans;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &--yellow {
            color: $color-yellow;
        }

        .button__text {
            color: $color-white;
            font-family: $gmarketSans;
            font-size: 12px;
            line-height: 16px;
            text-underline-offset: 2px;
        }
    }

    .button__tooltip-close {
        vertical-align: middle;

        &::before {
            @include useSvg-icons("close_tooltip", 16px);
            display: block;
            content: "";
        }
    }

    &--second::before {
        top: -5px;
        transform: rotate(180deg);
    }
}

.image__blank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: $color-gray-50;

    &::after {
        display: block;
        content: "";
        width: 22px;
        height: 22px;
        background: url("../img/single/image_blank.svg") no-repeat 0 0;
        background-size: cover;
    }

    &--48 {
        &::after {
            width: 48px;
            height: 48px;
        }
    }

    &--64 {
        &::after {
            width: 64px;
            height: 64px;
        }
    }
}

.box__detail {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    .list__count {
        &::after {
            display: none;
        }
    }

    .box__dropdown {
        + button {
            margin-left: 12px;

            &.button--delete {
                padding: 0 11px;

                &::before {
                    margin-right: 0;
                }
            }
        }
    }

}

.box__label {
    padding: 10px 0;

    .text {
        display: inline-block;
        margin-right: 4px;
    }
}

.box__sellerid {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-wrap: wrap;
    gap: 12px;
    max-height: 20px;
    padding-right: 80px;

    &.is-open {
        max-height: none;

        .button__opener {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .button__opener {
        display: none;
        position: absolute;
        top: 0;
        right: 15px;
        width: 20px;
        height: 20px;

        &::after {
            @include useSvg-icons("icon_arrow_down-20", 20px);
            display: block;
            content: "";
        }
    }

    .text__sellerid {
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.box__floating {
    position: fixed;
    right: 32px;
    bottom: 32px;
    z-index: 10;

    .button {
        display: block;
        width: 64px;
        height: 64px;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0 2px 8px rgba($color-black, .12);

        + .button {
            margin-top: 16px;
        }
    }

    .button__top {
        border: 1px solid $color-gray-300;
        background-color: $color-white;

        &::after {
            @include useSvg-icons("icon_arrow-up", 32px);
            display: inline-block;
            content: "";
        }

        &:hover {
            &::after {
                @include useSvg-icons("icon_arrow-up-hover", 32px);
            }
        }
    }

    .button__help {
        border: 1px solid $color-blue-600;
        background-color: $color-blue-500;
        color: $color-white;
        font-family: $gmarketSans;
        font-size: 14px;

        &:hover {
            font-size: 0;

            &::after {
                @include useSvg-icons("icon_help-hover", 32px);
                display: inline-block;
                content: "";
            }
        }
    }

    &-bottom {
        display: flex;
        position: sticky;
        right: -24px;
        bottom: 0;
        left: -24px;
        z-index: 10;
        justify-content: flex-end;
        gap: 12px;
        padding: 12px 32px;
        border-top: 1px solid $color-gray-300;
        background: $color-white;
        font-size: 0;

        .button {
            width: 180px;
        }

        .box__button-save {
            .button {
                justify-content: end;
                width: 115px;
                padding-right: 8px;
                border-radius: 8px 0 0 8px;
                text-align: right;

                + .button {
                    justify-content: start;
                    width: 65px;
                    padding-right: 16px;
                    padding-left: 0;
                    border-radius: 0 8px 8px 0;
                    text-align: left;
                }
            }
        }

        .box__floating-wording {
            display: flex;
            position: relative;
            align-items: center;
            gap: 12px;
            color: $color-white;
            font-family: $gmarketSans;

            .text__floating {
                &-title {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                }

                &-sub {
                    font-size: 16px;
                    line-height: 22px;
                }

                &--allkill {
                    position: absolute;
                    bottom: 20%;
                    left: -10px;
                    width: 207px;
                    height: 70px;
                    background: url("../img/single/allkill_nudge.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }
        }
    }
}

.box__gap {
    display: flex;
    position: relative;
    flex: 1;
    flex-wrap: wrap;
    gap: 8px;

    &--vertical {
        flex-direction: column;
    }

    &--center {
        align-items: center;
    }

    &-4,
    &-12,
    &-16,
    &-20,
    &-24,
    &-32 {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        font-size: 0;
    }

    &-4 {
        gap: 4px;
    }

    &-12 {
        gap: 12px;
    }

    &-16 {
        gap: 16px;
    }

    &-20 {
        gap: 20px;
    }

    &-24 {
        gap: 24px;
    }

    &-32 {
        gap: 32px;
    }
}

.line-gray-200 {
    display: block;
    border-top: 1px solid $color-gray-200;
}

.box__current-service {
    display: inline-flex;
    gap: 24px;

    .link__text {
        position: relative;
        text-decoration: none;

        ~.link__text {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: -12px;
                width: 1px;
                height: 12px;
                background-color: $color-gray-300;
                transform: translateY(-50%);
            }
        }
    }

    .text {
        color: $color-gray-500;
    }
}

.box__global {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 20px 0;

    &.is-active {
        .button__text--down::after {
            transform: rotate(-90deg);
        }
    }

    + .box__filter-item {
        border-top: none;
    }
}

.box__color-chip {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 4px;
}

.list__register-option {
    .list-item {
        display: flex;
        gap: 16px;
    }
}

.w-full {
    width: 840px !important;
}

.box__bg {
    width: 100%;
    padding: 16px;

    &--gray-50 {
        background: $color-gray-50;
    }
}

.image__wrap {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid $color-gray-300;
    border-radius: 4px;

    &--40 {
        width: 40px;
        height: 40px;
    }

    &--48 {
        width: 48px;
        height: 48px;
    }

    &--64 {
        width: 64px;
        height: 64px;
    }

    &--104 {
        width: 104px;
        height: 104px;
    }

    &:hover {
        border-color: $color-gray-400;
    }

    .image {
        width: 100%;
    }
}

.button__image-more {
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border: 1px solid $color-gray-300;
    border-radius: 4px;

    &--56 {
        width: 56px;
        height: 56px;
        border-radius: 6px;
    }

    &:hover {
        border-color: $color-gray-400;
    }

    .image {
        width: 100%;
    }
}

.image__more,
.text__more {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba($color-black, .3);
    color: $color-white;
}

.image__more {
    background: rgba($color-black, .4);

    .text {
        color: $color-white;
    }
}

.box__assistive { //유효성검사
    padding-top: 2px;
    padding-left: 16px;

    .list {
        &__check {
            display: flex;
            align-items: center;

            .list-item {
                margin-bottom: 0;

                ~ .list-item {
                    padding-left: 4px;
                }
            }

            .item-wrap {
                display: flex;
                align-items: center;
            }

            .text {
                order: 2;
                color: $color-gray-900;
                font-size: 12px;
                line-height: 16px;
            }

            .icon {
                order: 1;
            }

            &.type--default {
                .list-item {
                    color: $color-gray-900;
                }

                .icon {
                    @include useSvg-icons("icon-check-black-16", 16px);
                }
            }

            &.type--assistive {
                .list-item {
                    color: $color-gray-500;

                    &.is-active {
                        .text {
                            color: $color-blue-500;
                        }

                        .icon {
                            @include useSvg-icons("icon-check-blue-16", 16px);
                        }
                    }

                }

                .icon {
                    @include useSvg-icons("icon-check-black-16", 16px);
                }
            }
        }
    }
}

.list__item {
    .list-item {
        display: flex;
        position: relative;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;

        &::before {
            display: none;
            content: "";
            position: absolute;
            top: 0;
            right: -32px;
            bottom: 0;
            left: 0;
            left: -32px;
            z-index: -1;
            background: $color-gray-50;
        }

        &:hover::before {
            display: block;
            background: $color-gray-50;
        }



        &.is-active::before {
            display: block;
            background: $color-blue-50;
        }
    }
}

.box__item {
    display: flex;
    padding: 16px 0;

    .link__item {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0 32px;
        text-decoration: none;
    }

    .image__item {
        display: inline-flex;
        position: relative;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        border: 1px solid $color-gray-300;
        border-radius: 4px;

        &--add {
            border: 1px dashed $color-gray-400;
            border-radius: 6px;
            background: $color-white;

            &::before {
                @include useSvg-icons("plus", 32px);
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        img {
            border-radius: 3px;
        }
    }

    .text__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 16px;

        &-name {
            @include multi-ellipsis(2);
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }

        &-group {
            display: flex;
            margin-top: 4px;

            .text__wrap {
                &::before {
                    display: inline-block;
                    content: "";
                    position: relative;
                    width: 1px;
                    height: 12px;
                    margin: 0 8px 2px;
                    background: $color-gray-300;
                    vertical-align: middle;
                }
            }

            .text--red {
                max-width: 725px;
                text-align: left;
                white-space: normal;
            }
        }
    }
}

.box__settle {
    margin-top: 30px;
    padding: 20px 30px 36px;
    border: 1px solid $color-gray-600;
    border-radius: 8px;

    .text__settle {
        &-title {
            margin-bottom: 20px;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-size: 20px;
            line-height: 28px;
        }

        &-price {
            margin-left: 11px;
            color: $color-gray-500;
        }

        &-num {
            color: $color-gray-800;
            font-weight: 700;
        }
    }

    .list__settle {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        font-size: 0;

        .list-item {
            display: flex;
            justify-content: space-between;
            width: calc(50% - 8px);
            padding: 17px 16px;
            border: 1px solid $color-gray-300;
            background: $color-gray-50;

            .text {
                font-size: 16px;
                line-height: 22px;

                &__price {
                    color: $color-gray-500;
                    font-family: $gmarketSans;
                    font-weight: 400;

                    .text__num {
                        color: $color-gray-800;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}

.box__shipping-label {
    padding: 30px 32px 0;

    .box__label-wrap {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        font-size: 0;

        .box__label-info {
            display: inline-block;
            position: relative;
            width: 376px;
            min-height: 210px;
            padding: 20px;
            border: 1px solid $color-gray-800;

            >.box__gap-12 {
                flex-wrap: nowrap;
                height: 100%;
            }

            .text__title {
                color: $color-gray-600;
                font-family: $font-default;
                font-size: 12px;
                line-height: 16px;
            }

            .box__order {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                &-address {
                    .text {
                        color: $color-gray-900;

                        + .text {
                            margin-top: 4px;
                        }
                    }
                }

                &-info {
                    .text__order {
                        &-title {
                            margin-bottom: 8px;
                            color: $color-gray-900;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 20px;
                            vertical-align: middle;

                            .text__order--id {
                                color: $color-gray-600;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        &-sub {
                            color: $color-gray-800;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    &:not(:has(.text__order-sub)) {
                        margin-bottom: 16px;
                    }
                }
            }

            + .box__label-info {
                margin-left: 80px;

                &::before {
                    display: inline-block;
                    content: "";
                    position: absolute;
                    top: 18px;
                    left: -40px;
                    height: 174px;
                    border-left: 1px dashed $color-gray-500;
                }
            }
        }
    }
}

.box__request {
    padding: 30px 30px 30px 76px;
    border: 1px solid $color-gray-600;
    border-radius: 8px;

    &-directly {
        .list__request {
            >.list-item {
                .box__request-step::before {
                    @include useSvg-icons("icon_selected", 36px);
                }

                &.is-active {
                    .box__information--blue {
                        display: none;
                    }

                    + .list-item:not(.list-item.is-active) {
                        .box__request-step::before {
                            @include useSvg-icons("icon_selected_on", 36px);
                            top: 3px;
                        }
                    }
                }
            }
        }
    }

    &-processing {
        padding-right: 129px;
    }

    &-hold {
        padding: 30px;

        .box__request-step {
            &::before {
                content: inherit;
            }

            .text__step-title {
                margin: 0 0 16px;
            }
        }
    }

    &-step {
        position: relative;
        font-size: 0;

        .text__step {
            color: $color-gray-600;
            font-size: 11px;
            line-height: 14px;

            &-title {
                margin-top: 4px;
                color: $color-gray-800;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
            }

            &-desc {
                margin-top: 2px;
                color: $color-gray-600;
                font-size: 14px;
                line-height: 20px;
                word-break: keep-all;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: -52px;
            width: 36px;
            height: 36px;
        }
    }

    .list__request {
        >.list-item {
            display: flex;
            position: relative;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 16px;

            &.icon-collect {
                .box__request-step::before {
                    @include useSvg-icons("icon_selected_collect", 36px);
                }

                &.is-checked {
                    .box__request-step::before {
                        @include useSvg-icons("icon_selected_collect_on", 36px);
                    }
                }
            }

            &.icon-hold {
                .box__request-step::before {
                    @include useSvg-icons("icon_selected_pending", 36px);
                }

                &.is-checked {
                    .box__request-step::before {
                        @include useSvg-icons("icon_selected_pending_on", 36px);
                    }
                }
            }

            &.icon-approval {
                .box__request-step::before {
                    @include useSvg-icons("icon_selected_approval", 36px);
                }

                &.is-checked {
                    .box__request-step::before {
                        @include useSvg-icons("icon_selected_approval_on", 36px);
                    }
                }
            }

            &.icon-exchange {
                .box__request-step::before {
                    @include useSvg-icons("icon_selected_exchange", 36px);
                }

                &.is-checked {
                    .box__request-step::before {
                        @include useSvg-icons("icon_selected_exchange_on", 36px);
                    }
                }
            }

            &.is-active {
                padding-bottom: 50px;

                .box__request-step::before {
                    @include useSvg-icons("ico_checked_blue-20", 24px);
                    top: -1px;
                    left: -46px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 32px;
                bottom: 8px;
                left: -34px;
                width: 1px;
                background: $color-gray-300;
            }

            &:first-child:last-child,
            &:last-child,
            &:not(.is-active) {
                padding-bottom: 0;

                &::before {
                    content: none;
                }
            }
        }
    }

    .box__table-row + .box__information {
        margin: 0;
    }
}

.box__top-side {
    display: flex;
    position: absolute;
    top: 20px;
    right: 24px;
    align-items: center;

    .button + .button {
        margin-left: 12px;
    }

    .box__dropdown {
        font-size: initial;
    }
}

// 딜 가격 계산기
.box__deal {
    &-calculator {
        font-size: 0;
        white-space: nowrap;

        &--item {
            display: inline-block;
            margin: 0 4px;
            vertical-align: top;

            &:last-of-type {
                margin-right: 0;
            }

            > .text {
                ~ .form {
                    margin-top: 8px;
                }
            }

            .form {
                display: block;
            }
        }

        .form {
            width: 140px !important;
            @media only screen and (max-width: 1391px) {
                width: 112px !important;
            }

            &.is-complete {
                .form__inner {
                    &::after {
                        @include useSvg-icons("complete", 16px);
                        right: auto;
                        left: 16px;
                    }

                    .form__input {
                        padding: 10px 31px 10px 36px;
                    }
                }
            }

            .text--red {
                width: auto;
                white-space: initial;
            }
        }

        > .icon {
            margin: 40px 4px 0;
            text-align: center;
            vertical-align: top;

            &-equals {
                width: 80px;
            }
        }

        .button--apply-price {
            width: 80px;
            margin: 34px 4px 0;
            box-shadow: 0 2px 8px 0 rgba($color-black, .12);

            &::before {
                @media only screen and (max-width: 1569px) {
                    margin: -1px 4px 1px 0;
                    transform: rotate(90deg);
                }
            }
        }
    }

    &-price {
        overflow: hidden;
        border: 1px solid $color-gray-300;
        border-radius: 8px;

        &--head {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 4px;
            min-height: 73px;
            padding: 16px 32px;
            border-bottom: 1px solid $color-gray-300;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background: $color-blue-50;

            .text {
                font-family: $gmarketSans;
                font-size: 16px;

                &__price {
                    color: inherit;
                    font-size: 18px;
                }
            }
        }

        &--info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px 0;
            margin: 0 32px;
            padding: 24px 0 16px;

            + .box__deal-price--info {
                padding: 16px 0 24px;
                border-top: 1px solid $color-gray-200;

                .box__deal-price--total {
                    .text {
                        // display: block;
                        margin-bottom: 2px;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;

                        &__point {
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &__price {
                            font-size: 16px;
                        }
                    }
                }

                .box__deal-calculator > .icon {
                    margin-top: 12px;
                }
            }
        }

        &--total {
            width: 170px;
            @media only screen and (max-width: 1569px) {
                width: 100%;
            }

            &.is-disabled {
                .text {
                    opacity: .6;
                }
            }

            .text {
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;

                &__point {
                    margin-top: 11px;
                    font-family: $gmarketSans;
                    font-size: 22px;
                    line-height: 32px;
                }

                &__price {
                    color: inherit;
                    font-size: 24px;
                }
            }
        }

        + .box__align:has(.button--refresh) {
            margin-top: 12px;

            .text {
                color: $color-gray-600;
                line-height: 20px;
            }

            .button--refresh {
                white-space: nowrap;
            }
        }

        + .button--refresh {
            float: right;
            margin-top: 12px;
        }
    }
}

.box__faq {
    padding: 32px 40px 10px;

    .title {
        margin-bottom: 14px;
        color: #424242;
        font-family: $font-default;
        font-size: 20px;
        line-height: 28px;
    }
}

.list__accordion {
    .list-item {
        &.is-open {
            .button__accordion {
                padding-bottom: 12px;

                &::after {
                    transform: rotate(-90deg);
                }
            }

            .box__accordion-content {
                display: block;
                padding-bottom: 22px;

                .text + .box__table {
                    margin-top: 12px;
                }
            }
        }

        + .list-item {
            border-top: 1px solid #eee;
        }
    }

    .button__accordion {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        padding: 22px 0;

        &::after {
            @include useSvg-icons("icon_chevron_right", 24px);
            content: "";
            transform: rotate(90deg);
        }

        .text {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .box__accordion-content {
        display: none;
    }
}

.box__consult-service {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    .text__consult {
        font-size: 18px;
        line-height: 24px;

        &-emphasis {

            color: $color-gray-800;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;

            .text {
                color: $color-gray-600;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .link__button {
        width: 325px;
    }
}

.icon {
    display: inline-block;

    &::after {
        display: inline-block;
        content: "";
    }

    &-equals {
        &::after {
            @include useSvg-icons("icon_equals", 16px);
        }
    }

    &-minus {
        &::after {
            @include useSvg-icons("icon_minus", 16px);
        }
    }

    &-plus {
        &::after {
            @include useSvg-icons("icon_plus", 16px);
        }
    }

    &__new {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin: 2px 0 0 7px;
        border-radius: 50%;
        background-color: $color-red-500;
        vertical-align: top;
    }
}

.box__complete {
    margin: -28px -24px 0;
    padding: 40px 0;

    .title__complete {
        position: relative;
        padding-top: 56px;
        color: $color-gray-900;
        font-family: $gmarketSans;
        font-weight: 500;
        font-size: 22px;
        line-height: 31.9px;
        text-align: center;

        &::before {
            @include useSvg-icons("ico_checked_blue-20", 48px);
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &:has(+ .box__complete-content) {
            padding-bottom: 20px;

            + .box__complete-content {
                padding-top: 20px;
                border-top: 1px solid $color-gray-200;
            }
        }
    }

    .box__complete-content {
        > .box__gap-24 {
            justify-content: center;
        }

        .box__complete-content-inner {
            .box__gap {
                margin-top: 8px;

                &:first-of-type {
                    margin: 0;
                }
            }
        }

        .text__none {
            width: 108px;
            margin: 0;
            text-align: center;
        }

        .text__complete {
            color: $color-gray-700;
            font-size: 16px;
        }

        .text--gmarket,
        .text--auction {
            width: 11px;
            font-size: 16px;
        }

        .link__icon {
            display: inline-block;
            width: 108px;
            font-size: 16px;
            text-decoration: none;
            white-space: nowrap;
        }

        .button__wrap {
            margin-top: 16px;

            .button--medium {
                width: 140px;
            }
        }
    }
}

.box__shadow-bubble {
    position: relative;
    padding: 10px 20px;
    border-radius: 200px;
    box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, .3), -5px 10px 20px 0 rgba(0, 0, 0, .06);

    &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 16px;
        height: 16px;
        background: url("../img/single/bubble-tap-shadow.png") no-repeat 0 0 / contain;
        transform: translateX(-50%);
    }

    .text__bubble-shadow {
        background: linear-gradient(to right, #01c400, #00c3a0, #067dfd);
        background-clip: text;
        font-family: $gmarketSans;
        font-weight: 700;
        font-size: 20px;
        -webkit-text-fill-color: transparent;
    }
}

.box__star {
    @include useSvg-icons("icon_point-empty", 68px);

    &-wrap {
        display: flex;
        align-items: center;
        height: 20px;
    }

    .icon__star {
        @include useSvg-icons("icon_point-full", 68px);
        display: block;
    }

    + .text {
        margin-left: 4px;
        font-weight: 700;
    }
}

//광고
.box__content {
    width: 100%;
    font-size: 0;

    .box__emphasis-wrap {
        display: flex;
        position: relative;
        justify-content: space-between;
    }

    .box__emphasis {
        font-size: 0;

        &::after {
            display: inline-block;
            content: "";
            height: 100%;
            vertical-align: middle;
        }

        .text__emphasis {
            display: inline-block;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-size: 32px;
            line-height: 45px;
            letter-spacing: -1px;
            vertical-align: middle;
            word-break: keep-all;
        }

        .box__button-wrap {
            margin-top: 32px;

            .button {
                margin: 0 8px;
                box-shadow: 0 10px 20px 0 $color-white;

                &--blue {
                    box-shadow: 0 10px 20px 0 rgba($color-blue-500, .3);
                }
            }
        }

        &--center {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .text__emphasis {
                font-size: 40px;
                line-height: 52px;
            }
        }
    }

    .list__emphasis {
        margin-top: 36px;

        .list-item {
            display: flex;
            position: relative;
            padding: 16px 20px;
            border: 1px solid $color-gray-300;
            border-bottom: none;
            background: $color-gray-50;

            &:first-child {
                border-radius: 8px 8px 0 0;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray-300;
                border-radius: 0 0 8px 8px;
            }

            &:only-child {
                border-radius: 8px;
            }

            &::before {
                @include useSvg-icons("ico_checked_blue-20", 20px);
                content: "";
                left: 20px;
                margin-right: 4px;
            }

            &.is-fail {
                border-color: $color-red-300;
                background: $color-red-50;

                &:first-child + .list-item {
                    border-top: 1px solid $color-red-300;
                }

                &::before {
                    @include useSvg-icons("icon_fail_red", 20px);
                }
            }

            .text + .text {
                margin-left: 24px;
                color: $color-gray-700;
            }
        }
    }

    .button__emphasis {
        position: relative;
        margin-top: 24px;
        padding-top: 24px;
        text-align: center;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 1px;
            background: $color-gray-200;
        }

        .text__desc {
            display: inline-block;
            color: $color-gray-800;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            vertical-align: middle;

            + .button {
                width: 140px;
                margin-left: 16px;
                vertical-align: middle;
            }
        }
    }

    .box__visual {
        position: relative;
        margin-top: 28px;

        .text__visual-description {
            margin: 64px 0 24px;
            color: $color-gray-600;
            font-family: $gmarketSans;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            word-break: keep-all;
        }

        .box__visual-image {
            width: 100%;
        }
    }

    .text__gradation {
        &--type1 { // 타이틀
            background: linear-gradient(114.63deg, #00c400 1.62%, #00b8c4 70.6%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &--type2 { // 디스크립션
            background: linear-gradient(90deg, #01c400 20.24%, #00c3a0 42.81%, #067dfd 65.17%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &--type3 { // 말풍선
            background: linear-gradient(90deg, #01c400 0%, #00c3a0 49.42%, #067dfd 98.38%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &--type4 {
            background: linear-gradient(98deg, #0aadf3 0%, #2890ff 39%, #8470ff 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .title__service {
        margin-top: 16px;
    }

    .box__information-wrap {
        .box__information {
            margin-top: 12px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    &--full {
        max-width: 100%;
        padding: 80px 0 40px;
    }
}

// 광고,부가서비스
.box__preview {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    padding: 40px;
    background: $color-gray-200;

    &:not(body) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0 0;
        border: 1px solid $color-gray-300;
        border-radius: 8px;
        background: $color-gray-50;

        .box__preview-wrap {
            max-width: 100%;
            height: auto;
            padding-bottom: 0;
        }

        .box__preview-content {
            width: 320px;
            max-width: 100%;
            height: 600px;
            margin: 0 auto;
            padding-bottom: 0;
            border: 12px solid $color-white;
            border-radius: 32px;
            box-shadow: 0 6px 14px 0 rgba($color-black, .1);

            &--pc {
                width: 700px;
                height: 460px;
                border-radius: 24px;

                ~ .button__preview--toggle {
                    top: 380px;
                }
            }
        }
    }

    &-wrap {
        position: relative;
        height: 720px;
        margin-top: 40px;
        padding-bottom: 77px;

        .button__preview--toggle {
            position: absolute;
            top: 520px;
            left: 50%;
            border-radius: 100px;
            box-shadow: 0 4px 12px 0 rgba($color-black, .2);
            transform: translateX(-50%);

            &.type--gmarket {
                @include useSvg-icons("toggle_gmarket", 76px);
            }

            &.type--auction {
                @include useSvg-icons("toggle_auction", 76px);
            }
        }

        .box__tab--list {
            margin: 20px 0 0;
            border: none;
            background: transparent;
            text-align: center;

            .button__tab {
                &.is-active::after {
                    content: none;
                }
            }

            + .link__icon {
                margin-top: 40px;
            }
        }
    }

    &-content {
        overflow: hidden;
        overflow-y: auto;
        height: 635px;
        padding-bottom: 20px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: $color-gray-500;
        }
    }

    &-inner {
        overflow: hidden;
        position: relative;
        width: 388px;
        height: 720px;
        border: 14px solid $color-white;
        border-radius: 38px;
        background: $color-white;
        box-shadow: 0 6px 14px 0 rgba($color-black, .1);
    }

    &-header {
        position: relative;

        .link__text {
            position: absolute;
            top: 68px;
            left: 60px;

            .text {
                @include ellipsis;
                display: inline-block;
                max-width: 180px;
                color: #141414;
                font-size: 16px;
            }
        }
    }

    &-image {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 0;
        padding-top: 100%;
        background: $color-gray-50;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;

        .box__official-store {
            font-size: 12px;
            line-height: 16px;

            .text__brand {
                color: $color-blue-800;
            }

            .text__seller {
                color: $color-gray-700;

                &::before {
                    @include useSvg-icons("icon_store", 16px);
                    display: inline-block;
                    content: "";
                    margin: -2px 2px 2px 0;
                    vertical-align: middle;
                }

                &::after {
                    @include useSvg-icons("icon_chevron_right",12px);
                    display: inline-block;
                    content: "";
                    margin: -1px 0 1px;
                    vertical-align: middle;
                }
            }

            .bar--gray {
                margin: 1px 4px -1px;
                vertical-align: inherit;
            }
        }

        .title {
            margin: 0;
            color: $color-black;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;

            &--default {
                color: $color-gray-500;
            }
        }

        .box__rating-info {
            display: flex;
            align-items: center;

            .text {
                color: $color-gray-700;
                font-family: Roboto,sans-serif;
                font-weight: normal;
            }
        }

        .box__price-wrap {
            .text__price {
                font-family: $gmarketSans;
            }

            .text__discount {
                .text {
                    margin: 0;
                    font-weight: 600;
                    font-size: 16px;

                    &__price {
                        position: relative;
                        top: 1px;
                        font-size: 18px;
                    }
                }
            }

            .text__original {
                position: relative;
                top: 1px;

                &::after {
                    content: "";
                    position: absolute;
                    top: 12px;
                    right: 0;
                    left: 0;
                    height: 1px;
                    background: $color-gray-500;
                }

                .text {
                    margin: 0;
                    color: $color-gray-500;
                    font-size: 11px;

                    &__price {
                        position: relative;
                        top: 1px;
                        color: $color-gray-500;
                        font-weight: normal;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &-info {
        padding: 16px 0;
    }

    &-button {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 77px;
    }

    .title__preview {
        color: $color-gray-800;
        font-family: $gmarketSans;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
    }

    .title__example {
        color: $color-gray-800;
        font-family: $gmarketSans;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .text__example {
        margin-top: 8px;
        color: $color-gray-600;
        font-family: $gmarketSans;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
    }

    .list__preview {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;

        .list-item {
            position: relative;

            &.is-active .button__preview {
                border-color: $color-blue-500;
                background: $color-blue-500;
                color: $color-white;
            }

            .button__preview {
                height: 40px;
                padding: 0 16px;
                border: 1px solid $color-gray-400;
                border-radius: 200px;
                background: $color-white;
                color: $color-gray-800;
                font-family: $gmarketSans;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                white-space: nowrap;
            }
        }

        &--sample {
            .list-item {
                &.is-active {
                    .button__preview {
                        background: $color-blue-50;
                        color: $color-blue-500;
                    }
                }

                .button__preview {
                    position: relative;
                    padding-left: 4px;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-position: 0 0;
                        background-size: 30px auto;
                        background-repeat: no-repeat;
                    }

                    &::before {
                        display: inline-block;
                        content: "";
                        width: 30px;
                        height: 30px;
                        margin-right: 4px;
                        border-radius: 50%;
                        background: $color-white;
                        vertical-align: middle;
                    }

                    &--beauty {
                        &::after {
                            background-image: url("../img/single/image_tab_beauty.png");
                        }
                    }

                    &--food {
                        &::after {
                            background-image: url("../img/single/image_tab_food.png");
                        }
                    }

                    &--furniture {
                        &::after {
                            background-image: url("../img/single/image_tab_furniture.png");
                        }
                    }
                }
            }
        }
    }

    .link__icon {
        margin-top: 24px;
        font-size: 16px;
        line-height: 20px;
    }

    &--exposure {
        .box__preview-wrap {
            width: 100%;
            margin-top: 20px;
        }

        .list__preview {
            margin-top: 0;
        }

        .text__example {
            margin-top: 0;
        }

        .box__image-wrap {
            width: 100%;
            margin-top: 24px;
        }
    }
}

.box__chain {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 792px;
    margin: 0 auto;
    margin-top: 40px;
    @media only screen and (min-width: 1680px) {
        max-width: 1200px;
    }

    &-wrap {
        margin-top: 16px;
    }
}

.box__chain-item {
    width: 384px;
    padding: 48px 32px;
    border: 1px solid $color-gray-200;
    border-radius: 12px;
    background: $color-gray-50;

    .title__chain {
        text-align: center;

        .text__chain {
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-size: 25px;
            line-height: 32px;
            text-wrap: nowrap;
        }

        .text__chain-sub {
            display: inline-block;
            margin-top: 12px;
            color: $color-gray-600;
            font-size: 16px;
            line-height: 22px;

            &::before {
                @include useSvg-icons("icon_money", 16px);
                display: inline-block;
                content: "";
                margin-right: 8px;
                vertical-align: middle;
            }
        }
    }

    .box__visual {
        position: relative;
        min-height: 186px;

        .box__visual-content {
            border: solid 1px $color-gray-300;
            background-color: $color-white;
            box-shadow: 0 6px 14px 0 rgba($color-black, .1);
        }

        &:not(.box__visual--item) {
            padding-top: 22px;

            .box__visual-content {
                width: 266px;
                height: 128px;
                padding: 16px 56px 16px 16px;
                border-radius: 20px;

                .text__visual {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .text {
                        text-wrap: nowrap;
                    }

                    .text--gray {
                        margin-left: 0;
                        font-size: 11px;
                        line-height: 14px;
                    }
                }

                .box__progress {
                    .box__progress-item {
                        display: flex;
                        gap: 8px;
                        margin-top: 9px;

                        .text__progress {
                            flex-shrink: 0;
                            color: $color-gray-700;
                            font-size: 12px;
                            line-height: 16px;
                        }

                        &:first-of-type {
                            margin-top: 14px;
                        }
                    }

                    .box__progress-bar {
                        display: inline-block;
                        height: 14px;
                        border-radius: 8px;

                        &--after {
                            background: linear-gradient(95deg, #0aadf3 0%, #2890ff 39%, #8470ff 100%);
                        }

                        &--before {
                            background: $color-gray-400;
                        }
                    }
                }
            }
        }

        .box__orange {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 104px;
            height: 104px;
            border-radius: 50%;
            background: #ff7638;

            .text__orange {
                color: $color-white;
                font-family: $gmarketSans;
                font-size: 14px;
                line-height: 20px;

                &.text__point {
                    font-size: 18px;
                }
            }

            &::after {
                @include useSvg-icons("bubble_tap_orange", 14px);
                content: "";
                position: absolute;
                bottom: 0;
                left: 14px;
            }
        }

        &--item {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 200px;
                height: 186px;
                background: url("../img/single/image_ad_chain.png") no-repeat;
                background-size: contain;
                transform: translateX(-50%);
            }

            .box__visual-content {
                position: relative;
                top: 38px;
                z-index: 1;
                width: 280px;
                height: 101px;
                border-radius: 8px;
            }

            .text__visual-title {
                padding: 10px 14px 8px;
                border-bottom: 1px solid $color-gray-200;
                color: $color-gray-800;
                font-family: $gmarketSans;
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
            }

            .text__visual {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 10px 14px;
            }
        }

        &--people .text__visual::before {
            display: block;
            content: "";
            width: 72px;
            height: 40px;
            background: url("../img/single/image_ad_people.png") no-repeat;
            background-size: contain;
        }

        &--person .text__visual::before {
            display: block;
            content: "";
            width: 40px;
            height: 40px;
            background: url("../img/single/image_ad_person.png") no-repeat;
            background-size: contain;
        }
    }

    .list__information {
        .text {
            display: inline-block;
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
            }

            &::before {
                @include useSvg-icons("icon-check-blue-16", 20px);
                display: inline-block;
                content: "";
                margin-right: 4px;
                vertical-align: middle;
            }
        }
    }

    .button__wrap {
        margin-top: 24px;

        .button {
            width: 154px;
        }
    }
}

// lottie
.box__lottie {
    position: relative;
    flex-shrink: 0;
    margin: 0 auto;

    &.is-active {
        .text__lottie,
        .text__bubble {
            animation-name: lottieTextAni;
        }

        .text__bar {
            animation-name: lottieBarTextAni;
        }
    }

    .text__lottie,
    .text__bubble {
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        animation-fill-mode: forwards;
        timing-function: ease-in-out;
    }

    .text__lottie {
        font-family: $gmarketSans;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        text-align: center;

        &--small {
            font-size: 20px;
            line-height: 28px;
        }

        &-wrap {
            position: absolute;
        }

        &.gray {
            color: $color-gray-500;
            font-size: 16px;
            line-height: 22px;
            animation-name: lottieBarTextAni;
            animation-duration: .8s;
            animation-delay: .25s;
        }

        &.white {
            color: $color-white;
            font-size: 16px;
            line-height: 22px;
            animation-name: lottieBarTextAni;
            animation-duration: .8s;
            animation-delay: 2.02s;
        }
    }

    .text__bubble {
        color: $color-white;
        font-family: $gmarketSans;
        font-size: 16px;
        line-height: 22px;
    }

    .text__bar {
        opacity: 0;
        position: absolute;
        color: $color-white;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        animation-fill-mode: forwards;
        timing-function: ease-in-out;
    }

    .image__lottie {
        width: 100%;
    }

    + .text__emphasis {
        margin-top: 8px;
    }

    .text.text--gray {
        text-align: center;
    }

    &--extra { // 부가서비스 신규, 이용중
        .text__lottie-wrap {
            top: 0;
            left: 48px;
            width: 320px;
            height: 68px;
        }

        .text__bubble {
            padding: 11px 12px 11px 28px;
            border-radius: 200px;
            background: $color-black;
            animation-duration: .8s;
            animation-delay: 2.6s;

            &::after {
                @include useSvg-icons("icon_lottie_arr", 10px);
                content: "";
                position: absolute;
                bottom: -10px;
                left: 78px;
            }
        }

        .text__bar {
            top: 166px;
            left: 16px;
            animation-duration: .3s;
            animation-delay: 2.2s;
        }

        &.is-active {
            .text__bubble {
                animation-name: lottieTextAniBlack;
            }
        }
    }

    &--experience,
    &--experience2 { // AI매출업 무료체험
        .text__lottie-wrap {
            top: 46px;
            left: 612px;
            width: 198px;
            height: 100px;
        }

        .text__lottie {
            padding: 0 32px;
            animation-duration: .8s;
            animation-delay: 3.3s;
        }
    }

    &--experience2 { // AI매출업 무료체험2
        .text__lottie {
            animation-duration: .8s;
            animation-delay: 1.1s;
        }
    }

    &--induce { // AI매출업 증액유도1
        .text__lottie-wrap {
            &.bar-first {
                bottom: 60px;
                left: 165px;
                width: 116px;
                height: 64px;

                .text__lottie {
                    top: 50% !important;
                }
            }

            &.bar-second {
                bottom: 60px;
                left: 165px;
                width: 116px;
                height: 126px;

                .text__lottie {
                    top: calc(50% - 28px) !important;
                }
            }

            &.bar-third {
                bottom: 60px;
                left: 412px;
                width: 160px;
                height: 190px;

                .text__lottie {
                    top: auto !important;
                    bottom: 20px;
                    transform: translate(-50%, 0);

                    .price {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }

            &.bar-fourth {
                bottom: 60px;
                left: 598px;
                width: 160px;
                height: 244px;

                .text__lottie {
                    top: auto !important;
                    bottom: 20px;
                    transform: translate(-50%, 0);

                    .price {
                        font-size: 20px;
                        line-height: 28px;

                        .big {
                            font-size: 32px;
                            line-height: 32px;
                        }
                    }
                }
            }

            &.bubble-small {
                top: 222px;
                left: 128px;
                width: 194px;
                height: 48px;

                .text__lottie {
                    padding: 0 20px;
                    animation-duration: .6s;
                    animation-delay: 2.2s;
                }
            }

            &.bubble-large {
                top: 40px;
                left: 554px;
                width: 250px;
                height: 106px;

                .text__lottie {
                    padding: 0 32px;
                    animation-duration: .6s;
                    animation-delay: 2.2s;
                }
            }
        }
    }

    &--progressbar,
    &--progressbar2 { // AI매출업 증액유도2, 파워클릭 추천
        .text__lottie-wrap {
            top: 46px;
            left: 612px;
            width: 198px;
            height: 100px;
        }

        .text__lottie {
            padding: 0 32px;
            animation-duration: .8s;
            animation-delay: 1.2s;
        }
    }

    &--progressbar2 { // 파워클릭 추천
        .text__lottie {
            animation-duration: .7s;
            animation-delay: .6s;
        }
    }

    &--progressbar-large { // 파워클릭 대행사 추천
        .text__lottie-wrap {
            top: 46px;
            left: 578px;
            width: 270px;
            height: 100px;
        }

        .text__lottie {
            padding: 0 32px;
            animation-duration: .6s;
            animation-delay: .5s;
        }
    }

    &--center { // 판매자 광고센터 광고
        .text__lottie-wrap {
            top: 57px;
            left: 539px;
            width: 198px;
            height: 100px;
        }

        .text__lottie {
            padding: 0 32px;
            animation-duration: .8s;
            animation-delay: 1.2s;
        }
    }
}

@keyframes lottieTextAniBlack {
    0% {
        opacity: 0;
        top: 75%;
    }

    100% {
        opacity: 1;
        top: 50%;
    }
}

@keyframes lottieTextAni {
    0% {
        opacity: 0;
        top: 100%;
    }

    100% {
        opacity: 1;
        top: 50%;
    }
}

@keyframes lottieBarTextAni {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// swiper - 넛징광고
.box__swiper--chain {
    overflow: hidden;
    position: relative;
    margin-top: 16px;

    .link__chain {
        display: block;
        overflow: hidden;
        border: solid 1px rgba($color-black, .15);
        border-radius: 8px;

        img {
            width: 100%;
        }
    }

    .swiper-button {
        position: absolute;
        top: 50%;
        z-index: 1;
        cursor: pointer;
        transform: translateY(-50%);

        &::after {
            @include useSvg-icons("arrow_white", 48px);
            display: block;
            content: "";
            width: 48px;
            height: 48px;
        }

        &--prev {
            left: 32px;
        }

        &--next {
            right: 32px;

            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            opacity: 1;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: rgba($color-white, .5);

            &-active {
                background: $color-white;
            }
        }
    }
}

// 알림
.box__notice {
    &.box {
        padding: 20px 24px;
    }

    &-set {
        display: inline-block;
        font-size: 0;
        vertical-align: top;

        .text {
            font-family: $font-default;
        }

        .form {
            height: 20px;
            margin-left: 8px;
            vertical-align: middle;
        }
    }

    &-summary {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        margin-bottom: 16px;

        &:has(.title) {
            justify-content: space-between;
        }

        .title {
            margin: 0;
            font-size: 18px;
            line-height: 24px;
        }

        .box__product-setting {
            gap: 0;
            padding: 0;

            .box__setting::after {
                margin: 0 16px 0 4px;
            }
        }

        .button__refresh {
            padding: 7px;
            border: 1px solid $color-gray-400;
            border-radius: 6px;
            background: $color-white;

            &::before {
                @include useSvg-icons("refresh", 24px);
                display: block;
                content: "";
            }

            &:hover {
                border-color: $color-gray-500;
                background: $color-gray-50;
            }
        }
    }

    &-inner {
        position: relative;
        padding-left: 56px;

        &::before {
            @include useSvg-icons("count_bg_active", 44px);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
        }

        &::after {
            @include useSvg-icons("icon_menu-check_white", 24px);
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
        }

        &:has(.text__type--seller-agree) {
            &::after {
                @include useSvg-icons("icon_seller-agree", 24px);
            }
        }

        .image__wrap {
            position: absolute;
            right: -8px;
            bottom: -4px;
            width: 44px;
            height: 44px;
        }

        .text__type {
            font-family: $gmarketSans;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;

            + .text {
                @include ellipsis;
                margin-top: 8px;
            }
        }
        
        .text {
            width: calc(100% - 54px);
        }

        .link__text {
            display: inline-block;
            margin-top: 8px;
        }
    }

    .box__no-content {
        height: 198px;
    }

    .box__top-side {
        .box__dropdown {
            .button__opener,
            .button__option {

                font-family: $gmarketSans;
            }
        }
    }
}

// 에러페이지
.box__error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &--inner {
        padding-bottom: 60px;
        text-align: center;

        .title {
            margin-bottom: 8px;
            font-size: 28px;
            line-height: 36px;

            &--error::before {
                @include useSvg-icons("icon_nodata_gray_small", 64px);
                display: block;
                content: "";
                margin: 0 auto 16px;
            }
        }

        .text {
            color: $color-gray-600;
        }

        .box__gap-12 {
            justify-content: center;
            margin-top: 32px;

            .button--xxlarge {
                width: 220px;
            }
        }
    }
}

.box__quantity {
    position: relative;
    margin-bottom: 16px;
    font-size: 0;

    &.is-fold {
        .box__quantity-guide {
            border-radius: 8px;
        }

        .box__quantity-data {
            display: none;
        }

        .button--open {
            &::before {
                transform: rotate(180deg);
            }
        }
    }

    &--red {
        .box__quantity-guide {
            border: 1px solid $color-red-300;
            background-color: $color-red-50;

            &::before {
                @include useSvg-icons("emoji_cry", 40px);
            }
        }
    }

    &-guide {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        padding: 16px 40px 16px 92px;
        border: 1px solid $color-blue-300;
        border-radius: 8px 8px 0 0;
        background-color: $color-blue-50;

        &::before {
            @include useSvg-icons("emoji_smile", 40px);
            content: "";
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);
        }

        .box__guide-text {
            display: flex;
            align-items: center;
        }
    }

    &-data {
        padding: 20px 40px;
        border: 1px solid $color-gray-300;
        border-top: none;
        border-radius: 0 0 8px 8px;
        background-color: $color-white;
    }

    &-detail {
        display: flex;
        align-items: center;

        .title__detail {
            margin-right: 12px;
            color: $color-gray-600;
            font-size: 14px;
            line-height: 20px;
    
            &::after {
                @include useSvg-icons("arrow-right_fill", 8px);
                display: inline-block;
                content: "";
            }
        }
    }

    &-count {
        &-wrap {
            display: inline-flex;

            + .title__detail {
                &::before {
                    display: inline-block;
                    content: "";
                    width: 1px;
                    height: 14px;
                    margin: 0 12px -2px;
                    background: #d9d9d9;
                }
            }
        }

        .text__count {
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-size: 16px;
            line-height: 23px;
            vertical-align: middle;


            &--red {
                color: $color-red-500;
                font-weight: 700;
            }
        }

        + .text {
            margin-left: 4px;
        }
    }

    .button--open {
        display: inline-flex;
        margin-left: 0;
    }
}

// 프로모션 배너 노출
.box__promotion {
    display: flex;
    overflow: hidden;
    align-items: center;
    min-height: 220px;
    margin: 16px 0;
    padding: 0 300px 0 80px;
    border: 0;
    background: linear-gradient(117.43deg, #ab2dde -52.09%, #5400b3 69.66%);

    &::before {
        content: "";
        position: absolute;
        top: 13px;
        left: 0;
        width: 544px;
        height: 208px;
        background-image: url("../img/single/image_bigsmileday_before.png");
        background-size: 100%;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 637px;
        height: 299px;
        background-image: url("../img/single/image_bigsmileday_after.png");
        background-size: 100%;
    }

    .text__tag {
        display: inline-block;
        max-width: fit-content;
        height: 34px;
        padding: 10px 16px;
        border-radius: 88px;
        background: rgba(0, 0, 0, .2);
        color: $color-white;
        font-family: $gmarketSans;
        font-size: 16px;
        line-height: 16px;

        .text__yellow {
            color: $color-yellow;
        }
    }

    .text__title {
        margin-top: 6px;
        margin-bottom: 0;
        color: $color-white;
        font-size: 28px;
        line-height: 36px;

        .text__yellow {
            color: #ffce3f;
        }
    }

    .button--medium {
        max-width: fit-content;
        margin-top: 12px;
        border: 0;
        color: #5501b4;
    }

    &--inner {
        position: relative;
    }

    &--comingsoon {
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        height: 666px;
        border: 0;
        background: linear-gradient(117.43deg, #ab2dde -52.09%, #5400b3 69.66%);

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 424px;
            height: 666px;
            background-size: 100%;
        }

        &::before {
            left: 0;
            background-image: url("../img/single/image_bigs_preparing_before.png");
        }

        &::after {
            right: 0;
            background-image: url("../img/single/image_bigs_preparing_after.png");
        }

        .box__promotion--inner {
            font-family: $gmarketSans;
            text-align: center;

            .text__yellow {
                color: $color-yellow;
            }

            .box__inner--title {
                color: $color-white;
                font-size: 22px;
                line-height: 22px;

                .text__yellow {
                    margin-top: 12px;
                    font-size: 50px;
                    line-height: 50px;
                }
            }

            .image__logo {
                width: 280px;
                height: 250px;
                margin: 24px auto 0;
                background-image: url("../img/single/image_bigsmileday-logo.png");
                background-size: 100%;
            }

            .text {
                margin-top: 24px;
                color: $color-white;
                font-size: 36px;
                line-height: 54px;

                .text__point {
                    font-size: 44px;
                }
            }
        }
    }

    // 웹폼 기본 스타일
    &-wrap {
        margin: 0 -24px 48px;
        color: $color-gray-900;
        font-family: $gmarketSans;

        img {
            width: auto;
            max-width: 100%;
        }
    }

    &-inner {
        position: relative;
        width: 774px;
        margin: 0 auto;
    }

    &-section {
        scroll-margin-top: 64px;
    }

    &-tab {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        background: $color-yellow;

        .list__event-tab {
            display: flex;
            justify-content: center;

            .list-item {
                position: relative;
                width: 167px;

                &:not(:first-child)::before {
                    opacity: .2;
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: -1px;
                    width: 1px;
                    height: 32px;
                    background: $color-black;
                    transform: translateY(-50%);
                }
            }

            .link {
                display: block;
                opacity: .4;
                height: 64px;
                color: $color-gray-900;
                font-size: 14px;
                line-height: 64px;
                text-align: center;
                text-decoration: none;
                transition: color .2s linear;

                &.is-active,
                &:hover {
                    opacity: 1;
                    font-weight: bold;
                }
            }
        }
    }

    &-faq {
        padding: 70px 0;
        background: #f4f4f4;
        word-break: keep-all;
        scroll-margin-top: 64px;

        .box__faq {    
            &-title {
                margin-bottom: 40px;
                text-align: center;

                .text {
                    color: $color-gray-900;
                    font-size: 44px;
                    line-height: 54px;

                    &__bsd {
                        display: block;
                        color: #6408c9;
                        font-weight: bold;
                    }
                }
            }

            &-item {
                margin-top: 12px;
                padding: 30px;
                border-radius: 16px;
                background: $color-white;
            }
        }

        .text {
            margin: 12px 0 0 33px;
            font-size: 16px;
            line-height: 24px;

            ~ .text {
                margin-top: 10px;
            }

            &--gray {
                display: block;
                color: $color-gray-600;
            }

            &__title {
                position: relative;
                padding-left: 33px;
                font-weight: bold;
                font-size: 20px;
                line-height: 26px;
            }

            &__q {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
