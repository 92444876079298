*:not(html, body, .box__navigation, .box__preview-content) {
    &::-webkit-scrollbar {
        width: 18px;
        min-height: 18px;
    }

    &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 20px;
        background: $color-gray-500;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
        background: $color-gray-50;
    }

    &::-webkit-scrollbar-corner {
        border-top: 1px solid $color-gray-400;
        border-bottom: 1px solid $color-gray-400;
        background: $color-gray-50;
    }

    &::-webkit-scrollbar-track:vertical {
        border-right: 1px solid $color-gray-400;
        border-left: 1px solid $color-gray-400;
    }

    &::-webkit-scrollbar-track:horizontal {
        border-top: 1px solid $color-gray-400;
        border-bottom: 1px solid $color-gray-400;
    }
}

body,
input,
select,
textarea,
button {
    font-family: $font-default;
}

html {
    scroll-behavior: smooth;
}

body {
    background: $color-gray-100;
    cursor: $cursor-default;
}

a,
button,
input[type=file],
input[type=radio],
input[type=checkbox] {
    cursor: $cursor-hover;
}

input::-webkit-inner-spin-button {
    appearance: none;
}

input::-webkit-autofill,
input::-webkit-autofill:hover,
input::-webkit-autofill:focus,
input::-webkit-autofill:active {
    box-shadow: 0 0 0 1000px $color-white inset !important;
    transition: background-color 9999s ease-out;
    transition: background-color 5000s ease-in-out 0s;
}

a.button {
    text-decoration: none;
}

.for-a11y,
caption span,
legend {
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    text-indent: -9999px;
}

#skip-navigation a {
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    z-index: 1020;
    width: 100%;
    padding: 9px 0;
    background: #1179ea;
    color: $color-white;
    font-size: 0;
    line-height: 0;
    text-align: center;
    text-decoration: none;

    &:active,
    &:focus {
        opacity: .7;
        top: 0;
        font-weight: bold;
        font-size: 13px;
        line-height: 1.5;
    }
}

.text__point {
    font-weight: 700 !important;
}

.text__primary {
    color: $color-blue-500 !important;
}

.text__secondary {
    color: $color-red-500 !important;
}

.text__underline {
    text-decoration: underline !important;
}
