.cs-center {
    min-width: 1280px;
    padding: 60px 0 0 220px;

    .header {
        .box__header {
            background: $color-blue-600;
        }

        .box__service .link {
            color: $color-blue-200;

            &__logo {
                color: $color-white;

                .icon__beta {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7493%) hue-rotate(144deg) brightness(120%) contrast(100%);
                }
            }
        }

        .box__menu .link {
            color: $color-white;
        }

        .list-item + .list-item::before {
            background: $color-blue-300;
        }

        .button__logout {
            background: $color-blue-500;
        }

        .text__time {
            color: $color-blue-200;

            .text__primary {
                color: $color-white !important;
            }
        }
    }

    .footer {
        .link__text {
            text-decoration: underline;
        }
    }

    .box {
        &__navigation {
            padding-top: 18px;
        }

        &__nav-link .link__icon::after {
            display: none;
        }

        &__container {
            padding: 28px 24px 24px;

            .title {
                margin-bottom: 28px;
                color: $color-gray-800;
                font-family: $font-default;
                font-size: 20px;
                line-height: 28px;
            }

            .box {
                margin-bottom: 16px;
                padding: 32px 40px;

                + .box {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__title-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28px;

            .title {
                margin-bottom: 0;
            }
        }

        &__keyword {
            display: flex;
            justify-content: center;
            gap: 12px;

            .link__text {
                display: inline-flex;
                align-items: center;
                height: 40px;
                padding: 0 16px;
                border: 1px solid $color-gray-400;
                border-radius: 35px;
                background-color: $color-white;
                color: $color-gray-800;
                font-size: 14px;
                text-decoration: none;

                &::after {
                    content: none;
                }
            }
        }

        &__search {
            width: 700px;

            &-header-main {
                padding: 52px 0;
                text-align: center;

                .title {
                    margin-bottom: 24px;
                    color: $color-gray-900;
                    font-family: $gmarketSans;
                    font-size: 32px;
                    line-height: 1;
                    letter-spacing: -1px;
                }
            }

            &-header {
                .box__title-wrap {
                    align-items: center;
                    justify-content: center;
                    gap: 24px;
                    margin-bottom: 0;

                    .title {
                        color: $color-gray-900;
                        font-family: $gmarketSans;
                        font-size: 24px;
                        line-height: 1;
                        letter-spacing: -1px;
                    }
                }
            }

            &--enter {
                &.is-open {
                    .form__input {
                        border: 1px solid $color-gray-800;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            &-keyword {
                top: 55px;
                border: 1px solid $color-gray-800;
                border-top: 0;

                .list-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    padding: 4px 16px;
                    border-top: 1px solid $color-gray-300;

                    &:hover {
                        background-color: $color-gray-50;
                    }
                }

                .text {
                    color: $color-gray-500;
                    font-size: 12px;
                    line-height: 16px;
                    white-space: nowrap;
                }

                .button__option {
                    overflow: hidden;
                    max-width: 591px;
                    padding: 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &-no-result {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 783px;

                .text {
                    color: $color-gray-500;
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &-detail {
                padding-bottom: 44px !important;
                color: $color-gray-800;
                font-size: 14px;
                line-height: 20px;

                > .title {
                    padding: 10px 0 30px;
                    border-bottom: 1px solid $color-gray-200;
                    font-size: 24px;
                }
            }

            +.box__keyword {
                margin-top: 20px;
            }

            .form__input {
                border: 1px solid $color-gray-800;

                &-xlarge {
                    height: 56px;
                    padding: 18px 40px 18px 16px;
                    border-radius: 8px;
                    font-size: 16px;
                }
            }

            .button__search {
                &::before {
                    @include useSvg-icons("icon_search", 24px);
                }
            }
        }

        &__question {
            padding-bottom: 24px;

            .title {
                margin-bottom: 12px;
            }

            .box__pagination {
                padding-top: 20px;
                border-top: 1px solid $color-gray-200;
            }
        }

        &__consult {
            +.box__consult {
                margin-top: 40px;
            }

            &-wrap {
                padding-top: 36px;
            }

            .text {
                &__top {
                    margin-bottom: 10px;
                    font-size: 16px;
                    line-height: 22px;
                }

                &__emphasis {
                    display: flex;
                    align-items: center;
                    font-family: "Noto Sans KR",sans-serif;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 32px;
                }

                &__sub {
                    margin-left: 4px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        &__column-wrap {
            .box {
                margin: 0;
            }
        }

        &__file-attach {
            max-width: 484px;
        }

        &__inquiry {
            display: flex;
            gap: 4px;
            padding: 14px 16px 20px;
            text-align: left;

            + .box__inquiry {
                padding: 20px 16px 14px;
                border-top: 1px solid $color-gray-300;
            }

            > .text {
                font-weight: 700;

                &__question {
                    color: $color-blue-500;
                }

                &__answer {
                    color: $color-green-500;
                }
            }

            &-inner {
                .text {
                    &__point {
                        margin-bottom: 4px;
                    }

                    + .box__file-attach {
                        margin-top: 12px;

                        .box__file-item:not(:last-child) {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }

    .box__table {
        .table-row {
            &.is-active {
                +.table-row--inquiry {
                    display: table-row;
                }
            }

            &--inquiry {
                display: none;

                &:hover {
                    .table-data {
                        background: $color-white !important;
                    }
                }

                .table-data {
                    padding: 0;
                    white-space: normal;
                }
            }
        }
    }

    .text {
        &__category {
            margin-left: 8px;
            color: $color-gray-600;
            font-size: 14px;
        }
    }

    .link__text {
        text-decoration: none;
    }

    .button__more {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        border-top: 1px solid $color-gray-200;
        color: $color-gray-800;
        font-size: 16px;
        line-height: 22px;

        &::after {
            @include useSvg-icons("icon_arrow_down-16-800", 16px);
            content: "";
            margin-left: 2px;
        }

        &.is-active {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .list {
        &__question {
            overflow: hidden;

            .list-item {
                border-bottom: 1px solid $color-gray-200;

                &:last-child {
                    border-bottom: 0;
                }
            }

            .link__text {
                display: block;
                padding: 24px 0;

                &::after {
                    content: none;
                }

                .text__question {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    .icon {
        display: block;
        margin: 0 auto 20px;
        background-image: url("../img/icons-svg.svg") !important;

        &__smile-delivery {
            @include useSvg-icons("icon_smile-delivery", 80px);
        }

        &__chat-consult {
            @include useSvg-icons("icon_chat-consult", 80px);
        }

        &__ask {
            @include useSvg-icons("icon_ask", 80px);
        }
    }

    .ee-contents {
        padding-bottom: 0;
        border-bottom: 0;

        * {
            max-width: 900px !important;
        }

        .ee-image {
            max-width: 900px !important;
        }
    }
}

.box {
    &__location {
        margin-top: 32px;
        padding-top: 24px;
        border-top: 1px solid $color-gray-200;
    }

    &__column {
        width: 100%;
        @media only screen and (min-width: 1920px) {
            width: calc(50% - 8px);
        }

        &-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        &-half {
            width: calc(50% - 8px);
            @media only screen and (min-width: 1920px) {
                width: calc(25% - 12px);
            }
        }
    }
}

.list {
    &__location {
        display: flex;

        .list-item {
            font-size: 0;

            .link__text {
                &::after {
                    content: none;
                }
            }

            &::after {
                @include useSvg-icons("icon_chevron_right",16px);
                display: inline-block;
                content: "";
                width: 16px;
                height: 16px;
                margin: 0 4px;
                vertical-align: middle;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }

            .text {
                display: inline-block;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
