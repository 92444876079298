.box__panel {
    &.is-fold {
        .box__panel-content {
            display: none;
        }

        .button--open {
            &::before {
                transform: rotate(180deg);
            }
        }
    }

    &-badge {
        display: inline-flex;
        position: relative;
        top: 12px;
        z-index: 1;
        align-items: center;
        height: 32px;
        padding: 0 16px;
        border: 1px solid $color-blue-200;
        border-radius: 6px 0 4px;
        background: $color-blue-50;
        box-shadow: 0 6px 14px 0 rgba(0, 0, 0, .1);
        font-size: 0;

        &::before {
            display: inline-block;
            content: "";
        }

        &.icon-delivery::before {
            @include useSvg-icons("icon_badge_delivery", 20px);
        }

        &.icon-information::before {
            @include useSvg-icons("information_mark_blue", 16px);
        }

        &.icon-search-total::before {
            @include useSvg-icons("icon_search-total-fill", 16px);
        }
        
        &.icon-bsd {
            border: 1px solid #e5c4fc;
            background: #fbf5ff;

            &::before {
                @include useSvg-icons("information_mark_bsd", 16px);
            }

            .text__panel-badge {
                color: #7b00e7;
            }
        }

        + .box__panel {
            margin-top: 4px;
        }

        .text__panel-badge {
            position: relative;
            margin-left: 4px;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &-head {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        height: 72px;
        padding: 0 40px;

        .title__panel {
            color: $color-gray-800;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;

            &-information {
                display: inline-block;
                position: relative;
                padding-left: 25px;
                color: $color-gray-600;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    width: 1px;
                    height: 12px;
                    background-color: $color-gray-300;
                    transform: translateY(-50%);
                }

                + .link__text {
                    margin-left: 8px;
                    font-weight: 400;
                }
            }

            .button__guide-faq {
                margin: -1px 0 1px 4px;
            }

            .required-mark + .button__guide-faq {
                margin-left: 0;
            }

            .text--gray {
                display: inline-block;
                margin: -2px 0 2px 8px;
                vertical-align: middle;
            }
        }

        + .box__panel-content {
            border-top: 1px solid $color-gray-300;
        }
    }

    &-summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 20px;

        + .box__panel-summary {
            padding: 20px 0;
            border-top: 1px solid $color-gray-200;
        }

        > .box__align {
            width: auto;
        }

        .text {
            color: $color-gray-800;
            font-size: 18px;
            line-height: 24px;

            + .text--gray {
                margin-right: 0;
                color: $color-gray-600;
                font-size: 14px;
            }

            > .text__primary {
                font-weight: 600;
            }

            &.text--gray {
                color: $color-gray-600;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-content {
        position: relative;
        padding: 24px 40px;

        .box__filter {
            padding: 0;

            &-item {
                &:first-of-type {
                    padding: 0 0 20px;
                    border-top: none;
                }

                &:last-of-type {
                    padding: 20px 0 0;
                }

                &:first-of-type:last-of-type {
                    margin-bottom: 0;
                    padding: 0;
                }

                &.box__filter-item--announce {
                    padding: 0;
                }
            }

            &-head {
                min-width: 232px;
                padding-right: 24px;

                .text {
                    word-break: keep-all;
                }
            }

            &-content {
                width: calc(100% - 232px);

                .text__description {
                    margin: 12px 0 0;
                }
            }

            .button__wrap:not(.box__calendar .box__layer-calendar .button__wrap) {
                padding-bottom: 4px;
            }
        }

        .box__table {
            + .box__panel-summary {
                margin-top: 24px;
            }

            + .box__pagination {
                margin-top: 20px;

                + .box__information {
                    margin-top: 20px;
                }
            }
        }

        .box__information {
            margin-bottom: 0;

            + .box__filter,
            + .box__panel-summary {
                margin-top: 24px;
            }
        }

        .box__button {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            &::after {
                display: inline-block;
                content: "";
                width: 1px;
                height: 24px;
                margin: 8px 12px 0 0;
                background: $color-gray-300;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &-group {
                display: flex;
                gap: 12px 0;
            }
        }

        > .box__global:not(.is-active) {
            padding-bottom: 0;
        }
    }

    .box__setting {
        display: flex;
        align-items: center;
        gap: 12px;

        &--start {
            align-items: flex-start;
        }

        .form + .button {
            margin-left: 0;
        }

        .image__wrap {
            + .text {
                margin: 0;
            }
        }
    }

    .required-mark {
        &::before {
            margin: -2px 5px 2px 9px;
        }
    }

    &--item-name {
        .form--side {
            width: 100%;
            max-width: 840px;
        }

        .box__bundle {
            .box__group,
            .box__flag-wrap,
            .box__filter-group {
                width: 100%;
            }

            .box__flag-wrap {
                max-width: 840px;
            }

            > .box__filter-group {
                flex: 1;
            }
        }
    }

    &--item-category {
        .box__search,
        .box--gray {
            max-width: 840px;
        }

        .box__category-wrapper {
            max-width: 1340px;
        }
    }

    &--item-catalogue,
    &--smile-delivery {
        .box--gray,
        .form__multi {
            width: 100%;
            max-width: 840px;
        }
    }

    &--item-option {
        .box__button-group {
            flex-wrap: wrap;

            .button {
                white-space: nowrap;
            }
        }
    }

    &--item-keyinfo {
        .box__filter-group--center {
            width: 100%;

            .box__search {
                width: 100%;
                max-width: 840px;
            }
        }

        .box__search--enter .box__category-wrap {
            max-height: 360px;
        }
    }

    &--item-info {
        .box__group {
            width: 100%;
            max-width: 840px;

            .form--textarea {
                width: 100%;
            }
        }
    }

    &--item-delivery {
        .box__table {
            .table {
                width: 762px;
            }
        }
    }

    &--sale-price {
        .box__gap-20 {
            margin-top: 16px;

            + .box__gap-20 {
                padding-top: 20px;
                border-top: 1px solid $color-gray-200;
            }
        }
    }

    &--sale-method {
        .box__bundle + .box__bundle--line {
            margin-top: 0;
        }
    }

    &--deal-info {
        .box__filter-content {
            max-width: 989px;
        }
    }

    &--deal-item {
        .box__filter-content {
            max-width: 989px;

            .form,
            .form--textarea {
                width: 100%;
            }
        }

        .box__item-info {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    &--announce-seller {
        .box__gap {
            .form {
                width: 100%;
                max-width: 840px;
            }
        }
    }

    &--seller-terms {
        .box__gap {
            >.link__text {
                color: $color-gray-500;
                line-height: 16px;

                &::after {
                    @include useSvg-icons("icon_arrow_down-12-500", 12px);
                }
            }
        }
    }

    &--announce-event {
        .box__gap {
            .form {
                width: 100%;
                max-width: 840px;
            }
        }
    }

    &-view {
        display: flex;
        align-items: center;
        padding: 24px 40px;

        .title {
            margin-right: 32px;
            margin-bottom: 0;
            color: $color-gray-800;
            font-size: 20px;
            line-height: 28px;
        }

        .text {
            font-size: 16px;
            line-height: 22px;

            .link__text {
                margin-left: 8px;
            }
        }

        .bar--gray {
            margin: 0 16px;
        }

        .form + .text {
            font-size: 14px;
        }

        .button {
            &:last-of-type {
                flex-shrink: 0;
                margin-left: auto;
            }
        }
    }
}
