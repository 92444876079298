.text__badge {
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin: 0 4px;
    padding: 0 4px;
    border-radius: 20px;
    background: $color-blue-500;
    color: $color-white;
    font-size: 11px;
    line-height: 14px;
    vertical-align: middle;
        
    &::before {
        @include useSvg-icons("icon_badge", 16px);
        display: inline-block;
        content: "";
    }

    &--none::before {
        display: none;
    }
}

.text__flag {
    opacity: .9;
    position: absolute;
    top: -1px;
    left: -1px;
    height: 20px;
    padding: 2px 4px;
    border-radius: 6px 0 4px;
    background: $color-gray-800;
    color: $color-white;
    font-size: 12px;
    line-height: 16px;

    &--blue {
        background: $color-blue-500;
    }
}
