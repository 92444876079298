.box__ad {
    .box__tab {
        margin-bottom: 16px;

        .list__tab {
            .list-item {
                &.icon__extra {
                    &::before {
                        @include useSvg-icons("icon_extra-service", 56px);
                    }

                    &.is-active {
                        &::before {
                            @include useSvg-icons("icon_extra-service_on", 56px);
                        }
                    }
                }

                &.icon__ai {
                    &::before {
                        @include useSvg-icons("icon_ai", 56px);
                    }

                    &.is-active {
                        &::before {
                            @include useSvg-icons("icon_ai_on", 56px);
                        }
                    }
                }

                &.icon__power {
                    &::before {
                        @include useSvg-icons("icon_power-click", 56px);
                    }

                    &.is-active {
                        &::before {
                            @include useSvg-icons("icon_power-click_on", 56px);
                        }
                    }
                }

                &.icon__ad {
                    &::before {
                        @include useSvg-icons("icon_ADcenter", 56px);
                    }

                    &.is-active {
                        &::before {
                            @include useSvg-icons("icon_ADcenter_on", 56px);
                        }
                    }
                }
            }
        }
    }

    .box__board {
        overflow: visible;
        height: 100%;
        padding: 100px 0;
        border: 1px solid $color-gray-300;
        border-radius: 8px;
        background: $color-white;
    }

    .box__content {
        max-width: 900px;

        .text__emphasis {
            letter-spacing: -1px;
        }

        .box__emphasis {
            .box__button-wrap {
                .button {
                    width: fit-content;
                    padding: 0 28px;
                }
            }
        }
    }

    .box__preview {
        max-width: 900px;
        margin-top: 16px;
    }

    .box__chain {
        max-width: 900px;
        margin-top: 80px;
    }

    .box__chain-item {
        width: 900px;
        padding: 24px 32px;

        .list__information {
            width: 368px;
            margin: 0 auto;

            .text {
                font-family: $gmarketSans;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
