@font-face { /* noto-sans-kr-300 - korean */
  font-family:'Noto Sans KR';font-style:normal;font-weight:300;
  src:local('Noto Sans KR Light'), local('NotoSansKR-Light'),
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-300.woff') format('woff');/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {/* noto-sans-kr-regular - korean */
  font-family:'Noto Sans KR';font-style:normal;font-weight:400;
  src:local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-regular.woff') format('woff');/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {/* noto-sans-kr-500 - korean */
  font-family:'Noto Sans KR';font-style:normal;font-weight:500;
  src:local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-500.woff') format('woff');/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {/* noto-sans-kr-700 - korean */
  font-family:'Noto Sans KR';font-style:normal;font-weight:700;
  src:local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('//script.gmarket.com/fonts/noto-sans-kr-v11-korean-700.woff') format('woff');/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family:'Gmarket Sans';font-style:normal;font-weight:700;
  src:local('Gmarket Sans Bold'),local('GmarketSans-Bold'),
  url('//script.gmarket.com/fonts/GmarketSansBold.woff2') format('woff2'),/* Chrome 26+,Opera 23+,Firefox 39+ */
  url('//script.gmarket.com/fonts/GmarketSansBold.woff') format('woff');/* Chrome 6+,Firefox 3.6+,IE 9+,Safari 5.1+ */
}
@font-face {
  font-family:'Gmarket Sans';font-style:normal;font-weight:500;
  src:local('Gmarket Sans Medium'),local('GmarketSans-Medium'),
  url('//script.gmarket.com/fonts/GmarketSansMedium.woff2') format('woff2'),/* Chrome 26+,Opera 23+,Firefox 39+ */
  url('//script.gmarket.com/fonts/GmarketSansMedium.woff') format('woff');/* Chrome 6+,Firefox 3.6+,IE 9+,Safari 5.1+ */
}
@font-face {
  font-family:'Gmarket Sans';font-style:normal;font-weight:300;
  src:local('Gmarket Sans Light'),local('GmarketSans-Light'),
  url('//script.gmarket.com/fonts/GmarketSansLight.woff2') format('woff2'),/* Chrome 26+,Opera 23+,Firefox 39+ */
  url('//script.gmarket.com/fonts/GmarketSansLight.woff') format('woff');/* Chrome 6+,Firefox 3.6+,IE 9+,Safari 5.1+ */
}
