.coupon {
    .box__complete {
        margin: 0;

        .title__complete:has(+.box__complete-content) + .box__complete-content {
            padding-top: 36px;
        }

        .box__complete-content .button__wrap {
            margin-top: 42px;
        }

        .text {
            font-size: 16px;
        }
    }

    .box__panel {
        .box__filter-content {
            .form--side {
                max-width: 840px;
            }

            .box__tab--radio {
                width: fit-content;

                .button__tab {
                    width: 132px;
                }
            }

            .text__information {
                + .list__information {
                    margin: 2px 0 0;
                }

                &--blue + .list__information {
                    margin: 2px 0 12px;
                }
            }

            .box__search {
                max-width: 840px;
                margin-top: -4px;
            }

            .box__table-wrap {
                width: 100%;
            }

            .box__group .text {
                .text.text--gray {
                    position: relative;
                    top: -2px;
                    font-size: 12px;
                }
            }
        }

        .box__filter-group.box__filter-group--vertical {
            .box__align--between {
                max-width: 840px;

                &:has(.button--upload) {
                    max-width: 100%;
                }
            }
    
            .box__table:not(.box__table--scroll) {
                max-width: 840px;
                margin-bottom: 10px;
            
                &:has(.box__pagination) {
                    margin-bottom: 0;
                }

                ~ .box__pagination {
                    max-width: 840px;
                }
            }
        }
    }

    .box__category {
        .list__category {
            .list-item {
                &.is-selected {
                    background-color: $color-blue-50;
                }
    
                &.is-active,
                &:hover {
                    background: $color-blue-50;
                }
            }
        }
    }
}
