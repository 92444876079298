.box__dropdown {
    display: inline-block;
    position: relative;
    font-size: 0;
    vertical-align: top;

    &.is-open {
        .button__opener {
            border: 1px solid $color-gray-900;
            background: $color-white;
            color: $color-gray-800;

            &::after {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .list {
            display: block;
        }

        .box__category-wrap {
            display: flex;
        }

        &:not(.box__dropdown--top) .button__opener {
            border-bottom-color: $color-gray-300;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.is-disabled {
        cursor: default;
        pointer-events: none;

        &.is-active {
            .button__opener {
                color: $color-gray-600;
            }
        }

        .button__opener {
            background: $color-gray-100;
            color: $color-gray-400;

            &::after {
                opacity: .4;
            }
        }
    }

    &.is-error {
        .button__opener {
            border-color: $color-red-500;
        }

        > .text {
            display: block;
            margin: 3px 0 0 16px;
            color: $color-red-500;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &.is-active {
        &.is-disabled {
            color: $color-gray-600;
        }

        .button__opener {
            color: $color-gray-800;
        }
    }

    &.w-122 {
        width: 122px;

        + .box__search {
            .form__input {
                padding-left: 136px;
            }

            .form__label {
                left: 136px;
            }
        }
    }

    &.w-112 {
        width: 114px;

        + .box__search {
            .form__input {
                padding-left: 130px;
            }

            .form__label {
                left: 130px;
            }
        }
    }

    .button__opener {
        @include ellipsis;
        position: relative;
        width: 100%;
        height: 40px;
        padding: 0 42px 0 16px;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        background: $color-white;
        color: $color-gray-800;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;

        &::after {
            @include useSvg-icons("icon_arrow_down-20", 20px);
            content: "";
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }

        > .box__flag {
            margin-right: 4px;
        }
    }

    &:not(.box__dropdown--top) {
        .list {
            border-top: 0;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }
    }

    .list {
        @include custom-scrollbar-track;
        display: none;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 5;
        max-height: 321px;
        border: 1px solid $color-gray-900;
        background: $color-white;
    }

    .list-item {
        position: relative;

        &.is-selected {
            .button__option {
                background: $color-white;
                color: $color-blue-500;
            }
        }

        &:last-child {
            .button__option {
                border-bottom: none;
            }
        }
    }

    .button__option {
        position: relative;
        width: 100%;
        min-height: 40px;
        padding: 9px 16px;
        border-bottom: 1px solid $color-gray-200;
        background: $color-white;
        color: $color-gray-800;
        font-size: 14px;
        text-align: left;

        &:hover {
            background: $color-gray-50;
            color: $color-blue-500;
        }

        &--star {
            @include useSvg-icons("flag_star", 20px);
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
        }

        > .box__flag {
            margin-right: 4px;
        }
    }

    &--input {
        .list {
            max-height: 305px !important;
            padding: 16px 16px 0;
        }

        .list-item {
            padding-bottom: 16px;
        }
    }

    &--multi {
        .list {
            max-height: 384px !important;
            padding: 0 16px;
        }

        .list-item {
            padding: 16px 0;

            + .list-item {
                border-top: 1px solid $color-gray-200;
            }
        }

        .form {
            display: block;

            + .form {
                padding-top: 16px;
            }
        }

        .text__mall {
            display: block;
            color: $color-blue-500;
            font-size: 11px;

            + .form {
                padding: 16px 0 0;
            }

            + .form--all {
                display: block;
                padding: 16px 0;
                border-bottom: 1px solid $color-gray-200;
            }
        }
    }

    &--search {
        .form {
            position: absolute;
            top: 1px;
            bottom: 1px;
            left: 1px;
            z-index: 1;

            &__input {
                height: 38px;
                border: 0;
            }
        }

        .text__result--none {
            height: 40px;
            padding: 0 16px;
            border-bottom: 1px solid $color-gray-200;
            background: $color-gray-50;
            color: $color-blue-500;
            font-size: 14px;
            line-height: 40px;
            text-align: left;
        }
    }

    &--entry {
        &.is-open {
            .button__opener::before {
                display: none;
            }
        }

        .form {
            position: absolute;
            top: 1px;
            right: 54px;
            bottom: 1px;
            left: 1px;
            z-index: 1;
            width: auto !important;

            &__input {
                height: 38px;
                border: 0;
            }
        }
    }

    &--date {
        .button__option {
            &:disabled {
                color: $color-gray-500;

                &:hover {
                    color: $color-gray-500;
                }

                .text__date {
                    color: $color-gray-400;
                    text-decoration: line-through;
                }
            }
        }
    }

    &--double {
        >.text--red {
            margin: 2px 0 0 16px;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &--star {
        width: 100%;
        max-width: 1072px;

        .list-item {
            &.is-active {
                .button__option--star {
                    @include useSvg-icons("flag_star_active", 20px);
                }
            }

            &:has(.button__option--star) {
                .button__option {
                    padding: 7px 16px 7px 44px;
                }
            }
        }

        .button__option {
            height: auto;
            padding: 7px 16px;

            .text {
                display: block;
            }
        }
    }

    &--large {
        width: 100%;

        .button__opener {
            height: 56px;
            border-radius: 8px;
            font-size: 16px;
        }

        .list {
            max-height: 449px;
        }

        .button__option {
            height: 56px;
            min-height: 56px;
            font-size: 16px;
        }
    }

    &--top {
        &.is-open {
            .button__opener {
                border-top-color: $color-white;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .list {
                bottom: 100%;
                border-bottom: 0;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                &::-webkit-scrollbar-track {
                    border-top-right-radius: 6px;
                }
            }
        }
    }

    &--scroll-none {
        .list {
            max-height: none;
        }
    }
}

.box__split {
    display: flex;
    position: relative;
    width: 163px;
    height: 40px;

    .button--excel {
        width: 130px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        white-space: nowrap;
    }

    .box__dropdown {
        position: static;
        width: 33px;

        .button__opener {
            padding: 0;
            border-bottom: 0;
            border-bottom: 1px solid $color-gray-400;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background: $color-white;
            color: $color-gray-800;

            &::after {
                top: 50%;
                right: 6px;
                z-index: 1;
            }
        }

        .button__option {
            @include ellipsis;
            word-spacing: -1px;
        }

        .list {
            top: 40px;
            left: auto;
            min-width: 100%;
            border: 1px solid $color-gray-900;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .text {
                font-size: 14px;
            }
        }

        &.is-open {
            .button__opener {
                border-top: 1px solid $color-gray-400;
                border-right: 1px solid $color-gray-400;
                border-bottom-right-radius: 6px;
                background: $color-gray-100;
            }

            .text {
                color: inherit;
            }
        }
    }
}
