.product-contact {
    .box__table {
        &--scroll:not(.is-empty) {
            max-width: 100%;
            height: 800px;
            min-height: 584px;
            resize: vertical;
        }

        &--scroll-body {
            max-height: 270px;

            &.is-empty {
                .table-body {
                    height: 220px;
                }

                + .box__no-content {
                    height: 220px;
                }
            }
        }

        .table-data--multiline {
            .button__text {
                margin-left: 0;
                text-align: left;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
                
                &:has(.text--gray) {
                    &:hover {
                        text-decoration-color: $color-gray-500;
                    }
                }
            }

            &.table-data--ellipsis {
                .text {
                    @include multi-ellipsis(6);
                }
            }
        }

        .table-data--scroll {
            .table-data__inner {
                max-height: 158px;
            }
        }
        
        .table-row {
            &.is-select {
                .table-data--scroll {
                    .table-data__inner {
                        max-height: 154px;
                    }
                }
            }
        }

        .box__check {
            @include useSvg-icons("icon_type_superdeal", 20px);
        }

        .form--textarea {
            .form__inner {
                width: 340px;
                height: 130px;
            }
        }

        &--row {
            overflow-x: visible;
            font-family: "Noto Sans KR",sans-serif;

            .table-data {
                white-space: normal;

                &__inner {
                    flex-wrap: wrap;

                    +.table-data__inner {
                        margin-top: 8px;
                    }
                }
            }

            .box__divide {
                .text__sub {
                    margin-top: 4px;
                    color: $color-gray-500;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .box__gap {
                .box__gap {
                    .button--tiny {
                        margin-left: 4px;
                    }
                }
            }

            .text {
                &__point {
                    > .text--gray {
                        padding-left: 4px;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    .box__product {
        &-setting {
            &:has(.bar--gray) {
                .bar--gray {
                    height: 24px;
                }
            }

            .text {
                .box__tooltip {
                    margin-left: 4px;
                }
            }
        }
    }
    
    .box__panel-content {
        padding: 32px 40px;
    }

    .box__summary-update {
        display: flex;
        position: absolute;
        top: 34px;
        right: 39px;
        z-index: 1;
        align-items: center;

        .text__update {
            position: static;
        }

        .box__tooltip {
            margin: 2px 0 0 4px;
        }
    }

    //문의관리 > 구매내역 상세정보
    .box__purchase {
        flex-shrink: 0;
        width: 532px;
        padding: 28px 32px;
        border-top: 1px solid $color-gray-500;
        border-bottom: 1px solid $color-gray-300;
        border-left: 1px solid $color-gray-300;

        @media only screen and (max-width: 1280px) {
            width: 465px;
        }

        &-detail {
            display: flex;
        }

        &-item {
            display: flex;
            gap: 20px;

            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                +.box__purchase-item {
                    margin-top: 24px;
                }
            }

            .image__wrap {
                &:hover {
                    border-color: $color-gray-300;
                }
            }
        }

        &-content {
            .title {
                margin-bottom: 0;
                color: $color-gray-800;
                font-family: $font-default;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
            }

            .text__price {
                display: block;
                margin-top: 18px;
                font-family: $gmarketSans;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;

                .num {
                    font-weight: 600;
                }
            }
        }

        &-option {
            .text {
                color: $color-gray-600;

                + .text {
                    margin-top: 4px;
                }
            }
        }
    }
}

.text__secret {
    display: inline-flex;
    color: $color-gray-500;
    font-size: 12px;
    line-height: 16px;

    &::before {
        @include useSvg-icons("icon_pass", 16px);
        content: "";
    }
}

.box__dropdown--contact {
    .list {
        max-height: none;
    }

    .button__option {
        @include ellipsis;
    }
}

.box__number-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 192px;
}

// 리뷰내용보기 > 리뷰
.box__review {
    @include custom-scrollbar-track;
    overflow-y: auto;
    flex-shrink: 0;
    width: calc(50% - 10px);
    min-height: 521px;
    max-height: calc(100vh - 90px - 152px - 64px);

    .text {
        font-family: "Noto Sans KR",sans-serif;
    }

    &-content {
        display: flex;
        gap: 20px;
    }

    &-item {
        display: flex;
        gap: 12px;
        padding: 20px 24px;
        border-bottom: 1px solid $color-gray-300;

        .image__wrap {
            &:hover {
                border-color: $color-gray-300;
            }
        }
    }

    &-detail {
        padding: 20px 24px;
    }

    &-information {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        .text__thumbsup {
            color: $color-gray-600;

            &::before {
                @include useSvg-icons("icon_thumbsup", 16px);
                display: inline-block;
                content: "";
                margin: -2px 2px 0 1px;
                vertical-align: middle;
            }
        }
    }

    &-evaluation {
        display: flex;
        align-items: center;
        margin-right: 16px;

        > .text {
            color: $color-gray-600;
        }

        .bar--gray {
            margin: 0 12px;
        }
    }

    &-value {
        margin-top: 16px;

        .text {
            + .text {
                margin-left: 22px;
            }

            &__point {
                margin-right: 8px;
            }
        }
    }

    &-text {
        margin-top: 16px;

        .text--gray {
            color: $color-gray-500;
        }

        + .box__photo-wrap {
            margin-top: 20px;
        }
    }

    .box__item {
        &-detail {
            .title {
                margin-bottom: 0;
                color: $color-gray-800;
                font-family: $font-default;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                + .box__item-option {
                    margin-top: 4px;
                }
            }
        }

        &-option {
            .text {
                color: $color-gray-600;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .box__photo {
        width: 360px;

        .box {
            &__image-wrap {
                height: 326px;

                .image {
                    object-fit: cover;
                }
            }

            &__text-wrap {
                border-top: 1px solid $color-gray-300;
            }
        }

        .button__preview {
            position: absolute;
            right: 12px;
            bottom: 12px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            background-color: rgba($color-gray-800, .9);

            &::before {
                @include useSvg-icons("icon_expand", 20px);
                display: inline-block;
                content: "";
                vertical-align: middle;
            }
        }
    }
}

// 리뷰내용보기 > 답변하기
.box__entertype {
    height: calc(100% - 55px);
    padding: 8px 23px;

    &-wrap {
        flex-shrink: 0;
        width: calc(50% - 10px);
        min-height: 521px;
        max-height: calc(100vh - 90px - 152px - 64px);
        border: 1px solid $color-gray-900;
        border-radius: 8px;
        background: $color-gray-50;
        box-shadow: 0 6px 14px 0 rgba($color-black, .1);

        .box__process-setting {
            margin-bottom: 0;
            padding: 16px 24px;
            border-bottom: 1px solid $color-gray-300;
        }
    }

    .box__filter {
        &-item {
            padding: 16px 0;

            &--small {
                .box__filter-head {
                    padding: 4px 0;
                }
            }

            &--h138 {
                height: calc(100% - 138px);

                .box__filter-content,
                .form--textarea {
                    height: 100%;
                }
            }
        }

        &-head {
            width: 82px;
            min-width: 82px;
        }

        &-content {
            width: calc(100% - 82px);
        }
    }

    .box__number-wrap {
        gap: 4px;
        width: 100%;

        .button__number {
            width: 27px;
        }
    }

    .form--textarea {
        height: 100%;

        .form__inner {
            width: 308px;
            height: 100%;
        }
    }
}

.box__inquiry-history {
    .box__inquiry-qa {
        display: flex;
        position: relative;
        gap: 4px;
        
        +.box__inquiry-qa {
            margin-top: 16px;
        }

        &--gray {
            padding: 20px 0;

            &::before {
                @include absolute-full;
                content: "";
                right: -32px;
                left: -32px;
                z-index: -1;
                background: $color-gray-50;
            }
        }

        .text {
            &__main {
                flex: 1;
            }

            &__date {
                display: block;
                margin-top: 6px;
                color: $color-gray-500;
                font-size: 12px;
            }

            &__secret {
                flex: 0 0 auto;
                align-self: center;
                margin-left: 20px;
            }
        }
    }
}

.box__layer-content {
    &.is-scroll {
        .box__inquiry-history {
            .box__inquiry-qa--gray {
                &::before {
                    right: -14px;
                }
            }
        }
    }
}
