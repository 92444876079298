.box__count {
    display: flex;
    padding: 0 20px;

    &-list {
        position: relative;
        flex: 1;
        min-width: 212px;
        padding: 0 24px 28px;

        &::before {
            content: "";
            position: absolute;
            top: 20px;
            left: 0;
            width: 1px;
            height: 40px;
            background: $color-gray-300;
        }

        &:first-of-type::before {
            display: none;
        }
    }

    &-item {
        padding-top: 15px;
    }

    .text__count {
        margin-top: 2px;

        .number {
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            vertical-align: middle;
        }

        .text {
            display: inline-block;
            margin-left: 2px;
        }
    }

    .text__description {
        margin: 8px 0 0;
        word-break: keep-all;
    }
}
