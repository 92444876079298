.salesmanage {
    .box__information {
        .text__wrap {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .box__summary {
        .box__detail {
            .list__count {
                .list-item {
                    width: 200px;
                }
            }
        }
    }

    .box__product {
        .box__gap {
            flex: none;
        }
    }

    .box__filter.box .box__filter-group {
        .box__gap-12 {
            width: 100%;
        }
    }

    .text__description {
        width: max-content;
    }

    .icon-delivery {
        position: relative;

        &::before {
            @include useSvg-icons("icon_badge_delivery", 16px);
            display: inline-block;
            content: "";
            margin-right: 2px;
            vertical-align: middle;
        }

        .text__delivery-today {
            color: $color-blue-500;
            font-family: $gmarketSans;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }
    }
}


// 배송라벨 인쇄, 주문서 출력
@media print {
    html,
    body {
        -webkit-print-color-adjust: exact;
    }

    .box__popup .wrap .box__layer-inner {
        padding: 0;
    }

    .box__popup .box__layer-head,
    .box__popup .button__wrap,
    .box__popup--shipping-label .box__layer-content > div:not(.box__shipping-label),
    .box__popup--order .box__layer-content > .box__information {
        display: none !important;
    }

    .box__popup--shipping-label,
    .box__popup--order {
        overflow: visible;
    }

    .box__popup--shipping-label .wrap .box__layer-content,
    .box__popup--order .wrap .box__layer-content {
        overflow-y: visible;
        padding: 0;
    }

    .box__shipping-label {
        padding-top: 85px;
    }
}
