.homemain {
    min-width: 1280px;
    padding: 60px 0 0 220px;

    .box {
        &__container {
            iframe {
                width: 100%;
            }

            .box__dropdown {
                width: 220px;
            }

            &.box__notice {
                position: relative;
                min-height: calc(100vh - 256px - 60px);
                padding: 28px 24px 24px;

                .box__notice-summary {
                    .box__dropdown {
                        width: 136px;
                    }
                }
            }
        }

        &--gray {
            background: $color-gray-200;
        }

        &__column {
            width: 100%;
            @media only screen and (min-width: 1920px) {
                width: calc(50% - 8px);
            }

            &-group {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
                padding: 24px;

                .box {
                    & + .box {
                        margin: 0;
                    }
                }
            }

            &-new {
                width: calc(50% - 8px);
                padding: 32px;

                .box__button-wrap {
                    display: flex;
                    gap: 8px;
                }
            }

            &-fixed {
                width: calc(50% - 8px);
            }

            &-full {
                width: 100%;
            }

            &-half {
                width: calc(50% - 8px);
                @media only screen and (min-width: 1920px) {
                    width: calc(25% - 12px);
                }

                &--small {
                    width: calc(25% - 12px);

                    .box__column--head {
                        .title {
                            width: 100%;
                        }

                        .text {
                            @include ellipsis;
                            flex: 1;
                            width: 0;
                        }
                    }
                }
            }

            &-banner {
                &--text {
                    border-color: $color-blue-200;
                    background: $color-blue-50;
                }
            }

            &-statistics {
                .box__column {
                    &--head {
                        padding: 12px 24px;
                    }

                    &--body {
                        height: 436px;
                    }
                }

                .list-item__legend {
                    &--superdeal {
                        &::before {
                            @include useSvg-icons("legend_bar_superdeal_main", 43px);
                        }
                    }

                    &--allkill {
                        &::before {
                            @include useSvg-icons("legend_bar_allkill_main", 34px);
                        }
                    }
                }

                .text__info {
                    + .text {
                        margin-top: 6px;
                    }
                }
            }

            &-swiper {
                width: 100%;

                .box__column--body {
                    position: relative;
                    padding: 20px 0;
                }
            }

            &-promotion {
                position: relative;
                width: 100%;

                .title {
                    padding: 3px 0 1px;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &-exhibition {
                width: 100%;
                padding: 24px 188px 84px 32px;
                border-color: $color-blue-500;
                background: $color-blue-50;
                font-family: $gmarketSans;

                &-set {
                    width: calc(50% - 8px);
                    border-color: $color-gray-300;
                }

                .box__inner--title {
                    font-weight: 600;

                    ~ .text {
                        margin-bottom: 0;
                        color: $color-gray-900;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .text__t {
                    display: inline-block;
                    width: 55px;
                    height: 18px;
                    margin-right: 8px;
                    border-radius: 10px;
                    background: rgba($color-black, .4);
                    color: $color-white;
                    font-size: 10px;
                    line-height: 19px;
                    text-align: center;
                    vertical-align: top;
                }

                .button--more {
                    right: auto;
                    bottom: 24px;
                    left: 32px;
                    border-radius: 50px;

                    &::after {
                        @include useSvg-icons("icon_chevron_right",16px);
                        margin: -1px 0 1px 4px;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6184%) hue-rotate(223deg) brightness(129%) contrast(110%);
                        transform: rotate(0deg);
                    }

                    &:not(.button--blue) {
                        border-color: rgba($color-black, .1);
                        color: $color-gray-800;

                        &::after {
                            filter: brightness(0) saturate(100%) invert(0%) sepia(2%) saturate(8%) hue-rotate(353deg) brightness(93%) contrast(103%);
                        }
                    }
                }

                .box__image {
                    top: 50%;
                    right: 32px;
                    width: 138px;
                    margin-top: -69px;
                }
            }

            &--head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 64px;
                padding: 12px 24px 11px;
                border-bottom: 1px solid $color-gray-300;
                white-space: nowrap;

                .title {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    margin: 0;
                    font-family: $font-default;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;

                    .text {
                        color: $color-gray-600;
                    }

                    .link {
                        color: inherit;
                        text-decoration: none;

                        &::after {
                            @include useSvg-icons("icon_arrow_active", 20px);
                            display: inline-block;
                            content: "";
                            margin: -1px 0 1px 4px;
                            vertical-align: middle;
                            transform: rotate(180deg);
                        }
                    }

                    .box__tooltip {
                        margin: -1px 0 1px 4px;
                    }
                }

                .text {
                    color: $color-gray-500;
                    font-size: 12px;
                    line-height: 16px;
                }

                .button__refresh {
                    padding: 10px 0;

                    &::after {
                        @include useSvg-icons("icon_main_refresh", 20px);
                        display: inline-block;
                        content: "";
                        margin: 0 0 0 4px;
                        vertical-align: middle;
                    }
                }
            }

            &--body {
                min-height: 176px;
                padding: 20px 24px;
            }
        }

        &__inner {
            &--half {
                display: inline-block;
                position: relative;
                width: 50%;
                padding-right: 24px;

                + .box__inner--half {
                    padding-right: 0;
                    padding-left: 24px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 1px;
                        background: $color-gray-200;
                    }
                }
            }

            &--summary {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid $color-gray-200;

                .text {
                    font-size: 16px;
                }
            }

            &--title {
                font-family: $gmarketSans;
                font-weight: normal;
                font-size: 24px;
                line-height: 32px;

                + .text {
                    margin: 8px 0 32px;
                    font-family: $gmarketSans;
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            &--link {
                display: block;
                overflow: hidden;
                position: relative;
                height: 186px;
                padding: 48px 188px 0 32px;
                border: 1px solid rgba($color-black, .1);
                border-radius: 8px;
                text-decoration: none;

                img {
                    position: absolute;
                    top: 50%;
                    right: 32px;
                    width: 138px;
                    margin-top: -69px;
                }

                .title {
                    @include ellipsis;
                    max-width: 100%;
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;

                    + .text {
                        margin-top: -4px;
                    }
                }

                .text {
                    @include ellipsis;
                    max-width: 100%;
                    font-family: $gmarketSans;
                    font-size: 16px;
                    line-height: 22px;

                    &__tag {
                        display: inline-block;
                        max-width: fit-content;
                        padding: 3px 8px;
                        border-width: 1px;
                        border-style: solid;
                        border-radius: 10px;
                        font-family: $gmarketSans;
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
            }
        }

        &__graph {
            &-summary {
                height: 60px;
                padding: 0;

                > .text {
                    color: $color-gray-500;
                    font-family: $gmarketSans;
                    font-size: 12px;
                    line-height: 16px;

                    .icon__arrow {
                        display: inline-block;
                        position: relative;

                        &::after {
                            display: inline-block;
                            content: " ";
                            width: 7px;
                            height: 2px;
                            margin: -1px 4px 1px;
                            background: $color-gray-500;
                            vertical-align: middle;
                        }
                    }

                    &--increase {
                        color: $color-red-500;

                        .icon__arrow {
                            &::after {
                                @include useSvg-icons("icon_increase_value", 16px);
                                margin: -1px 0 1px;
                                background-color: transparent;
                            }
                        }
                    }

                    &--decrease {
                        color: $color-blue-500;

                        .icon__arrow {
                            &::after {
                                @include useSvg-icons("icon_decrease_value", 16px);
                                margin: -1px 0 1px;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            &-content {
                padding: 0;

                .box__chart {
                    padding: 0;
                }
            }

            .box__tab--button {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            .list__legend {
                margin-bottom: 0;
            }

            .box__tooltip-bubble {
                &--superdeal {
                    width: 45px;

                    &::after {
                        @include useSvg-icons("icon_superdeal_main", 45px);
                    }
                }

                &--allkill {
                    width: 36px;

                    &::after {
                        @include useSvg-icons("icon_allkill_main", 36px);
                    }
                }
            }
        }

        &__announcement {
            > .text {
                display: block;
                margin-bottom: 12px;
                font-weight: 600;
            }

            + .box__announcement {
                margin-top: 16px;
                padding-top: 16px;
                border-top: 1px solid $color-gray-200;
            }
        }

        &__more {
            position: absolute;
            right: 20px;
            bottom: 0;
            left: 20px;
            padding: 16px 4px 20px;
            border-top: 1px solid $color-gray-200;
        }

        &__image {
            position: absolute;
            right: 24px;
            bottom: 24px;
            width: 120px;
        }

        .box__tab--list {
            display: flex;
            position: relative;
            justify-content: space-around;
            margin-bottom: 20px;

            .button__tab {
                height: 42px;
                padding: 2px 0 0;

                &.is-active::after {
                    right: 0;
                    left: 0;
                }

                &::before {
                    display: none;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1px;
                height: 12px;
                margin-top: -6px;
                background: $color-gray-300;
            }
        }

        .box__tab--panel {
            padding-bottom: 50px;

            .list__announcement {
                overflow: hidden;
                max-height: 284px;

                .text {
                    align-items: flex-start;

                    &__ellipsis {
                        max-width: 100%;
                    }

                    &__label {
                        display: inline-block;
                    }

                    &:has(.icon__new) {
                        .text__ellipsis {
                            max-width: calc(100% - 10px);
                        }
                    }
                }

                .icon__new {
                    width: 5px;
                    height: 5px;
                    margin: 4px 0 0 5px;
                    background-color: $color-red-500;
                }
            }

            .box__no-content {
                height: 280px;
            }
        }
    }

    .list__announcement {
        .link__text {
            &:hover {
                .text__ellipsis {
                    text-decoration: underline;
                }
            }
        }
    }

    .text__info {
        color: $color-gray-600;
        font-size: 14px;

        .link__info {
            position: relative;
            margin-right: 2px;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-weight: 600;
            font-size: 20px;
            text-decoration: none;

            &:hover {
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 1px;
                    left: 0;
                    height: 1px;
                    background: $color-gray-800;
                }
            }

            &.text__primary {
                .text {
                    color: inherit;
                }

                &:hover {
                    &::after {
                        background: $color-blue-500;
                    }
                }
            }

            &.text__secondary {
                &:hover {
                    &::after {
                        background: $color-red-500;
                    }
                }
            }
        }
    }

    .list {
        &__info {
            + .list__info {
                margin-top: 12px;
                padding-top: 16px;
                border-top: 1px solid $color-gray-200;
            }

            .list-item {
                display: flex;
                justify-content: space-between;
                margin-top: 7px;
                line-height: 28px;
                white-space: nowrap;

                &:first-child {
                    margin-top: 0;
                }

                .box__left {
                    .box__flag {
                        margin: 2px 8px 0 0;
                        line-height: 0;
                        vertical-align: top;
                    }

                    .text {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 28px;
                    }
                }

                &--sub {
                    margin-top: 4px;
                    line-height: 22px;

                    .text__info {
                        font-size: 12px;

                        .link__info {
                            font-weight: 400;
                            font-size: 16px;
                        }
                    }

                    .box__left {
                        .text {
                            color: $color-gray-600;
                            font-size: 14px;

                            &::before {
                                @include useSvg-icons("icon_clamp", 16px);
                                display: inline-block;
                                content: "";
                                margin: -1px 2px 1px 0;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }

            &-sale {
                .list-item {
                    height: 30px;
                    margin-top: 3px;

                    .box__left {
                        .text {
                            color: $color-gray-700;
                        }
                    }

                    .text__info {
                        font-size: 12px;

                        .link__info {
                            font-weight: 400;
                            font-size: 16px;

                            .text {
                                font-family: $gmarketSans;
                                font-size: 16px;
                            }
                        }

                        .num {
                            color: $color-gray-800;
                            font-family: $gmarketSans;
                            font-weight: 400;
                            font-size: 16px;
                        }
                    }
                }
            }

            &-orders,
            &-order {
                display: flex;
                gap: 16px;
                padding-top: 16px;

                .list-item {
                    display: block;
                    position: relative;
                    height: 104px;
                    margin-top: 0;

                    &:nth-child(2) {
                        display: block;
                        flex-grow: 3;

                        .box__item-order {
                            width: calc(33% - 12px);
                        }
                    }

                    &:nth-child(1) {
                        display: block;
                        flex-grow: 2;

                        .box__item-order {
                            width: calc(50% - 12px);
                        }
                    }

                    + .list-item {
                        padding-left: 16px;
                        border-left: 1px solid $color-gray-200;
                    }

                    .text {
                        display: block;
                        margin-bottom: 24px;
                    }
                }

                .box__item-order {
                    display: inline-block;
                    position: relative;
                    padding-left: 56px;

                    .text {
                        margin-bottom: 2px;
                    }

                    &::before {
                        @include useSvg-icons("count_bg", 44px);
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 10px;
                        left: 10px;
                    }

                    &.icon__apply {
                        &-new::after {
                            @include useSvg-icons("icon_apply_new", 24px);
                        }

                        &-imminent::after {
                            @include useSvg-icons("icon_apply_imminent", 24px);
                        }

                        &-imminent2::after {
                            @include useSvg-icons("icon_product_imminent", 24px);
                        }

                        &-delay::after {
                            @include useSvg-icons("icon_product_delay", 24px);
                        }

                        &-delay2::after {
                            @include useSvg-icons("icon_refund_delaying", 24px);
                        }

                        &-delay3::after {
                            @include useSvg-icons("icon_exchange_delaying", 24px);
                        }
                    }

                    + .box__item-order {
                        margin-left: 16px;
                    }
                }
            }

            &-order { //ph1
                gap: 24px;

                .list-item {
                    &:nth-child(2) {
                        flex-grow: 2;

                        .box__item-order {
                            width: calc(50% - 12px);
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(3) {
                        flex-grow: 1;

                        .box__item-order {
                            width: 100%;
                        }
                    }

                    + .list-item {
                        padding-left: 24px;
                    }
                }

                .box__item-order + .box__item-order {
                    margin-left: 24px;
                }
            }
        }

        &__service {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 24px 40px;
            padding: 8px 40px;

            .link {
                display: block;
                position: relative;
                color: $color-gray-800;
                font-family: $gmarketSans;
                font-size: 16px;
                text-decoration: none;

                &::before {
                    display: inline-block;
                    content: "";
                    width: 42px;
                    height: 42px;
                    margin: -1px 12px 1px 0;
                    border: 1px solid $color-gray-300;
                    border-radius: 18px;
                    background-color: $color-white;
                    vertical-align: middle;
                }

                &::after {
                    @include useSvg-icons("icon_menu-check", 24px);
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 10px;
                }

                &--icons {
                    &::after {
                        @include useSvg-icons("icon_truck-fast", 24px);
                    }
                }

                &--icons2 {
                    &::after {
                        @include useSvg-icons("icon_receipt-long", 24px);
                    }
                }

                &--icons3 {
                    &::after {
                        @include useSvg-icons("icon_tv-play", 24px);
                    }
                }

                &--icons4 {
                    &::after {
                        @include useSvg-icons("icon_person", 24px);
                    }
                }

                &--icons5 {
                    &::after {
                        @include useSvg-icons("icon_store", 24px);
                    }
                }
            }
        }
    }
}
