.box__product {
    padding: 23px 39px;

    &-setting {
        display: flex;
        position: relative;
        flex-grow: 0;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding-bottom: 24px;

        .text__case {
            margin: 0;
            color: $color-gray-800;
            font-family: $font-default;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;

            .text__case-number {
                color: $color-blue-500;
                font-weight: 700;
            }

            + .button {
                margin-left: 8px;
            }
        }

        .box__setting {
            display: inline-flex;
            position: relative;
            flex-shrink: 0;
            align-items: center;
            gap: 12px;

            &::after {
                display: inline-block;
                content: "";
                width: 1px;
                height: 24px;
                margin-right: 12px;
                background: $color-gray-300;
            }

            &:last-child::after {
                display: none;
            }

            &-wrap {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 12px 0;

                &:first-of-type {
                    .button {
                        font-family: $font-default;
                    }
                }
            }

            .button {
                flex-shrink: 0;
            }
        }

        .box__flag-wrap {
            gap: 8px;
        }

        + .box__product-setting {
            align-items: flex-start;
            padding: 24px 0 20px;
            border-top: 1px solid $color-gray-300;

            .text__case {
                font-size: 16px;
                line-height: 22px;
            }
        }

        + .box__announce {
            margin-bottom: 24px;
        }

        &--sub {
            @media only screen and (max-width: 1680px) {
                align-items: flex-start;
                
                .text__case {
                    width: 100%;
                }

                .bar--gray {
                    display: none;
                }

                .box__setting-wrap {
                    flex-wrap: nowrap;
                }
            }
        }
    }

    .box__setting--option {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 20px 0;
        border-top: 1px solid $color-gray-300;

        &:has(.bar--gray) {
            gap: 8px;

            .bar--gray {
                height: 24px;
            }
        }

        .box__search {
            .form__input {
                padding-right: 52px;
            }

            .button__delete {
                right: 16px;
            }
        }

        .box__dropdown {
            z-index: 5;
        }

        .form__select {
            width: 180px;
        }
    }
}
