.box__filter {
    padding: 3px 39px;

    &-item {
        display: flex;
        position: relative;
        padding: 20px 0;
        border-top: 1px solid $color-gray-200;

        &:first-of-type {
            border-top: none;
        }

        &:first-child:last-child {
            margin-bottom: -4px;
            padding-bottom: 23px;
            border: 0;
        }

        &.is-hide {
            display: none;

            + .button--more {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }

        &--small .box__filter-head {
            padding: 0;
        }

        &--full {
            flex-direction: column;

            .box__filter-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 20px 0;

                &:first-of-type {
                    padding: 0 0 20px;
                }
            }

            .box__filter-content {
                width: 100%;
            }
        }

        &--gray {
            border-top: none;

            + .box__filter-item--gray {
                border-top: 1px solid $color-gray-200;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: -40px;
                bottom: 0;
                left: -40px;
                background: $color-gray-50;
            }

            &:last-of-type::before {
                bottom: -24px;
                border-radius: 0 0 8px 8px;
            }

            .box__bundle--line {
                border-top: 1px solid $color-gray-300;
            }
        }

        &--half {
            .box__filter-content {
                width: auto;
                min-width: 184px;

                @media only screen and (max-width: 1060px) {
                    &:nth-child(2) {
                        flex: 0;
                    }

                    &:has(.form) {
                        flex: initial;
                    }
                }

                &:has(.form:not(.box__gap--center .form)) {
                    padding: 9px 0;
                }

                &:has(.form .form__input[type="text"]) {
                    padding: 0;
                }

                + .box__filter-head {
                    margin-left: 136px;

                    &::before {
                        display: inline-block;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -60px;
                        width: 1px;
                        height: 40px;
                        background: $color-gray-300;
                    }
                }

                .form__textarea {
                    min-width: 271px;
                }

                .box__gap-12 {
                    width: 100%;
                }
            }
        }

        &.box__filter-item--announce {
            margin-bottom: -4px;

            + .box__filter-item {
                border-top: 0;
            }

            .box__announce {
                width: 100%;
                max-width: 972px;
            }
        }
    }

    &-head {
        position: relative;
        width: 160px;
        min-width: 160px;
        padding: 9px 0;

        .text {
            color: $color-gray-700;
            font-size: 16px;
            line-height: 22px;

            &--gmarket,
            &--auction {
                + .text {
                    margin: 0;
                }
            }
        }

        .box__tooltip {
            margin-left: 4px;
        }

        .button__guide-faq {
            margin-left: 4px;
            vertical-align: middle;
        }

        + .box__filter-head {
            border-top: 1px solid $color-gray-200;
        }
    }

    &-content {
        position: relative;
        width: calc(100% - 160px);
        font-size: 0;

        .box__bundle--line {
            margin-top: 20px;
        }
    }

    &-group {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 16px;
        font-size: 0;

        .box__gap {
            width: 100%;

            &-16,
            &-20,
            &-24 {
                width: 100%;
            }

            > .box__flag-wrap {
                width: auto;

                + .box__flag-wrap {
                    margin-left: 52px;
                }
            }
        }

        &--vertical {
            flex-direction: column;
        }

        &--center {
            align-items: center;

            &:has(.form.is-error),
            &:has(.box__dropdown.is-error) {
                align-items: start;

                .is-error ~ .form {
                    padding-top: 10px;
                }
            }
        }

        .box__dropdown:not(.form__multi .box__dropdown) {
            width: 180px;

            &.is-open {
                z-index: 5; //여기서만 필요
            }
        }

        .box__side {
            display: inline-flex;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            .text__information + .button__text {
                margin-left: 8px;
            }
        }

        &--line {
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $color-gray-200;
        }

        .text__item {
            color: $color-gray-600;
            font-size: 14px;
            line-height: 22px;
        }

        .button__wrap:not(.box__calendar .box__layer-calendar .button__wrap) {
            display: inline-block;
            padding: 0 !important;
            border: 0 !important;

            .button {
                width: 120px;

                + .button {
                    margin-left: 12px;
                }
            }
        }

        + .box__information {
            margin-top: 20px;
        }

        > .box__flag-wrap:not(.box__flag-wrap--absolute) {
            width: 100%;
        }

        &.box__filter-extra {
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 0;

            .form__radio ~ .form__label {
                line-height: 40px;
            }

            .box__calendar .box__date .form__input-day {
                width: 82px;
            }

            + .box__gap-12 {
                margin: 24px 0 32px;
                padding-top: 24px;
                border-top: 1px solid $color-gray-200;

                .form__radio ~ .form__label {
                    font-size: 16px;
                }
            }
        }
    }

    &--gray {
        background: $color-gray-50 !important;

        .box__filter {
            &-head {
                width: 144px;
                min-width: 144px;
            }

            &-content {
                width: calc(100% - 104px);
                max-width: 840px;
            }

            &-group {
                gap: 12px;
            }
        }

        .button__wrap:not(.box__calendar .box__layer-calendar .button__wrap) {
            padding: 20px 0 17px;
        }

        .button.button--more {
            bottom: 20px;
        }
    }

    .button__wrap {
        gap: 16px;
        padding: 28px 0 25px;
        border-top: 1px solid $color-gray-200;

        .button {
            width: 140px;
        }

        &.is-hide {
            display: none;
        }
    }

    .box__tab--product {
        padding: 20px 0;
    }

    &.box {
        .box__filter {
            &-group {
                gap: 12px;
            }
        }
    }
}
