$screen-xsm: 320px;
$screen-sm: 828px;
$screen-md: 1024px;
$screen-lg: 1366px;
$screen-xlg: 1920px;

// font
$font-default: -apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,Roboto,Noto Sans CJK KR,Tahoma,"Noto Sans KR",sans-serif;
$gmarketSans: "Gmarket Sans", sans-serif;

// color
$color-blue-900: #072182;
$color-blue-800: #0231a6;
$color-blue-700: #0048c8;
$color-blue-600: #0062e5;
$color-blue-500: #067dfd;
$color-blue-400: #329cff;
$color-blue-300: #68bbff;
$color-blue-200: #a7d9ff;
$color-blue-100: #cbe8ff;
$color-blue-50: #eef7ff;

$color-red-900: #7d0800;
$color-red-800: #9f0a01;
$color-red-700: #bf0a03;
$color-red-600: #da120d;
$color-red-500: #ef2b2a;
$color-red-400: #ff5454;
$color-red-300: #ff8585;
$color-red-200: #ffbfbf;
$color-red-100: #ffdadb;
$color-red-50: #fff5f5;

$color-yellow: #ffd200;
$color-green-500: #00c400;

$color-black: #000;
$color-gray-900: #222;
$color-gray-800: #424242;
$color-gray-700: #616161;
$color-gray-600: #757575;
$color-gray-500: #9e9e9e;
$color-gray-400: #bdbdbd;
$color-gray-300: #e0e0e0;
$color-gray-200: #eee;
$color-gray-100: #f5f5f5;
$color-gray-50: #fafafa;
$color-white: #fff;

//cursor customer
$cursor-default: url("../img/single/cursor_arrow.svg"), auto;
$cursor-hover: url("../img/single/cursor_hover.svg"), auto;
$cursor-grabbing: url("../img/single/cursor_grabbing.svg"), auto;
