.box__information {
    position: relative;
    font-size: 0;

    &--gray,
    &--blue,
    &--red {
        padding: 16px;
        border-radius: 8px;
    }

    &--gray {
        margin-bottom: 16px; //하단과 영역잡음
        border: 1px solid $color-gray-300;
        background: $color-gray-50;
    }

    &--blue {
        border: 1px solid $color-blue-100;
        background: $color-blue-50;

        .title__information,
        .text__information {
            color: $color-blue-500;

            &::before {
                @include useSvg-icons("information_mark_blue", 16px);
            }
        }

        .list__information .list-item {
            color: $color-blue-500;

            &::before {
                background: $color-blue-300;
            }
        }
    }

    &--red {
        border: 1px solid $color-red-100;
        background: $color-red-50;

        .title__information,
        .text__information {
            color: $color-red-500;

            &::before {
                @include useSvg-icons("information_mark_red", 16px);
            }
        }

        .list__information .list-item {
            color: $color-red-500;

            &::before {
                background: $color-red-300;
            }
        }
    }

    &--fit { // box_information의 영역이 100%가 아닌 경우 사용
        width: fit-content;
    }

    .icon__refresh {
        @include useSvg-icons("icon_refresh", 20px);
        display: inline-block;
        margin: -1px 4px 1px 0;
        vertical-align: middle;
    }

    .link {
        color: $color-blue-500;
    }

    + .box__select-group,
    &:not(.box__information--gray) + .box__table-row {
        margin-top: 20px;
    }

    &:not(.box__information--gray) + .box__process-setting {
        margin-top: 16px;

        > .text__layer {
            > .text__primary {
                font-weight: 600;
            }
        }
    }

    ~ .box__information--gray {
        margin-bottom: 0;
    }

    .text__last {
        margin-top: 20px;
        padding-left: 0;
        color: $color-gray-600;
    }

    .list__information {
        + .button {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
        }
    }
}

.title__information {
    position: relative;
    margin-bottom: 8px;
    padding-left: 20px;
    color: $color-gray-800;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &::before {
        @include useSvg-icons("information_mark_gray", 16px);
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
    }

    &--exclm::before {
        @include useSvg-icons("icon_alert-blue-fill", 16px);
    }

    + .text__information::before {
        display: none;
    }
}

.text__information {
    position: relative;
    padding-left: 20px;
    color: $color-gray-600;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;

    &::before {
        @include useSvg-icons("information_mark_gray", 16px);
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
    }

    &:not(p) {
        display: inline-block;
    }

    &--blue { // box__information 없이 단독으로 사용할 때만 text__information의 --modifier 사용
        color: $color-blue-500;

        &::before {
            @include useSvg-icons("information_mark_blue", 16px);
        }
    }

    &--red {
        color: $color-red-500;

        &::before {
            @include useSvg-icons("information_mark_red", 16px);
        }
    }

    + *:not(.text__information,.box__table,img,.box__editor--area) {
        display: inline-block;
        margin-left: 8px;
        vertical-align: middle;
    }

    + img {
        margin-top: 14px;
    }
}

.list__information {
    .list-item {
        position: relative;
        margin-bottom: 4px;
        padding-left: 20px;
        color: $color-gray-600;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 6px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $color-gray-400;
        }

        .text {
            vertical-align: baseline;
        }
    }
}
