.playauto {
    .box {
        &__information {
            + .button__wrap {
                margin-top: 16px;

                .button {
                    width: 268px;
                }
            }
        }

        &__playauto {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            padding: 48px;

            .text__logo {
                @include useSvg-icons("logo_playauto", 130px);
                margin-bottom: 24px;
            }

            .title {
                margin-bottom: 12px;
                color: $color-gray-800;
            }

            .text {
                margin-bottom: 32px;
                font-family: "Noto Sans KR",sans-serif;
                font-size: 16px;
                line-height: 22px;
                word-break: keep-all;

                &__wrap {
                    margin-bottom: 16px;
                }
            }

            .link__view {
                display: flex;
                position: relative;
                flex-direction: column;
                width: 198px;
                height: 62px;
                padding: 10px 16px;
                border-radius: 6px;
                text-decoration: none;

                .image__logo {
                    margin-bottom: 4px;
                }

                .link__text {
                    color: $color-white;
                    font-family: $gmarketSans;
                    font-size: 14px;

                    &::after {
                        margin: 0 0 0 3px;
                    }
                }

                &--esmplus,
                &--smiledelivery {
                    .link__text--right {
                        &::after {
                            @include useSvg-icons("arrow_white-24", 16px);
                            transform: rotate(90deg);
                        }
                    }
                }

                &--esmplus {
                    background-color: $color-blue-500;

                    .image__logo {
                        @include useSvg-icons("icon_playauto-esmplus", 80px);
                        margin-top: 2px;
                        margin-bottom: 6px;
                    }
                }

                &--smiledelivery {
                    background-color: #282864;

                    .image__logo {
                        @include useSvg-icons("icon_playauto-smiledelivery", 80px);
                    }
                }

                &--playauto {
                    border: 1px solid $color-gray-400;

                    .image__logo {
                        @include useSvg-icons("logo_playauto", 83px);
                    }

                    .link__text {
                        color: $color-gray-900;
                    }
                }

            }
        }

        &__image {
            flex-shrink: 0;
            width: calc(50% - 20px);
            padding: 10px 0;

            .image {
                display: block;
                width: 438px;
                margin: 0 auto;
            }
        }
    }
}
