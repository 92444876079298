.box__summary {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 39px 0;

    &--expanded {
        display: block;
        padding: 0;

        .list__count {
            gap: 12px 0;

            .list-item {
                margin-bottom: 0;
            }
        }

        &:has(.button__detail) {
            margin-bottom: 35px !important;
        }
    }

    .text__update {
        position: absolute;
        top: 23px;
        right: 23px;
        color: $color-gray-500;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .list {
        &__count {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            align-items: flex-end;
            padding-right: 34px;

            &::after {
                display: inline-block;
                content: "";
                position: absolute;
                right: 34px;
                bottom: 19px;
                width: 1px;
                height: 48px;
                background: $color-gray-300;
            }

            &:last-child {
                .list-item:last-child {
                    margin-right: 0;
                }

                &::after {
                    display: none;
                }
            }

            &.is-expanded {
                padding-bottom: 43px;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 27px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $color-gray-200;
                }

                .list-item.is-active {
                    .box__subitem {
                        display: block;
                    }
                }

            }
        }

        &-item {
            margin-right: 32px;
            margin-bottom: 20px;

            &.is-active {
                .button__count {
                    &::before {
                        @include useSvg-icons("count_bg_active", 44px);
                    }
                }
            }

            &--strong {
                .button__count::after {
                    @include useSvg-icons("count_strong", 24px);
                }
            }

            &--exposure {
                .button__count::after {
                    @include useSvg-icons("count_exposure", 24px);
                }
            }

            &--unexposed {
                .button__count::after {
                    @include useSvg-icons("count_unexposed", 24px);
                }
            }

            &--best {
                .button__count::after {
                    @include useSvg-icons("count_best", 24px);
                }
            }

            &--manage {
                .button__count::after {
                    @include useSvg-icons("count_manage", 24px);
                }
            }

            &.icon__total {
                .button__count::after {
                    @include useSvg-icons("icon_total", 24px);
                }
            }

            &.icon__apply {
                &-rejected {
                    .button__count::after {
                        @include useSvg-icons("icon_apply_rejected", 24px);
                    }
                }

                &-progressing {
                    .button__count::after {
                        @include useSvg-icons("icon_apply_progressing", 24px);
                    }
                }

                &-done {
                    .button__count::after {
                        @include useSvg-icons("icon_apply_done", 24px);
                    }
                }

                &-new {
                    .button__count::after {
                        @include useSvg-icons("icon_apply_new", 24px);
                    }
                }

                &-imminent {
                    .button__count::after {
                        @include useSvg-icons("icon_apply_imminent", 24px);
                    }
                }
            }

            &.icon__showing {
                .button__count::after {
                    @include useSvg-icons("icon_showing", 24px);
                }

                &-end {
                    .button__count::after {
                        @include useSvg-icons("icon_showing_end", 24px);
                    }
                }

                &-stop {
                    .button__count::after {
                        @include useSvg-icons("icon_showing_stop", 24px);
                    }
                }
            }

            &.icon__cancel {
                .button__count::after {
                    @include useSvg-icons("icon_cancel", 24px);
                }

                &-request {
                    .button__count::after {
                        @include useSvg-icons("icon_cancel_request", 24px);
                    }
                }
            }

            &.icon__deposit {
                .button__count::after {
                    @include useSvg-icons("icon_deposit", 24px);
                }
            }

            &.icon__deadline {
                .button__count::after {
                    @include useSvg-icons("icon_deadline", 24px);
                }
            }

            &.icon__tomorrow {
                .button__count::after {
                    @include useSvg-icons("icon_tomorrow", 24px);
                }
            }

            &.icon__after {
                .button__count::after {
                    @include useSvg-icons("icon_after", 24px);
                }
            }

            &.icon__today {
                .button__count::after {
                    @include useSvg-icons("icon_today", 24px);
                }
            }

            &.icon__delivery {
                .button__count::after {
                    @include useSvg-icons("icon_delivery", 24px);
                }

                &-done {
                    .button__count::after {
                        @include useSvg-icons("icon_delivery_done", 24px);
                    }
                }

                &-penalty {
                    .button__count::after {
                        @include useSvg-icons("icon_delivery_penalty", 24px);
                    }
                }

                &-today {
                    .button__count::after {
                        @include useSvg-icons("icon_delivery_today", 24px);
                    }
                }
            }

            &.icon__limit-product {
                .button__count::after {
                    @include useSvg-icons("icon_limit_product", 24px);
                }
            }

            &.icon__gift {
                .button__count::after {
                    @include useSvg-icons("icon_gift", 24px);
                }
            }

            &.icon__customer-new {
                .button__count::after {
                    @include useSvg-icons("icon_customer_new", 24px);
                }
            }

            &.icon__product {
                .button__count::after {
                    @include useSvg-icons("icon_product", 24px);
                }


                &-combine {
                    .button__count::after {
                        @include useSvg-icons("icon_product_combine", 24px);
                    }
                }

                &-cancel {
                    .button__count::after {
                        @include useSvg-icons("icon_product_cancel", 24px);
                    }
                }

                &-delay {
                    .button__count::after {
                        @include useSvg-icons("icon_product_delay", 24px);
                    }
                }

                &-imminent {
                    .button__count::after {
                        @include useSvg-icons("icon_product_imminent", 24px);
                    }
                }
            }

            &.icon__overseas {
                .button__count::after {
                    @include useSvg-icons("icon_overseas", 24px);
                }
            }

            &.icon__delay {
                .button__count::after {
                    @include useSvg-icons("icon_delay", 24px);
                }
            }

            &.icon__missing {
                .button__count::after {
                    @include useSvg-icons("icon_missing", 24px);
                }
            }

            &.icon__calculate {
                .button__count::after {
                    @include useSvg-icons("icon_calculate", 24px);
                }

                &-done {
                    .button__count::after {
                        @include useSvg-icons("icon_calculate_done", 24px);
                    }
                }
            }

            &.icon__stock-less {
                .button__count::after {
                    @include useSvg-icons("icon_stock_less", 24px);
                }
            }

            &.icon__sale {
                .button__count::after {
                    @include useSvg-icons("icon_sale", 24px);
                }

                &-ends {
                    .button__count::after {
                        @include useSvg-icons("icon_sale_ends", 24px);
                    }
                }

                &-not {
                    .button__count::after {
                        @include useSvg-icons("icon_sale_not", 24px);
                    }
                }

                &-stop {
                    .button__count::after {
                        @include useSvg-icons("icon_sale_stop", 24px);
                    }
                }
            }

            &.icon__sold-out {
                .button__count::after {
                    @include useSvg-icons("icon_sold_out", 24px);
                }
            }

            &.icon__exposure-limit {
                .button__count::after {
                    @include useSvg-icons("icon_exposure_limit", 24px);
                }
            }

            &.icon__refund {
                &-request {
                    .button__count::after {
                        @include useSvg-icons("icon_refund_request", 24px);
                    }
                }

                &-delaying {
                    .button__count::after {
                        @include useSvg-icons("icon_refund_delaying", 24px);
                    }
                }
            }

            &.icon__complete {
                .button__count::after {
                    @include useSvg-icons("icon_complete", 24px);
                }
            }

            &.icon__holding {
                .button__count::after {
                    @include useSvg-icons("icon_holding", 24px);
                }
            }

            &.icon__exchange {
                .button__count::after {
                    @include useSvg-icons("icon_exchange", 24px);
                }

                &-delaying {
                    .button__count::after {
                        @include useSvg-icons("icon_exchange_delaying", 24px);
                    }
                }
            }

            &.icon__low-rate {
                .button__count::after {
                    @include useSvg-icons("icon_star-line", 24px);
                }
            }

            &.icon__high-rate {
                .button__count::after {
                    @include useSvg-icons("icon_star-fill", 24px);
                }
            }

            &.icon__photo-review {
                .button__count::after {
                    @include useSvg-icons("icon_camera", 24px);
                }
            }

            &.icon__repurchase-review {
                .button__count::after {
                    @include useSvg-icons("icon_heart", 24px);
                }
            }

            &.icon__emergency-message {
                .button__count::after {
                    @include useSvg-icons("icon__emergency-message", 24px);
                }
            }

            &.icon__board-contact {
                .button__count::after {
                    @include useSvg-icons("icon__board-contact", 24px);
                }
            }

            &.icon__imminent {
                .button__count::after {
                    @include useSvg-icons("icon__imminent", 24px);
                }
            }

            &.icon__bsd-lack {
                .button__count--bsd::before {
                    @include useSvg-icons("icon_bsd-lack", 44px);
                }
            }
        }
    }

    .box__item-container {
        display: flex;
        position: relative;
        font-size: 0;
    }

    .button__count {
        position: relative;
        padding-left: 56px;
        text-align: left;

        &::before {
            @include useSvg-icons("count_bg", 44px);
            content: "";
            position: absolute;
            bottom: 3px;
            left: 0;
        }

        &::after {
            display: inline-block;
            content: "";
            position: absolute;
            bottom: 13px;
            left: 10px;
        }

        &.is-active {
            .text,
            .text__number {
                color: $color-blue-500 !important;
            }

            .text__secondary {
                color: $color-red-500 !important;
            }
        }

        &--bsd {
            &::before {
                @include useSvg-icons("icon_bsd-price", 44px);
            }

            &::after {
                content: none;
            }
        }
    }

    .icon__arrow {
        display: inline-block;

        &::before {
            @include useSvg-icons("icon_increase_value", 16px);
            display: inline-block;
            content: " ";
            margin: -1px 0 1px;
            vertical-align: middle;
        }
    }

    .text {
        display: block;
        margin-bottom: 30px;

        + .text {
            position: absolute;
            bottom: 0;
            width: max-content;
            margin: 0;
            font-family: $gmarketSans;
            font-size: 12px;
            line-height: 16px;
        }

        &__number {
            display: inline-block;
            margin-right: 2px;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }
        
        &__bsd {
            color: #5501b4;
        }

        &__count {
            position: relative;
            top: 1px;
            margin-left: 4px;
            font-family: $font-default;
        }

        &--increase {
            color: $color-red-500;
        }

        &--decrease {
            color: $color-blue-500;

            .icon__arrow {
                &::before {
                    @include useSvg-icons("icon_decrease_value", 16px);
                }
            }
        }

        &--same {
            color: $color-gray-500;

            .icon__arrow {
                &::before {
                    width: 7px;
                    height: 2px;
                    margin: -1px 6px 1px 4px;
                    background: $color-gray-500;
                    vertical-align: middle;
                }
            }
        }
    }

    .box__tooltip {
        margin: 2px 0 0 5px;
    }

    .box__extra {
        display: flex;
        position: relative;
        padding: 24px 39px 19px;
        border-bottom: 1px solid $color-gray-300;
        white-space: nowrap;

        .title__extra {
            margin: 4px 40px 0 0;
            color: $color-gray-600;
            font-size: 14px;
            line-height: 20px;

            &::after {
                @include useSvg-icons("arrow-right_fill", 8px);
                display: inline-block;
                content: "";
            }
        }

        .list__extra {
            display: flex;
            flex-wrap: wrap;
            gap: 12px 40px;
        }

        .list-item__extra {
            font-size: 0;

            .box__flag {
                float: left;
                position: relative;
                top: 1px;
            }
        }

        .text__extra {
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 12px;
            padding-left: 8px;
            color: $color-gray-800;
            font-size: 16px;
            line-height: 24px;
            vertical-align: top;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        .number__extra {
            display: inline-block;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 24px;

            .button__count {
                height: 22px;
                padding: 0;
                font-family: $gmarketSans;
                font-weight: 700;
                font-size: 16px;

                .text__number {
                    font-weight: normal;
                    font-size: 14px;
                }

                .text,
                .text__secondary {
                    font-weight: 700;
                    font-size: 16px;
                }

                &::before {
                    display: none;
                }

                &.is-active {
                    .text__secondary {
                        color: $color-blue-500 !important;
                    }
                }
            }

            > .text {
                display: inline-block;
                position: relative;
                top: -1px;
                margin: 0;
                line-height: 24px;
            }

            + .number__extra {
                &::before {
                    display: inline-block;
                    content: "";
                    width: 1px;
                    height: 14px;
                    margin: 2px 2px -2px 6px;
                    background: #d9d9d9;
                }
            }
        }
    }

    .box__detail {
        flex-direction: column;
        padding: 24px 40px;

        &:has(.button__detail) {
            padding-bottom: 38px;
        }

        &.is-active {
            height: auto;

            .box__detail-inner {
                display: block;
            }

            .button__detail {
                &::after {
                    transform: rotate(180deg);
                }
            }

            .list__count {
                gap: 46px 0;
            }
        }

        &-inner {
            display: none;
            margin-top: 15px;

            .text__number {
                color: $color-gray-800;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
            }

            .text__unit {
                color: $color-gray-500;
                font-size: 12px;
            }
        }

        &-item {
            position: relative;
            padding: 0 0 8px 56px;

            + .box__detail-item {
                padding: 8px 0 0 56px;
                border-top: 1px solid $color-gray-200;
            }

            .box__flag {
                position: absolute;
                left: 11px;

                &--gmarket {
                    bottom: 9px;
                }

                &--auction {
                    top: 9px;
                }
            }
        }

        .button__detail-info {
            text-align: left;

            &.is-active {
                .text__number,
                .text__unit {
                    color: $color-blue-500;
                }
            }
        }

        .list__count {
            .list-item {
                width: 190px;
                margin-right: 0;
            }
        }
    }

    .box__subitem {
        display: none;

        .list__count {
            position: absolute;
            flex-wrap: nowrap;
            margin-top: 16px;
            padding-top: 16px;
            padding-right: 0;

            .list-item {
                width: auto;
                margin-right: 24px;
            }

            .button__count {
                padding-left: 0;

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .text {
            display: inline-block;
            margin-bottom: 0;

            &__number {
                font-size: 14px;
                line-height: 20px;
            }

            + .text {
                position: relative;
                bottom: -1px;
                margin-left: 4px;
                color: $color-gray-500;
                font-family: $font-default;
            }
        }
    }

    .button__detail {
        display: inline-flex;
        position: absolute;
        bottom: -20px;
        left: 50%;
        z-index: 1;
        align-items: center;
        height: 40px;
        padding: 8px 14px;
        border: 1px solid $color-gray-700;
        border-radius: 24px;
        background: $color-white;
        color: $color-gray-800;
        box-shadow: 0 2px 8px rgba($color-black, .06);
        font-size: 16px;
        transform: translateX(-50%);

        &::after {
            @include useSvg-icons("icon_arrow_down-20", 20px);
            display: inline-block;
            content: "";
            margin-left: 4px;
        }
    }

    &--head {
        white-space: nowrap;

        .title {
            margin-bottom: 4px;
        }

        > .text {
            margin-bottom: 0;
            font-family: $gmarketSans;
            font-size: 20px;
            line-height: 32px;
        }

        .box__detail {
            margin-bottom: 10px;

            &::after {
                content: "";
                position: absolute;
                top: 67px;
                bottom: 30px;
                left: 280px;
                width: 1px;
                background: $color-gray-300;
            }
        }
    }
}
