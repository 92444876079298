.box__tab {
    &--list {
        margin-bottom: 16px;
        padding: 0 24px;
        border: 1px solid $color-gray-300;
        border-radius: 8px;
        background: $color-white;
        font-size: 0;

        .button__tab {
            display: inline-block;
            position: relative;
            height: 54px;
            padding: 0 16px;
            color: $color-gray-600;
            font-family: $gmarketSans;
            font-size: 16px;

            &.is-active {
                color: $color-blue-500;

                &::after {
                    content: "";
                    position: absolute;
                    right: 16px;
                    bottom: 0;
                    left: 16px;
                    height: 1px;
                    background: $color-blue-500;
                }

                .text__num {
                    background: $color-blue-500;
                }
            }

            + .button__tab {
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 12px;
                    margin-top: -6px;
                    background: $color-gray-300;
                }
            }
        }

        .text__num {
            position: absolute;
            top: -11px;
            left: 50%;
            padding: 4px 8px;
            border-radius: 16px;
            background: $color-gray-600;
            color: $color-white;
            font-weight: 600;
            font-size: 10px;
            transform: translateX(-50%);
        }
    }

    &--sort {
        display: inline-block;
        position: relative;
        font-size: 0;

        &::before {
            @include absolute-full;
            content: "";
            border: 1px solid $color-gray-300;
            border-radius: 6px;
            background: $color-gray-100;
            font-size: 14px;
        }

        .button__tab {
            position: relative;
            width: 74px;
            height: 32px;
            color: $color-gray-500;

            &.is-active {
                border: 1px solid $color-blue-500;
                border-radius: 6px;
                background: $color-white;
                color: $color-blue-500;
            }
        }
    }

    &--multiple {
        display: inline-flex;
        overflow: hidden;
        flex-wrap: wrap;
        width: 100%;
        max-width: 712px;

        .button__tab {
            position: relative;
            width: calc(100% /4);
            height: 40px;
            border-bottom: 1px solid $color-gray-400;
            border-left: 1px solid $color-gray-400;
            background: $color-white;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;

            &:nth-child(4n),
            &:last-child {
                border-right: 1px solid $color-gray-400;
            }

            &:nth-child(-n+4) {
                border-top: 1px solid $color-gray-400;
            }

            &:first-child {
                border-top-left-radius: 6px;
            }

            &:nth-child(4) {
                border-top-right-radius: 6px;
            }

            &:nth-last-child(-n+4):nth-child(4n+1) {
                border-bottom-left-radius: 6px;
            }

            &:last-child,
            &:nth-last-child(-n+4):nth-child(4n) {
                border-bottom-right-radius: 6px;
            }

            &:nth-last-child(-n+4):nth-child(4n+1):last-child,
            &:nth-last-child(-n+4):nth-child(4n+2):last-child,
            &:nth-last-child(-n+4):nth-child(4n+3):last-child {
                width: calc((100% / 4) + 1px);
            }

            &[aria-checked=true],
            &.is-active {
                background: $color-blue-500;
                color: $color-white;

                &::before {
                    @include useSvg-icons("ico_checked_white-16", 16px);
                    display: inline-block;
                    content: "";
                    margin: -1px 2px 1px 0;
                    vertical-align: middle;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    z-index: 1;
                    box-sizing: border-box;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    border: 1px solid $color-blue-600;
                }

                &:first-child::after {
                    border-top-left-radius: 6px;
                }

                &:nth-child(4)::after {
                    border-top-right-radius: 6px;
                }

                &:nth-last-child(-n+4):nth-child(4n+1)::after {
                    border-bottom-left-radius: 6px;
                }

                &:last-child::after,
                &:nth-last-child(-n+4):nth-child(4n)::after {
                    border-bottom-right-radius: 6px;
                }
            }

            &:disabled {
                background: $color-gray-100;
                color: $color-gray-400;
            }
        }

        + .text--red {
            margin-top: 2px;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &--button {
        display: inline-block;
        overflow: hidden;
        position: relative;
        border-radius: 6px;
        font-size: 0;
        white-space: nowrap;
        max-inline-size: max-content;

        &.w-168 {
            .button__tab {
                width: 168px;
            }
        }

        .button__tab {
            display: inline-block;
            position: relative;
            width: 134px;
            height: 40px;
            border-top: 1px solid $color-gray-400;
            border-bottom: 1px solid $color-gray-400;
            border-left: 1px solid $color-gray-400;
            background: $color-white;
            color: $color-gray-900;
            font-size: 14px;
            text-align: center;
            vertical-align: top;

            &::before {
                @include useSvg-icons("ico_checked_white-16", 16px);
                display: none;
                content: "";
                margin: -1px 2px 1px 0;
                vertical-align: middle;
            }

            &:disabled {
                background: $color-gray-100;
                color: $color-gray-400;
            }

            &.is-active {
                border: none;
                background: $color-blue-500;
                color: $color-white;

                &::before {
                    display: inline-block;
                }

                &:last-child {
                    border-right: 0;
                }

                + .button__tab {
                    border-left: 0;
                }
            }

            &:first-child {
                border-radius: 6px 0 0 6px;
            }

            &:last-child {
                border-right: 1px solid $color-gray-400;
                border-radius: 0 6px 6px 0;
            }
        }

        &--four {
            width: 668px;

            .button__tab {
                border-bottom: 1px solid $color-gray-400;
            }
        }
    }

    &--radio {
        display: inline-block;
        position: relative;
        height: fit-content;
        border-radius: 6px;
        background: $color-white;
        font-size: 0;
        white-space: nowrap;

        &::before {
            @include absolute-full;
            content: "";
            z-index: 1;
            border: 1px solid $color-gray-400;
            border-radius: 6px;
        }

        &.w-113 {
            .button__tab {
                width: 113px;
            }
        }

        .button__tab {
            position: relative;
            z-index: 1;
            min-width: 64px;
            height: 40px;
            padding: 0 16px;
            color: $color-gray-800;
            font-size: 14px;
            text-align: center;

            &::before {
                @include absolute-full;
                content: "";
                border-left: 1px solid $color-gray-400;
            }

            &:first-child {
                &::before {
                    display: none;
                }

                &.is-active {
                    &::after {
                        border-radius: 6px 0 0 6px;
                    }
                }
            }

            &:last-child {
                &.is-active {
                    &::after {
                        border-radius: 0 6px 6px 0;
                    }
                }
            }

            &.is-active {
                color: $color-blue-500;

                + .button__tab {
                    &::before {
                        display: none;
                    }
                }

                &::after {
                    @include absolute-full;
                    display: inline-block;
                    content: "";
                    z-index: 1;
                    border: 1px solid $color-blue-500;
                }
            }

            &:disabled {
                z-index: 0;
                background: $color-gray-100;
                color: $color-gray-400;
            }
        }
    }

    &--product {
        font-size: 0;

        .button__tab {
            display: inline-block;
            position: relative;
            height: 40px;
            padding: 0 16px;
            color: $color-gray-600;
            font-family: $gmarketSans;
            font-size: 18px;

            &.is-active {
                color: $color-blue-500;
            }

            &:first-of-type {
                padding-left: 0;
            }

            + .button__tab {
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 12px;
                    margin-top: -6px;
                    background: $color-gray-300;
                }
            }
        }
    }

    .list__tab {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        @media only screen and (min-width: 1380px) {
            grid-template-columns: repeat(4, 1fr);
        }

        .list-item {
            position: relative;
            padding: 24px 24px 24px 96px;
            border: 1px solid $color-gray-300;
            border-radius: 8px;
            background: $color-white;

            &::before {
                content: "";
                position: absolute;
                top: 24px;
                left: 24px;
            }

            .button__tab {
                width: 100%;
                text-align: left;

                .text {
                    color: $color-gray-700;

                    &__tab {
                        display: block;
                        margin-bottom: 4px;
                        color: $color-gray-900;
                        font-family: $gmarketSans;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }

            &.is-active {
                border-color: $color-blue-500;
                background: $color-blue-50;

                .button__tab {
                    .text,
                    .text__tab {
                        color: $color-blue-500;
                    }
                }
            }
        }
    }
}
