.box__exposure {
    flex-shrink: 0;
    width: 274px;
    border: solid 1px $color-gray-900;
    border-radius: 8px;
    background: $color-gray-50;
    box-shadow: 0 6px 14px 0 rgba($color-black, .1);

    &-wrap {
        display: flex;
        margin-bottom: 16px;

        .box {
            width: 100%;
            margin: 0 20px 0 0;

            &__process-setting {
                margin-bottom: 0;
                padding: 14px 24px;
                border-bottom: 1px solid $color-gray-300;
            }

            &__filter-group {
                display: grid;
                overflow: hidden;
                overflow-y: auto;
                align-content: flex-start;
                grid-template-columns: repeat(3, 1fr);
                gap: 18px 20px;
                height: 600px;
                padding: 24px;

                > .form {
                    width: 33.3333%;
                    margin-right: 0;
                    white-space: nowrap;
                }
            }
        }
    }

    .box__button {
        gap: 4px;

        .button__arrow {
            width: 24px;
            height: 24px;

            &--top::before {
                @include useSvg-icons("icon_arrow_down-20", 20px);
            }

            &--bottom::before {
                @include useSvg-icons("icon_arrow_down-20", 20px);
            }

            &--first::before {
                @include useSvg-icons("icon_arrow_double", 20px);
            }

            &--last::before {
                @include useSvg-icons("icon_arrow_double", 20px);
            }
        }
    }

    .list__exposure {
        @include custom-scrollbar-track;
        display: flex;
        overflow: hidden;
        overflow-y: auto;
        flex-direction: column;
        gap: 8px;
        height: 554px;
        padding: 16px 24px;

        .list-item {
            position: relative;
            flex-shrink: 0;
            height: 40px;

            &::before {
                @include absolute-full;
                display: block;
                content: "";
                z-index: 0;
                border-radius: 6px;
                background: $color-gray-200;
            }

            .box__exposure-item {
                display: flex;
                position: relative;
                z-index: 1;
                flex-shrink: 0;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 40px;
                border: solid 1px $color-gray-400;
                border-radius: 6px;
                background: $color-white;

                &:hover {
                    border: 1px solid $color-gray-900;

                }

                &.is-active {
                    border: 2px solid $color-blue-500;
                }
            }
        }

        .list-item__fixed {
            position: relative;
            flex-shrink: 0;
            height: 22px;
            font-size: 0;

            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 9px;
                left: 0;
                width: 100%;
                height: 2px;
                border-radius: 2px;
                background: $color-blue-300;
            }

            &:hover {
                .bar::before {
                    background: $color-blue-600;
                }

                .text {
                    background: $color-blue-600;
                }
            }
        }

        .text__fixed {
            position: absolute;
            width: 100%;

            .bar {
                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    border-radius: 2px;
                    background: $color-blue-500;
                }
            }

            .text {
                display: inline-flex;
                position: relative;
                left: 50%;
                align-items: center;
                height: 22px;
                padding: 0 8px;
                border-radius: 11px;
                background: $color-blue-500;
                color: $color-white;
                font-size: 11px;
                transform: translateX(-50%);

                &::before {
                    @include useSvg-icons("icon_drag", 16px);
                    display: inline-block;
                    content: "";
                }
            }
        }

        .button__exposure {
            width: 100%;
            height: 100%;
            padding-left: 8px;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;
            text-align: left;

            &::before {
                @include useSvg-icons("icon_move", 20px);
                display: inline-block;
                content: "";
                vertical-align: middle;
            }
        }

        .button__delete {
            flex-shrink: 0;
            width: 30px;
            height: 100%;

            &::before {
                @include useSvg-icons("icon_delete_16", 16px);
                display: inline-block;
                content: "";
            }
        }
    }

    .box__reset {
        border-top: 1px solid $color-gray-400;

        .button__reset {
            width: 100%;
            height: 40px;
            border-radius: 0 0 8px 8px;
            background: $color-gray-50;
            color: $color-gray-900;
            font-family: $gmarketSans;
            font-size: 12px;
            text-align: center;

            &::before {
                @include useSvg-icons("refresh", 16px);
                display: inline-block;
                content: "";
                vertical-align: middle;
            }
        }
    }
}
