
* + .box__tooltip {
    margin-left: 4px;
}

.box__tooltip {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    &:hover {
        .box__tooltip-information {
            display: block;
        }
    }

    &.is-error {
        .button__tooltip::before {
            @include useSvg-icons("information_mark_login", 16px);
        }

        + .text {
            margin-left: 4px;
        }
    }

    &--rt {
        .box__tooltip-information {
            bottom: 24px;
            left: -8px;
        }
    }

    &--lt {
        .box__tooltip-information {
            right: -8px;
            bottom: 24px;
        }
    }

    &--rb {
        .box__tooltip-information {
            top: 24px;
            left: -8px;
        }
    }

    &--lb {
        .box__tooltip-information {
            top: 24px;
            right: -8px;
        }
    }

    .button__tooltip {
        display: block;
        width: 16px;
        height: 16px;

        &::before {
            @include useSvg-icons("information_mark", 16px);
            display: inline-block;
            content: "";
        }
    }

    &-information {
        display: none;
        position: absolute;
        z-index: 5;
        // min-width: 328px;
        width: max-content;
        max-width: 328px;
        padding: 16px;
        border: 1px solid $color-gray-900;
        border-radius: 8px;
        background: $color-white;
        text-align: left;
        white-space: wrap;

        .text__tooltip {
            color: $color-gray-900;
            font-family: "Noto Sans KR",sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;

            &--title {
                margin-bottom: 8px;
                font-weight: 700;
            }

            &--list {
                position: relative;
                margin-bottom: 8px;
                padding-left: 12px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: $color-gray-400;
                }
            }

            + img {
                margin-top: 12px;
            }
        }
    }

    &--list {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 8px 16px;
    }

    .list__guide {
        gap: initial;
        margin-top: 16px;

        .list-item {
            .text__tooltip:not(.text__num) {
                flex: 1;
            }
        }
    }

}

.layer__tooltip {
    display: none;
    position: absolute;
    left: 50%;
    border: 1px solid $color-gray-900;
    border-radius: 6px;
    background: $color-white;
    box-shadow: 0 2px 8px 0 rgba($color-black, .12);
    transform: translateX(-50%);

    .link__advice {
        display: block;
        height: 40px;
        padding: 0 16px;
        color: $color-gray-800;
        font-family: $font-default;
        font-size: 14px;
        line-height: 40px;
        text-decoration: none;
        white-space: nowrap;

        + .link__advice {
            border-top: 1px solid $color-gray-200;
        }

        &:hover {
            color: $color-gray-900;
        }

        .text--gmarket,
        .text--auction {
            position: relative;
            top: -2px;
        }
    }
}

.is-error {
    .box__tooltip {
        position: absolute;
        top: 50%;
        left: 8px;
        z-index: 1;
        transform: translateY(-50%);

        .button__tooltip::before {
            @include useSvg-icons("information_mark_login", 16px);
        }

        &:hover {
            z-index: 2;
        }
    }
}
