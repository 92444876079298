.promotion {
    .box__summary {
        .box__detail {
            .list__count {
                gap: 40px;

                .list-item {
                    width: auto;
                    min-width: 150px;
                }
            }
        }
    }

    .box__tooltip-bubble {
        .text__tooltip {
            white-space: nowrap;
        }
    }

    .box__floating-bottom {
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 0 -24px;
        border-top: 0;
        background: #430890;

        .button {
            width: auto;

            &--more {
                position: static;
                color: #6408c9;

                &::after {
                    @include useSvg-icons("icon_chevron_right",16px);
                    margin: -1px 0 1px 4px;
                    filter: brightness(0) saturate(100%) invert(29%) sepia(43%) saturate(3676%) hue-rotate(254deg) brightness(70%) contrast(146%);
                    transform: rotate(0deg);
                }
            }

            &--download {
                border-color: #823ad5;
                background-color: #823ad5;
                color: $color-white;

                &::before {
                    display: none;
                }

                &::after {
                    @include useSvg-icons("download_white", 16px);
                    content: "";
                    margin: -1px 0 1px 4px;
                }
            }
        }
    }
}
