.box__snack {
    display: none;
    position: fixed;
    bottom: 40px;
    left: 50%;
    z-index: 10;
    align-items: center;
    min-height: 64px;
    padding: 16px 24px;
    border-radius: 8px;
    background: $color-gray-900;
    box-shadow: 0 2px 8px 0 rgba($color-black, .12);
    white-space: nowrap;
    transform: translate(-50%, 0);

    &.is-active {
        display: flex;
    }

    .text__snack {
        color: $color-white;
        font-family: $gmarketSans;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        &.icon {
            &::before {
                display: inline-block;
                content: "";
                margin-right: 8px;
                vertical-align: middle;
            }

            &--red::before {
                @include useSvg-icons("exclm_mark_red-24", 24px);
            }
        }

        + .text__snack {
            margin: 0 24px 0 20px;
        }

        .text--gray {
            color: $color-white;
        }
    }

    .box__button {
        display: flex;
        position: relative;
        gap: 16px;
        padding-left: 24px;
        border-left: 1px solid rgba($color-white, .2);

        .button {
            &__prev {
                &.is-disabled {
                    opacity: .3;
                }

                &::before {
                    @include useSvg-icons("arrow_white-24", 24px);
                    display: block;
                    content: "";
                }
            }

            &__next {
                &.is-disabled {
                    opacity: .3;
                }

                &::before {
                    @include useSvg-icons("arrow_white-24", 24px);
                    display: block;
                    content: "";
                    transform: rotate(180deg);
                }
            }

            &__close {
                &::before {
                    @include useSvg-icons("close_image", 24px);
                    display: block;
                    content: "";
                }
            }
        }
    }
}
