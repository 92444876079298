.box__category {
    overflow: hidden;
    position: relative;
    max-height: 358px;

    &-wrapper {
        position: relative;
        width: 100%;

        .button__text {
            position: absolute;
            top: -32px;
            right: 4px;
            color: $color-gray-700;
            font-size: 13px;
            text-decoration: none;

            &::after {
                @include useSvg-icons("delivery_delete", 16px);
                display: inline-block;
                content: "";
                margin: -1px 0 1px 4px;
                vertical-align: middle;
                filter: brightness(0) saturate(100%) invert(40%) sepia(0%) saturate(1%) hue-rotate(345deg) brightness(94%) contrast(92%);
            }
        }
    }

    &-wrap {
        display: flex;
        overflow: hidden;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        background: $color-white;
        font-size: 0;

        .box__category {
            flex: 1;
        }

        &.is-error {
            border-color: $color-red-500;

            + .text--red {
                margin: -10px 0 0 16px;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    &-inner {
        overflow-y: auto;
        position: relative;
        height: 100%;
    }

    &-add {
        margin-top: 12px;
        text-align: center;

        .button {
            width: 198px;
        }
    }

    &:not(:first-of-type) {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            background: $color-gray-300;
        }
    }

    .text__category {
        display: block;
        padding: 10px 16px;
        color: $color-gray-500;
        font-size: 14px;
        line-height: 20px;
    }

    .list__category {
        .list-item {
            position: relative;
            height: 40px;

            &.is-selected {
                background: $color-white;

                .button__category {
                    color: $color-blue-500;
                }
            }

            &.is-active,
            &:hover {
                background: $color-gray-50;

                .button__category {
                    color: $color-blue-500;
                }
            }
        }
    }

    .button__category {
        @include ellipsis;
        display: block;
        width: calc(100% - 40px);
        padding: 10px 0 10px 16px;
        color: $color-gray-800;
        text-align: left;

        &--more {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            padding: 12px;

            &::before {
                @include useSvg-icons("arrow-right", 16px);
                display: block;
                content: "";
            }
        }
    }
}

.box__dropdown,
.box__search {
    .box__category {
        &-wrap {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            z-index: 5;
            max-height: 320px;
            border-color: $color-gray-900;
            border-top: 0;
            border-radius: 0 0 6px 6px;
        }
    }
}
