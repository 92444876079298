.seller {
    &.antimoneylaundering {
        .box__table-row {
            > .table {
                > .table-body >.table-row {
                    > th.table-data,
                    > td.table-data {
                        padding: 14px 16px;
                    }

                    th.table-data {
                        vertical-align: top;

                        .text {
                            color: $color-gray-700;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }

            + .box__table-row {
                margin-top: 25px;
            }
        }
    }

    .button__wrap {
        margin-top: 24px;

        .button--xlarge {
            width: 200px;
        }
    }

    .box__filter-group {
        .box__flag-wrap {
            width: auto;
        }
    }

    .box__detail {
        .list-item {
            + .list-item {
                margin-top: 12px;
            }
        }
    }
}
