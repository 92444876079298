.box__service {
    margin-bottom: 32px;
    padding: 24px 0 32px;
    border-bottom: 1px solid $color-gray-300;

    .title__service {
        margin-bottom: 12px;
        color: $color-gray-800;
        font-family: $gmarketSans;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        .gmarket {
            color: $color-green-500;
        }

        .auction {
            color: $color-red-500;
        }

        .link__text {
            margin-left: 8px;
            font-family: $font-default;
            font-weight: normal;
        }
    }

    .text__service {
        color: $color-gray-800;
        font-size: 14px;
        line-height: 20px;

        .link__text {
            margin-left: 12px;
        }
    }

    .box__filter-group {
        gap: 60px;
        margin-top: 12px;

        .form__label {
            line-height: 40px;

            .point-text {
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
            }

            + .box__tooltip {
                margin-left: -4px;
            }
        }

        + .text {
            margin-top: 10px;
            padding-top: 20px;
            border-top: 1px solid $color-gray-200;
        }
    }

    .box__price--total {
        position: relative;
        top: -1px;
        font-size: 0;
        white-space: nowrap;

        .text {
            &__price {
                position: relative;
                top: 1px;
                margin-left: 8px;
                color: $color-blue-500;
                font-family: $gmarketSans;
                font-weight: normal;
                font-size: 18px;

                .num {
                    font-weight: 600;
                    font-size: 20px;
                }
            }
        }
    }

    &-info {
        border: solid 1px $color-gray-300;
        border-radius: 8px;

        + .title__service {
            margin-top: 20px !important;
        }

        &.is-default .box__service-inner,
        &.is-default .box__service-total {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }

    &-inner {
        display: flex;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: $color-gray-50;
    }

    &-item {
        width: 50%;
        padding: 24px 32px;

        + .box__service-item {
            border-left: 1px solid $color-gray-300;
        }
    }

    &-name {
        margin-bottom: 6px;

        .form {
            vertical-align: middle;
        }

        .form__label {
            margin-left: 12px;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
        }

        .box__tooltip {
            vertical-align: middle;
        }

        + .text {
            margin-left: 32px;
        }
    }

    &-content {
        padding: 24px 32px;
        border-top: solid 1px $color-gray-300;
        font-size: 0;
    }

    &-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 76px;
        padding: 0 32px;
        border-top: 1px solid $color-gray-300;
        background: $color-blue-50;

        > .text {
            color: $color-gray-600;

        }

        .text__primary {
            margin-right: 8px;
            font-family: $gmarketSans;
        }

        &--plus {
            height: 92px;

            > .text {
                max-width: 250px;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                white-space: nowrap;
            }

            .text__primary {
                display: block;
                margin-bottom: 4px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .icon-equals {
            @include useSvg-icons("extra_equals", 22px);
        }

        .icon-minus {
            @include useSvg-icons("extra_minus", 22px);
        }

        .icon-plus {
            @include useSvg-icons("extra_plus", 22px);
        }
    }

    &-setting {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $color-gray-200;

        .box__filter {
            &-item {
                padding: 0;
                border: 0 none;

                + .box__filter-item {
                    margin-top: 16px;
                }

                + .text__service {
                    margin: 16px 0 12px;
                }
            }

            &-head {
                display: flex;
                align-items: center;
                width: 116px;
                min-width: 116px;

                .box__tooltip {
                    margin-left: 4px;
                }
            }

            &-group {
                margin-top: 0;

                .form__label {
                    line-height: 20px;
                }
            }
        }
    }

    &-use {
        position: relative;
        padding: 16px 32px 16px 168px;
        border-top: 1px solid $color-gray-300;
        border-radius: 0 0 8px 8px;
        background: #fffce5;
        font-size: 0;

        .text {
            color: $color-gray-700;
            line-height: 24px;

            &__tag {
                position: absolute;
                top: 16px;
                left: 32px;
                width: 120px;
                border: 1px solid $color-gray-600;
                border-radius: 20px;
                color: $color-gray-600;
                font-size: 12px;
                line-height: 22px;
                text-align: center;
            }

            &__service {
                margin-right: 12px;
                color: $color-gray-900;
                font-weight: bold;
            }

            + .text {
                margin-top: 7px;
            }
        }
    }

    .box__calendar .box__layer-calendar {
        @at-root .box__layer & {
            left: -174px;
        }
    }
}
