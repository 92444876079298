.box__legal {
    display: flex;
    flex-direction: column;
    gap: 16px;

    
    &-notice {
        >.text {
            margin-bottom: 16px;
        }

        .text {
            color: $color-gray-600 !important;
        }
        
        &:last-child {
            margin-bottom: 0;
        }

        &-desc {
            border-radius: 8px;
            background: $color-gray-50;

            .list__guide {
                gap: 8px;
                padding: 16px;
            }

            .list__guide-detail {
                margin-top: 8px;
            }
        }

        &-table {
            .table {
                width: 100%;
                border-spacing: 2px;
                border-collapse: separate;

                thead {
                    th {
                        padding: 8px;
                        background: $color-gray-50;
                    }
                }

                tbody {
                    td {
                        padding: 8px;
                        background: $color-gray-50;
                    }
                }
            }
        }
    }

    .text {
        color: $color-gray-500;
        font-size: 12px;
        line-height: 16px;
    }

    .list__legal-notice {
        .list-item {
            margin-top: 8px;
        }
    }
}

.list__guide {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text__num {
        margin-right: 2px;
    }

    .list-item {
        display: flex;
        flex-wrap: wrap;

        .text:not(.text__num) {
            flex: 1;
        }
    }

    &-detail {
        width: 100%;
        margin-top: 4px;
        padding-left: 13px;
    }
}
