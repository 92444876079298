.box__search {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    // gap: 16px;
    width: 100%;
    font-size: 0;

    &--enter {
        &.is-open {
            .form__input {
                border: 1px solid $color-gray-900;
                border-bottom-color: $color-gray-300;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            .box__search-keyword {
                display: block;
            }

            .box__category-wrap {
                display: flex;
            }
        }

        .box__category-wrap {
            overflow-y: auto;
            max-height: 200px;

            .box__category {
                overflow: initial;
                width: 33.33%;

                &-inner {
                    height: auto;
                }

                &:not(:first-of-type) {
                    &::after {
                        display: none;
                    }

                    .box__category-inner::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 1px;
                        background: $color-gray-300;
                    }
                }
            }

            &::-webkit-scrollbar-track {
                border-bottom-right-radius: 6px;
            }
        }
    }

    .button__delete {
        right: 52px;
    }

    .button__search {
        position: absolute;
        top: 50%;
        right: 1px;
        padding: 9px 16px;
        border-radius: 6px;
        transform: translateY(-50%);

        &::before {
            @include useSvg-icons("icon_search", 20px);
            display: block;
            content: "";
        }
    }

    &-keyword {
        display: none;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        top: 40px;
        right: 0;
        left: 0;
        z-index: 5;
        max-height: 241px;
        border: 1px solid $color-gray-900;
        border-top: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        background-color: $color-white;

        &::-webkit-scrollbar-track {
            border-bottom-right-radius: 6px;
        }

        .button__option {
            width: 100%;
            height: 40px;
            min-height: 40px;
            padding: 0 16px;
            color: $color-gray-800;
            font-size: 14px;
            text-align: left;

            &:hover {
                background: $color-gray-50;
                color: $color-blue-500;
            }
        }

        > .text {
            padding: 10px 16px;
            font-weight: 600;

            &__primary {
                background: $color-gray-50;
                font-weight: normal;
            }
        }

        + .text {
            margin-top: 16px;
        }
    }

    &-help {
        display: flex;
        align-items: center;
        height: 40px;
        min-height: 40px;
        padding: 0 16px;

        .text__help {
            position: relative;
            padding-left: 12px;
            color: $color-gray-600;
            font-size: 14px;
            line-height: 20px;
            text-align: left;

            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $color-gray-400;
            }
        }
    }

    + .box__tab--button {
        margin-top: 20px;
    }
}
