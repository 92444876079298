.form {
    &.is-complete {
        .form__inner {
            .form__input {
                padding-right: 50px;
            }

            &::after {
                @include useSvg-icons("complete", 24px);
                content: "";
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
            }
        }
    }

    &.is-error {
        .form__input {
            border: 1px solid $color-red-500;

            &:focus {
                border: 1px solid $color-red-500;
            }
        }
    }

    &__inner {
        overflow: hidden;
        position: relative;
        font-size: 0;

        .button__delete {
            @include useSvg-icons("icon_delete", 20px);
            display: none;
            position: absolute;
            top: 50%;
            right: 16px;
            z-index: 1;
            background-color: $color-white;
            transform: translateY(-50%);
        }
    }

    &__input {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 10px 30px 10px 16px;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        background: $color-white;
        color: $color-gray-800;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        caret-color: $color-blue-500;

        &::placeholder {
            color: $color-gray-400;
        }

        &:focus {
            &::placeholder,
            ~ .form__label {
                color: transparent;
            }
        }

        &:not(:disabled):hover {
            background: $color-white !important;
        }

        &:focus,
        &:-webkit-autofill {
            border-color: $color-gray-900;
            background: $color-white !important;
            outline: none;
        }

        &:disabled {
            background: $color-gray-100;
            color: $color-gray-600;

            ~ .form__label {
                color: $color-gray-400;
            }

            ~ .text__unit {
                color: $color-gray-400;
            }
        }

        &:not(:placeholder-shown) {
            ~ .form__label {
                display: none;
            }
        }

        &[type="password"] {
            font-size: 24px !important;

            &::placeholder {
                font-size: initial;
            }
        }

        ~ .form__label {
            position: absolute;
            top: 50%;
            left: 16px;
            color: $color-gray-400;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
            pointer-events: none;
            transform: translateY(-50%);
        }

        &-small {
            height: 32px;
            padding: 8px 40px 8px 16px;
            font-size: 12px;

            ~ .form__label {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &-xlarge {
            height: 48px;
            padding: 14px 40px 14px 16px;
        }

        &-xxlarge {
            height: 56px;
            padding: 18px 40px 18px 16px;
            border-radius: 8px;
            font-size: 16px;

            ~ .form__label {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &__group {
        display: inline-flex;
        flex-direction: column;
        width: 100%;

        .form {
            ~ .form {
                margin-top: -1px !important;

                .form__input {
                    border-radius: 0 0 6px 6px;

                }
            }

            &.is-error {
                &:first-of-type {
                    .form__input {
                        z-index: 1;

                        ~ .form__label {
                            z-index: 1;
                        }
                    }
                }
            }

            &:first-of-type {
                .form__input {
                    border-radius: 6px 6px 0 0;
                }

            }

            &__input {
                &:focus {
                    z-index: 1;

                    ~ .form__label {
                        z-index: 1;
                    }
                }
            }
        }

        .text--red {
            margin: 2px 16px 0;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .button {
        position: absolute;
        top: 50%;
        right: 8px;
        min-width: 83px;
        height: 40px;
        border-radius: 6px;
        font-size: 14px;
        transition: background .3s ease-in-out;
        transform: translateY(-50%);

        &--blue {
            background: $color-blue-500;

            &:hover {
                background: $color-blue-600;
            }
        }

        &--gray {
            background: $color-gray-500;
            font-family: $gmarketSans;
        }
    }

    .text {
        display: block;
        margin: 2px 0 0 16px;
        color: $color-gray-600;
        font-size: 12px;
        line-height: 16px;

        &__unit {
            position: absolute;
            top: 50%;
            right: 16px;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;
            transform: translateY(-50%);
        }

        & + & {
            margin-top: 0;
        }
    }

    &--lines {
        .form__input {
            height: 56px;
            padding: 24px 40px 10px 15px;
            border-radius: 8px;
            font-size: 16px;
            line-height: 22px;

            &:focus,
            &:not(:placeholder-shown),
            &:-webkit-autofill {
                ~ .form__label {
                    display: inline-block;
                    top: 18px;
                    left: 18px;
                    color: $color-gray-400;
                    font-size: 11px;
                    line-height: 14px;
                    transition: all .25s ease;
                }
            }

            ~ .form__label {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &--cta {
        .form__inner {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        &.is-complete {
            .form__inner::after {
                right: 106px;
            }

            .form__input {
                padding-right: 130px !important;
            }
        }

        .form__input {
            width: 296px;
            height: 56px;
            padding-right: 130px;
            border-radius: 8px;
            font-size: 16px;
        }

        .button__delete {
            right: 106px;
        }
    }

    &--system {
        flex: 1;

        .form__input { // 테이블 안에서 사용되는 input 요소
            @include ellipsis;
            padding: 0 8px;
            border: 1px solid transparent;
            border-radius: 0;
            text-align: center;

            &:hover {
                max-width: 100%;
                padding: 0 32px 0 8px;
                border-color: $color-gray-500;
                text-align: left;

                + .form__label--pencil {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    left: 8px;
                    padding-right: 20px;
                    text-align: left;
                    transform: translateY(-50%);

                    &::after {
                        @include useSvg-icons("icon_pencil", 16px);
                        display: block;
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                }
            }

            &:focus,
            &:-webkit-autofill {
                max-width: 100%;
                padding: 0 32px 0 8px;
                border-color: $color-blue-500;
                color: $color-blue-500;
                outline: none;
                text-align: left;

                + .form__label--pencil {
                    &::after {
                        display: none;
                    }
                }
            }

            &:focus {
                ~ .button__delete {
                    display: block;
                }
            }

            &:valid {
                ~ .form__label--pencil {
                    font-size: 0;
                }
            }
        }

        .form__label--pencil {
            display: block !important;
            left: 8px;
        }

        .button__delete {
            @include useSvg-icons("icon_delete_small", 16px);
            display: none;
            right: 8px;
        }

        .box__color-chip {
            position: absolute;
            top: 50%;
            left: 8px;
            z-index: 1;
            transform: translateY(-50%);

            + .text__value,
            + .form__input {
                padding-left: 40px !important;
            }
        }

        .text__value {
            display: inline-block;
            padding: 0 8px;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 40px;
        }

        .box__tooltip {
            + .form__inner {
                .form__input {
                    padding-left: 28px !important;
                }

                &:has(.box__color-chip) {
                    .box__color-chip {
                        margin-left: 22px;
                    }

                    .form__input {
                        padding-left: 60px !important;
                    }
                }
            }
        }

        &.is-error {
            .box__tooltip-information {
                max-width: max-content;
            }
        }
    }

    &--side {
        .form__input {
            padding-right: 60px;

            &:disabled {
                ~ .text__side {
                    color: $color-gray-400;
                }
            }
        }
    }

    &--file {
        width: 100%;

        .form__input:focus {
            ~ .form__label {
                left: 1px;
                color: $color-gray-900;
            }
        }

        .form__label {
            display: block !important;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            padding: 9px 0;
            border-radius: 6px;
            background: $color-white;
            color: $color-gray-900;
            text-align: center;
            transform: translateY(0);

            &::before {
                @include useSvg-icons("icon_plus_black", 16px);
                display: inline-block;
                content: "";
                margin: -1px 4px 1px 0;
                vertical-align: middle;
            }
        }
    }

    &--icon {
        .form__input {
            padding-left: 44px;

            &:focus {
                + .icon {
                    @include useSvg-icons("icon_pass-on", 20px);
                }
            }
        }

        .icon {
            @include useSvg-icons("icon_pass", 20px);
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
        }

        .form__label {
            left: 44px;
        }
    }

    &--color {
        &.is-active {
            .box__layer--color-picker {
                display: block;
                z-index: 2;
            }

            .form__input {
                border-color: $color-gray-900;
            }
        }

        .form__input:valid {
            ~ .form__label--pencil {
                font-size: 0;
            }
        }

        .form__label--pencil {
            display: block !important;
            position: absolute !important;
            left: 44px;
        }

        .button__color-chip {
            position: absolute;
            top: 50%;
            left: 16px;
            z-index: 1;
            width: 20px;
            height: 20px;
            border: 1px solid rgba(0, 0, 0, .1);
            border-radius: 2px;
            transform: translateY(-50%);

            + .form__input {
                padding-left: 44px !important;
            }
        }
    }
}

.box__form--double {
    .box__group {
        + .text {
            margin-top: 2px;
            font-size: 12px;
            line-height: 16px;
        }
    }

    + .text__information {
        margin-top: 0;
    }
}
