.sellerdeal {
    &__introduction {
        padding: 28px 0 0;

        .title {
            margin: 0 24px 16px;
        }

        .button {
            width: 220px;
        }

        .box__superdeal {
            padding: 100px 0;

            &-inner {
                width: 900px;
                height: 100%;
                margin: 0 auto;
            }

            &--banner {
                position: relative;
                height: 400px;
                padding: 0;

                .box__superdeal-inner {
                    width: 100%;
                    margin: 0;
                    padding-top: 66px;
                    background: url("../img/single/sellerdeal_banner.png") 50% 0 no-repeat;
                    background-size: cover;
                }

                .box__superdeal-title {
                    width: 860px;
                    height: 160px;
                    margin: 0 auto;
                    background: url("../img/single/sellerdeal_title.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }

                .button {
                    position: absolute;
                    bottom: 94px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &--information {
                height: 1098px;
                background: $color-white;

                .box__superdeal-inner {
                    background: url("../img/single/sellerdeal_info.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--application {
                height: 1207px;
                background: $color-gray-50;

                .box__superdeal-inner {
                    position: relative;
                    background: url("../img/single/sellerdeal_application_recommend.png") 50% 0 no-repeat;
                    background-size: contain;
                
                    .box__lottie {
                        position: absolute;
                        bottom: 0;
                        width: 900px;
                        height: 450px;
                        background: url("../img/single/sellerdeal_graph_bg.png") 50% 0 no-repeat;
                        background-size: auto 100%;
                    }
                }
            }

            &--exposure {
                height: 2348px;
                background: $color-white;

                .box__superdeal-inner {
                    background: url("../img/single/sellerdeal_exposure.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--change {
                height: 531px;
                padding: 0;
                background: $color-white;

                .box__superdeal-inner {
                    background: url("../img/single/sellerdeal_change.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--success {
                height: 672px;
                background: $color-gray-50;

                .box__superdeal-inner {
                    background: url("../img/single/sellerdeal_success_story.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--recommend {
                height: 704px;
                background: $color-white;

                .box__superdeal-inner {
                    background: url("../img/single/sellerdeal_recommend.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--prepare {
                height: 596px;
                background: $color-gray-100;

                .box__superdeal-inner {
                    background: url("../img/single/sellerdeal_prepare.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--allkill {
                height: 767px;
                background: $color-gray-50;

                .box__superdeal-inner {
                    background: url("../img/single/allkill_info.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--alkill-exposure {
                height: 1172px;
                background: $color-white;

                .box__superdeal-inner {
                    background: url("../img/single/allkill_exposure.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }

            &--standard {
                height: 275px;
                padding: 70px 0 69px;
                background: $color-gray-100;

                .box__superdeal-inner {
                    background: url("../img/single/product_standard.png") 50% 0 no-repeat;
                    background-size: auto 100%;
                }
            }
        }

        .box__floating-bottom {
            align-items: center;
            justify-content: center;
            gap: 64px;
            border-top: 0;
            background-image: linear-gradient(130deg, #01c428 19%, #01c48e 84%, #067dfd 114%);
            background-size: auto 100%;
        }
    }

    >.box__information--red {
        margin-bottom: 16px;

        .list__information {
            max-width: 1180px;
        }
    }

    .text__information {
        .link__text {
            display: inline-block;
        }
    }

    .box__panel {
        .box__information--red {
            margin-top: 12px;
        }
    }

    .box__information + .button__wrap {
        margin-top: 20px;
    }

    .box__sellerdeal-done {
        padding: 100px 0 159px;
        text-align: center;

        &-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &::before {
                @include useSvg-icons("ico_checked_blue-48", 48px);
                display: block;
                content: "";
                margin-bottom: 8px;
            }

            .text__title {
                margin-bottom: 27px;
                color: $color-gray-900;
                font-family: $gmarketSans;
                font-size: 22px;
                line-height: 32px;
            }
        }

        .box__image {
            width: 683px;
            height: 188px;
            margin: 0 auto;
        }

        .text__wrap {
            margin-top: 41px;

            .text + .text {
                margin: 2px 0 0;
            }
        }

        .button__wrap {
            margin-top: 24px;
        }
    }

    .box__board-wrap--74 {
        max-width: 1200px;
    }
}
