.box {
    &__navigation {
        overflow-y: auto;
        position: fixed;
        top: 60px;
        bottom: 0;
        left: 0;
        width: 220px;
        padding: 12px 20px 28px;
        border-right: 1px solid $color-gray-300;
        background: $color-gray-50;
    }

    &__nav {
        &-search {
            margin-bottom: 12px;

            .box__dropdown {
                .button__opener {
                    width: 158px;
                    height: 44px;
                    margin-left: -12px;
                    padding: 12px 42px 8px 16px;
                    padding-right: 0;
                    border-color: $color-gray-50;
                    border-bottom: 0;
                    background: transparent;
                    color: $color-gray-800;

                    &::after {
                        display: inline-block;
                        position: static;
                        margin: -2px 0 2px 2px;
                        vertical-align: middle;
                        transform: translateY(0);
                    }
                }

                .button__option {
                    height: 28px;
                    min-height: 28px;
                    border-bottom: 0;

                    &:hover {
                        background: transparent;
                    }
                }

                .list {
                    left: -12px;
                    padding-bottom: 12px;
                }

                &.is-open {
                    .button__opener {
                        border-color: $color-gray-900;
                        border-bottom: 0;
                        background: $color-white;

                        &::after {
                            transform: translateY(0) rotate(180deg);
                        }
                    }
                }
            }
        }

        &-menu {
            > ul {
                margin: 0 -20px;
            }

            .list {
                &__menu-depth2 {
                    display: none;
                    margin: 0 20px;
                    padding: 4px 0 22px;
                    border-bottom: 1px solid $color-gray-300;

                    .link__menu {
                        display: block;
                        padding: 8px 0;
                        color: $color-gray-800;
                        font-size: 14px;
                        line-height: 22px;
                        text-decoration: none;
                        white-space: nowrap;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-blue-500;
                        }

                        &.is-preparing {
                            color: $color-gray-500;
                            cursor: $cursor-default;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $color-gray-500;
                            }

                            &::after {
                                display: inline-block;
                                content: "준비중";
                                align-items: center;
                                justify-content: center;
                                width: 36px;
                                height: 16px;
                                margin: -1px 0 1px 4px;
                                border-radius: 100px;
                                background: $color-gray-200;
                                color: $color-gray-600;
                                font-family: $gmarketSans;
                                font-size: 9px;
                                line-height: 16px;
                                text-align: center;
                                vertical-align: middle;
                            }
                        }

                        &--sub {
                            &::after {
                                @include useSvg-icons("icon_chevron_right",16px);
                                display: inline-block;
                                content: "";
                                margin: -1px 0 1px 4px;
                                vertical-align: middle;
                                transform: rotate(90deg);
                            }

                            &:hover,
                            &:active,
                            &:focus {
                                &::after {
                                    filter: brightness(0) saturate(100%) invert(33%) sepia(31%) saturate(5070%) hue-rotate(199deg) brightness(102%) contrast(99%);
                                }
                            }
                        }

                        .icon__new {
                            margin: -1px 0 1px 9px;
                            vertical-align: middle;
                        }
                    }

                    .link__icon {
                        &::after {
                            @include useSvg-icons("icon_link_gray", 16px);
                            width: 16px;
                            height: 16px;
                            margin: -1px 0 2px 2px;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            &::after {
                                filter: brightness(0) saturate(100%) invert(33%) sepia(31%) saturate(5070%) hue-rotate(199deg) brightness(102%) contrast(99%);
                            }
                        }
                    }
                }

                &__menu-depth3 {
                    display: none;
                    margin: 6px -20px;
                    padding: 12px 0 12px 32px;
                    background: $color-gray-100;

                    .link__menu {
                        display: block;
                        padding: 6px 0;
                        color: $color-gray-600;
                        font-size: 13px;
                        line-height: 20px;
                        text-decoration: none;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $color-blue-500;
                        }
                    }

                    .link__icon {
                        &::after {
                            @include useSvg-icons("icon_link_gray", 14px);
                            width: 14px;
                            height: 14px;
                        }
                    }
                }

                &-item--active {
                    > .list__menu-depth2,
                    > .list__menu-depth3, {
                        display: block;
                    }

                    > .link__menu {
                        color: $color-blue-500;

                        &--sub::after {
                            filter: brightness(0) saturate(100%) invert(37%) sepia(54%) saturate(4886%) hue-rotate(200deg) brightness(102%) contrast(101%);
                            transform: rotate(270deg);
                        }
                    }

                    .button__depth:not(.is-preparing, .button__depth--link) {
                        &::after {
                            transform: rotate(180deg);
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            .button__depth {
                position: relative;
                width: 100%;
                height: 54px;
                padding: 0 20px;
                color: $color-gray-900;
                font-family: $gmarketSans;
                font-size: 15px;
                text-align: left;

                &.is-preparing {
                    &::after {
                        display: flex;
                        content: "준비중";
                        align-items: center;
                        justify-content: center;
                        width: 36px;
                        height: 16px;
                        border-radius: 100px;
                        background: $color-gray-200;
                        color: $color-gray-600;
                        font-size: 9px;
                    }

                    + .list__menu-depth2 {
                        .link__menu {
                            opacity: .5;
                            cursor: $cursor-default;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $color-gray-800;
                            }
                        }

                        .link__icon {
                            &:hover,
                            &:active,
                            &:focus {
                                &::after {
                                    filter: brightness(0) saturate(100%) invert(20%) sepia(95%) saturate(2%) hue-rotate(314deg) brightness(86%) contrast(83%);
                                }
                            }
                        }
                    }
                }

                &::after {
                    @include useSvg-icons("icon_arrow_down-20", 20px);
                    content: "";
                    position: absolute;
                    top: 18px;
                    right: 20px;
                    width: 20px;
                    height: 20px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    right: 20px;
                    bottom: 0;
                    left: 20px;
                    height: 1px;
                    background: $color-gray-300;
                }

                &:not(:disabled):hover {
                    background: $color-gray-200;
                }

                &--link {
                    display: inline-flex;
                    align-items: center;
                    text-decoration: none;

                    &::after {
                        @include useSvg-icons("icon_link_gray", 16px);
                        width: 16px;
                        height: 16px;
                    }
                }

                .icon__new {
                    @include useSvg-icons("icon_nav_new", 20px);
                    margin: -1px 0 1px 2px;
                    border-radius: 0;
                    background-color: transparent;
                    vertical-align: middle;
                }

                .icon__beta {
                    @include useSvg-icons("icon_nav_beta", 28px);
                    display: inline-block;
                    margin: -1px 0 1px 4px;
                    vertical-align: middle;
                }
            }
        }

        &-support {
            padding: 40px 0;

            + .box__nav-support {
                margin-top: -16px;
                padding-top: 0;
            }

            > .text {
                display: block;
                margin-bottom: 12px;
                font-size: 12px;
                line-height: 16px;
            }
        }

        &-link {
            display: grid;
            gap: 8px;

            .link__icon {
                text-decoration: none;

                &::after {
                    filter: brightness(0) saturate(100%) invert(20%) sepia(95%) saturate(2%) hue-rotate(314deg) brightness(86%) contrast(83%);
                }

                &:hover,
                &:active,
                &:focus {
                    &::after {
                        filter: brightness(0) saturate(100%) invert(33%) sepia(31%) saturate(5070%) hue-rotate(199deg) brightness(102%) contrast(99%);
                    }

                    .text {
                        color: $color-blue-500;
                    }
                }
            }
        }

        &-help {
            .text {
                display: block;
                margin-bottom: 8px;
                padding-bottom: 8px;
                border-bottom: 1px solid $color-gray-900;
                color: $color-gray-900;
                font-family: $gmarketSans;
            }

            .button {
                width: 100%;
                margin-top: 8px;
                padding: 0;
                white-space: nowrap;
            }
        }
    }
}
