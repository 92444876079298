.box__graph {
    &-summary {
        position: relative;
        padding: 32px 40px 30px;

        .title__graph {
            display: inline-block;
            color: $color-gray-800;
            font-size: 16px;
            line-height: 22px;
            vertical-align: middle;
        }

        .box {
            &__tooltip {
                margin-left: 4px;
            }

            &__side {
                position: absolute;
                top: 32px;
                right: 40px;
            }
        }
    }

    &-content {
        position: relative;
        padding: 0 40px;

        .box__chart {
            width: 100%;
            height: auto;
            padding: 48px 0 0;
        }
    }

    .list__legend {
        margin: 31px 0 48px;
    }
}
