.box__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;

    .button__arrow {
        width: 32px;
        height: 32px;

        &::before {
            @include useSvg-icons("pagination_arrow", 32px);
            display: inline-block;
            content: "";
        }

        &:disabled {
            cursor: $cursor-default;

            &::before {
                @include useSvg-icons("pagination_arrow_disabled", 32px);
            }
        }

        &--next {
            &::before {
                @include useSvg-icons("pagination_arrow", 32px);
                transform: rotate(180deg);
            }

            &:disabled {
                cursor: $cursor-default;

                &::before {
                    @include useSvg-icons("pagination_arrow_disabled", 32px);
                }
            }
        }
    }

    .list__pagination {
        display: flex;
        align-items: center;
        gap: 6px;
        width: auto;

        .list-item__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            .button__pagination {
                width: 32px;
                height: 32px;
                color: $color-gray-700;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                cursor: $cursor-hover;

                &:disabled {
                    @include useSvg-icons("icon_abbreviation", 32px);
                }
            }

            &:hover {
                .button__pagination {
                    color: $color-gray-800;
                }
            }

            &.is-active {
                .button__pagination {
                    color: $color-blue-500;
                }
            }
        }
    }
}
