.box__swiper {
    overflow: hidden;
    position: relative;
    width: 100%;

    ~ .swiper {
        // &-slide {
        //     width: 100% !important;
        //     @media only screen and (min-width: 1920px) {
        //         width: calc(50% - 8px) !important;
        //     }
        // }

        &-pagination {
            position: absolute;
            top: -42px;
            right: 101px;
            bottom: auto;
            left: auto;
            z-index: 4;
            width: auto;
            color: $color-gray-500;
            font-size: 14px;
            line-height: 20px;

            .swiper-pagination-current {
                color: $color-gray-800;
            }
        }

        &-button-next,
        &-button-prev {
            top: -26px;
            right: 24px;
            left: auto;
            z-index: 4;
            width: 32px;
            height: 32px;
            border: 1px solid $color-gray-400;
            background: $color-white;

            &::after {
                @include useSvg-icons("icon_arrow_active", 20px);
                font-size: 0;
            }

            &.swiper-button-disabled {
                opacity: 1;
                cursor: pointer;
            }
        }

        &-button-prev {
            right: 56px;
            border-right: 0;
            border-radius: 6px 0 0 6px;
        }

        &-button-next {
            border-left: 1px solid $color-gray-200;
            border-radius: 0 6px 6px 0;

            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &--inner {
        img {
            width: auto;
            height: 100%;
        }
    }

    &--marketing {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            width: 1px;
            background: $color-white;
        }

        .swiper {
            &-slide {
                padding: 0 24px;
                border-right: 1px solid $color-gray-200;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        .title {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 56px;
            margin-right: -24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            word-break: keep-all;

            &::before {
                display: inline-block;
                content: "";
                min-width: 56px;
                vertical-align: top;
            }

            &--superdeal::before {
                @include useSvg-icons("icon_gmarket-superdeal", 56px);
            }

            &--allkill::before {
                @include useSvg-icons("icon_action-allkill", 56px);
            }

            &--ai-sales::before {
                @include useSvg-icons("icon_ai-sales", 56px);
            }

            &--big-promotion::before {
                @include useSvg-icons("icon_big-promotion", 56px);
            }

            &--extra-service::before {
                @include useSvg-icons("icon_extra-service-purple", 56px);
            }

            &--price-comparison::before {
                @include useSvg-icons("icon_price-comparison", 56px);
            }

            &--power-click::before {
                @include useSvg-icons("icon_power-click-purple", 56px);
            }
        }

        .text {
            margin: 16px 0;
            color: $color-gray-700;
        }
    }

    &--banner {
        overflow: hidden;

        .swiper {
            &-slide {
                padding: 24px 56px;
            }

            &-button-next,
            &-button-prev {
                position: absolute;
                top: 50%;
                width: 32px;
                height: 32px;
                margin-top: -16px;

                &::after {
                    @include useSvg-icons("icon_arrow", 32px);
                    content: "";
                }
            }

            &-button-next {
                right: 16px;

                &::after {
                    transform: rotate(180deg);
                }
            }

            &-button-prev {
                left: 16px;
            }

            &-pagination-bullet {
                margin: 0 4px;
            }
        }

        .link {
            @include ellipsis;
            display: block;
            color: $color-gray-800;
            font-family: $gmarketSans;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            text-decoration: none;
        }
    }

    &--promotion {
        ~ .swiper {
            &-button-next {
                top: 22px;
                right: 0;
            }

            &-button-prev {
                top: 22px;
                right: 32px;
            }

            &-pagination {
                top: 6px;
                right: 77px;
            }
        }
    }

    &--category {
        overflow: visible !important;
        min-height: 290px;
        margin: 0 40px 25px;
        border-bottom: 1px solid $color-gray-200;

        &-wrap {
            overflow: hidden;
        }
        
        &-preview {
            .box__gap-24 {
                &.is-preview {
                    .text:not(.text__ranking) {
                        color: $color-gray-500 !important;
                    }

                    &:hover {
                        background: initial;
                    }
                }
            }
        }

        .box__gap {
            flex-wrap: nowrap;

            &-24 {
                justify-content: space-between;
                height: 48px;
                padding: 0 16px;

                &:hover {
                    background: $color-blue-50;
                }

                &.is-disabled {
                    .text:not(.text__ranking) {
                        color: $color-gray-300 !important;
                    }
                }

                &.is-active {
                    background: $color-blue-50;

                    .box__gap {
                        .text:not(.text__ranking) {
                            color: $color-blue-500;
                        }
                    }
                }
            }

            .text {
                color: $color-gray-700;
                font-family: $gmarketSans;
                font-size: 15px;
                line-height: 24px;

                &__ranking {
                    width: 24px;
                    color: $color-gray-800;
                    text-align: center;

                    +.text {
                        padding-left: 26px;
                    }
                }
            }

            .button__star--toggle-24 {
                margin: -1px 0 1px;
            }
        }

        > .box__gap-24 {
            border-bottom: 1px solid $color-gray-200;
            border-radius: 0;

            &:hover {
                background: inherit;
            }
        }

        .swiper {
            &-pagination {
                bottom: -25px !important;
            }

            &-button-next,
            &-button-prev {
                position: absolute;
                top: 50%;
                width: 32px;
                height: 32px;
                margin-top: -16px;

                &::after {
                    @include useSvg-icons("icon_arrow", 32px);
                    content: "";
                }
            }

            &-button-next {
                right: -30px;

                &::after {
                    transform: rotate(180deg);
                }
            }

            &-button-prev {
                left: -30px;
            }
        }
    }
}
