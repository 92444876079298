.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border: 1px solid $color-gray-400;
    border-radius: 8px;
    background: $color-white;
    color: $color-gray-900;
    font-family: $gmarketSans;
    vertical-align: top;
    transition: background .3s ease-in-out;

    &:hover {
        border-color: $color-gray-500;
        background: $color-gray-50;
    }

    &:disabled {
        opacity: .4;
        cursor: default;
        pointer-events: none;
    }

    &.button--download::before {
        @include useSvg-icons("download_black", 16px);
        content: "";
        margin-right: 4px;
    }

    &.button--upload::before {
        @include useSvg-icons("upload_white", 16px);
        content: "";
        margin-right: 4px;
        filter: brightness(0) saturate(100%) invert(24%) sepia(5%) saturate(0%) hue-rotate(145deg) brightness(93%) contrast(87%);
    }

    &.button--excel {
        padding: 0 15px 0 8px !important;
        font-family: $font-default;
        white-space: nowrap;

        &::before {
            @include useSvg-icons("icon_excel", 24px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--delete {
        &::before {
            @include useSvg-icons("delivery_delete", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--plus {
        &::before {
            @include useSvg-icons("icon_plus_white", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--plus-black {
        &::before {
            @include useSvg-icons("icon_plus_black", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--refresh {
        display: flex;
        gap: 4px;

        &::before {
            @include useSvg-icons("popup_refresh", 16px);
            content: "";
        }
    }

    &.button--open {
        padding: 0 3px;

        &::before {
            @include useSvg-icons("icon_accordion", 24px);
            content: "";
        }
    }

    &.button--search {
        &::before {
            @include useSvg-icons("icon_search-gray", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--cs-center {
        &::before {
            @include useSvg-icons("icon_cs-center", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--apply {
        &::before {
            @include useSvg-icons("icon_arrow_apply", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--apply-price {
        &::before {
            @include useSvg-icons("icon_right_arrow", 16px);
            content: "";
            margin-right: 4px;
        }
    }

    &.button--settle {
        width: 100%;
        margin-top: 24px;
        border-color: $color-gray-300;
        background: $color-gray-50;

        &::before {
            @include useSvg-icons("icon_w", 24px);
            content: "";
            margin-right: 8px;
        }

        &::after {
            @include useSvg-icons("icon_chevron_right", 16px);
            content: "";
            margin-left: 4px;
        }
    }

    &.button--old {
        width: 100%;
        border-color: $color-gray-300;
        background: $color-gray-50;

        &::after {
            @include useSvg-icons("icon_chevron_right-20", 20px);
            content: "";
            margin-left: 4px;
        }
    }

    &.button--more {
        position: absolute;
        right: 40px;
        bottom: 28px;
        z-index: 1;

        &::after {
            @include useSvg-icons("icon_arrow_down-24", 24px);
            content: "";
            margin-left: 18px;
            transform: rotate(180deg);
        }

        &-notice {
            display: block;
            width: 268px;
            margin: 24px auto 0;

            &::after {
                @include useSvg-icons("popup_arrow", 16px);
                display: inline-block;
                content: "";
                margin: -1px 0 1px 4px;
                vertical-align: middle;
                transform: rotate(180deg);
            }

            &.is-active {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &.button--graph {
        width: 108px;
        border-color: $color-blue-500;
        color: $color-blue-500;
        font-size: 14px;

        &::before {
            @include useSvg-icons("table_calculator", 20px);
            display: block;
            content: "";
            margin-right: 4px;
        }
    }

    &--noto {
        font-family: Noto Sans CJK KR,"Noto Sans KR",sans-serif;
    }

    &--blue {
        border: none;
        background: $color-blue-500;
        color: $color-white !important;

        &:hover {
            background: $color-blue-600;
            transition: background .3s ease-in-out;
        }

        &.button--download::before {
            @include useSvg-icons("download_white", 16px);
        }

        &.button--upload::before {
            @include useSvg-icons("upload_white", 16px);
            filter: brightness(0) saturate(100%) invert(99%) sepia(8%) saturate(153%) hue-rotate(327deg) brightness(121%) contrast(100%);
        }
    }

    &--gray {
        border: none;
        background: $color-gray-600;
        color: $color-white !important;

        &:hover {
            background: $color-gray-600;
            transition: background .3s ease-in-out;
        }
    }

    &--xxlarge {
        height: 56px;
        font-size: 18px;

        &.button--download::before {
            @include useSvg-icons("download_large_black", 20px);
        }

        &.button--blue {
            &.button--download::before {
                @include useSvg-icons("download_large_white", 20px);
            }
        }
    }

    &--xlarge {
        height: 48px;
        font-size: 16px;
    }

    &--large {
        height: 44px;
        font-size: 16px;
    }

    &--medium {
        height: 40px;
        border-radius: 6px;
        color: $color-gray-800;
        font-size: 14px;
    }

    &--small {
        height: 32px;
        padding: 0 8px;
        border-radius: 6px;
        color: $color-gray-800;
        font-size: 12px;
    }

    &--tiny {
        height: 28px;
        padding: 0 8px;
        border-radius: 6px;
        color: $color-gray-800;
        font-size: 12px;
    }

    &__text {
        color: $color-gray-800;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;

        &--small {
            font-size: 12px;
            line-height: 16px;
        }

        &--down {
            text-align: left;
            text-decoration: none;

            &::after {
                @include useSvg-icons("icon_chevron_right",16px);
                display: inline-block;
                content: "";
                margin: -1px 0 1px 2px;
                vertical-align: middle;
                transform: rotate(90deg);
            }

            &.is-active {
                &::after {
                    transform: rotate(-90deg);
                }
            }
        }

        &--gray {
            color: $color-gray-500;

            &::after {
                filter: brightness(0) saturate(100%) invert(74%) sepia(6%) saturate(11%) hue-rotate(358deg) brightness(84%) contrast(98%);
            }
        }

        &--poll {
            font-size: 15px;
            line-height: 34px;
            text-decoration: none;

            &::before {
                @include useSvg-icons("icon_poll",34px);
                display: inline-block;
                content: "";
                margin: -1px 4px 1px 0;
                vertical-align: middle;
            }
        }

        &--category {
            position: absolute;
            top: 28px;
            right: 40px;
            font-family: $gmarketSans;
            font-size: 15px;
            line-height: 28px;
            text-decoration: none;

            &::before {
                @include useSvg-icons("icon_favorites",28px);
                display: inline-block;
                content: "";
                margin: -1px 8px 1px 0;
                vertical-align: middle;
            }
        }

        &.ellipsis {
            @include ellipsis;
            width: 100%;
        }

        &.text__primary::after {
            filter: brightness(0) saturate(100%) invert(37%) sepia(54%) saturate(4886%) hue-rotate(200deg) brightness(102%) contrast(101%);
        }
    }

    &__back {
        position: absolute;
        top: 12px;
        left: 15px;
        z-index: 1;
        font-size: 0;

        &::before {
            @include useSvg-icons("back_layer", 32px);
            display: inline-block;
            content: "";
        }
    }

    .text__num {
        font-size: 16px;

        &::before {
            display: inline-block;
            opacity: .5;
            content: "";
            width: 1px;
            height: 12px;
            margin: -1px 8px 1px 0;
            background: $color-white;
            vertical-align: middle;
        }
    }

    .text--gmarket,
    .text--auction {
        margin-right: 4px;
    }
}

.button__wrap {
    display: flex;
    position: relative;
    justify-content: center;
    gap: 12px;

    .button--xxlarge {
        width: 190px;
    }

    &--200 {
        .button--xxlarge,
        .button--xlarge {
            width: 200px;
        }
    }

    &--480 {
        .button--xxlarge {
            width: 214px;
        }
    }

    &--600 {
        .button--xxlarge {
            width: 274px;
        }
    }
}

.button__guide-faq {
    margin-left: 4px;
    vertical-align: middle;

    &::before {
        @include useSvg-icons("question_mark", 16px);
        display: block;
        content: "";
    }
}

.button__upload {
    display: inline-flex;
    align-items: center;
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    cursor: $cursor-hover;

    &::before {
        @include useSvg-icons("icon_plus_white", 16px);
        content: "";
        margin-right: 4px;
    }

    &:hover {
        background: $color-blue-600;
        transition: background .3s ease-in-out;
    }
}

.button__star {
    &--toggle {
        height: 20px;

        &::before {
            @include useSvg-icons("flag_star", 20px);
            display: inline-block;
            content: "";
        }

        &.is-active {
            &::before {
                @include useSvg-icons("flag_star_active", 20px);
            }
        }
    }

    &--toggle-24 {
        height: 24px;

        &::before {
            @include useSvg-icons("flag_star", 24px);
            display: inline-block;
            content: "";
        }

        &.is-active {
            &::before {
                @include useSvg-icons("flag_star_active", 24px);
            }
        }

        &.is-disabled,
        &:disabled {
            &::before {
                @include useSvg-icons("flag_star_active", 24px);
                filter: brightness(0) saturate(100%) invert(72%) sepia(49%) saturate(1%) hue-rotate(46deg) brightness(95%) contrast(97%);
            }
        }
    }
}

// 위 아래 이동 버튼
.box__button {
    display: flex;
    gap: 12px;

    .button__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: solid 1px $color-gray-400;
        border-radius: 4px;
        background: $color-white;

        &::before {
            display: block;
            content: "";
        }

        &--top::before {
            @include useSvg-icons("icon_arrow_down-20", 24px);
            transform: rotate(180deg);
        }

        &--bottom::before {
            @include useSvg-icons("icon_arrow_down-20", 24px);
        }

        &--first::before {
            @include useSvg-icons("icon_arrow_double", 24px);
            transform: rotate(180deg);
        }

        &--last::before {
            @include useSvg-icons("icon_arrow_double", 24px);
        }

        &:hover {
            border-color: $color-gray-500;
            background: $color-gray-50;
        }
    }
}

.link__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid $color-gray-300;
    border-radius: 8px;
    background-color: $color-gray-50;
    color: $color-gray-800;
    font-family: "Noto Sans KR",sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;

    &::after {
        @include useSvg-icons("icon_chevron_right-20", 20px);
        content: "";
        margin-left: 4px;
    }

    ~ .link__button {
        margin-top: 8px;
    }
}

.button__number {
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 6px;
    font-size: 12px;

    &.is-active {
        border: none;
        background: $color-blue-500;
        color: $color-white;
    }

    &.is-none {
        border-style: dashed;
        color: $color-gray-500;
    }
}
