.box__toast {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 10;
    min-height: 64px;
    padding: 21px 24px;
    border-radius: 8px;
    background: $color-gray-900;
    box-shadow: 0 2px 8px 0 rgba($color-black, .12);
    white-space: nowrap;
    transform: translate(-50%, 0);

    &.is-show {
        visibility: visible;
        opacity: 1;
        bottom: 40px;
    }

    &-notice {
        opacity: 0;
        position: fixed;
        top: 50px;
        right: 10px;
        z-index: 10;
        width: 400px;
        min-height: 102px;
        padding: 16px 56px 13px 20px;
        border-radius: 16px;
        background: $color-gray-900;
        box-shadow: 0 16px 24px 0 rgba($color-black, .2);
        transition: opacity .3s;

        &--concurrent {
            top: auto;
        }

        &:not(.box__toast-notice--concurrent) {
            .text {
                @include ellipsis;
            }
        }

        &.is-show {
            opacity: 1;
        }

        &-inner {
            + .link__text {
                display: inline-block;
                margin-top: 4px;
            }
        }

        .title {
            color: $color-white;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;

            + .text {
                margin-top: 8px;

                + .text:not(.text__sub) {
                    margin-top: 8px;
                }

                &__wrap {
                    margin-top: 8px;
                }
            }
        }

        .text {
            color: $color-white;

            &__wrap {
                min-height: 40px;

                .text + .text {
                    margin-left: 0;
                }
            }
        }

        .button__close {
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 1;
            font-size: 0;

            &::before {
                @include useSvg-icons("close_layer", 24px);
                display: inline-block;
                content: "";
                filter: invert(1);
            }
        }
    }

    .icon__alert {
        @include useSvg-icons("exclm_mark_transparent-24", 24px);
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
    }

    .text {
        &__toast {
            color: $color-white;
            font-family: $gmarketSans;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }

        &__point {
            color: $color-yellow;
            font-weight: normal !important;
        }
    }
}
