.header {
    .box {
        &__header {
            display: flex;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 20;
            align-items: center;
            justify-content: space-between;
            min-width: 1280px;
            height: 60px;
            padding: 0 8px 0 12px;
            background: $color-gray-900;
            font-family: $gmarketSans;

            .link {
                display: inline-block;
                line-height: 27px;
                text-decoration: none;
                vertical-align: middle;
            }
        }

        &__service {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            padding: 0;
            border-bottom: 0;

            .link__logo {
                display: block;
                padding: 0 12px;
                color: $color-white;
                font-weight: 700;
                font-size: 18px;
                line-height: 60px;
                text-decoration: none;
            }

            .link {
                padding: 0 12px;
                color: $color-gray-500;
                font-size: 16px;
            }

            .icon__beta {
                @include useSvg-icons("icon_nav_beta", 32px);
                display: inline-block;
                margin: 0 -12px 0 2px;
            }
        }

        &__menu {
            display: flex;

            .link {
                padding: 0 16px;
                color: $color-white;
                font-size: 14px;
            }
        }
    }

    .list {
        display: flex;
        align-items: center;
        font-size: 0;

        &-item {
            position: relative;

            + .list-item {
                &::before {
                    display: inline-block;
                    content: "";
                    width: 1px;
                    height: 12px;
                    margin: -1px 0 1px;
                    background: $color-gray-700;
                    vertical-align: middle;
                }
            }

            &:hover {
                .layer__tooltip {
                    display: block;
                    top: 100%;
                }
            }

            &--info {
                .link {
                    padding: 0 12px;

                    &::after {
                        @include useSvg-icons("icon_chevron_right",16px);
                        display: inline-block;
                        content: "";
                        margin: -1px 0 1px 2px;
                        vertical-align: middle;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6184%) hue-rotate(223deg) brightness(129%) contrast(110%);
                    }

                    &__access {
                        margin-left: 12px;
                        color: inherit;
                        text-decoration: none;
                    }
                }

                .text {
                    &__info {
                        display: inline-block;
                        margin: -1px 16px 1px 0;
                        color: $color-gray-500;
                        font-family: $font-default;
                        font-size: 13px;
                        vertical-align: middle;
                    }

                    &__master {
                        margin-right: 4px;
                        padding: 2px 5px;
                        border: 1px solid $color-blue-500;
                        border-radius: 100px;
                        color: $color-blue-500;
                        font-size: 10px;
                    }

                    &__seller {
                        color: $color-gray-500;
                        font-size: 13px;
                    }
                }

                .icon__person {
                    &::before {
                        @include useSvg-icons("person", 16px);
                        display: inline-block;
                        content: "";
                        margin: -1px 4px 1px 0;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .button {
        &__logout {
            width: 61px;
            height: 28px;
            border-radius: 6px;
            background: $color-gray-800;
            color: $color-white;
            font-size: 12px;
        }

        &__bell {
            @include useSvg-icons("bell", 24px);
            position: relative;
            margin: -1px 16px 1px;
            vertical-align: middle;

            .text__num {
                position: absolute;
                top: -5px;
                left: 12px;
                min-width: 16px;
                padding: 0 4px;
                border-radius: 16px;
                background: $color-red-500;
                color: $color-white;
                font-family: $font-default;
                font-size: 9px;
                line-height: 16px;
            }
        }
    }
}
