.list__announcement {
    width: 100%;

    .list-item {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .link__text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        text-decoration: none;

        &::after {
            content: none;
        }
    }

    .text {
        display: flex;
        overflow: hidden;
        flex: 1;
        align-items: center;
        white-space: nowrap;

        &__ellipsis {
            @include ellipsis();
            display: inline-block;
            max-width: calc(100% - 24px);
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;
        }

        &__date {
            color: $color-gray-400;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
        }

        &__sort {
            min-width: 48px;
            color: $color-gray-600;
            font-size: 16px;
            text-align: center;
        }

        &__status {
            display: inline-flex;
            align-items: center;
            color: $color-gray-500;

            &::before {
                @include useSvg-icons("icon_arrow_inactive", 20px);
                content: "";
                margin-right: 4px;
            }

            &--prev {
                &::before {
                    transform: rotate(-90deg);
                }
            }

            &--next {
                &::before {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .icon__new {
        @include useSvg-icons("icon_nav_new", 20px);
        margin: 0 0 0 4px;
        background-color: transparent;
        vertical-align: middle;
    }
}

.box {
    &__announcement-wrap {
        border-top: 1px solid $color-gray-500;

        .list__announcement {
            .list-item {
                border-bottom: 1px solid $color-gray-300;

                &:hover {
                    background-color: $color-gray-50;
                }
            }

            .link__text {
                padding: 20px 16px;
            }
        }

        +.box__pagination {
            margin-top: 20px;
        }

        &.is-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 320px;
            border-bottom: 1px solid $color-gray-300;

            .text {
                color: $color-gray-500;
                font-size: 16px;
                text-align: center;
            }
        }
    }

    &__paging {
        .list__announcement {
            .list-item {
                + .list-item {
                    border-top: 1px solid $color-gray-300;
                }
            }

            .link__text {
                padding: 28px 32px;
            }
        }
    }
}

.announce {
    .list__announcement {
        .list-item {
            margin-bottom: 0;
        }

        .link__text {
            gap: 32px;
            font-size: 16px;
            line-height: 22px;
        }

        .text__ellipsis {
            font-size: 16px;
            line-height: 22px;
        }

        .text__date {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .box__panel-head {
        .list__announcement {
            .list-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 32px;
            }
        }
    }

    .box__view {
        margin-bottom: 28px;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-gray-300;
        color: $color-gray-800;
        font-size: 14px;
        line-height: 20px;

        img {
            width: auto;
            max-width: 100%;
        }

        .link__text {
            display: block;

            &--right {
                &::after {
                    @include useSvg-icons("arrow-right_half", 16px);
                }
            }
        }
    }
}
