.form {
    display: inline-block;
    position: relative;
    font-size: 0;

    &:last-of-type {
        .form__checkbox ~ .form__label,
        .form__radio ~ .form__label {
            margin-right: 0;
        }
    }

    &.is-active .form__file {
        color: $color-gray-800;
    }

    &:has(.form__checkbox) {
        & + .box__tooltip {
            margin-left: -4px;
        }
    }

    &:has(.form__radio ~ .box__tooltip) {
        .form__label {
            margin-right: 0;
        }
    }

    &__checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid $color-gray-400;
        border-radius: 4px;
        background: $color-white;
        vertical-align: middle;
        cursor: pointer;

        &::after {
            display: none;
            content: "";
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            background: $color-gray-50;
        }

        &:active {
            border: none;
            background: $color-blue-500;

            &::after {
                @include useSvg-icons("checked_active", 20px);
                display: block;
            }
        }

        &:checked {
            border: none;
            background: $color-blue-500;

            &::after {
                @include useSvg-icons("checked", 20px);
                display: block;
            }
        }

        &:disabled {
            background: $color-gray-100;
            cursor: default;
            pointer-events: none;

            &:checked {
                &::after {
                    @include useSvg-icons("checked_disabled", 20px);
                    display: block;
                }
            }

            ~ .form__label {
                color: $color-gray-500;
                pointer-events: none;

                .text--gmarket,
                .text--auction {
                    color: $color-gray-500 !important;
                }
            }
        }
    }

    &__radio {
        width: 20px;
        height: 20px;
        border: 1px solid $color-gray-400;
        border-radius: 50%;
        background: $color-white;
        vertical-align: middle;
        cursor: pointer;

        &::after {
            display: none;
            content: "";
            position: relative;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            background: $color-gray-50;
        }

        // &:active {
        //     border: 1px solid $color-blue-600;

        //     &::after {
        //         display: block;
        //         background: $color-blue-600;
        //     }
        // }

        &:checked {
            border: 1px solid $color-blue-500;

            &::after {
                display: block;
                background: $color-blue-500;
            }
        }

        &:disabled {
            border: 1px solid $color-gray-400;
            background: $color-gray-100;
            cursor: default;
            pointer-events: none;

            &:checked {
                &::after {
                    display: block;
                    background: $color-gray-400;
                }
            }

            ~ .form__label {
                color: $color-gray-500;
                pointer-events: none;
            }
        }

        + .box__calendar {
            margin-left: 8px;
        }
    }

    &__checkbox,
    &__radio {
        ~ .form__label {
            display: inline-block;
            position: relative;
            margin: 0 8px;
            color: $color-gray-800;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;
            cursor: $cursor-hover;
        }
    }

    &__toggle {
        position: relative;
        width: 38px;
        height: 20px;
        margin: 0;
        border-radius: 100px;
        background: $color-gray-300;
        vertical-align: top;
        cursor: pointer;
        appearance: none;

        &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $color-white;
            transition: left 250ms linear;
        }

        &:checked {
            background: $color-blue-500;

            &::before {
                left: 20px;
            }
        }

        &:disabled {
            cursor: $cursor-default;

            &::before {
                background: $color-gray-100;
            }

            &:checked {
                background: $color-gray-400;

                &::before {
                    background: $color-white;
                }
            }
        }
    }

    &--textarea {
        &.is-error {
            .form__inner {
                border-color: $color-red-500;
            }
        }

        &.is-active {
            .form__inner {
                border-color: $color-gray-900;
            }
        }

        .form__inner {
            overflow: hidden;
            height: 100%;
            padding-bottom: 31px;
            border: 1px solid $color-gray-400;
            border-radius: 6px;
            background: $color-white;

            &::after {
                content: "";
                position: absolute;
                bottom: 30px;
                left: 0;
                width: 100%;
                height: 1px;
                background: $color-gray-300;
            }
        }

        .form__textarea {
            min-width: 100%;
            max-width: 100%;
            height: 100%;
            padding: 11px 16px;
            border: 0;
            border-radius: 6px 6px 0 0;
            resize: none;

            &:disabled {
                opacity: 1;
                background: $color-gray-100;
                color: $color-gray-600;
                -webkit-text-fill-color: $color-gray-600;

                + .text__side {
                    color: $color-gray-400;
                }
            }
        }

        .text__side {
            bottom: 5px !important;
        }

        .text__information {
            position: absolute;
            bottom: 7px;
            left: 16px;
            font-size: 12px;
            line-height: 16px;

            &::before {
                top: 0;
            }
        }
    }

    &__textarea {
        min-width: 580px;
        max-width: calc(100% - 176px);
        height: 40px;
        min-height: 40px;
        padding: 9px 30px 9px 16px;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        color: $color-gray-800;
        outline: none;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        caret-color: $color-blue-500;

        &::placeholder {
            color: $color-gray-400;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        &:focus {
            border-color: $color-gray-900;

            &::placeholder {
                color: transparent;
            }
        }

        &.is-error {
            border-color: $color-red-500;

            + .text.text--red {
                display: block;
                margin: 2px 0 0  16px;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    &__file {
        display: inline-block;
        height: 40px;
        padding: 10px 16px;
        border: solid 1px $color-gray-400;
        border-radius: 6px;
        background: $color-white;
        color: $color-gray-400;
        font-size: 14px;
        vertical-align: middle;

        &-label {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 104px;
            height: 40px;
            border-radius: 6px;
            background: $color-blue-500;
            color: $color-white;
            font-size: 14px;
            vertical-align: middle;
            cursor: pointer;
        }

        &:disabled {
            background: $color-gray-100;
            color: $color-gray-400;
        }

        &::placeholder {
            color: $color-gray-400;
        }
    }

    &__file-image {
        position: relative;
        width: 48px;
        height: 48px;
        border: 1px dashed $color-gray-400;
        border-radius: 6px;

        &::before {
            @include useSvg-icons("plus", 24px);
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .form__file {
            opacity: 0;
            position: relative;
            z-index: 1;
            width: 48px;
            height: 48px;
        }
    }

    .text__side {
        position: absolute;
        right: 16px;
        bottom: 10px;
        font-size: 14px;
        line-height: 20px;

        .text__count {
            color: $color-gray-400;
        }
    }

    &--gmarket,
    &--auction {
        .form__label {
            margin-left: 16px;

            &::before {
                display: block;
                content: "";
            }
        }

        .text--red {
            margin: 2px 0 0 36px;
        }
    }

    &--gmarket {
        .form__label::before {
            @include useSvg-icons("bi_gmarket", 77px);
        }
    }

    &--auction {
        .form__label::before {
            @include useSvg-icons("bi_auction", 90px);
        }
    }

    &__multi {
        display: inline-block;
        position: relative;

        .form {
            &__input {
                width: 226px;
                height: 40px;
                padding: 0 90px 0 16px;
                border-color: $color-gray-400;
                font-size: 14px;
                line-height: 20px;

                &:focus,
                &:-webkit-autofill {
                    border-color: $color-gray-900;
                }
            }

            &__label {
                font-size: 14px;
                line-height: 20px;
            }

            &.is-error {
                margin-bottom: 18px;
            }

            .text--red {
                position: absolute;
            }
        }

        .box__dropdown {
            position: absolute;
            top: 0;
            right: 0;

            &.is-open {
                .button__opener {
                    border-color: $color-gray-900;
                    border-bottom-color: $color-gray-300;
                    background: $color-white;

                    &::before {
                        display: none;
                    }
                }
            }

            .button__opener {
                border-color: transparent;
                background: transparent;
                color: $color-gray-800;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 24px;
                    background: $color-gray-300;
                    transform: translateY(-50%);
                }
            }
        }

        &--search {
            .box__dropdown {
                right: auto;
                left: 0;
                z-index: 1;

                .button__opener::before {
                    right: 0;
                    left: auto;
                }
            }

            .form__input {
                width: 100%;
                padding: 0 90px 0 116px;
            }

            .form__label {
                left: 116px;
            }
        }
    }

    &--select {
        &.is-error {
            .form__select {
                border-color: $color-red-500;
            }
        }

        &.is-focus {
            .form__inner {
                &::after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

        &.is-disabled {
            .form__inner {
                &::after {opacity: .4;
                }
            }

            .form__select {
                background-color: $color-gray-100;
                color: $color-gray-400;
                pointer-events: none;
            }
        }

        .form__inner {
            &::after {
                @include useSvg-icons("icon_arrow_down-20", 20px);
                content: "";
                position: absolute;
                top: 50%;
                right: 15px;
                pointer-events: none;
                transform: translateY(-50%);
            }
        }
    }

    &__select {
        position: relative;
        width: auto;
        height: 40px;
        padding: 0 42px 0 16px;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        color: $color-gray-800;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
        appearance: none;
    }
}

.box__file {
    &-attach {
        flex: 1;
        max-width: calc(100% - 146px);

        .button {
            &__change {
                margin-left: 12px;
                cursor: $cursor-hover;
            }

            &--delete {
                padding: 0;
                border: 0;
                background-color: transparent;

                &::before {
                    @include useSvg-icons("icon_data-del-gray-16", 16px);
                    margin: 0;
                }
            }
        }

        .text {
            &__file-label {
                color: $color-gray-400;
            }

            &__file-name {
                @include ellipsis;
                flex: 1;
                width: 295px;
            }

            &__file-volume {
                min-width: 72px;
                color: $color-gray-500;
                text-align: right;
            }
        }

        &--52 {
            max-width: none;

            .box__file-item {
                height: 52px;
                padding: 16px 20px;
                border: 1px solid $color-gray-300;
                background-color: $color-gray-50;

                &:last-child {
                    margin-bottom: 16px;
                }
            }

            .box__no-content {
                margin-bottom: 16px;
                padding: 32px 0;
                border: 1px solid $color-gray-300;
                border-radius: 6px;
                background: $color-gray-50;

                .text__no-content {
                    font-family: $gmarketSans;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        margin-bottom: 12px;
        padding: 0 20px 0 16px;
        border: 1px solid $color-gray-400;
        border-radius: 6px;
        background-color: $color-gray-100;

        &:last-child {
            margin-bottom: 0;
        }

        &.is-error {
            border-color: $color-red-500;

            + .text--red {
                margin: -10px 0 0 16px;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    &-right {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-left: 24px;
    }

    &-upload {
        flex: 0 0 auto;

        .button__upload {
            width: 134px;
        }
    }
}

.form__chip {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 48px;

    &--square {
        min-width: 52px;

        .form__chip-chk {
            width: 32px;
            height: 32px;
            margin: 4px;
            border: none;
            border-radius: 4px;

            &::after {
                content: "";
                position: absolute;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                border: 1px solid $color-gray-300;
                border-radius: 6px;
            }

            &:checked {
                &::after {
                    border-color: $color-blue-500;
                }
            }

            &.ico-chk-white:checked {
                &::before {
                    @include useSvg-icons("ico_checked_white-20", 20px);
                }
            }

            &.ico-chk-blue:checked {
                &::before {
                    @include useSvg-icons("ico_checked_blue-20", 20px);
                }
            }
        }

        .form__chip-label {
            margin-top: 4px;
            font-size: 11px;
            line-height: 14px;
        }
    }

    &-chk {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 1px solid rgba($color-black, .25);
        border-radius: 50%;

        &:checked {
            border-color: $color-blue-500;

            &::before {
                display: block;
                content: "";
            }

            + .form__chip-label {
                color: $color-blue-500;
            }
        }

        &.ico-chk-white:checked {
            &::before {
                @include useSvg-icons("ico_checked_white-16", 16px);
            }
        }

        &.ico-chk-blue:checked {
            &::before {
                @include useSvg-icons("ico_checked_blue-16", 16px);
            }
        }
    }

    &-label {
        margin-top: 4px;
        color: $color-gray-600;
        font-size: 12px;
        line-height: 16px;
    }
}
