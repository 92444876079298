.footer {
    .box__footer {
        height: 256px;
        padding: 48px 0 0;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        .list__foot-info {
            display: flex;
            justify-content: center;
            margin-bottom: 4px;

            .list-item {
                position: relative;
                color: $color-gray-700;
                white-space: nowrap;

                + .list-item {
                    &::before {
                        display: inline-block;
                        content: "";
                        width: 1px;
                        height: 10px;
                        margin: -1px 8px 1px;
                        background: $color-gray-300;
                        vertical-align: middle;
                    }
                }

                &:hover {
                    .layer__tooltip {
                        display: block;
                        bottom: 100%;
                        margin: 0 0 0 6px;
                    }
                }
            }

            .button__text {
                color: $color-gray-700;
            }

            .link {
                display: inline-block;
                color: $color-gray-800;
                text-decoration: none;
            }
        }

        .box__foot-info {
            margin: 8px 0 35px;

            .list__foot-info {
                margin-bottom: 16px;

                .link {
                    padding: 8px 0;
                }
            }

            .text__foot-dec {
                display: inline-block;
                color: $color-gray-500;
            }

            .button__text {
                color: $color-gray-500;
            }
        }

        .text__copyright {
            color: $color-gray-500;

            .text__inner {
                color: $color-gray-800;
            }

            .icon__logo {
                @include useSvg-icons("logo_footer", 78px);
                display: inline-block;
                margin: -3px 24px 3px 0;
                vertical-align: middle;
            }
        }
    }
}
