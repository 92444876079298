.box__help-wrap {
    height: 100%;
    background: #fff;

    .box__layer-head {
        padding: 0 24px 16px;
        border-bottom: 0;
        background-color: $color-blue-500;
    }

    .box__layer-content {
        max-height: calc(100% - 128px - 64px);
        padding: 0;
    }

    .text__layer-title {
        padding: 20px 0;
        color: $color-white;
        font-family: $gmarketSans;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }

    .title {
        color: $color-gray-900;
        font-family: $font-default;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }

    .box {
        &__help {
            padding: 4px 20px 4px 24px;
        }

        &__detail-content {
            padding-top: 20px;

            .title {
                margin-bottom: 32px;
                color: $color-gray-800;
                font-size: 22px;
                line-height: 32px;
            }

            .text {
                margin-bottom: 16px;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;

                &__desc {
                    margin-bottom: 16px;
                    color: $color-gray-600;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        &__image {
            position: relative;
            min-height: 38px;

            + .box__link {
                margin-top: 16px;
            }
        }

        &__link {
            margin-bottom: 12px;

            .link__text {
                display: inline-block;

                &--button {
                    margin-bottom: 4px;
                }
            }
        }

        &__search {
            &-keyword {
                .list-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    padding: 0 16px;
                    border-top: 1px solid $color-gray-300;

                    &:hover {
                        background-color: $color-gray-50;
                    }
                }

                .text {
                    color: $color-gray-400;
                    font-size: 12px;
                    line-height: 16px;
                    white-space: nowrap;
                }

                .button__option {
                    @include ellipsis;
                    width: auto;
                    max-width: 265px;
                    padding: 0;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            &-content {
                padding-top: 20px;
            }

            &-category {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .link {
                    display: inline-block;
                    height: 36px;
                    padding: 8px 16px;
                    border-radius: 4px;
                    background-color: $color-gray-100;
                    color: $color-gray-900;
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: none;
                }
            }

            &-data {
                position: relative;
                margin-top: 32px;
            }

            &-result {
                padding-top: 20px;

                .title {
                    margin-bottom: 20px;
                }

                .box__no-content {
                    height: 500px;

                    .text {
                        margin-bottom: 20px;
                        font-size: 20px;
                        line-height: 28px;
                        text-align: center;

                        &__ellipsis {
                            @include ellipsis;
                            display: inline-block;
                            max-width: 330px;
                            margin: -1px 0 1px;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .form__input {
                font-size: 16px;

                ~.form__label {
                    font-size: 16px;
                }
            }

            .button__search {
                padding: 11px 16px;
            }

            .box__search-keyword {
                top: 47px;
            }
        }

        &__no-content {
            height: 268px;

            &::before {
                display: none;
            }

            .text__no-content {
                color: $color-gray-400;
            }
        }
    }

    .list {
        &__question {
            > .list-item {
                position: relative;
                border-bottom: 1px solid $color-gray-300;

                &:last-child {
                    border-bottom: 0;
                }

                &.is-open {
                    .button__opener {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }

                    .list__content {
                        display: block;
                    }

                    .box__board-spinner {
                        display: block;
                    }
                }
            }

            .box__board-spinner {
                display: none;
                height: 140px;

                .box__loader-circle {
                    top: 30px;
                }
            }
        }

        &__content {
            display: none;
            padding-bottom: 20px;
        }

        &__search-result {
            .list-item {
                border-top: 1px solid $color-gray-300;
            }

            .link__result {
                padding: 20px 0;
            }
        }

        &__search-data {
            .list-item {
                display: flex;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .text {
                flex: 1;
                padding: 0 5px 0 12px;
                color: $color-gray-900;
                font-size: 16px;
                line-height: 22px;

                &__question {
                    padding: 1px 0 0;
                    color: $color-gray-500;
                    font-size: 14px;
                }

                .link__text {
                    color: $color-gray-900;
                    font-size: 16px;
                    text-align: left;
                    word-break: break-all;
                }
            }
        }
    }

    .button {
        &__back {
            top: 16px;
            left: 24px;

            &::before {
                @include useSvg-icons("icon_prev-white", 32px);
            }
        }

        &__close {
            position: absolute;
            top: 16px;
            right: 24px;
            font-size: 0;

            &::before {
                @include useSvg-icons("icon_close-white", 32px);
                display: inline-block;
                content: "";
            }
        }

        &__search {
            &::before {
                @include useSvg-icons("icon_search-gray", 24px);
            }
        }

        &__image-search {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 6px !important;
            background-color: rgba(0, 0, 0, .3);

            &::after {
                @include useSvg-icons("icon_search-gray", 24px);
                display: block;
                content: "";
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(16%) hue-rotate(324deg) brightness(104%) contrast(100%);
            }
        }

        &__delete {
            width: 24px;
            background-color: $color-white;

            &--all {
                position: absolute;
                top: 2px;
                right: 0;
                color: $color-gray-500;
                font-size: 14px;
                line-height: 20px;
            }

            &--small {
                width: 22px;
                height: 22px;
                margin-left: -3px;
                padding: 3px;

                &::after {
                    @include useSvg-icons("icon_delete-16", 16px);
                    display: block;
                    content: "";
                }
            }
        }

        &__opener {
            @include ellipsis;
            width: calc(100% - 30px);
            color: $color-gray-900;
            font-weight: 600;
            font-size: 18px;
            line-height: 64px;
            text-align: left;

            &::after {
                @include useSvg-icons("icon_arrow_down-24", 24px);
                content: "";
                position: absolute;
                top: 20px;
                right: 0;
            }
        }
    }

    .link {
        &__result {
            display: block;
            padding: 11px 0;
            color: $color-gray-900;
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
        }

        &__text--button {
            position: relative;
            height: 40px;
            padding: 0 16px;
            border-radius: 6px;
            background-color: $color-blue-500;
            color: $color-white;
            font-size: 14px;
            line-height: 40px;

            &::after {
                margin: -1px 0 1px 2px;
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(16%) hue-rotate(324deg) brightness(104%) contrast(100%);
            }
        }
    }
}
