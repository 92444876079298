body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
th,
td,
form,
fieldset,
legend,
textarea,
input,
select,
button,
article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

article,
aside,
dialog,
footer,
header,
section,
footer,
menu,
nav,
figure,
main {
    display: block;
}

html,
body {
    height: 100%;
    text-size-adjust: none;
}

img,
form,
fieldset,
svg,
li {
    border: 0;
    vertical-align: top;
}

ul,
ol {
    list-style: none;
}

button {
    overflow: visible;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    touch-action: manipulation;
}

button:disabled {
    cursor: default;
}

button::-moz-focus-inner {
    margin: -1px;
    padding: 0;
}

input,
button,
select {
    border-radius: 0;
    appearance: none;
    appearance: none;
    appearance: none;
}

input::-webkit-contacts-auto-fill-button, //safari에서만 발생하는 오류
input::-webkit-credentials-auto-fill-button {
    display: none !important;
    visibility: hidden;
    position: absolute;
    right: 0;
    pointer-events: none;
}

address,
caption,
em {
    font-style: normal;
}


em,
address {
    font-style: normal;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

img {
    width: 100%;
}
