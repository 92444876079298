.box__chart {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    width: 735px;
    height: 385px;
    padding: 48px 0 25px;

    .box__no-content {
        height: 315px;
    }

    .box__board {
        border: 0;
    }
}

.list__legend {
    display: flex;
    justify-content: center;
    font-size: 0;
}

.list-item__legend {
    display: inline-block;
    margin-right: 22px;
    color: $color-gray-700;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &:last-child {
        margin-right: 0;
    }

    &::before {
        display: inline-block;
        content: "";
        margin-right: 6px;
    }

    &--dash { // blue
        &::before {
            @include useSvg-icons("legend_dash", 22px);

        }
    }

    &--dash-green {
        &::before {
            @include useSvg-icons("legend_dash", 22px);
            filter: brightness(0) saturate(100%) invert(42%) sepia(94%) saturate(1116%) hue-rotate(86deg) brightness(100%) contrast(117%);
        }
    }

    &--dash-red {
        &::before {
            @include useSvg-icons("legend_dash", 22px);
            filter: brightness(0) saturate(100%) invert(36%) sepia(98%) saturate(5590%) hue-rotate(350deg) brightness(105%) contrast(88%);
        }
    }

    &--line-green {
        &::before {
            @include useSvg-icons("legend_line", 22px);
            height: 5px;
        }
    }

    &--blue {
        &::before {
            @include useSvg-icons("legend_blue", 22px);
        }
    }

    &--gray {
        &::before {
            @include useSvg-icons("legend_gray", 22px);
        }
    }

    &--mint {
        &::before {
            @include useSvg-icons("legend_mint", 22px);
        }
    }

    &--purple {
        &::before {
            @include useSvg-icons("legend_purple", 22px);
        }
    }

    &--gray-dot {
        &::before {
            @include useSvg-icons("legend_gray-dot", 22px);
            position: relative;
            top: -1px;
        }
    }

    &--bar-blue {
        &::before {
            @include useSvg-icons("legend_bar_blue", 22px);
            position: relative;
            top: 2px;
        }
    }

    &--bar-blue2 {
        &::before {
            @include useSvg-icons("legend_bar_blue2", 22px);
            position: relative;
            top: 2px;
        }
    }

    &--bar-gray {
        &::before {
            @include useSvg-icons("legend_bar_gray", 22px);
            position: relative;
            top: 2px;
        }
    }

    &--bar-mint {
        &::before {
            @include useSvg-icons("legend_bar_mint", 22px);
            position: relative;
            top: 2px;
        }
    }

    &--bar-purple {
        &::before {
            @include useSvg-icons("legend_bar_purple", 22px);
            position: relative;
            top: 2px;
        }
    }

    &--bar-red {
        &::before {
            @include useSvg-icons("legend_bar_red", 22px);
            position: relative;
            top: 2px;
        }
    }

    &--superdeal {
        &::before {
            @include useSvg-icons("legend_bar_superdeal", 44px);
            vertical-align: top;
        }
    }

    &--allkill {
        &::before {
            @include useSvg-icons("legend_bar_allkill", 36px);
            vertical-align: top;
        }
    }
}

.box__layer-chart,
.box__graph {
    .box__tooltip-bubble {
        visibility: hidden;
        opacity: 0;
        padding: 8px 12px;
        border: 1px solid $color-gray-900;
        border-radius: 6px !important;
        background: $color-white;

        &::before {
            @include useSvg-icons("tooltip_triangle_small", 9px);
            bottom: -5px;
            left: 14px;
        }

        &--br {
            &::before {
                right: 14px;
                left: auto;
            }
        }

        &--bc {
            &::before {
                left: 50%;
                margin-left: -4.5px;
            }
        }

        &--tl {
            margin-top: 5px;

            &::before {
                top: -5px;
                bottom: auto;
                transform: rotate(180deg);
            }
        }

        &--tr {
            margin-top: 5px;

            &::before {
                top: -5px;
                right: 14px;
                bottom: auto;
                left: auto;
                transform: rotate(180deg);
            }
        }

        &--tc {
            margin-top: 5px;

            &::before {
                top: -5px;
                bottom: auto;
                left: 50%;
                margin-left: -4.5px;
                transform: rotate(180deg);
            }
        }

        &--superdeal,
        &--allkill {
            height: 27px;
            padding: 0 !important;
            border: 0 !important;
            background: none !important;
            box-shadow: none;

            &::before {
                display: none !important;
            }

            &::after {
                display: block;
                content: "";
            }
        }

        &--superdeal {
            width: 50px;

            &::after {
                @include useSvg-icons("icon_superdeal", 50px);
            }
        }

        &--allkill {
            width: 40px;

            &::after {
                @include useSvg-icons("icon_allkill", 40px);
            }
        }

        .text__tooltip {
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-gap: 0 8px;
            font-size: 12px;
            line-height: 16px;

            &-title {
                display: inline-block;
                color: $color-gray-600;
                font-weight: 400;
                text-align: left;
            }

            &-price {
                color: $color-gray-800;
                font-weight: 600;
                text-align: right;
            }

            &-date {
                display: block;
                margin-bottom: 2px;
                color: $color-gray-600;
                font-family: $gmarketSans;
                font-size: 10px;
                line-height: 12px;
                text-align: left;
            }
        }
    }
}
