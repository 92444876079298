@use "sass:math";

$pc-vars: (
	'bank_bankofamerica': (
		offset-x: 0px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_bookook': (
		offset-x: -52px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_busan': (
		offset-x: 0px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_capefn': (
		offset-x: -52px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_citi': (
		offset-x: -104px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_cu': (
		offset-x: -104px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_daishin': (
		offset-x: 0px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_dbfi': (
		offset-x: -52px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_deutsche': (
		offset-x: -104px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_dgb': (
		offset-x: -156px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_ebestsec': (
		offset-x: -156px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_epost': (
		offset-x: -156px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_eugenefn': (
		offset-x: 0px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_fsb': (
		offset-x: -52px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_hanwhawm': (
		offset-x: -104px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_hiib': (
		offset-x: -156px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_hmsec': (
		offset-x: -208px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_hsbc': (
		offset-x: -208px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_ibk': (
		offset-x: -208px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_icbc': (
		offset-x: -208px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_imeritz': (
		offset-x: 0px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_iprovest': (
		offset-x: -52px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_jb': (
		offset-x: -104px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_jeju': (
		offset-x: -156px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_jp': (
		offset-x: -208px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_k': (
		offset-x: -260px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_kakao': (
		offset-x: -260px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_kb': (
		offset-x: -260px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_kdb': (
		offset-x: -260px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_keb': (
		offset-x: -260px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_kiwoom': (
		offset-x: 0px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_kj': (
		offset-x: -52px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_kn': (
		offset-x: -104px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_koreainvestment': (
		offset-x: -156px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_mg': (
		offset-x: -208px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_miraeasset': (
		offset-x: -260px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_myasset': (
		offset-x: -312px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_nfcf': (
		offset-x: -312px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_nh': (
		offset-x: -312px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_samsung': (
		offset-x: -312px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_sbisb': (
		offset-x: -312px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_sc': (
		offset-x: -312px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_shin': (
		offset-x: 0px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_shinyoung': (
		offset-x: -52px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_sks': (
		offset-x: -104px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_suhyup': (
		offset-x: -156px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_toss': (
		offset-x: -208px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
	'bank_woori': (
		offset-x: -260px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 360px,
		total-height: 360px,
		imageSrc: '../img/pc.png'
	),
);
$pc-origin: (
  total-width: 360px,
  total-height: 360px,
  imageSrc: '../img/pc.png'
);

@mixin sprite-pc-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-pc-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-pc-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-pc {
  @include sprite-pc-size($pc-origin);
  @include sprite-pc-image($pc-origin);
}

@mixin sp-pc($image, $size: true) {
  @include set-pc;
	background-position: math.div(map-get(map-get($pc-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($pc-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($pc-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($pc-vars, $image), 'height'), 2);
	}
}
